<template>
  <div class="wrapper">
    <div class="flex">
      <div class="position-relative">
        <a-image :preview="false" :height="251" :width="320" :src="require('@/assets/new/user/leftback.png')"/>
        <div class="flex align-center flex-direction position-absolute" style="top: 0;left: 0;width: 100%;height: 100%;">
          <a-avatar class="mt-5" :size="112" :src="userInfo.avatar" size="small" @on-error="Avatar">
            <template #icon>
              <a-image :src="require('@/assets/images/default.png')" :preview="false"/>
            </template>
          </a-avatar>
          <div class="text-white mt-3 mb-5">{{ userInfo.nickname }}</div>
          <a-space>
            <div class="user-invitecode cu-center cursor-pointer"
                 @click="() =>{ $Copy({text: userInfo.invitationCode})}">ID:{{ userInfo.invitationCode }}
            </div>
            <div class="user-invitecode cu-center cursor-pointer" @click="outLogin">退出登录</div>
          </a-space>
        </div>
      </div>
      <a-card class="ml-5" style="flex: 1;height: 251px;" :body-style="{padding: '30px 98px 34px 67px' }" title="我的钱包">
        <a-row :gutter="[20,24]">
          <a-col :span="6">
            <div class="cu-center-first-col">
              <div class="amount-usdt">{{ statistics.nowMoney ? statistics.nowMoney : '0.00' }}</div>
              <div class="amount-info">可用余额(USDT)</div>
            </div>
          </a-col>
          <a-col :span="6">
            <div class="cu-center-first-col">
              <div class="amount-usdt">{{ statistics.recharge ? statistics.recharge : '0.00' }}</div>
              <div class="amount-info">余额充值(USDT)</div>
              <div class="rechange cu-center cursor-pointer mt-3" @click="toWallet(1)">
                充值
              </div>
            </div>
          </a-col>
          <a-col :span="6">
            <div class="cu-center-first-col">
              <div class="amount-usdt">{{ statistics.monetary ? statistics.monetary : '0.00' }}</div>
              <div class="amount-info">余额消费(USDT)</div>
            </div>
          </a-col>
          <a-col :span="6">
            <div class="cu-center-first-col">
              <div class="amount-usdt">{{ statistics.withdraw ? statistics.withdraw : '0.00' }}</div>
              <div class="amount-info">累计已提(USDT)</div>
              <div class="withdraw cu-center cursor-pointer mt-3" @click="toWallet(2)">
                提现
              </div>
            </div>
          </a-col>
        </a-row>
      </a-card>
    </div>
    <!--    海报-->
    <div class="mt-5 cursor-pointer">
      <a-image :preview="false" :src="require('@/assets/new/user/index_banner.png')" @click="$router.push({path: '/Share'})"></a-image>
    </div>
    <!--    订单-->
    <a-card class="list-users mt-5" :bodyStyle="{padding: '10px 20px 0 20px'}">
      <template #title>
        我的订单
      </template>
      <template #extra>
        <div class="text-blue text-button cursor-pointer"
             @click="() =>{$router.push({path:'/MyOrder'})}">
          全部订单 >
        </div>
      </template>
      <div class="card-content">
        <Grid :col="5" :border="false">
          <GridItem v-for="(item, index) in orderItem" :key="index">
            <div class="text-center text-h5 font-weight-bold cursor-pointer" @click="toOrder(item)">{{ item.num }}</div>
            <div class="text-center mt-3 cursor-pointer" @click="toOrder(item)">{{ item.name }}</div>
          </GridItem>
        </Grid>
      </div>
      <div class="mt-3 mb-10">
        <List :loading="orderLoading">
          <ListItem class="mt-4 mb-4" style="border-bottom: 1px solid #E5E5E5;" v-for="(item,index) in orderList" :key="index">
            <ListItemMeta v-for="(items,indexs) in item.orderInfoList" :key="indexs" class="position-relative">
              <template #avatar>
                <div class="cursor-pointer"
                     @click="() => {$router.push({path:'/Product', query:{ id: items.productId}})}">
                  <a-image :preview="false" :width="100" :height="100" :src="items.image"
                           :fallback="require('@/assets/index/logo.png')"></a-image>
                </div>
              </template>
              <template #title>
                <div class="cursor-pointer"
                     @click="() => {$router.push({path:'/Product', query:{ id: items.productId }})}">
                  <span class="font-weight-bold text-lg">{{ items.productName }}</span> <span class="">x {{ items.payNum }}</span>
                </div>
              </template>
              <template #description>
                <div style="color: #999999;">{{ items.sku }}</div>
                <div class="position-absolute font-weight-bold text-lg" style="bottom: 0;color: #F97700;">{{ item.payPrice }}
                  USDT
                </div>
              </template>
            </ListItemMeta>
            <template #action>
              <li>
                <div :style="'color: ' + getColor(item.status)" class="text-right text-lg">{{
                    orderStatusFilter(item.status)
                  }}
                </div>
                <div class="mt-6 view-order cursor-pointer text-lg"
                     @click="() => {$router.push({path:'/OrderDetails', query:{orderNo: item.orderNo}})}">查看订单
                </div>
              </li>
            </template>
          </ListItem>
        </List>
      </div>
    </a-card>
  </div>
</template>

<script>
import { getOrderList, orderNum } from '@/api/order.js'
import { orderStatusFilter } from '@/filter/commFilter'
import { getMyAccountApi } from '@/api/user'

export default {
  name: 'Profile',
  data() {
    return {
      userInfo: {},
      orderItem: [{
        name: '待付款',
        status: 0
      }, {
        name: '待发货',
        status: 1
      }, {
        name: '待收货',
        status: 4
      }, {
        name: '待评价',
        status: -10
      }, {
        name: '售后/退款',
        status: -11
      }],
      orderLoading: false,
      orderList: [],
      statistics: {},
    }
  },
  mounted() {
    let user = localStorage.getItem('USER_INFO')
    if (user) {
      this.userInfo = JSON.parse(user)
    }
    this.getUserInfo()
  },
  created() {
    this.userBalance()
    this.getOrderData()
    this.getOrderList()
  },
  methods: {
    toWallet(event) {
      this.$router.push({ path: '/MyWallet' })
    },
    userBalance() {
      getMyAccountApi()
          .then(res => {
            this.statistics = res.data
          })
    },
    toOrder(item) {
      this.$router.push({
        path: '/MyOrder',
        query: {
          status: item.status,
        }
      })
    },
    getColor(status) {
      const statusMap = {
        0: '#269FFA',
        1: '#269FFA',
        4: '#FFAB1B',
        5: '#FFAB1B',
        6: '#999999',
        9: '#999999'
      }
      return statusMap[status]
    },
    orderStatusFilter,
    /**
     * 获取订单列表
     */
    getOrderList: function () {
      let that = this
      that.orderLoading = true
      getOrderList({
        status: -1,
        page: 1,
        limit: 8,
      })
          .then(res => {
            let list = res.data.list || []
            that.orderList = that.SplitArray(list, that.orderList)
            this.orderLoading = false
          })
    },
    SplitArray(list, sp) {
      if (typeof list != 'object') return []
      if (sp === undefined) sp = []
      for (var i = 0; i < list.length; i++) {
        sp.push(list[i])
      }
      return sp
    },
    getOrderData() {
      let that = this
      orderNum()
          .then(res => {
            that.orderItem.forEach((item, index) => {
              switch (item.name) {
                case '待付款':
                  item.num = res.data.awaitPayCount
                  break
                case '待发货':
                  item.num = res.data.awaitShippedCount
                  break
                case '待收货':
                  item.num = res.data.receiptCount
                  break
                case '待评价':
                  item.num = res.data.awaitReplyCount
                  break
                default:
                  item.num = res.data.refundCount
              }
            })
          })
    },
    /**
     * 退出登录
     */
    outLogin() {
      this.$store.dispatch('GETLOGOUT')
    },
    Avatar() {
      this.userInfo.avatar = require('@/assets/images/default.png')
    },
    getUserInfo() {
      this.$store.dispatch('USERINFO')
          .then(res => {
            this.userInfo = res
            this.avatar = this.userInfo.avatar
            this.nickname = this.userInfo.nickname
          })
    },
  }

}
</script>

<style scoped lang="scss">
.rechange {
  width: 72px;
  height: 32px;
  border-radius: 16px;
  border: 1px solid #3BB686;

  font-weight: 400;
  font-size: 16px;
  color: #3BB686;
  line-height: 34px;
}

.withdraw {
  width: 72px;
  height: 32px;
  border-radius: 16px;
  border: 1px solid #FB3A5C;

  font-weight: 400;
  font-size: 16px;
  color: #FB3A5C;
  line-height: 34px;
}

.amount-usdt {
  font-weight: bold;
  font-size: 28px;
  color: #000000;
  line-height: 34px;
}

.amount-info {
  margin-top: 10px;
  font-weight: 400;
  font-size: 16px;
  color: #000000;
  line-height: 34px;
}

.view-order:hover {
  color: #FFFFFF;
  background-color: #3A69FB;
}

.view-order {
  width: 104px;
  height: 38px;
  border-radius: 19px;
  border: 1px solid #E8E8E8;

  font-size: 16px;
  color: #999999;

  display: flex;
  justify-content: center;
  align-items: center;
}

.user-invitecode {
  width: 106px;
  height: 28px;
  background: rgba(19, 192, 147, 0);
  border-radius: 16px;
  border: 1px solid #FFFFFF;

  font-weight: 400;
  font-size: 16px;
  color: #FFFFFF;
  line-height: 60px;
}

.wrapper {
  width: 100%;

  .cart-users {
    width: 100%;
    height: 251px;
    border-radius: 12px;
    opacity: 1;
  }

  .list-users {
    width: 100%;
    border-radius: 12px;
    opacity: 1;
  }
}
</style>
