<template>
  <div class="bg-banner-img"></div>
  <div class="page-container-all-rank width_1200_auto">
    <div class="page-join-content">
      <div style="width:100%;margin-bottom: 25px">
        <a-row :gutter="[16, 24]">
          <a-col :span="8" class="cu-center" style="display: flex;flex-direction: column;">
            <div class="top-image-2">
              <div class="position-relative">
                <a-image :preview="false" :width="148" :height="172"
                         class="top-image border-image"
                         :src="require('@/assets/new/share/two.png')"/>
                <div class="position-absolute" style="top: 32px;left: 8px;">
                  <a-image class="border-image" :preview="false" style="border-radius: 50%;border: 2px solid #fff;"
                           :width="132" :height="132"
                           :src="Two.avatar"
                           :fallback="require('@/assets/my/logo.png')"/>
                </div>
              </div>
              <div class="text-white mt-2 cu-center text-lg">{{ Two.nickname }}</div>
              <div class="top-money-2">{{ Two.brokeragePrice }} USDT</div>
            </div>
          </a-col>
          <a-col :span="8" class="cu-center" style="display: flex;flex-direction: column;">
            <div class="position-relative">
              <a-image :preview="false" :width="182" :height="208"
                       class="top-image border-image"
                       :src="require('@/assets/new/share/one.png')"/>
              <div class="position-absolute" style="top: 35px;left: 10px;">
                <a-image :preview="false" style="border-radius: 50%;" :width="163.1" :height="163.1" :src="One.avatar"
                         :fallback="require('@/assets/my/logo.png')"/>
              </div>
            </div>
            <div class="text-white mt-2 cu-center text-lg">{{ One.nickname }}</div>
            <div class="top-money-1">{{ One.brokeragePrice }} USDT</div>
          </a-col>
          <a-col :span="8" class="cu-center" style="display: flex;flex-direction: column;">
            <div class="top-image-3">
              <div class="position-relative">
                <a-image :preview="false" :width="150" :height="173"
                         class="top-image border-image"
                         :src="require('@/assets/new/share/three.png')"/>
                <div class="position-absolute" style="top: 32px;left: 10px;">
                  <a-image class="border-image" :preview="false" style="border-radius: 50%;border: 2px solid #fff;"
                           :width="132" :height="132" :src="Three.avatar"
                           :fallback="require('@/assets/my/logo.png')"/>
                </div>
              </div>
              <div class="text-white mt-2 cu-center text-lg">{{ Three.nickname }}</div>
              <div class="top-money-3">{{ Three.brokeragePrice }} USDT</div>
            </div>
          </a-col>
        </a-row>
      </div>

      <div class="bg-image">
      </div>
    </div>
    <div class="page-btns">
      <a-card :bodyStyle="{padding: '0'}">
        <a-table :pagination="false" :columns="columns" :loading="loading"
                 :dataSource="listData">
          <template #bodyCell="{ column, record, index }">
            <template v-if="column.key === 'rownum'">
              <div v-if="showMy" class="text-bold">
                <a-image v-if="(record.rownum ? record.rownum : (record.rownum ? index + 2 : index + 3)) === 4"
                         :preview="false" :width="38" :height="31" :src="require('@/assets/new/share/4.png')"/>
                <a-image v-if="(record.rownum ? record.rownum : (record.rownum ? index + 2 : index + 3)) === 5"
                         :preview="false" :width="38" :height="31" :src="require('@/assets/new/share/5.png')"/>
                <a-image v-if="(record.rownum ? record.rownum : (record.rownum ? index + 2 : index + 3)) === 6"
                         :preview="false" :width="38" :height="31" :src="require('@/assets/new/share/6.png')"/>
                <a-image v-if="(record.rownum ? record.rownum : (record.rownum ? index + 2 : index + 3)) === 7"
                         :preview="false" :width="38" :height="31" :src="require('@/assets/new/share/7.png')"/>
                <a-image v-if="(record.rownum ? record.rownum : (record.rownum ? index + 2 : index + 3)) === 8"
                         :preview="false" :width="38" :height="31" :src="require('@/assets/new/share/8.png')"/>
                <a-image v-if="(record.rownum ? record.rownum : (record.rownum ? index + 2 : index + 3)) === 9"
                         :preview="false" :width="38" :height="31" :src="require('@/assets/new/share/9.png')"/>
                <a-image v-if="(record.rownum ? record.rownum : (record.rownum ? index + 2 : index + 3)) === 10"
                         :preview="false" :width="38" :height="31" :src="require('@/assets/new/share/10.png')"/>
                <a-image v-if="(record.rownum ? record.rownum : (record.rownum ? index + 2 : index + 3)) === 99"
                         :preview="false" :width="38" :height="31" :src="require('@/assets/new/share/99.png')"/>
              </div>
              <div v-if="!showMy" class="text-bold">
                <a-image v-if="(index + 4) === 4" :preview="false" :width="38" :height="31"
                         :src="require('@/assets/new/share/4.png')"/>
                <a-image v-if="(index + 4) === 5" :preview="false" :width="38" :height="31"
                         :src="require('@/assets/new/share/5.png')"/>
                <a-image v-if="(index + 4) === 6" :preview="false" :width="38" :height="31"
                         :src="require('@/assets/new/share/6.png')"/>
                <a-image v-if="(index + 4) === 7" :preview="false" :width="38" :height="31"
                         :src="require('@/assets/new/share/7.png')"/>
                <a-image v-if="(index + 4) === 8" :preview="false" :width="38" :height="31"
                         :src="require('@/assets/new/share/8.png')"/>
                <a-image v-if="(index + 4) === 9" :preview="false" :width="38" :height="31"
                         :src="require('@/assets/new/share/9.png')"/>
                <a-image v-if="(index + 4) === 10" :preview="false" :width="38" :height="31"
                         :src="require('@/assets/new/share/10.png')"/>
                <a-image v-if="(index + 4) === 99" :preview="false" :width="38" :height="31"
                         :src="require('@/assets/new/share/99.png')"/>
              </div>
            </template>
            <template v-if="column.key === 'avatar'">
              <div class="cu-center-first-row">
                <a-image class="border-image" :preview="false" style="border-radius: 50%;border: 2px solid #fff;"
                         :width="48" :height="48"
                         :src="record.avatar"
                         :fallback="require('@/assets/my/logo.png')"/>
                <div class="text-bold ml-2">{{ record.nickname }}</div>
              </div>
            </template>
            <template v-if="column.key === 'brokeragePrice'">
              <div class="text-bold text-red">
                {{ record.brokeragePrice <= 0 ? '0.00' : record.brokeragePrice }} USDT
              </div>
            </template>
          </template>
        </a-table>
      </a-card>
    </div>
  </div>
</template>
<script>
import { myRankData, rankDataList, } from '@/api/tg.js'

export default {
  data() {
    return {
      visible2: false,
      promotionInfo: {},
      bgColor: '#e93323',
      invitationCode: '',
      spreadDataInfo: {},
      showBotm: false,
      urlPre: '',
      loading: false,
      listData: [],
      myRank: {},
      showMy: false,
      columns: [
        {
          title: '排名',
          dataIndex: 'rownum',
          key: 'rownum',
          align: 'center',
        },
        {
          title: '用户',
          dataIndex: 'avatar',
          key: 'avatar',
          align: 'center',
          width: '300px',
        },
        {
          title: '佣金总额',
          dataIndex: 'brokeragePrice',
          key: 'brokeragePrice',
          align: 'center',
        }
      ],
      One: {},
      Two: {},
      Three: {},
    }
  },
  created() {
    if (this.$store.getters.token) {
      this.getRanklist()
    } else {
      this.$router.push({
        path: '/',
        query: {}
      })
    }
  },
  methods: {
    getRanklist: function () {
      let that = this
      that.loading = true
      this.getMyRankData()
      this.listData = []
      setTimeout(() => {
        rankDataList()
            .then(res => {
              this.list = res.data
              if (this.list && this.list.length > 0) {
                this.list.forEach(item => {
                  if (item.id === this.$store.getters.uid) {
                    item.nickname = '我'
                  }
                })
              }
              that.One = this.list[0] || {}
              that.Two = this.list[1] || {}
              that.Three = this.list[2] || {}
              let listFilter = that.list.filter((v, i) => {
                return i > 2
              })
              if (that.myRank && that.myRank.rownum > 10) {
                that.showMy = true
                that.listData[0] = that.myRank
                listFilter.forEach((item, index) => {
                  that.listData.push(item)
                })
              } else {
                that.listData = listFilter
              }
              that.loading = false
            })
      }, 300)
    },
    getMyRankData: function () {
      myRankData()
          .then(res => {
            this.myRank = res.data
            this.myRank.nickname = '我'
          })
    },
  }
}
</script>

<style scoped lang="scss">
.border-image {
  border: 2px solid #fff !important;
}

.top-image-2 {
  margin-bottom: -160px;

  .top-money-2 {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;

    height: 35px;
    transform: rotate(0deg);
    border-radius: 50px;
    background: rgba(22, 22, 22, 0.5);
    color: #fff;
  }
}

.top-image-3 {
  margin-bottom: -250px;

  .top-money-3 {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;

    height: 35px;
    transform: rotate(0deg);
    border-radius: 50px;
    background: rgba(22, 22, 22, 0.5);
    color: #fff;
  }
}

.top-money-1 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;

  width: 222px;
  height: 35px;
  transform: rotate(0deg);
  border-radius: 50px;
  background: rgba(22, 22, 22, 0.5);
  color: #fff;
}


.bg-banner-img {
  position: fixed;
  width: 1920px;
  height: 748px;
  background: url('@/assets/new/share/rankbanner.png') repeat-y;
  background-size: cover;
  box-sizing: border-box;
}

.page-container-all-rank {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: flex-start;
  flex-direction: column;
  flex-wrap: nowrap;

  .page-btns {
    margin-top: -25px;
    width: 1600px;
  }

  .page-join-content {
    margin-top: 39px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-wrap: nowrap;

    .bg-image {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      width: 888px;
      height: 235px;
      background: url('@/assets/new/share/dizuo.png') repeat-y;
      background-size: cover;
      box-sizing: border-box;
    }
  }

}
</style>
