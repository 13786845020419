<template>
  <div class="wrapper">
    <div class="position-relative">
      <a-image :preview="false" :src="require('@/assets/new/help/supInfo.png')" :width="1920" :height="200"/>
      <div class="position-absolute" style="top: 90px;left: 200px;">
        <div class="top-font-help-0">
          {{ mainMenus.title }}
          <span class="help-subTitle-0">
            {{ mainMenus.subTitle }}
          </span>
        </div>
      </div>
    </div>

    <div class="width_1200_auto mt-7 mb-7">
      <a-card :body-style="{padding: '10px'}" class="position-relative">
        <template #title>
          <div style="padding: 50px 0 34px 31px;">
            <div class="top-font-help">{{ issueInfo.title }}</div>
            <div class="help-subTitle">{{ issueInfo.createTime }} 更新</div>
          </div>
        </template>
        <div class="position-absolute" style="top: 53px;left: 0;">
          <a-image :preview="false" :width="10" :height="23" :src="require('@/assets/new/help/h_line.png')"/>
        </div>
        <div class="flex align-center justify-start" style="padding: 30px 38px">
          <div class="ml-2" v-html="issueInfo.content"></div>
        </div>
      </a-card>
    </div>
  </div>
</template>

<script>
import { getArticleDetails } from '@/api/api'
import * as exData from '../data/index'

export default {
  name: 'Help',
  data() {
    return {
      loading: false,
      issueInfo: [],
      menusMain: exData.menus,
      mainMenus: {},
      id: 0,
      cid: 0,
    }
  },
  created() {
    this.id = this.$route.query.id
    this.cid = this.$route.query.cid
    this.mainMenus = this.menusMain.find(item => item.cid.toString() === this.cid)
    this.queryArticleDetails()
  },
  methods: {
    queryArticleDetails() {
      getArticleDetails(this.id)
          .then(res => {
            this.issueInfo = res.data
          })
    },
  }
}
</script>

<style scoped lang="scss">
.top-font-help-0 {
  font-weight: bold;
  font-size: 48px;
  color: #FFFFFF;
  line-height: 20px;

  .help-subTitle-0 {
    font-size: 16px;
    color: #EEEEEE;
    font-weight: 300 !important;
  }
}

.top-font-help {
  font-weight: 500;
  font-size: 28px;
  color: #000000;
  line-height: 34px;
}

.help-subTitle {
  font-weight: 400;
  font-size: 14px;
  color: #6B6C6D;
  line-height: 38px;
}

.wrapper {
  font-weight: 400;
  font-size: 18px;
  color: #FFFFFF;
  line-height: 31px;
  margin-bottom: 25px;
  min-height: 1200px;
}
</style>
