<template>
  <div class="width_1200 container-top-page">
    <div class="position-relative">
      <a-image :src="require('@/assets/new/danbao/top.png')" :preview="false" :height="150" :width="'100%'"></a-image>
      <div class="img-btn">
        <a-space :size="16">
          <div class="position-relative menu-top cursor-pointer" @click="recordsToZc">
            <div class="menu-top-img-left">
              <div class="top-font-menu mr-1">申请仲裁</div>
              <a-image :src="require('@/assets/new/danbao/right-jt.png')" :preview="false" :width="24"
                       :height="24"></a-image>
            </div>
          </div>

          <div class="position-relative menu-top cursor-pointer" @click="records">
            <div class="menu-top-img-right">
              <div class="top-font-menu mr-1">我的担保记录</div>
              <a-image :src="require('@/assets/new/danbao/right-jt.png')" :preview="false" :width="24"
                       :height="24"></a-image>
            </div>
          </div>
        </a-space>
      </div>
    </div>

    <div class="mt-3 width_1200">
      <div class="process-step">
        <a-card :bodyStyle="{padding: '20px'}">
          <template #title>
            <div class="text-bold">我的担保记录</div>
          </template>
          <template #extra>
            <div>【担保交易】是一项<span class="text-blue">实时、安全、快捷、自定义</span>的在线担保交易</div>
          </template>
          <empty v-if="hisList.length === 0" :_-title="'暂无担保记录'"/>
          <a-table v-else :pagination="false" :loading="listLoading" :columns="columns" :dataSource="hisList">
            <template #bodyCell="{ column, record }">
              <template v-if="column.key === 'createTime'">
                <div class="table-cell-font">{{ record.createTime }}</div>
              </template>
              <template v-if="column.key === 'hisOrder'">
                <div class="table-cell-font">{{ record.hisOrder }}</div>
              </template>
              <template v-if="column.key === 'orderStatus'">
                <div class="table-cell-font">
                  <a-tag color="green" v-if="record.orderStatus === 1">担保中</a-tag>
                  <a-tag color="#108ee9" v-if="record.orderStatus === 2">完成</a-tag>
                </div>
              </template>
              <template v-if="column.key === 'myCode'">
                <div class="table-cell-font">{{ record.myCode }}</div>
              </template>
              <template v-if="column.key === 'toCode'">
                <div class="table-cell-font">{{ record.toCode }}</div>
              </template>
              <template v-if="column.key === 'tgName'">
                <div class="table-cell-font">{{ record.tgName }}</div>
              </template>
              <template v-if="column.key === 'tgUrl'">
                <div class="table-cell-font golbal-btn">
                  <a @click="openLink(record.tgUrl)">
                    加入群组
                  </a>
                </div>
              </template>
            </template>
          </a-table>
          <!-- 分页 -->
          <div class="page-size mt-5" v-if="hisList && hisList.length > 0">
            <a-pagination :page-size="listLimit" :total="listTotal" show-less-items @change="changeListPageNum"
                          :show-size-changer="false"
                          @page-size-change="changeListPageSize"/>
          </div>
        </a-card>
      </div>

      <a-card class="mt-5 mb-5" :bodyStyle="{padding: '0'}">
        <template #title>
          <span>交易流程</span>
        </template>
        <div class="cu-center">
          <a-image :preview="false" :src="require('@/assets/new/danbao/line.png')" :width="1473" :height="203"/>
        </div>
      </a-card>
      <!--      常见问题 -->
      <div class="process-step mb-6">
        <a-card class="margin-top-sm" :body-style="{padding: '10px'}">
          <template #title>
            <a-image :preview="false" :src="require('@/assets/join/qa.png')" :width="32" :height="32"></a-image>
            常见问题
          </template>
          <a-collapse v-model:activeKey="activeKey" expand-icon-position="end" :bordered="false" ghost accordion>
            <a-collapse-panel v-for="(item, index) in issueList" :key="index">
              <template #header>
                <div class="d-flex align-center">
                  <div class="iss-item-coll-title">
                    <a-image :src="require('@/assets/issue.png')" :width="28" :height="22"></a-image>
                    <div class="text-coll">{{ 'Q' + (index + 1) }}</div>
                  </div>
                  <div class="ml-1">
                    {{ item.issue }}
                  </div>
                </div>
              </template>
              <div class="html-font" v-html="item.answer"></div>
            </a-collapse-panel>
          </a-collapse>
        </a-card>
      </div>
    </div>
  </div>
</template>

<script>
import { pullHis, queryNormalIssue } from '@/api/tg.js'

export default {
  name: 'GuaranteeRecord',
  components: {},
  data() {
    return {
      activeKey: '',
      issueList: [],
      page: 1,
      limit: 12,
      total: 0,
      cusList: [],
      loading: false,
      step: 1,
      current: 0,
      tgData: {},
      color: '#2BA0FF',
      payVisible: false,
      payPassword: '',
      showModel: false,
      tipsOne: '您的余额不足,去充值?',
      tgShareUrl: '',
      listPage: 1,
      listLimit: 12,
      listTotal: 0,
      hisList: [],
      listLoading: false,
      columns: [
        {
          title: '订单编号',
          key: 'hisOrder',
          align: 'center'
        },
        {
          title: '担保群组',
          key: 'tgName',
          align: 'center'
        },
        {
          title: '发起方平台ID',
          key: 'myCode',
          align: 'center'
        },
        {
          title: '接收方平台ID',
          key: 'toCode',
          align: 'center'
        },
        {
          title: '状态',
          key: 'orderStatus',
          align: 'center'
        },
        {
          title: '时间',
          key: 'createTime',
          align: 'center'
        },
        {
          title: '群组链接',
          key: 'tgUrl',
          align: 'center'
        }
      ],
    }
  },
  created() {
    this.getPullHisList()
    this.getIssueList()
  },
  methods: {
    recordsToZc() {
      window.open('https://t.me/dbw51zc')
    },
    openLink(url) {
      window.open(url)
    },
    getIssueList() {
      let data = {
        page: 1,
        limit: 100,
        issueType: 0
      }
      queryNormalIssue(data)
          .then(res => {
            this.issueList = res.data.list
          })
    },
    records() {
      this.getPullHisList()
    },
    changeListPageNum(val) { // 修改页码
      this.listPage = val
      this.getPullHisList()
    },
    changeListPageSize(val) { // 修改页数
      this.listPage = 1
      this.listLimit = val
      this.getPullHisList()
    },
    getPullHisList() {
      let that = this
      that.listLoading = true
      let data = {
        page: that.listPage,
        limit: that.listLimit
      }
      pullHis(data)
          .then(function (res) {
            that.hisList = res.data.list
            that.listTotal = res.data.total
            that.listLoading = false
          })
    },
  }
}

</script>
<style scoped lang="scss">
.text-color-cus {
  font-weight: 400;
  font-size: 14px;
  color: #DD4F4F;
}

.table-cell-font {
  color: #888888 !important;
}

.process-step-alm {
  margin: 25px 40px;
  background: #FEF5F5;
  border-radius: 20px;
  padding: 20px 40px;
}

.input-code-cus {
  width: 268px;
  height: 48px;
  box-shadow: 0px 3px 10px 0px rgba(107, 131, 200, 0.08);
  border-radius: 10px;
  border: 1px solid #E9EAEB;
}

.container-top-page {
  margin: 1px auto;
  padding: 0;
  border-radius: 12px;
}

.menu-top {
  .menu-top-img-left {
    width: 165px;
    height: 50px;
    background: linear-gradient(-90deg, #FF8726, #F65F13);
    box-shadow: 0px 2px 13px 0px rgba(246, 96, 20, 0.31);
    border-radius: 14px;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .menu-top-img-left:hover {
    transform: scale(1.03); /* 鼠标悬浮时放大 */
    background-color: #3B64DA; /* 悬浮时改变背景色 */
    color: #3A69FB;
  }

  .menu-top-img-right {
    width: 212px;
    height: 50px;
    background: linear-gradient(89deg, #39ADFF 1%, #6559FF 100%);
    box-shadow: 0px 5px 20px 0px rgba(101, 89, 255, 0.21);
    border-radius: 14px;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .menu-top-img-right:hover {
    transform: scale(1.03); /* 鼠标悬浮时放大 */
    background-color: #3B64DA; /* 悬浮时改变背景色 */
    color: #3A69FB;
  }
}

.danbao-cell-btn1:hover {
  transform: scale(1.03); /* 鼠标悬浮时放大 */
}

.danbao-cell-btn2:hover {
  transform: scale(1.03); /* 鼠标悬浮时放大 */
}

.top-font-menu {
  font-weight: 400;
  font-size: 18px;
  color: #FFFFFF;
  line-height: 21px;
}

.img-btn {
  position: absolute;
  right: 30%;
  bottom: 50px;
}

.join-btn {
  width: 153px;
  height: 42px;
  border-radius: 6px;
  background: #269FFA;

  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.17px;

  color: #FFFFFF;
}

.cu-tag {
  width: 63px;
  border-radius: 6px;
}

.share-btn {
  width: 153px;
  height: 42px;
  opacity: 1;
  border-radius: 6px;
  box-sizing: border-box;
  border: 1px solid #269FFA;

  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.17px;
  color: #269FFA;
}

.curl-link {
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.17px;
  color: #269FFA;
}


.warning-font {
  font-size: 14px;
  font-weight: normal;
  line-height: 25px;
  letter-spacing: 0px;
  color: #7E7E7E;
}

.pay-btn {
  top: 807px;
  width: 299px;
  height: 48px;
  background: linear-gradient(-90deg, #0080D9, #0052D9);
  box-shadow: 0px 5px 16px 0px rgba(71,100,184,0.3);
  border-radius: 10px;

  font-size: 16px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0px;
  color: #FFFFFF;

}

.font-1 {
  font-size: 14px;
  font-weight: normal;
  line-height: 25px;
  letter-spacing: 0px;
  color: #E43737;
}

.content-title {
  width: 98px;
  height: 25px;

  font-size: 14px;
  font-weight: 500;
  line-height: 25px;
  color: #999999;
}

.content-info {
  font-size: 14px;
  font-weight: normal;
  line-height: 25px;
  letter-spacing: 0px;
}

.process-step {
  margin-top: 20px;
}

:deep(.ant-steps-item-title) {
  font-size: 14px;
  font-weight: 350;
  line-height: 25px;
  letter-spacing: 0px;
}

.ivu-avatar-large.ivu-avatar-icon {
  font-size: 28px;

  display: flex;
  align-items: center;
  justify-content: center;
}

:deep(.ant-steps-item-tail) {
  top: 12px !important;
  margin-inline-start: 60px !important;
}

.danbao-cell-btn1 {
  width: 121px;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #E4E4E4;
  color: #3D3D3D;
}

.danbao-cell-btn2 {
  width: 121px;
  height: 40px;
  border-radius: 5px;
  background: #269FFA;
  color: #FFFFFF;
}

.btm-btns {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-around;
}

.cursor-pointer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pointer {
  cursor: pointer;
}

.list-title {
  margin-top: 6px;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
}

.list-content {
  margin-top: 6px;
  font-size: 14px;
  color: #999999;
}

.list-g-x {
  padding: 20px 0 20px 10px;
}

.record-btn {
  padding-left: 10px;
  padding-right: 10px;
  height: 32px;
  border-radius: 50px;
  box-sizing: border-box;
  border: 1px solid #298FFF;

  font-size: 14px;
  font-weight: 350;
  color: #269FFA;
}

.font-danbao {
  font-size: 16px;
  font-weight: 350;
  line-height: 26px;
  color: #3D3D3D;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.container {
  margin: 0 auto;
  padding: 40px 0;
  border-radius: 12px;
  min-height: 1600px;
}
</style>
