<template>
  <div class="page-container-all width_1200_auto">
    <a-image :preview="false" :width="1600" :height="200"
             class="cursor-pointer"
             :src="require('@/assets/new/partner/banner_jion.png')"></a-image>

    <div class="page-join-content">
      <div class="page-container">
        <a-card :head-style="{padding: '20px 32px', height: '79px'}"
                :body-style="{padding: '34px 0', width: '1600px'}">
          <template #title>
            填写信息
          </template>
          <div class="pl-10 pr-10 mb-5">
            <div v-if="apply === 0 || apply === 1 || apply === 2" class="item-from-audit">
              <div class="ing" v-if="formObject.auditStatus === 0">{{ '审核中,请稍后查看' }}</div>
              <div class="suc" v-if="formObject.auditStatus === 1">{{ '恭喜,审核通过' }}</div>
              <div class="err" v-if="formObject.auditStatus === 2">{{ '审核未通过' }}</div>
              <div class="err text-center" v-if="formObject.auditStatus === 2">
                {{ '原因' }}: {{ formObject.reason }}
              </div>
            </div>

            <a-form :model="formObject" :label-col="{ style: { width: '209px' } }" :labelCol="{ span: 5}"  @finish="onFinish">
              <a-form-item label="姓名:" name="name" :rules="[{ required: true, message: '请输入姓名' }]">
                <a-input class="input-cell" :maxlength="16" v-model:value="formObject.name"
                         :disabled="apply === 0 || apply === 1"
                         placeholder="请输入姓名"></a-input>
              </a-form-item>

              <a-form-item label="电子邮箱:" name="email"
                           :rules="[{ required: true, message: '请输入电子邮箱'}, {type:'email', message: '请输入正确的电子邮箱'}]">
                <a-input class="input-cell" v-model:value="formObject.email" :disabled="apply === 0 || apply === 1"
                         placeholder="请输入电子邮箱"></a-input>
              </a-form-item>

              <a-form-item label="联系方式:" :name="formObject.linkFlag === 0 ? 'telegram' : 'whatsapp'"
                           :rules="[{ required: true, message: '请输入联系方式链接' }]">
                <div class="flex flex-direction">
                  <a-form-item-rest>
                    <a-radio-group :disabled="apply === 0 || apply === 1" style="margin-bottom: 10px"
                                   v-model:value="formObject.linkFlag" @change="changes">
                      <a-radio :value="0">Telegram</a-radio>
                      <a-radio :value="1">Whatsapp</a-radio>
                    </a-radio-group>
                  </a-form-item-rest>
                  <a-input v-if="formObject.linkFlag === 1" class="input-cell" @input="whatAppInput"
                           v-model:value="formObject.whatsapp" :disabled="apply === 0 || apply === 1"
                           placeholder="请输入Whatsapp账号链接"></a-input>
                  <a-input v-if="formObject.linkFlag === 0" class="input-cell" @input="phoneInput"
                           v-model:value="formObject.telegram" :disabled="apply === 0 || apply === 1"
                           placeholder="请输入电报账号链接"></a-input>
                </div>
              </a-form-item>

              <a-form-item label="申请全球合伙人计划的目的:" name="cause"
                           :rules="[{ required: true, message: '请输入申请全球合伙人计划的目的' }]">
                <a-textarea :rows="4" v-model:value="formObject.cause" placeholder="请输入申请全球合伙人计划的目的"
                            :disabled="apply === 0 || apply === 1"></a-textarea>
              </a-form-item>

              <a-form-item label="个人优势与资源:" name="advantage"
                           :rules="[{ required: true, message: '请输入个人优势与资源' }]">
                <a-textarea :rows="4" v-model:value="formObject.advantage" placeholder="请输入请输入个人优势与资源"
                            :disabled="apply === 0 || apply === 1"></a-textarea>
              </a-form-item>

              <a-form-item label=" " :colon="false">
                <a-button v-if="apply === null || apply === 2" type="link" class="from-btn cu-center"
                          html-type="submit">
                  提交申请
                </a-button>
              </a-form-item>
            </a-form>
          </div>
        </a-card>
      </div>
    </div>
  </div>
</template>
<script>
import { addPartner, partnerAuditInfo, } from '@/api/tg.js'

export default {
  data() {
    return {
      prefix: 'https://t.me/',
      whatsAppPrefix: 'https://wa.me/',
      formObject: {
        id: null,
        name: '',
        email: '',
        linkFlag: 0,
        telegram: '',
        whatsapp: '',
        cause: '',
        advantage: '',
        partneInfo: {},
      },
      apply: null,
    }
  },
  created() {
    this.getPartneInfo()
  },
  methods: {
    changes(n) {
      this.formObject.linkFlag = n.target.value
      if (n.target.value === 0) {
        this.formObject.whatsapp = ''
      }
      if (n.target.value === 1) {
        this.formObject.telegram = ''
      }
    },
    whatAppInput(e) {
      this.$nextTick(() => {
        let inputValue = this.formObject.whatsapp
        let value = 'https://wa.me'
        if (inputValue === value) {
          this.formObject.whatsapp = this.whatsAppPrefix
          return
        }
        value = 'https://wa.m'
        if (inputValue === value) {
          this.formObject.whatsapp = this.whatsAppPrefix
          return
        }
        value = 'https://wa.'
        if (inputValue === value) {
          this.formObject.whatsapp = this.whatsAppPrefix
          return
        }
        value = 'https://wa'
        if (inputValue === value) {
          this.formObject.whatsapp = this.whatsAppPrefix
          return
        }
        value = 'https://w'
        if (inputValue === value) {
          this.formObject.whatsapp = this.whatsAppPrefix
          return
        }
        value = 'https://'
        if (inputValue === value) {
          this.formObject.whatsapp = this.whatsAppPrefix
          return
        }
        value = '@'
        if (inputValue === value) {
          this.formObject.whatsapp = this.whatsAppPrefix
          return
        }
        const nonChineseValue = inputValue.replace(/[\u4e00-\u9fa5\s]/g, '')
            .replace(/@/g, '')
        if (nonChineseValue !== inputValue) {
          if (!nonChineseValue.startsWith(this.whatsAppPrefix)) {
            this.formObject.whatsapp = this.whatsAppPrefix + nonChineseValue
          } else {
            this.formObject.whatsapp = nonChineseValue
          }
        } else {
          if (!inputValue.startsWith(this.whatsAppPrefix)) {
            this.formObject.whatsapp = this.whatsAppPrefix + nonChineseValue
          } else {
            this.formObject.whatsapp = nonChineseValue
          }
        }
      })
    },
    phoneInput(e) {
      this.$nextTick(() => {
        let inputValue = this.formObject.telegram
        let value = 'https://t.me'
        if (inputValue === value) {
          this.formObject.telegram = this.prefix
          return
        }
        value = 'https://t.m'
        if (inputValue === value) {
          this.formObject.telegram = this.prefix
          return
        }
        value = 'https://t.'
        if (inputValue === value) {
          this.formObject.telegram = this.prefix
          return
        }
        value = 'https://t'
        if (inputValue === value) {
          this.formObject.telegram = this.prefix
          return
        }
        value = 'https://'
        if (inputValue === value) {
          this.formObject.telegram = this.prefix
          return
        }
        value = '@'
        if (inputValue === value) {
          this.formObject.telegram = this.prefix
          return
        }
        const nonChineseValue = inputValue.replace(/[\u4e00-\u9fa5\s]/g, '')
            .replace(/@/g, '')
        if (nonChineseValue !== inputValue) {
          if (!nonChineseValue.startsWith(this.prefix)) {
            this.formObject.telegram = this.prefix + nonChineseValue
          } else {
            this.formObject.telegram = nonChineseValue
          }
        } else {
          if (!inputValue.startsWith(this.prefix)) {
            this.formObject.telegram = this.prefix + nonChineseValue
          } else {
            this.formObject.telegram = nonChineseValue
          }
        }
      })
    },
    getPartneInfo() {
      partnerAuditInfo()
          .then((res) => {
            if (res.data) {
              this.formObject = res.data
              this.apply = this.formObject.auditStatus
            }
          })
    },
    onFinish() {
      if (this.formObject.linkFlag === 0 && !this.formObject.telegram) {
        this.$Message.error('请输入电报链接')
        return
      }
      if (this.formObject.linkFlag === 1 && !this.formObject.whatsapp) {
        this.$Message.error('请输入whatsapp链接')
        return
      }
      const telegramRegex = /https:\/\/t\.me\/(joinchat\/)?[\w\d_]+(\/|\?[\w\d_=&]+)?/
      if (this.formObject.linkFlag === 0 && !telegramRegex.test(this.formObject.telegram)) {
        this.$Message.error('请输入正确的电报链接')
        return
      }
      const whatsappRegexPersonal = /https:\/\/wa\.me\/(\+?\d{1,3}[\s-]?)?\d{1,14}(\/)?(\?text=.+)?/
      if (this.formObject.linkFlag === 1 && !whatsappRegexPersonal.test(this.formObject.whatsapp)) {
        this.$Message.error('请输入正确的whatsapp链接')
        return
      }
      addPartner(this.formObject)
          .then((res) => {
            if (res.code === 200) {
              this.getPartneInfo()
              this.apply = '1'
            } else {
              this.$Message.error(res.message)
            }
          })
    },
  }
}
</script>

<style scoped lang="scss">
.item-from-audit {
  border-radius: 8px;
  height: 100px;
  background: #F4F4F4;
  width: 100%;
  padding: 10px 0;
  margin-bottom: 30px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .ing {
    color: #999999;
  }

  .suc {
    color: #269FFA;
  }

  .err {
    color: #FF0000;
  }
}

.margin-150 {
  margin-left: 200px;
}

.check-font {
  font-size: 14px;
  font-weight: normal;
  color: #FF6F4A;
}

.from-btn {
  width: 260px;
  height: 60px;
  background: linear-gradient(-90deg, #0080D9, #0052D9);
  box-shadow: 0px 5px 16px 0px rgba(71,100,184,0.3);
  border-radius: 10px;

  font-weight: 800;
  font-size: 18px;
  color: #FFFFFF;
  line-height: 38px;
}

.input-cell {
  width: 570px;
  height: 48px;
  box-shadow: 0 3px 10px 0rgba(107,131,200,0.08);
  border-radius: 10px;
  border: 1px solid #E9EAEB;
}

.page-container-all {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: flex-start;
  flex-direction: column;
  flex-wrap: nowrap;
  min-height: 1200px;

  .page-join-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-wrap: nowrap;

    .page-container {
      margin-top: 20px;
    }

    .page-container-info {
      margin-top: 20px;
    }

    .font-top1 {
      font-size: 80px;
      font-weight: normal;
      line-height: 25px;
      letter-spacing: 0px;
      color: #FFFFFF;
      text-shadow: 0px 6px 0px rgba(121, 14, 14, 0.6);
    }

    .font-top2 {
      font-size: 45px;
      font-weight: normal;
      line-height: 25px;
      letter-spacing: 0px;
      /* 全球合伙人 赚取 */
      color: #FFFFFF;
      margin-top: 60px;
    }

    .font-top3 {
      /* 高额佣金 */
      color: #FFF52E;
    }

    .page-join {
      margin-top: 40px;
      width: 132px;
      height: 38px;
      border-radius: 50px;
      opacity: 1;
      background: #FFCC00;

      display: flex;
      justify-content: center;
      align-items: center;

      .font-cus {
        font-size: 16px;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: -0.17px;
        color: #3D3D3D;
      }
    }
  }

}
</style>
