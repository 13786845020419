export default {
  home: {
    name: '首页',
    login: '登录',
  },
  header: {
    hello: '您好!',
    login: '登录',
    register: '注册',
    phone: '手机',
    homepage: '我的主页',
    wallet: '我的钱包',
    order: '我的订单',
    logout: '退出',
    chinese: '简体中文',
    or: '或',
  },
  common: {
    com_000: '确认',
    com_001: '取消',
    com_002: '输入国家或者区号搜索',
    com_003: '在线客服',
  },
  login: {
    l_00001: '欢迎登录',
    l_00002: '去注册',
    l_00003: '邮箱',
    l_00004: '请输入邮箱',
    l_00005: '请输入登录密码',
    l_00006: '忘记密码?',
    l_00007: '登录',
    l_00008: '手机号',
    l_00009: '请输入手机号码',
    l_00010: '请输入登录密码',
    l_00011: '忘记密码?',
    l_00012: '登录',
    l_00013: '合作账号登录',
    l_00014: '在线客服',
    l_00015: '邀请码',
    l_00016: '登录',
    l_00017: '取消',
    l_00018: '请输入邀请码',
    l_00019: '密码不能为空',
    l_00020: '请阅读并同意协议规则和隐私政策',
    l_00021: '邮箱不能为空',
    l_00022: '邮箱输入不正确',
    l_00023: '手机号码不可以为空',
    l_00024: '我已阅读并同意',
    l_00025: '用户协议',
    l_00026: '和',
    l_00027: '隐私政策',
  },
  signUp: {
    s_00001: '欢迎注册',
    s_00002: '去登录',
    s_00003: '邮箱',
    s_00004: '请输入邮箱',
    s_00005: '邀请码',
    s_00006: '选填',
    s_00007: '下一步',
    s_00008: '已有账号,',
    s_00009: '请输入邀请码(选填)',
    s_00010: '去登录',
    s_00011: '请输入验证码',
    s_00012: '验证码发送至',
    s_00013: '邮箱验证码可能被判定为垃圾邮件，请注意查收',
    s_00014: '设置登录密码',
    s_00015: '密码必须包含至少1个字母和至少1个数字，且长度不低于8位。',
    s_00016: '请输入密码',
    s_00017: '请重复输入密码',
    s_00018: '密码安全:',
    s_00019: '注册',
    s_00021: '手机号',
    s_00022: '请输入手机号码',
    s_00023: '已选中',
    s_00024: '手机验证码可能被判定为垃圾短信，请注意查收',
    s_00025: '请输入邮箱地址',
    s_00026: '请输入正确的邮箱地址',
    s_00027: '请输入手机号码',
    s_00028: '请输入密码',
    s_00029: '请重复输入密码',
    s_00030: '手机号不可为空',
    s_00031: '验证码不可为空',
    s_00032: '验证码不正确',
    s_00033: '密码不可为空',
    s_00034: '密码必须包含至少1个字母和至少1个数字，且长度不低于8位。',
    s_00035: '重复密码不可为空',
    s_00036: '两次输入的密码不一致，请重新输入。',
    s_00037: '验证码不可为空',
    s_00038: '请输入手机号码',
    s_00039: '手机号码已存在或不正确',
    s_00040: '您输入的邀请码无效',
    s_00041: '邮箱不可为空',
    s_00042: '邮箱不正确',
    s_00043: '请输入重复密码',
    s_00044: '请输入验证码',
    s_00045: '请输入邮箱地址',
    s_00046: '您输入的邮箱地址不正确',
    s_00047: '您输入的邮箱地址已存在',
  },
  pwd: {
    pwd_001: '忘记密码',
    pwd_002: '去登录',
    pwd_003: '邮箱',
    pwd_004: '请输入邮箱',
    pwd_005: '已有账号?',
    pwd_006: '去登录',
    pwd_007: '请输入验证码',
    pwd_008: '验证码发送至',
    pwd_009: '邮箱验证码可能被判定为垃圾邮件，请注意查收。',
    pwd_010: '确认',
    pwd_011: '设置登录密码',
    pwd_012: '密码必须包含至少1个字母和至少1个数字，且长度不低于8位。',
    pwd_013: '请输入密码',
    pwd_014: '请重复输入密码',
    pwd_015: '密码安全:',
    pwd_016: '手机号',
    pwd_017: '请输入手机号码',
    pwd_018: '已选中',
    pwd_019: '下一步',
    pwd_020: '已有账号',
    pwd_021: '去登录',
    pwd_022: '请输入验证码',
    pwd_023: '验证码发送至',
    pwd_024: '手机验证码可能被判定为垃圾短信，请注意查收。',
    pwd_025: '请输入正确的邮箱地址',
    pwd_026: '请输入手机号码',
    pwd_027: '您输入的邮箱地址不存在',
  },
  f_00001: '关注官方电报频道',
  f_00002: '官方客服电报',
  f_00003: '小时在线',
  f_00004: '关于我们',
  f_00005: '平台简介',
  f_00006: '工作人员',
  f_00007: '加入我们',
  f_00008: '用户协议',
  f_00009: '商家入驻协议',
  f_00010: '交易保障',
  f_00011: '使用教程',
  f_00012: '争议仲裁',
  f_00013: '免责声明',
  f_00014: '帮助中心',
  f_00015: '入驻费率',
  f_00016: '担保费率',
  f_00017: '分享邀请',
  f_00018: '合伙人',
  f_00019: '帮助与支持',
  f_00020: '常见问题(FAQ)',
  f_00021: '争议仲裁',
  f_00022: '费用说明',
  f_00023: '政策与隐私',
  f_00024: '用户协议',
  f_00025: '隐私政策',
  f_00026: '商家入驻协议',
  f_00027: '免责申明',
  srh_0001: '搜索商品/商铺',
  srh_0002: '商品',
  srh_0003: '商铺',
  nav_0000: '所有交易分类',
  nav_0001: '首页',
  nav_0002: '51担保交易',
  nav_0003: '商户入驻',
  nav_0004: '推广分享',
  nav_0005: '供需',
  nav_0006: '成为合伙人',
  nav_0007: '曝光资讯',
  nav_0008: '关于我们',
  i_001: '欢迎来到51担保网！',
  i_002: '登录',
  i_003: '注册',
  i_004: '公告消息',
  i_005: 'TRX兑换',
  i_006: '供需发布',
  i_007: '曝光资讯',
  i_008: '人气精选',
  i_009: '新店推荐',
  i_010: '查看全部',
  i_011: '保证金:',
  i_012: '51担保交易',
  i_013: '交谈',
  i_014: '发起担保',
  i_015: '供应',
  i_016: '需求',
  i_017: '曝光、资讯',
  i_018: '热门、曝光、资讯排行',
  i_019: '交易有风险',
  i_020: '就来51担保',
  i_021: '线上交易',
  i_022: '安全无忧',
  cnf_000: '51担保',
  cnf_001: '51担保 保留所有权利'
}
