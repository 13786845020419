<template>
  <div class="scroll-wrapper">
    <div class="scroll-content">
      <!-- 动态插入公告内容 -->
      <span class="text-red text-df" v-for="(item, index) in list" :key="index">{{ item }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NoticeTop',
  props: {
    list: {
      type: Object,
      default: []
    }
  },
  data() {
  },
  created() {
  },
  methods: {},
}
</script>

<style lang="scss" scoped>
.scroll-wrapper {
  overflow: hidden; /* 隐藏超出的文字 */
  white-space: nowrap; /* 防止内容换行 */
  position: relative;
  color: red;
  width: 100%; /* 撑满容器宽度 */
}

.scroll-content {
  display: inline-block;
  white-space: nowrap;
  animation: scroll 50s linear infinite; /* 滚动动画 */
}

@keyframes scroll {
  0% {
    transform: translateX(100%); /* 起始位置：文字在右侧 */
  }
  100% {
    transform: translateX(-100%); /* 结束位置：文字在左侧 */
  }
}
</style>
