<template>
  <div class="wrapper">
    <div class="container">
      <!-- 商品筛选栏 -->
      <GoodsClassNav @getParams="getParams" :keywords="keywordsName"></GoodsClassNav>
      <!-- 商品展示容器 -->
      <div class="goods-box">
        <!-- 商品列表 -->
        <div class="goods-list-box">
          <!-- 排序 -->
          <div class="goods-list-tool" v-if="selectFlag === '0'">
            <a-space>
              <div class="cursor-pointer flex" v-for="(item, index) in goodsTool" :key="index"
                   @click="orderBy(item, index)">
                <span :class="{ 'goods-list-tool-active': index === sortIndex && !item.en }">
                  {{ item.title }}
                  <div class="flex flex-column ml-1">
                  <CaretUpOutlined style="width: 20px;height: 8px;"
                                   :style="item.sort && index === sortIndex ? {color : '#3B64DA'} : {color : '#B9B9BD'}"
                                   v-if="item.en"/>
                  <CaretDownOutlined style="width: 20px;height: 8px;"
                                     :style="!item.sort && index === sortIndex ? {color : '#3B64DA'} : {color : '#B9B9BD'}"
                                     v-if="item.en"/>
                </div>
                </span>
              </div>
            </a-space>
          </div>

          <!-- 商品信息 -->
          <!--          <a-spin size="large" :spinning="loading">-->
          <!-- 商品列表 -->
          <div v-if="selectFlag === '0'">
            <empty _-title="暂无更多商品信息" v-if="productList.length === 0 && !loading"/>
            <div class="goods-list">
              <a-row :gutetr="[20,19]" v-if="loading" style="width: 1600px">
                <a-col class="mer-card" :span="6" v-for="index in 20" :key="index">
                  <a-skeleton active :loading="loading" :paragraph="{ rows: 5 }"/>
                </a-col>
              </a-row>
              <a-card :bordered="false" class="goods-show-info hover-item-up"
                      :body-style="{padding: '0'}" v-for="(item, index) in productList"
                      :key="index" :loading="loading">
                <div @click="goGoodsDetail(item.id)">
                  <div class="goods-show-img">
                    <img :preview="false" class="goods-show-img" :width="280" :height="280" :src="item.image"/>
                  </div>
                  <div class="padding-lr-xs">
                    <div class="goods-show-detail">
                      {{ item.name }}
                    </div>
                    <div class="goods-show-price text-danger">
                      {{ item.price }} USDT
                    </div>
                    <div class="goods-show-num">
                      <div>{{ item.sales || 0 }} 人付款</div>
                      <div class="padding-lr-xs">|</div>
                      <div>{{ item.merName }}</div>
                    </div>
                  </div>
                </div>
              </a-card>
            </div>
          </div>
          <!-- 商铺  -->
          <div v-else>
            <empty _-title="暂无更多商铺信息" v-if="merchantList.length === 0 && !loading"/>
            <div class="goods-list-mer">
              <a-row :gutetr="[20,19]" v-if="loading" style="width: 1600px">
                <a-col class="mer-card" :span="6" v-for="index in 20" :key="index">
                  <a-skeleton active :loading="loading" avatar :paragraph="{ rows: 5 }"/>
                </a-col>
              </a-row>
              <a-row :gutter="24" :class="merchantList.length === 1 ? 'with-one' : ''">
                <a-col class="mer-card hover-item-up" :span="8" v-for="(item, index) in merchantList" :key="index">
                  <a-card :loading="loading" :bordered="false" :bodyStyle="{padding: '26px 32px;', height: '90px'}">
                    <template #title>
                      <div class="mer-card-title cursor-pointer">
                        <div class="avatar" @click="()=>{$router.push({path:'/MerHome', query:{merId: item.id}})}">
                          <a-avatar style="border: 1px solid #E8E8E8;" :src="item.avatar" :size="70"></a-avatar>
                        </div>
                        <div style="width: 100%">
                          <div class="d-flex justify-space-between align-center">
                            <div class="ml-4" @click="()=>{$router.push({path:'/MerHome', query:{merId: item.id}})}">
                              <div class="mer-name line1" style="max-width: 420px;">
                                {{ item.name }}
                              </div>
                              <div class="custom-font">
                                <a-image :src="require('@/assets/new/product/danbao.png')" :width="20" :height="19"/>
                                保证金：{{ item.earnestMoney }} USDT
                              </div>
                            </div>
                            <div class="attention-cus-mer cursor-pointer" v-if="!item.isCollect" @click="followToggle(item)">
                              <PlusOutlined/>
                              <span class="ml-1">关注</span>
                            </div>
                            <div class="attention-cus-mer-over cursor-pointer" v-if="item.isCollect" @click="followToggle(item)">
                              <span>已关注</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </template>
                    <div class="line2 text-gray text-df cursor-pointer"  @click="()=>{$router.push({path:'/MerHome', query:{merId: item.id}})}">
                        {{ item.intro }}
                    </div>
                  </a-card>
                </a-col>
              </a-row>
            </div>
          </div>
          <!--          </a-spin>-->
        </div>
      </div>
      <div class="goods-page">
        <a-pagination :page-size="where.limit" :total="total" show-less-items @change="changePageNum"
                      :show-size-changer="false"
                      @page-size-change="changePageSize"/>
      </div>
    </div>
  </div>
</template>

<script>
import GoodsClassNav from '@/components/nav/GoodsClassNav'
import * as apiGoods from '@/api/product'
import * as apiMers from '@/api/merchant'
import { CaretDownOutlined, CaretUpOutlined, PlusOutlined } from '@ant-design/icons-vue'
import { getMerCollectAddApi, getMerCollectCancelApi } from '@/api/merchant'

export default {
  name: 'GoodsList',
  beforeRouteEnter(to, from, next) {
    window.scrollTo(0, 0)
    next()
  },
  data() {
    return {
      sortIndex: 0, // 排序状态
      sortPriceIndex: false, // 判断价格升序还是降序
      goodsTool: [
        // 排序类型
        {
          title: '综合',
          en: '',
          sort: false
        },
        {
          title: '价格',
          en: 'priceOrder',
          sort: false
        },
        {
          title: '销量',
          en: 'salesOrder',
          sort: false
        },
      ],
      merchantList: [],
      productList: [], // 商品列表
      loading: false, // 加载状态
      total: 0, // 列表总数
      where: {
        keyword: '',
        keywords: '',
        priceOrder: '',
        salesOrder: '',
        //news: 0,
        page: 1,
        limit: 21,
        cid: '',
        merId: '',
        categoryId: '',
        selectFlag: '0',
      },
      selectFlag: '0',
      keywordsName: '',
    }
  },
  watch: {
    '$route': { // 监听路由
      handler(val, oVal) {
        if (val.name !== 'GoodsList') {
          return
        }
        const keyword = this.$route.query.keyword
        const selectFlag = this.$route.query.selectFlag
        if (!selectFlag) {
          this.selectFlag = '0'
        } else {
          this.selectFlag = selectFlag
        }
        if (keyword) {
          this.search({
            keyword: keyword,
            selectFlag: selectFlag
          })
          this.keywordsName = keyword
        }
        if (this.$route.query.categoryId) {
          let cateId = this.$route.query.categoryId.split(',')
          Object.assign(this.where, this.$route.query)
          this.where.categoryId = cateId[cateId.length - 1]
        }
        if (this.$route.query.promotionType) {
          this.where.promotionType = this.$route.query.promotionType
        }
        if (this.$route.query.promotionsId) {
          this.where.promotionsId = this.$route.query.promotionsId
        }
        this.getGoodsList()
      }
    },
  },
  created() {
    if (this.$route.query.categoryId) {
      let cateId = this.$route.query.categoryId.split(',')
      Object.assign(this.where, this.$route.query)
      this.where.categoryId = cateId[cateId.length - 1]
    } else {
      Object.assign(this.where, this.$route.query)
    }
    const keyword = this.$route.query.keyword
    if (keyword) {
      this.where.keyword = encodeURIComponent(keyword)
      this.keywordsName = keyword
    }
    const selectFlag = this.$route.query.selectFlag
    if (!selectFlag) {
      this.selectFlag = '0'
    } else {
      this.selectFlag = selectFlag
    }
    this.where.page = 1
    this.getGoodsList()
  },
  methods: {
    // 设置是否关注
    followToggle(item) {
      if (item.isCollect) {
        getMerCollectCancelApi(item.id)
            .then(res => {
              this.$nextTick(() => {
                item.isCollect = !item.isCollect
              })
            })
      } else {
        getMerCollectAddApi(item.id)
            .then(res => {
              this.$nextTick(() => {
                item.isCollect = !item.isCollect
              })
            })
      }
    },
    load({ done }) {
      done('empty')
    },
    // 搜索
    search(key) {
      this.keywordsName = key.keyword
      this.selectFlag = key.selectFlag
      this.where.keyword = encodeURIComponent(key.keyword)
      this.where.page = 1
      this.$router.push({
        path: '/GoodsList',
        query: {
          keyword: key.keyword,
          selectFlag: key.selectFlag,
        },
      })
      // this.getGoodsList()
    },
    orderBy(data, index) {
      this.sortIndex = index
      if (data.sort) {
        data.sort = false
      } else {
        data.sort = true
      }
      if (data.en === 'priceOrder') {
        if (data.sort) {
          this.where.salesOrder = ''
          this.where.priceOrder = 'asc'
        } else {
          this.where.salesOrder = ''
          this.where.priceOrder = 'desc'
        }
      }
      if (data.en === 'salesOrder') {
        if (data.sort) {
          this.where.priceOrder = ''
          this.where.salesOrder = 'asc'
        } else {
          this.where.priceOrder = ''
          this.where.salesOrder = 'desc'
        }
      }
      this.getGoodsList()
    },
    goGoodsDetail(goodsId) {
      // 跳转商品详情
      this.$router.push({
        path: '/Product',
        query: {
          id: goodsId,
        }
      })
    },
    // 分页 修改页码
    changePageNum(val) {
      this.where.page = val
      this.getGoodsList()
    },
    // 分页 修改页数
    changePageSize(val) {
      this.where.page = 1
      this.where.limit = val
      this.getGoodsList()
    },
    // 获取商品列表
    getGoodsList() {
      if (this.selectFlag === '0') {
        this.loading = true
        this.where.name = null
        this.productList = []
        this.merchantList = []
        this.where.limit = 20
        apiGoods.productList(this.where)
            .then(res => {
              this.$nextTick(() => {
                this.productList = res.data.list
              })
              this.loading = false
              this.where.salesOrder = ''
              this.where.priceOrder = ''
              this.total = res.data.total
            })
      }
      if (this.selectFlag === '1') {
        if (this.loadend) return
        if (this.loading) return
        this.loading = true
        this.productList = []
        this.merchantList = []
        this.where.keywords = this.where.keyword
        this.where.limit = 21
        apiMers.getMerSearchApi(this.where)
            .then(res => {
              this.merchantList = res.data.list
              this.loading = false
              this.total = res.data.total
            })
      }
    },
    getParams(key) {
      // 筛选条件回显
      this.where.page = 1
      Object.assign(this.where, key)
      this.$router.push({
        path: '/GoodsList',
        query: this.where,
      })
    },
  },
  components: {
    PlusOutlined,
    GoodsClassNav,
    CaretUpOutlined,
    CaretDownOutlined,
  },
}
</script>

<style scoped lang="scss">
@import "@/assets/styles/goodsList.scss";
.mer-card-title {
  height: 121px;
  padding: 24px 0 27px 6px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;

  .mer-level {
    font-size: 13px;
    color: #3B64DA;
  }

  .mer-name {
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
  }

  .avatar {
    height: 66px;
    margin-right: 15px;
  }
}

.with-one {
  width: 100%;
}

.goods-show-img {
  border-radius: 10px;
}

.mer-card {
  margin-bottom: 16px;
}

.custom-font {
  font-size: 14px;
  margin-top: 14px;
  color: #FF6600;
}

.cate-nav {
  margin-top: 10px;
}

.goods-show-info {
}

.goods-show-info > .goods-show-seller > .goods-show-buyer {
  width: 35px;
  height: 17px;
  white-space: nowrap;
  line-height: 17px;
  text-align: center;
  align-content: center;
  padding: 0 3px;
  background-color: #e23a3a;
}

.goods-show-tag {
  height: 18px;
  width: 32px;
  line-height: 14px;
  white-space: nowrap;
  text-align: center;
  align-items: center;
  padding: 0 3px;
}

.goods-show-seller {
  vertical-align: middle;
}

.container {
  margin: 0 auto;
  width: 1600px;
  position: relative;
}

.price-sort:hover {
  color: #e23a3a;
}

.goods-box {
  display: flex;
}

/* ---------------侧边广告栏开始------------------- */

.goods-show-right {
  display: flex;
  flex-direction: row;
  margin-top: 5px;
}


/* ---------------商品栏开始------------------- */
.goods-list-box {
  position: relative;
  width: 100%;
}

.goods-list-tool {
  width: 100%;
  height: 52px;
}

.goods-list-tool span {
  padding: 3px 5px;
  width: 66px;
  height: 30px;
  border-radius: 5px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: normal;
  line-height: 28px;
  letter-spacing: 0px;
  color: #5D5D5D;
}

.goods-list-tool span:first-child {
  padding: 3px 5px;
  width: 62px;
  height: 30px;
  border-radius: 5px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: normal;
  line-height: 28px;
  letter-spacing: 0px;
  color: #5D5D5D;
}

.goods-list-tool span:first-child:hover {
  color: #3B64DA;
  position: relative;
  text-decoration: none;
  z-index: 1;
}

.goods-list-tool .ivu-icon {
  font-weight: bold;
  font-size: 16px;
}

.goods-list-tool-active {
  color: #3B64DA !important;
}

/* ---------------商品栏结束------------------- */
</style>
