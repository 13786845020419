<template>
  <div class="wrapper ">
    <a-card class="cart-users"
            :head-style="{padding: '20px 32px', height: '79px'}"
            :bodyStyle="{padding: '50px 40px'}"
            :loading="loading">
      <template #title>
        编辑供需
      </template>
      <template #extra>
        <a-button class="item-item-btns cu-center" type="link" @click="toBack">
          返回
        </a-button>
      </template>
      <a-form :model="formData"
              :label-col="{ style: { width: '110px', display: 'flex', justifyContent: 'end', alignItems: 'center', paddingRight: '17px' }}"
              :wrapper-col="{ span: 14 }"
              @finish="onFinish">
        <a-form-item label="供需" name="categoryId" :rules="[{ required: true, message: '请选择供需类别' }]">
          <a-radio-group v-model:value="formData.categoryId">
            <a-radio :value="item.id" v-for="(item, index) in categoryList" :key="index">
              {{ item.name }}
            </a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="标题" name="title" :rules="[{ required: true, message: '请填写标题' }]">
          <a-input :maxlength="20" class="input-cell-supply" v-model:value="formData.title"
                   placeholder="请填写标题"></a-input>
        </a-form-item>
        <a-form-item label="供需描述" name="content" :rules="[{ required: true, message: '请填写供需描述' }]">
          <a-textarea class="input-cell-supply-area" :rows="6" :maxlength="formData.type === 1?600:200"
                      v-model:value="formData.content"
                      placeholder="请填写供需描述"></a-textarea>
        </a-form-item>
        <a-form-item label="电报账号" name="telegram" :rules="[{ required: true, message: '请输入电报账号连接' }]">
          <a-input class="input-cell-supply" v-model:value="formData.telegram" placeholder="请输入电报账号连接"
                   @input="phoneInput"></a-input>
        </a-form-item>
        <a-form-item class="mt-16" label=" " :colon="false">
          <a-space>
            <a-button class="submit-btn" type="primary" html-type="submit">确认</a-button>
            <a-button class="submit-btn-del ml-3" danger type="primary" @click="deleteTopic">删除</a-button>
          </a-space>
        </a-form-item>
      </a-form>
    </a-card>

    <a-modal v-model:open="openProduct" title="商品选择" @ok="handleOk" :width="'65%'"
             :bodyStyle="{height:'600px'}"
             okText='确认'
             cancelText="取消">
      <a-card :bodyStyle="{height:'600px'}">
        <a-tabs v-model:activeKey="activeKey" @change="changeProduct">
          <a-tab-pane key="1" tab="已购">
            <Scroll :height="500">
              <a-card dis-hover v-for="(item, index) in bought" :key="index" :bodyStyle="{padding: '10px'}"
                      class="mb-5">
                <List>
                  <ListItem>
                    <ListItemMeta description="">
                      <template #avatar>
                        <a-avatar :size="65" :shape="'square'" :src="item.image"/>
                      </template>
                      <template #title>
                        <div class="danbao-cell-title">{{ item.name }}</div>
                      </template>
                      <template #description>
                        <div class="danbao-cell-description">{{ item.price }} USDT</div>
                      </template>
                    </ListItemMeta>
                    <template #action>
                      <li>
                        <div class="mt-6 view-order">
                          <a-checkbox v-model:checked="item.checked" @change="ProCheck($event, item)"></a-checkbox>
                        </div>
                      </li>
                    </template>
                  </ListItem>
                </List>
              </a-card>
            </Scroll>
          </a-tab-pane>
          <a-tab-pane key="2" tab="收藏">
            <Scroll :height="500">
              <a-card dis-hover v-for="(item, index) in collect" :key="index" :bodyStyle="{padding: '10px'}"
                      class="mb-5">
                <List>
                  <ListItem>
                    <ListItemMeta description="">
                      <template #avatar>
                        <a-avatar :size="65" :shape="'square'" :src="item.image"/>
                      </template>
                      <template #title>
                        <div class="danbao-cell-title">{{ item.name }}</div>
                      </template>
                      <template #description>
                        <div class="danbao-cell-description">{{ item.price }} USDT</div>
                      </template>
                    </ListItemMeta>
                    <template #action>
                      <li>
                        <div class="mt-6 view-order">
                          <a-checkbox v-model:checked="item.checked" @change="ProCheck($event, item)"></a-checkbox>
                        </div>
                      </li>
                    </template>
                  </ListItem>
                </List>
              </a-card>
            </Scroll>
          </a-tab-pane>
          <a-tab-pane key="3" tab="浏览">
            <Scroll :height="500">
              <a-card dis-hover v-for="(item, index) in browse" :key="index" :bodyStyle="{padding: '10px'}"
                      class="mb-5">
                <List>
                  <ListItem>
                    <ListItemMeta description="">
                      <template #avatar>
                        <a-avatar :size="65" :shape="'square'" :src="item.image"/>
                      </template>
                      <template #title>
                        <div class="danbao-cell-title">{{ item.name }}</div>
                      </template>
                      <template #description>
                        <div class="danbao-cell-description">{{ item.price }} USDT</div>
                      </template>
                    </ListItemMeta>
                    <template #action>
                      <li>
                        <div class="mt-6 view-order">
                          <a-checkbox v-model:checked="item.checked" @change="ProCheck($event, item)"></a-checkbox>
                        </div>
                      </li>
                    </template>
                  </ListItem>
                </List>
              </a-card>
            </Scroll>
          </a-tab-pane>
        </a-tabs>
      </a-card>
    </a-modal>
  </div>
</template>
<script>
import { getCollectUserList, proBrowseApi, purchasedApi, } from '@/api/product'

import { communityCategoryListApi, noteDelApi, noteDetailApi, noteUpdateApi } from '@/api/discover.js'
import infiniteScroll from '@/components/scroll/infiniteScroll.vue'
import upLoad from '@/components/upload/index.vue'
import { Debounce } from '@/utils/validate'

import { Modal } from 'ant-design-vue'

export default {
  name: 'AddSupply',
  components: {
    infiniteScroll,
    upLoad
  },
  data() {
    return {
      categoryList: [], //逛逛分类
      formData: {
        categoryId: 1,
        image: '',
        content: '',
        cover: '',
        topicIds: '',
        proIds: '',
        video: '',
        id: 0,
        replyStatus: 1,
        title: '',
        type: 1, //1图文，2视频
        telegram: '',
      },
      activeKey: '1',
      proList: [],
      openProduct: false,
      prefix: 'https://t.me/',
      whereb: {
        page: 1,
        limit: 100,
      },
      wherec: {
        page: 1,
        limit: 100,
      },
      wheres: {
        page: 1,
        limit: 100,
      },
      checked: [],
      list: [],
      collect: [],
      bought: [],
      browse: [],
      checkedArr: [],
      disabled: false,
      showUploadType: 0,
      image: [],
      noteId: 0,
      cover: 'https://51danbao.oss-ap-southeast-1.aliyuncs.com/crmebimage/public/product/2024/08/10/ed8440fc0bf74bb488be8fcb81f78491zpf7rklzzi.png',
      topicList: [],
      discoverTopicList: '',
      replyStatus: '',
      categoryName: '',
      sexindex: '',
      loading: false,
      video: []
    }
  },
  watch: {
    checkedObj: {
      handler(n) {
      },
    }
  },
  mounted() {
    this.noteId = this.$route.query.noteId ? Number(this.$route.query.noteId) : 0
    this.getDetail()
    this.getCommunityCategoryList()
  },
  methods: {
    toBack(){
      this.$router.back(-1);
    },
    // 删除内容
    deleteTopic() {
      let that = this
      Modal.confirm({
        title: '确认删除吗?',
        onOk: () => {
          noteDelApi(this.noteId)
              .then(res => {
                if (res.code === 200) {
                  this.$router.push({
                    path: 'MySupply',
                    query: {}
                  })
                } else {
                  this.$Message.error(res.message)
                }
              })
        }
      })
    },
    /*获取图文详情*/
    getDetail() {
      this.loading = true
      noteDetailApi(this.noteId)
          .then(res => {
            this.formData = res.data
            this.productList = res.data.productList || []
            this.topicList = res.data.topicList || []
            //视频2 图文1
            if (this.formData.type === 2) {
              this.video = [{
                url: res.data.video,
                isCover: true
              }]
              this.showUploadType = 2
            } else {
              let images = res.data.image ? res.data.image.split(',') : []
              let index = images.findIndex(item => {
                if (item === this.formData.cover) {
                  return true
                }
              })
              this.showUploadType = 1
              images.map((item, index) => {
                this.image.push({
                  uid: index,
                  name: index + 'image.png',
                  status: 'done',
                  isCover: false,
                  url: item
                })
              })
            }
            this.proList = this.productList
            this.ProCheckSub(this.proList)
            this.discoverTopicList = res.data.topicList || []
            this.$store.commit('DiscoverTopic', this.discoverTopicList)
            this.replyStatus = this.formData.replyStatus === 2 ? true : false
            this.categoryName = this.formData.categoryName
            this.sexindex = this.formData.categoryId
            this.formData.telegram = res.data.telegram
            this.loading = false
          })
    },
    /**
     * 提交数据
     */
    formSubmit: Debounce(function (e) {
      let that = this
      if (!that.formData.title) {
        return this.$Message.error('请输入标题')
      }
      if (!that.formData.content) {
        return this.$Message.error('请输入描述')
      }
      if (!that.formData.telegram) {
        return this.$Message.error('请输入电报')
      }
      // 正确的电报账号
      if (that.formData.telegram.indexOf('https://') <= -1 ||
          that.formData.telegram === that.prefix ||
          that.formData.telegram.length <= that.prefix.length) {
        return this.$Message.error('电报输入不正确')
      }
      if (!that.formData.categoryId) {
        return this.$Message.error('请选择供需类别')
      }
      if (this.image.length > 0) {
        this.formData.cover = this.image[0].url ? this.image[0].url : this.image[0]
        if (this.formData.type === 2) {
          this.formData.cover = this.formData.cover ? this.formData.cover : this.cover
          this.formData.video = this.image[0].url ? this.image[0].url : this.image[0]
        }
      } else {
        this.formData.cover = this.formData.cover ? this.formData.cover : this.cover
      }
      this.formData.image = this.formData.image.toString()
      this.formData.noteId = this.noteId
      this.formData.proIds = this.proList.map(val => val.productId)
          .join(',')
      noteUpdateApi(that.formData)
          .then(res => {
            if (res.code !== 200) {
              this.$Message.error(res.message)
            } else {
              this.$router.push({
                name: 'MySupply',
                query: {}
              })
            }
          })
    }),
    uploads(e) {
      this.formData.image = e.uploadImgs
      this.showUploadType = e.type
      this.formData.type = e.type
      if (e.type === 0) {
        this.formData.type = 1
      }
    },
    handleOk() {
      this.proList = this.checkedArr
      this.openProduct = false
    },
    ProCheckSub(itemS) {
      itemS.forEach(foritem => {
        this.checkedArr.push(foritem)
      })
    },
    ProCheck(event, item) {
      if (item.checked) {
        this.checkedArr.push(item)
        this.checkedArr = Array.from(new Set(this.checkedArr))
      } else {
        let checkdItems = []
        this.checkedArr.forEach(foritem => {
          if (foritem.productId !== item.productId) {
            checkdItems.push(foritem)
          }
        })
        this.checkedArr = checkdItems
      }
    },
    changeProduct(e) {
      if (e === '1') {
        this.getBounht()
      }
      if (e === '2') {
        this.getCollect()
      }
      if (e === '3') {
        this.getBrowse()
      }
    },
    viewProduct() {
      this.getBounht()
      this.openProduct = true
    },
    onFinish(e) {
      this.formSubmit()
    },
    //已购
    getBounht() {
      let that = this
      purchasedApi(that.whereb)
          .then(res => {
                that.getInitchecked(res.data.list)
                that.bought = res.data.list
              }
          )
    },
    //收藏
    getCollect() {
      let that = this
      getCollectUserList(that.wherec)
          .then(res => {
                that.getInitchecked(res.data.list)
                that.collect = res.data.list
              }
          )
    },
    //足迹
    getBrowse() {
      var that = this
      proBrowseApi(that.wheres)
          .then(res => {
                that.getInitchecked(res.data.list)
                that.browse = res.data.list
              }
          )
    },
    /*获取初始化选中的数据*/
    getInitchecked(arr) {
      let that = this
      if (arr.length > 0) {
        arr.forEach((item, index) => {
          that.checkedArr.forEach((val, i) => {
            if ((item.productId === val.productId)) {
              item.checked = true
            }
          })
        })
      }
    },
    //分类列表
    getCommunityCategoryList() {
      communityCategoryListApi()
          .then(res => {
            this.categoryList = res.data
          })
    },
    phoneInput(e) {
      this.$nextTick(() => {
        let inputValue = e.target.value
        let value = 'https://t.me'
        if (inputValue === value) {
          this.formData.telegram = this.prefix
          return
        }
        value = 'https://t.m'
        if (inputValue === value) {
          this.formData.telegram = this.prefix
          return
        }
        value = 'https://t.'
        if (inputValue === value) {
          this.formData.telegram = this.prefix
          return
        }
        value = 'https://t'
        if (inputValue === value) {
          this.formData.telegram = this.prefix
          return
        }
        value = 'https://'
        if (inputValue === value) {
          this.formData.telegram = this.prefix
          return
        }
        const nonChineseValue = inputValue.replace(/[\u4e00-\u9fa5\s]/g, '')
            .replace(/@/g, '')
        if (nonChineseValue !== inputValue) {
          if (!nonChineseValue.startsWith(this.prefix)) {
            this.formData.telegram = this.prefix + nonChineseValue
          } else {
            this.formData.telegram = nonChineseValue
          }
        } else {
          if (!inputValue.startsWith(this.prefix)) {
            this.formData.telegram = this.prefix + nonChineseValue
          } else {
            this.formData.telegram = nonChineseValue
          }
        }
      })
    },
    toIndex() {
      this.$router.push({
        path: '/',
        query: {}
      })
    },
    tolist() {
      this.$router.push({
        name: 'Supply',
        query: {}
      })
    },
  }
}
</script>

<style scoped lang="scss">
.item-item-btns {
  width: 82px;
  height: 38px;
  background: #3B64DA;
  border-radius: 19px;

  font-weight: 400;
  font-size: 16px;
  color: #FFFFFF;
  line-height: 42px;
}

.submit-btn {
  width: 191px;
  height: 48px;
  background: linear-gradient(-90deg, #0080D9, #0052D9);
  box-shadow: 0px 5px 16px 0px rgba(71, 100, 184, 0.3);
  border-radius: 10px;

  font-weight: 800;
  font-size: 20px;
  color: #FFFFFF;
  line-height: 38px;
}

.input-cell-supply {
  height: 48px;
  box-shadow: 0px 3px 10px 0px rgba(107, 131, 200, 0.08);
  border-radius: 10px;
  border: 1px solid #E9EAEB;
}

.input-cell-supply-area {
  box-shadow: 0px 3px 10px 0px rgba(107, 131, 200, 0.08);
  border-radius: 10px;
  border: 1px solid #E9EAEB;
}

.submit-btn-del {
  width: 191px;
  height: 48px;
  background: linear-gradient(-90deg, red, #e72b5d);
  box-shadow: 0px 5px 16px 0px rgba(71, 100, 184, 0.3);
  border-radius: 10px;

  font-weight: 800;
  font-size: 20px;
  color: #FFFFFF;
  line-height: 38px;
}

.container {
  margin: 0 auto;
  padding: 40px 0;
  border-radius: 12px;
  min-height: 1600px;
}
</style>
