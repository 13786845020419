<template>
  <div>
    <div class="position-relative">
      <a-image :preview="false" width="1920px" height="480px" :src="require('@/assets/new/share/banner.png')"></a-image>
      <div class="position-absolute" style="bottom: 78px;left: 591px;">
        <div>
          <a-popover placement="bottomLeft" trigger="click" style="width: 610px;">
            <template #title>
              <div style="border-bottom: 1px solid #e8e8e8;height: 26px;">将链接分享至Telegram</div>
            </template>
            <template #content>
              <div class="mt-6">
                <div class="text-gray tg-url-share d-flex justify-space-between">
                  <div>{{ urlPre + `/pages/users/invite/index?spread=${this.invitationCode}` }}</div>
                  <div class="copy-btn" @click="copyShareUrl">复制</div>
                </div>
                <div class="telegram-btn" @click="toTelegram">
                  将链接分享至Telegram
                </div>
              </div>
            </template>
            <div class="banner-share-href">
              <div class="cursor-pointer">
                <span class="text-df">邀请链接: </span>
                <span class="text-df">
                {{ urlPre + `/pages/users/invite/index?spread=${this.invitationCode}` }}
                </span>
              </div>
              <div class="mr-5 cursor-pointer">
                <a-image :preview="false" :src="require('@/assets/new/share/copy.png')" width="18px" height="21px"/>
              </div>
            </div>
          </a-popover>
        </div>
        <div class="mt-5">
          <a-space :size="19">
            <a-popover placement="bottomLeft" trigger="click" style="width: 305px;">
              <template #title>
                <div style="border-bottom: 1px solid #e8e8e8;height: 26px;">将链接分享至Telegram</div>
              </template>
              <template #content>
                <div class="mt-6">
                  <div class="text-gray tg-url-share d-flex justify-space-between">
                    <div>{{ invitationCode }}</div>
                    <div class="copy-btn" @click="$Copy({text: invitationCode})">复制</div>
                  </div>
                  <div class="telegram-btn" @click="toTelegram">
                    将链接分享至Telegram
                  </div>
                </div>
              </template>
              <div class="banner-share-inviteCode cursor-pointer">
                <div class="text-df">邀请码: {{ invitationCode }}</div>
                <div class="mr-5 cursor-pointer">
                  <a-image :preview="false" :src="require('@/assets/new/share/copy.png')" width="18px" height="21px"/>
                </div>
              </div>
            </a-popover>
            <div class="banner-share-shareImg cursor-pointer" @click="()=>{$router.push({path:'/PosterShare'})}">
              <div class="text-df">生成海报 ></div>
              <div class="mr-5 cursor-pointer">
                <a-image :preview="false" :src="require('@/assets/new/share/shareImg.png')" width="24px" height="18px"/>
              </div>
            </div>
          </a-space>
        </div>
      </div>
    </div>
    <div class="page-container-all width_1200_auto">
      <div class="top-banner-font">
        如何获得奖励
      </div>
      <div class="top-banner-font-2 mt-5">
        当您的好友完成以下步骤，您即可获得 68 USDT +30%交易手续费返佣。
      </div>
      <div class="mt-13">
        <a-row :gutter="[42,24]" style="width: 1600px;" class="cu-center">
          <a-col :span="4">
            <div class="position-relative">
              <a-image class="img-round-cus" :preview="false" :src="require('@/assets/new/share/round.png')"
                       :width="216" :height="216"/>
              <div class="position-absolute" style="top: 60px;left: 70px;">
                <a-image class="img-big-small-cus" :preview="false" :src="require('@/assets/new/share/r-c1.png')"
                         :width="90" :height="87"/>
              </div>
              <div class="bottom-font-one">
                注册
              </div>
              <div class="bottom-font-two">
                使用您的邀请码或链接注册
              </div>
            </div>
          </a-col>
          <a-col :span="4" class="cu-center">
            <div class="arrow-container">
              <div class="arrow"></div>
            </div>
          </a-col>
          <a-col :span="4">
            <div class="position-relative">
              <a-image class="img-round-cus" :preview="false" :src="require('@/assets/new/share/round.png')"
                       :width="216" :height="216"/>
              <div class="position-absolute" style="top: 60px;left: 70px;">
                <a-image class="img-big-small-cus" :preview="false" :src="require('@/assets/new/share/r-c2.png')"
                         :width="90" :height="87"/>
              </div>
              <div class="bottom-font-one">
                商家入驻
              </div>
              <div class="bottom-font-two">
                提交51商户入驻申请
              </div>
            </div>
          </a-col>
          <a-col :span="4" class="cu-center">
            <div class="arrow-container">
              <div class="arrow"></div>
            </div>
          </a-col>
          <a-col :span="4">
            <div class="position-relative">
              <a-image class="img-round-cus" :preview="false" :src="require('@/assets/new/share/round.png')"
                       :width="216" :height="216"/>
              <div class="position-absolute" style="top: 60px;left: 70px;">
                <a-image class="img-big-small-cus" :preview="false" :src="require('@/assets/new/share/r-c3.png')"
                         :width="90" :height="87"/>
              </div>
              <div class="bottom-font-one">
                完成入驻
              </div>
              <div class="bottom-font-two">
                审核通过后，下发奖励
              </div>
            </div>
          </a-col>
        </a-row>
      </div>
      <div class="mt-16">
        <a-row :gutter="[19,24]" style="width: 1600px;">
          <a-col :span="12">
            <a-card :body-style="{padding: '38px 107px'}">
              <template #title>
                <div class="cu-center-between">
                  <div class="text-bold">我的收益</div>
                  <div class="right-btn-view cursor-pointer" @click="()=>{$router.push({path:'/IncomeShare'})}">查看详情
                    >
                  </div>
                </div>
              </template>
              <a-row :gutter="[87,24]">
                <a-col :span="12">
                  <div class="flex">
                    <a-image :preview="false" :width="70" :height="70"
                             :src="require('@/assets/new/share/c-l-1.png')"></a-image>
                    <div class="ml-3 cu-center-first-col">
                      <div>入驻费返佣(USDT)</div>
                      <div class="mt-2 text-bold">{{ pInfo.joinBrokerage || '0.00' }}</div>
                    </div>
                  </div>
                </a-col>
                <a-col :span="12">
                  <div class="flex">
                    <a-image :preview="false" :width="70" :height="70"
                             :src="require('@/assets/new/share/c-l-2.png')"></a-image>
                    <div class="ml-3 cu-center-first-col">
                      <div>交易佣金返佣(USDT)</div>
                      <div class="mt-2 text-bold">{{ pInfo.orderBrokerage || '0.00' }}</div>
                    </div>
                  </div>
                </a-col>
              </a-row>
            </a-card>
          </a-col>
          <a-col :span="12">
            <a-card :body-style="{padding: '38px 43px'}">
              <template #title>
                <div class="cu-center-between">
                  <div class="text-bold">我的邀请</div>
                  <div class="right-btn-view cursor-pointer" @click="()=>{$router.push({path:'/InviteShare'})}">查看详情
                    >
                  </div>
                </div>
              </template>
              <a-row :gutter="[95,24]">
                <a-col :span="8">
                  <div class="flex">
                    <a-image :preview="false" :width="70" :height="70"
                             :src="require('@/assets/new/share/c-r-1.png')"></a-image>
                    <div class="ml-3 cu-center-first-col">
                      <div>一级推荐人</div>
                      <div class="mt-2 text-bold">{{
                          peopleData.firstSpreadNum ? peopleData.firstSpreadNum : '0'
                        }}
                      </div>
                    </div>
                  </div>
                </a-col>
                <a-col :span="8">
                  <div class="flex">
                    <a-image :preview="false" :width="70" :height="70"
                             :src="require('@/assets/new/share/c-r-2.png')"></a-image>
                    <div class="ml-3 cu-center-first-col">
                      <div>二级推荐人</div>
                      <div class="mt-2 text-bold">{{
                          peopleData.secondSpreadNum ? peopleData.secondSpreadNum : '0'
                        }}
                      </div>
                    </div>
                  </div>
                </a-col>
                <a-col :span="8">
                  <div class="flex">
                    <a-image :preview="false" :width="70" :height="70"
                             :src="require('@/assets/new/share/c-r-3.png')"></a-image>
                    <div class="ml-3 cu-center-first-col">
                      <div>三级推荐人</div>
                      <div class="mt-2 text-bold">{{
                          peopleData.thirdSpreadNum ? peopleData.thirdSpreadNum : '0'
                        }}
                      </div>
                    </div>
                  </div>
                </a-col>
              </a-row>
            </a-card>
          </a-col>
        </a-row>
      </div>
      <!--    邀请列表-->
      <div class="mt-5">
        <a-card :body-style="{padding: '0',width: '1600px'}" title="邀请记录">
          <a-table :pagination="false" :loading="loading" :columns="columns" :dataSource="listData">
            <template #headerCell="{ column }">
              <template v-if="column.key === 'time'">
                <div class="text-left">{{ column.title }}</div>
              </template>
            </template>
            <template #bodyCell="{ column, record }">
              <template v-if="column.key === 'nickname'">
                <div>{{ record.nickname }}</div>
              </template>
              <template v-if="column.key === 'uid'">
                <div class="text-gray">{{ record.uid }}</div>
              </template>
              <template v-if="column.key === 'type'">
                <div class="text-bold">{{ record.type }}</div>
              </template>
              <template v-if="column.key === 'joinTime'">
                <div>{{ record.joinTime }}</div>
              </template>
              <template v-if="column.key === 'joinBrokerageSum'">
                <div class="text-red text-bold">{{ record.joinBrokerageSum ? record.joinBrokerageSum : '0.00' }} USDT
                </div>
              </template>
              <template v-if="column.key === 'orderBrokerageSum'">
                <div class="text-red text-bold">{{ record.orderBrokerageSum ? record.orderBrokerageSum : '0.00' }}
                  USDT
                </div>
              </template>
            </template>
          </a-table>
          <!-- 分页 -->
          <div class="page-size mt-5 mb-5 mr-5" v-if="listData && listData.length > 0">
            <a-pagination :page-size="pageData.limit" :total="total" :show-size-changer="false" show-less-items
                          @change="changePageNum" @page-size-change="changePageSize"/>
          </div>
        </a-card>
      </div>
    </div>
    <!--      排行榜-->
    <div class="mt-5 mb-5">
      <rank-share/>
    </div>
    <div class="width_1200_auto">
      <div class="mt-8 mb-8">
        <div class="text-bold">奖励规则:</div>
        <div class="text-df m-5">
          <div class="bottom-font-runk"> 1.
            邀请好友入驻51担保网，您可从商家入驻缴纳的店铺费用中获得三级别的收益。而“三级”表示这种收益存在三级别的代理体系。
          </div>
          <div class="bottom-font-runk"> 2. 通过自己的邀请码或链接注册的用户并成功入驻的为一级商家《一级商家店铺费返佣
            68SUDT，店铺交易手续费返佣30%》。
          </div>
          <div class="bottom-font-runk"> 3. 通过一级商家的邀请码或链接注册的用户并成功入驻的为二级商家《二级商家店铺费返佣
            48SUDT，店铺交易手续费返佣20%》。
          </div>
          <div class="bottom-font-runk"> 4. 通过一级商家的邀请码或链接注册的用户并成功入驻的为二级商家《二级商家店铺费返佣
            18SUDT，店铺交易手续费返佣10%》。
          </div>
          <div class="bottom-font-runk"> 5. 更多详细规则请查看《51担保网推广分享说明》。
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getUserInfo, myPromotion } from '@/api/user.js'
import { incomeData, inviteData, inviteDataList, spreadData, } from '@/api/tg.js'

import { company } from '@/config/index.js'
import { Divider, Scroll } from 'view-ui-plus'
import RankShare from '@/views/share/rankList.vue'

export default {
  components: {
    Divider,
    Scroll,
    RankShare,
  },
  data() {
    return {
      visible2: false,
      columns: [
        {
          title: '用户',
          dataIndex: 'nickname',
          key: 'nickname',
          align: 'center'
        }, {
          title: 'ID',
          dataIndex: 'inviteCode',
          key: 'inviteCode',
          align: 'center'
        }, {
          title: '加入时间',
          dataIndex: 'joinTime',
          key: 'joinTime',
          align: 'center'
        }, {
          title: '入驻返佣',
          dataIndex: 'joinBrokerageSum',
          key: 'joinBrokerageSum',
          align: 'center'
        }, {
          title: '返佣金额',
          dataIndex: 'orderBrokerageSum',
          key: 'orderBrokerageSum',
          align: 'center'
        },
      ],
      promotionInfo: {},
      bgColor: '#e93323',
      invitationCode: '',
      spreadDataInfo: {},
      showBotm: false,
      urlPre: '',
      pInfo: {},
      peopleData: {},
      loading: false,
      pageData: {
        page: 1,
        limit: 10,
      },
      listData: [],
      total: 0,
      grade: -1,
    }
  },
  mounted() {
    if (this.$store.getters.token) {
      this.urlPre = company.app
      this.getMyPromotion()
      this.getMyUserInfo()
      this.getSpreadData()
      this.getInviteData()
      this.upCallback()
      this.brokerageQuery()
    } else {
      this.$router.push({
        path: '/Login',
        query: {}
      })
    }
  },
  methods: {
    // 修改页码
    changePageNum(val) {
      this.pageData.page = val
      this.upCallback()
    },
    // 修改页数
    changePageSize(val) {
      this.pageData.page = 1
      this.pageData.limit = val
      this.upCallback()
    },
    upCallback() {
      let data = {
        grade: this.grade,
        page: this.pageData.page,
        limit: this.pageData.limit,
      }
      inviteDataList(data)
          .then((res) => {
            let curPageData = res.data
            if (curPageData && curPageData.length >= 0) {
              this.listData = res.data
              if (this.listData && curPageData.length >= 1) {
                this.total = this.listData[0].total
              }
            }
          })
    },
    getInviteData() {
      inviteData()
          .then(res => {
            this.peopleData = res.data
          })
    },
    brokerageQuery() {
      this.loading = true
      incomeData(this.pageData)
          .then((res) => {
            if (res.data) {
              this.pInfo.brokerage = res.data.brokerage
              this.pInfo.joinBrokerage = res.data.joinBrokerage
              this.pInfo.orderBrokerage = res.data.orderBrokerage
            }
            this.loading = false
          })
    },
    toTelegram() {
      window.open(`https://t.me/share/url?url=${this.urlPre}/pages/users/invite/index?spread=${this.invitationCode}`, '_blank')
    },
    pointToPartner() {
      this.$router.push({
        path: '/Partner',
        query: {}
      })
    },
    copyShareUrl() {
      this.$Copy({ text: this.urlPre + '/pages/users/invite/index?spread=' + this.invitationCode })
    },
    copyShare() {
      this.$Copy({ text: this.invitationCode })
    },
    getSpreadData() {
      spreadData()
          .then((res) => {
            this.spreadDataInfo = res.data
            if (this.spreadDataInfo.num >= 100) {
              this.showBotm = true
            }
          })
    },
    getMyUserInfo: function () {
      getUserInfo({
        page: 1,
        limit: 5
      })
          .then(res => {
            this.invitationCode = res.data.invitationCode
          })
    },
    /**
     * 获取个人用户信息
     */
    getMyPromotion: function () {
      myPromotion()
          .then(res => {
            this.promotionInfo = res.data
          })
    },
  }
}
</script>

<style scoped lang="scss">
.bottom-font-runk {
  font-weight: 400;
  font-size: 14px;
  color: #000000;
  line-height: 38px;
}

:deep(.ant-col-12:first-child) {
  padding-left: 0 !important;
}

:deep(.ant-col-12:last-child) {
  padding-right: 0 !important;
}

.right-btn-view {
  width: 94px;
  height: 32px;
  background: linear-gradient(-90deg, #0080D9, #0052D9);
  border-radius: 5px;

  font-weight: 400;
  font-size: 14px;
  color: #FFFFFF;
  line-height: 20px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.bottom-font-one {
  margin-top: 38px;
  font-weight: bold;
  font-size: 18px;
  color: #3F7EEA;
  text-align: center;
}

.bottom-font-two {
  margin-top: 10px;
  font-weight: 400;
  font-size: 14px;
  color: #000000;
  text-align: center;
}

.arrow-container {
  width: 165px;
  height: 12px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.arrow {
  width: 165px;
  height: 12px;
  margin-bottom: 100px;
  background: url('@/assets/new/share/right-jt.png') no-repeat center;
  background-size: contain;
  position: relative;
  transition: filter 0.3s ease;
}

.arrow::before {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, transparent, rgba(255, 255, 255, 0.5), transparent);
  animation: highlight 2s infinite;
  pointer-events: none;
}

@keyframes highlight {
  0% {
    left: -100%;
    right: 100%;
  }
  50% {
    left: 0%;
    right: 0%;
  }
  100% {
    left: 100%;
    right: -100%;
  }
}

.arrow:hover {
  filter: brightness(1.2); /* Add brightness on hover */
}

.top-banner-font-2 {
  font-weight: 400;
  font-size: 14px;
  color: #6B6C6D;
  line-height: 20px;
}

.top-banner-font {
  font-weight: 500;
  font-size: 32px;
  color: #000000;
  line-height: 20px;
}

.banner-share-shareImg {
  width: 305px;
  height: 60px;
  padding-left: 21px;
  background-color: #FFF118;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.banner-share-inviteCode {
  padding-left: 21px;
  width: 305px;
  height: 60px;
  background: #FFFFFF;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.banner-share-href {
  width: 630px;
  height: 60px;
  background: #FFFFFF;
  border-radius: 10px;
  padding-left: 21px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-title-view {
  width: 84px;
  height: 26px;
  border-radius: 50px;
  background: rgba(68, 68, 68, 0.6);

  font-size: 12px;
  font-weight: normal;
  line-height: 25px;
  letter-spacing: 0px;
  color: #FFFFFF;

  display: flex;
  justify-content: center;
  align-items: center;
}

.content-cus {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;

  .info-font {
    font-size: 16px;
    font-weight: normal;
    line-height: 38px;
    height: 38px;
    letter-spacing: 0px;
    color: #999999;
  }

  .info-font-1 {
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    height: 32px;
    letter-spacing: 0px;
    margin-top: 10px;
  }

  .botm-btns {
    margin-top: 10px;
    width: 120px;
    height: 35px;
    border-radius: 30px;
    box-sizing: border-box;
    border: 1px solid #E2E2E2;

    font-size: 14px;
    font-weight: normal;
    line-height: 32px;
    letter-spacing: 0px;

    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;
  }
}

.flex-title {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;

  .line-shu {
    width: 3px;
    height: 17px;
    background: #269FFA;
    border-radius: 10px;
  }
}

.page-container {
  width: 1600px;
  margin-top: 320px;

  .page-btns {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin-bottom: 50px;

    .btn-font {
      font-size: 15px;
      font-weight: normal;
      line-height: 25px;
      color: #FFFFFF;
      margin-right: 10px;
    }

    .btn--1 {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 192px;
      height: 50px;
      border-radius: 50px;
      background: #269FFA;
      margin-right: 10px;
    }

    .btn--2 {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 204px;
      height: 50px;
      border-radius: 50px;
      background: #FFBA18;
      margin-right: 10px;
    }

    .btn--3 {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-left: 10px;
      width: 418px;
      height: 50px;
      border-radius: 50px;
      background: linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 99%);

      font-size: 16px;
      font-weight: normal;
      line-height: 20px;
      color: #999999;
    }
  }
}


.page-container-all {
  margin-top: 69px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .page-join-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-wrap: nowrap;

    .font-top1 {
      font-size: 50px;
      font-weight: normal;
      line-height: 26px;
      letter-spacing: 0px;
      color: #FF553B;
    }

    .font-top2 {
      margin-top: 30px;
      font-size: 22px;
      font-weight: 350;
      line-height: 25px;
      letter-spacing: 0px;
      color: #FF6923;
    }

    .page-join {
      margin-top: 10px;
      font-size: 22px;
      font-weight: 900;
      line-height: 25px;
      letter-spacing: 0px;
      color: #FF6923;
    }
  }

}
</style>
