<template>
  <QRX :data="telUrl" :gradient="{
        type: 'linear',
        rotate: 45,
        colors: colors }"
       :shapes="{ body: 'square', eyeball: 'rounded', eyeframe: 'rounded' }"
  />
</template>

<script>
import QRX from '@qr-x/vue'

export default {
  name: 'qrcode',
  props: {
    telUrl: {
      type: String,
      default: ''
    },
    colors:{
      type:Array,
      default:[
        {value: '#D43305', stop: 0 },
        {value: '#EC5F5A', stop: 35},
        {value: '#FEBA52', stop: 100}
      ]
    }
  },
  components: {
    QRX
  }
}
</script>
