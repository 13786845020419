<template>
  <div class="navbar" :class="{'small-search-box':useClass === 'fixed-show'}">
    <div class="container width_1200_auto flex flex-a-c justify-center">
      <img
          src="../assets/index/logo.png"
          v-if="showLogo"
          class="logo-img"
          alt=""
          @click="toIndexClick"
      />

      <div class="d-flex justify-between align-center" style="flex: 1">
        <div class="d-flex ml-5 cu-center">
          <div class="padding-lr-sm" v-for="(item, index) in menu" :key="index" @click="toPage(item)">
            <div class="menu-font golbal-btn" :class="selectIndex === item.pathIndex ? 'golbal-btn-select' : ''">
              {{ item.name }}
            </div>
          </div>
        </div>
        <div class="d-flex ml-5 cu-center">
          <div :class="{'small-search-box':useClass === 'fixed-show'}" class="search-box mr-3">
            <a-input
                v-model:value="keyword"
                size="large"
                class="search"
                :placeholder="$t('srh_0001')">
              <template #prefix>
                <a-select v-model:value="selectMerOrPro" :bordered="false">
                  <a-select-option value="0" class="search-prefix">{{ $t('srh_0002') }}</a-select-option>
                  <a-select-option value="1" class="search-prefix">{{ $t('srh_0003') }}</a-select-option>
                </a-select>
                <a-divider style="margin-left: -10px;" type="vertical"/>
              </template>
              <template #suffix>
                <Button style="height: 24px;width: 24px;" type="primary" shape="circle" icon="ios-search"
                        @click="search"></Button>
              </template>
            </a-input>
          </div>

          <div class="padding-lr-xs" v-for="(item, index) in menuRight" :key="index" @click="toPage(item)">
            <div class="menu-font-cus golbal-btn"
                 :class="selectIndex === item.pathIndex ? 'golbal-btn-select' : ''">
              {{ item.name }}
            </div>
          </div>

          <div v-show="!userInfo.nickname" class="padding-lr-xs" v-for="(item, index) in menuRightEnd" :key="index"
               @click="toPage(item)">
            <div v-if="index === 0" class="menu-font-cus golbal-btn"
                 :class="selectIndex === item.pathIndex ? 'golbal-btn-select' : ''">
              {{ item.name }}
            </div>
            <div v-else class="menu-font-cus golbal-btn rigster"
                 :class="selectIndex === item.pathIndex ? 'golbal-btn-select' : ''">
              {{ item.name }}
            </div>
          </div>

          <div v-if="userInfo.nickname" class="ml-4 cursor-pointer cu-center golbal-btn">
            <div class="cu-center">
              <div class="cu-center" @click="goUserCenter()">
                <a-avatar class="person-icon" :src="userInfo.avatar" :size="42.2">
                  <template #icon>
                    <a-image :src="require('@/assets/images/default.png')" :preview="false"/>
                  </template>
                </a-avatar>
                <span class="ml-1 text-lg">{{ userInfo.nickname }}</span>
              </div>
              <a-dropdown arrow>
                <div class="cu-center">
                  <span class="ml-1"><CaretDownOutlined/></span>
                </div>
                <template #overlay>
                  <a-menu>
                    <a-menu-item>
                      <router-link class="nav-item flex justify-start align-center golbal-btn text-df"
                                   :to="`/MyWallet`">
                        <Icon custom="iconfont icon-qianbao mr-1 mb-1" :size="21"/>
                        我的钱包
                      </router-link>
                    </a-menu-item>
                    <a-menu-item>
                      <router-link class="nav-item flex justify-start align-center golbal-btn text-df"
                                   :to="`/UserInfo`">
                        <Icon custom="iconfont icon-geren mr-1 mb-1" :size="21"/>
                        个人信息
                      </router-link>
                    </a-menu-item>
                    <a-menu-item>
                      <router-link class="nav-item flex justify-start align-center golbal-btn text-df"
                                   :to="`/MyCollect`">
                        <Icon custom="iconfont icon-shoucang mr-1 mb-1 text-bold" :size="21"/>
                        我的收藏
                      </router-link>
                    </a-menu-item>
                    <a-menu-item>
                      <router-link class="nav-item flex justify-start align-center golbal-btn text-df"
                                   :to="`/Attention`">
                        <Icon custom="iconfont icon-dianpu mr-1 mb-1" :size="21"/>
                        关注店铺
                      </router-link>
                    </a-menu-item>
                    <a-menu-item>
                      <router-link class="nav-item flex justify-start align-center golbal-btn text-df" :to="`/History`">
                        <Icon custom="iconfont icon-zuji mr-1 mb-1" :size="21"/>
                        足迹
                      </router-link>
                    </a-menu-item>
                    <a-menu-item>
                      <div class="nav-item flex justify-start align-center golbal-btn text-df"
                           @click="logout">
                        <Icon type="ios-log-out text-bold mr-1 mb-1" :size="21"/>
                        退出
                      </div>
                    </a-menu-item>
                  </a-menu>
                </template>
              </a-dropdown>
            </div>
          </div>
        </div>
      </div>
    </div>

    <to-trx :visible="toTrxViable" @close="toTrxClose"/>
  </div>
</template>

<script>
import noticeTop from '@/components/notice-top/index.vue'
import { getArticleList } from '@/api/api'
import toTrx from '@/components/toTrx/index.vue'

import { CaretDownOutlined } from '@ant-design/icons-vue'

export default {
  name: 'search',
  components: {
    toTrx,
    noticeTop,
    CaretDownOutlined
  },
  props: {
    where: {
      type: Object,
      default: {}
    },
    showTag: { // 是否展示搜索栏下方热门搜索
      type: Boolean,
      default: true
    },
    showLogo: { // 是否展示左侧logo
      type: Boolean,
      default: true
    },
    selectFlag: { // 是否为店铺页面
      type: String,
      default: ''
    },
    storeId: { // 是否为店铺页面
      type: String,
      default: ''
    },
    hover: {
      type: Boolean,
      default: false
    },
    useClass: {
      type: null,
      default: ''
    }
  },
  watch: {
    '$store.getters.userInfo': {
      handler(newVal, oldVal) {
        this.$nextTick(() => {
          this.userInfo = newVal
        })
      }
    },
    '$store.getters.token': {
      handler(newVal, oldVal) {
        this.$nextTick(() => {
          if (!newVal) {
            this.userInfo = { nickname: '' }
          }
        })
      }
    },
    '$route': {
      handler(val) {
        this.tabIndexSelect(val)
        if (val.path !== '/GoodsList') {
          return
        }
        this.$nextTick(() => {
          this.keyword = this.$route.query.keyword
          const selectFlag = this.$route.query.selectFlag
          if (selectFlag === '0' || selectFlag === '1') {
            this.selectMerOrPro = selectFlag + ''
          } else {
            this.selectMerOrPro = '0'
          }
          if (this.keyword && (selectFlag === '0' || selectFlag === '1')) {
            this.search()
          }
        })
      }
    },
  },
  data() {
    return {
      selectMerOrPro: '0',
      keyword: '', // 搜索内容
      onlyStore: false,
      promotionTags: [],
      dataMsg: [],
      toTrxViable: false,
      selectIndex: 0,
      menu: [
        {
          name: '首页',
          path: '/',
          type: 0,
          pathIndex: 0,
        },
        {
          name: '51专群',
          path: '/Guarantee',
          type: 0,
          pathIndex: 1,
        },
        {
          name: '供需',
          path: '/Supply',
          type: 0,
          pathIndex: 3,
        },
        {
          name: '曝光资讯',
          path: '/Infor',
          type: 0,
          pathIndex: 4,
        },
        {
          name: '推广分享',
          path: '/Share',
          type: 0,
          pathIndex: 5,
        },
        {
          name: '商户入驻',
          path: '/enter',
          type: 0,
          pathIndex: 6,
        },
        {
          name: '合伙人',
          path: '/Partner',
          type: 0,
          pathIndex: 8,
        },
      ],
      menuRight: [
        {
          name: '我的订单',
          path: '/MyOrder',
          type: 0,
          pathIndex: 7,
        },
        {
          name: 'TRX兑换',
          path: '/',
          type: 1,
          pathIndex: 2,
        },
      ],
      menuRightEnd: [
        {
          name: '登录',
          path: '/login',
          type: 0,
          pathIndex: 9,
        },
        {
          name: '注册',
          path: '/SignUp',
          type: 0,
          pathIndex: 10,
        }
      ],
      userInfo: {},
    }
  },
  created() {
    if (this.where) {
      this.keyword = this.where.keyword
    }
    if (this.selectFlag) {
      this.selectMerOrPro = this.selectFlag
    }
    this.getCidArticle()
    this.tabIndexSelect(this.$route)

    let user = localStorage.getItem('USER_INFO')
    if (user) {
      this.userInfo = JSON.parse(user)
    }
  },
  methods: {
    tabIndexSelect(val) {
      if (val.path === '/') {
        this.selectIndex = 0
      } else if (val.path === '/Guarantee' || val.path === '/GuaranteeRecord') {
        this.selectIndex = 1
      } else if (val.path === '/Supply' || val.path === '/SupplyInfo' || val.path === '/AddSupply') {
        this.selectIndex = 3
      } else if (val.path === '/Infor' || val.path === '/Details') {
        this.selectIndex = 4
      } else if (val.path === '/Share' || val.path === '/IncomeShare' || val.path === '/InviteShare' || val.path === '/RankShare' || val.path === '/PosterShare' || val.path === '/PosterShare') {
        this.selectIndex = 5
      } else if (val.path === '/enter' || val.path === '/JoinPay' || val.path === '/PaySuc' || val.path === '/JoinHis' || val.path === '/AuditSuc' || val.path === '/AuditErr') {
        this.selectIndex = 6
      } else if (val.path === '/MyOrder' || val.path === '/OrderDetails') {
        this.selectIndex = 7
      } else if (val.path === '/Partner' || val.path === '/PartnerJoin') {
        this.selectIndex = 8
      } else if (val.path === '/login' || val.path === '/Login') {
        this.selectIndex = 9
      } else if (val.path === '/SignUp') {
        this.selectIndex = 10
      } else {
        this.selectIndex = -1
      }
    },
    logout() {
      this.$store.dispatch('GETLOGOUT')
    },
    goUserCenter() {
      this.$router.push({
        name: 'UserHome'
      })
    },
    toInfors() {
      this.$router.push({
        path: '/Infor'
      })
    },
    toTrxClose() {
      this.toTrxViable = false
    },
    toTrx() {
      this.toTrxViable = true
    },
    getCidArticle() {
      const data1 = {
        page: 1,
        limit: 1000
      }
      this.dataMsg = []
      getArticleList(6, data1)
          .then(res => {
            res.data.list.forEach(item => {
              this.dataMsg.push(item.title)
            })
          })
    },
    removeHtmlTags(str) {
      return str.replace(/<[^>]*>/g, '')
    },
    toPage(item) {
      this.selectIndex = item.pathIndex
      if (item.type === 0) {
        this.$router.push({
          path: item.path,
        })
      } else {
        this.toTrxViable = true
      }
    },
    toIndexClick() {
      this.$router.push({
        path: '/',
      })
    },
    search() { // 全平台搜索商品
      const data1 = {
        keyword: this.keyword,
        selectFlag: this.selectMerOrPro
      }
      this.$emit('onSearch', data1)
    },
  },
  computed: {},
}
</script>
<style scoped lang="scss">
.golbal-btn:hover {
  color: #2b85e4;
}

.golbal-btn-select {
  color: #2b85e4;
  font-weight: 500;

  border-bottom: 2px solid #2b85e4;;
}

.rigster {
  width: 60px;
  height: 32px;
  color: #FFFFFF;
  background: #0052D9;
  border-radius: 8px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.menu-font {
  font-weight: 480;
  font-size: 18px;
  line-height: 82px;
  cursor: pointer;
}

.menu-font-cus {
  font-weight: 480;
  font-size: 16px;
  line-height: 82px;
  cursor: pointer;
}

.navbar {
  max-width: 1920px !important;
  margin: 0 auto;
  height: 90px;
  position: relative;
  background: #FFFFFF;

  .notice-top {
    position: absolute;
    min-width: 100% !important;
    top: 0;
    margin: 0 auto;
    background-color: #FFFFFF !important;
  }
}


:deep(button) {
  color: #FFFFFF !important;
}

.only-store {
  text-align: right;
  color: #F31947;
  cursor: pointer;
}

.search-icon {
  width: 100%;
  height: 100%;
}

.small-search-box {
  height: 60px;
  margin: 0 !important;
}

.container {
  position: relative;
  height: 100%;
}

.search {
  width: 270px;
  height: 42px;
  border-radius: 50px;

  :deep(.ivu-input.ivu-input-large) {
    border: 1.4px solid #8DC9F6;
    box-sizing: border-box;
    border-radius: 19.6px;
    position: relative;
    padding-left: 26px;
    font-size: 14px;
    font-weight: normal;
    height: 21px;
    color: #999;

    &:focus {
      box-shadow: none;
    }
  }

  :deep(.ivu-input-group-append) {
    border-radius: 19.6px !important;
    cursor: pointer;
    box-sizing: border-box;
    border: 1.4px solid #F31947;
    width: 67.2px;
    height: 21px;
    position: absolute;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    z-index: 99;
    background-color: #F31947;
    color: #ffffff;

    button {
      font-size: 14px;
      font-weight: 600;
      line-height: 1;
    }
  }
}

.search-box {
  margin-left: 28px;
}

.logo-img {
  max-width: 172px;
  height: 42px !important;
  cursor: pointer;
}

.store-search {
  width: 55.6px;
  padding: 0 9px;
  border-radius: 0;
  border-radius: 3px;

  &:nth-child(2) {
    width: 55px;
    margin-left: -2px;
    border-radius: 3px;
  }
}

.btn-div {
  position: relative;
  height: 0px;
  top: -38px;
  left: 352px;
}

.history-list {

  margin-left: 28px;
}

.history-item {
  font-size: 13px;
  font-weight: normal;
  line-height: 16px;
  letter-spacing: 0px;
  margin-right: 17px;
  color: #666666;
  cursor: pointer;
}
</style>
