module.exports = {
  linkCustomerOne: [
    {
      headImg: 'https://51danbao.oss-ap-southeast-1.aliyuncs.com/crmebimage/pic/pic-301.png',
      name: '担保网招商-大果',
      telQrCode: 'https://t.me/dbw51888',
      account: '@dbw51888',
      telPhone: '+888 0497 5151'
    },
    {
      headImg: 'https://51danbao.oss-ap-southeast-1.aliyuncs.com/crmebimage/pic/pic-12.png',
      name: '担保网招商-大鹏',
      telQrCode: 'https://t.me/dbw515151',
      account: '@dbw515151',
      telPhone: '+888 0496 5151'
    },
    {
      headImg: 'https://51danbao.oss-ap-southeast-1.aliyuncs.com/crmebimage/pic/pic-333.png',
      name: '担保网招商-璐璐',
      telQrCode: 'https://t.me/dbw51lulu',
      account: '@dbw51lulu',
      telPhone: '+888 0327 6451'
    },
    {
      headImg: 'https://51danbao.oss-ap-southeast-1.aliyuncs.com/crmebimage/pic/pic-344.png',
      name: '担保网招商-黄河',
      telQrCode: 'https://t.me/dbw5155',
      account: '@dbw5155',
      telPhone: '+888 0251 4351'
    },
    {
      headImg: 'https://51danbao.oss-ap-southeast-1.aliyuncs.com/crmebimage/pic/pic-355.png',
      name: '担保网招商-大C',
      telQrCode: 'https://t.me/dbw5151C',
      account: '@dbw5151C',
      telPhone: '+888 0279 5151'
    },
    {
      headImg: 'https://51danbao.oss-ap-southeast-1.aliyuncs.com/crmebimage/pic/pic-366.png',
      name: '7*24小时在线客服',
      telQrCode: 'https://t.me/dbw51kf',
      account: '@dbw51kf',
      telPhone: '+888 0384 9651'
    },
    {
      headImg: 'https://51danbao.oss-ap-southeast-1.aliyuncs.com/crmebimage/pic/pic-377.png',
      name: '纠纷仲裁',
      telQrCode: 'https://t.me/dbw51zc',
      account: '@dbw51zc',
      telPhone: '+888 0797 6151'
    },
    {
      headImg: 'https://51danbao.oss-ap-southeast-1.aliyuncs.com/crmebimage/pic/pic-388.png',
      name: '交易员',
      telQrCode: 'https://t.me/dbw51a',
      account: '@dbw51a',
      telPhone: '+888 0972 4351'
    },
    {
      headImg: 'https://51danbao.oss-ap-southeast-1.aliyuncs.com/crmebimage/pic/pic-399.png',
      name: '交易员',
      telQrCode: 'https://t.me/dbw51b',
      account: '@dbw51b',
      telPhone: '+888 0963 7451'
    },
    {
      headImg: 'https://51danbao.oss-ap-southeast-1.aliyuncs.com/crmebimage/pic/pic-410.png',
      name: '交易员',
      telQrCode: 'https://t.me/dbw51c',
      account: '@dbw51c',
      telPhone: '+888 0364 7251'
    },
    {
      headImg: 'https://51danbao.oss-ap-southeast-1.aliyuncs.com/crmebimage/pic/pic-411.png',
      name: '交易员',
      telQrCode: 'https://t.me/dbw51d',
      account: '@dbw51d',
      telPhone: '+888 0762 9451'
    },
    {
      headImg: 'https://51danbao.oss-ap-southeast-1.aliyuncs.com/crmebimage/pic/pic-212.png',
      name: '客服娜娜',
      telQrCode: 'https://t.me/dbw51nana',
      account: '@dbw51nana',
      telPhone: '+888 0269 7451'
    },
    {
      headImg: 'https://51danbao.oss-ap-southeast-1.aliyuncs.com/crmebimage/pic/pic-413.png',
      name: '客服依依',
      telQrCode: 'https://t.me/dbw51yiyi',
      account: '@dbw51yiyi',
      telPhone: '+888 0379 8451'
    },
    {
      headImg: 'https://51danbao.oss-ap-southeast-1.aliyuncs.com/crmebimage/pic/pic-414.png',
      name: '客服依依',
      telQrCode: 'https://t.me/dbw51yiyi',
      account: '@dbw51yiyi',
      telPhone: '+888 0379 8451'
    },
    {
      headImg: 'https://51danbao.oss-ap-southeast-1.aliyuncs.com/crmebimage/pic/pic-415.png',
      name: '客服婷婷',
      telQrCode: 'https://t.me/dbw51tingting',
      account: '@dbw51tingting',
      telPhone: '+888 0293 6751'
    },
    {
      headImg: 'https://51danbao.oss-ap-southeast-1.aliyuncs.com/crmebimage/pic/pic-416.png',
      name: '客服萌萌',
      telQrCode: 'https://t.me/dbw51mengmeng',
      account: '@dbw51mengmeng',
      telPhone: '+888 0406 1351'
    },
    {
      headImg: 'https://51danbao.oss-ap-southeast-1.aliyuncs.com/crmebimage/pic/pic-217.png',
      name: '客服言',
      telQrCode: 'https://t.me/dbw51111',
      account: '@dbw51111',
      telPhone: '+888 0649 6151'
    },
    {
      headImg: 'https://51danbao.oss-ap-southeast-1.aliyuncs.com/crmebimage/pic/pic-418.png',
      name: '客服小Q',
      telQrCode: 'https://t.me/dbw5158',
      account: '@dbw5158',
      telPhone: '+888 0727 6251'
    },
    {
      headImg: 'https://51danbao.oss-ap-southeast-1.aliyuncs.com/crmebimage/pic/pic-419.png',
      name: '客服阿七',
      telQrCode: 'https://t.me/dbw5166',
      account: '@dbw5166',
      telPhone: '+888 0439 5051'
    },
  ],
  joinMerInfo: [
    {
      width: 127,
      height: 150,
      img: require('@/assets/new/enter/mer0.png'),
    },
    {
      width: 125,
      height: 130,
      img: require('@/assets/new/enter/mer1.png'),
    }
    ,
    {
      width: 124,
      height: 141,
      img: require('@/assets/new/enter/mer2.png'),
    }
    ,
    {
      width: 156,
      height: 124,
      img: require('@/assets/new/enter/mer3.png'),
    }
    ,
    {
      width: 124,
      height: 130,
      img: require('@/assets/new/enter/mer4.png'),
    }
    ,
    {
      width: 140,
      height: 130,
      img: require('@/assets/new/enter/mer5.png'),
    }
    ,
    {
      width: 138,
      height: 154,
      img: require('@/assets/new/enter/mer6.png'),
    }
    ,
    {
      width: 143,
      height: 137,
      img: require('@/assets/new/enter/mer7.png'),
    }
    ,
    {
      width: 161,
      height: 129,
      img: require('@/assets/new/enter/mer8.png'),
    }
    ,
    {
      width: 159,
      height: 116,
      img: require('@/assets/new/enter/mer9.png'),
    }
    ,
    {
      width: 122,
      height: 155,
      img: require('@/assets/new/enter/mer10.png'),
    }
    ,
    {
      width: 163,
      height: 131,
      img: require('@/assets/new/enter/mer11.png'),
    }
  ]
}
