module.exports = {
  menus: [
    {
      title: '51担保网入门',
      subTitle: '如果你是第一次访问 51担保网 ，请从这里开始。',
      img: require('@/assets/new/help/h_0.png'),
      cid: 9
    },
    {
      title: '账户管理',
      subTitle: '了解如何创建账户、以及您可以在 51担保网上做什么',
      img: require('@/assets/new/help/h_1.png'),
      cid: 10
    },
    {
      title: '交易问题',
      subTitle: '了解如何在 51担保网 上购买商品以及注意事项',
      img: require('@/assets/new/help/h_2.png'),
      cid: 11
    },
    {
      title: '商家入驻',
      subTitle: '了解如何入驻 51担保网 成为商家，并上架出售你的商品',
      img: require('@/assets/new/help/h_3.png'),
      cid: 12
    },
    {
      title: '51专群',
      subTitle: '了解如何在 51担保网 上发起专属1对1交易专群',
      img: require('@/assets/new/help/h_4.png'),
      cid: 13
    },
    {
      title: '推广分享',
      subTitle: '了解如何通过推广分享以及加入合伙人计划，轻松赚取佣金！',
      img: require('@/assets/new/help/h_5.png'),
      cid: 14
    },
    {
      title: '规则中心',
      subTitle: '了解商家规则、买家规则',
      img: require('@/assets/new/help/h_6.png'),
      cid: 15
    },
    {
      title: '退款售后',
      subTitle: '了解退货退款、退款规则',
      img: require('@/assets/new/help/h_7.png'),
      cid: 16
    },
    {
      title: '用户安全',
      subTitle: '详细了解 账户安全与账户信息',
      img: require('@/assets/new/help/h_8.png'),
      cid: 17
    },
    {
      title: '常见问题',
      subTitle: '了解 51担保网 上常见的问题',
      img: require('@/assets/new/help/h_9.png'),
      cid: 18
    },
  ],
}
