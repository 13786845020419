import request from '@/utils/api_req.js'

/**
 * 获取消息列表
 *
 */
export function getMsgs(data) {
  return request.get('msg/msgs', data)
}

/**
 * 单个读
 *
 */
export function getReadOnce(data) {
  return request.post('msg/readOnce', data)
}

/**
 * 全部读
 *
 */
export function getReadAll() {
  return request.get('msg/readAll')
}

/**
 * 未读个数
 */
export function getReadCount() {
  return request.get('msg/readCount')
}

/**
 * 未读个数
 */
export function readCountByChat() {
  return request.get('msg/readCountByChat')
}

/**
 * 未读个数
 */
export function readCountByMes() {
  return request.get('msg/readCountByMes')
}

/**
 * 按订单查询订单信息 充值提现
 */
export function getMsgsOrderInfo(orderNo) {
  return request.get('msg/msgs/order/' + orderNo);
}

/**
 * 按订单查询订单信息 入驻
 */
export function getMsgsJoinInfo(orderNo) {
  return request.get('msg/msgs/join/' + orderNo);
}


/**
 * 按订单查询订单信息 担保
 */
export function getMsgsDbInfo(orderNo) {
  return request.get('msg/msgs/db/' + orderNo);
}

/**
 * 按类型未读个数
 */
export function getReadCountByType(data) {
  return request.post('msg/readCountByType', data)
}

/**
 * 按类型未读个数
 */
export function getReadMsgs() {
  return request.get('msg/msgs/new')
}

/**
 * 按类型未读个数
 */
export function getSearchMsgs(keyword) {
  return request.get('msg/msgs/search/' + keyword)
}

/**
 * 客服打开保存
 */
export function msgCusInfoSave(data) {
  return request.post('msg/cus/save', data, {
    noAuth: true
  })
}

/**
 * 客服打开保存
 */
export function msgCusTempSave(data) {
  return request.post('msg/cus/tempSave', data, {
    noAuth: true
  })
}

/**
 * 消息列表
 */
export function msgCusList(data) {
  return request.post('msg/cus/list', data, {
    noAuth: true
  })
}

/**
 * 删除消息列表
 */
export function delCusList(data) {
  return request.post('msg/cus/del', data, {
    noAuth: true
  })
}

/**
 * 客服打开保存
 */
export function cusInfoSave(data) {
  return request.post('msg/cus/saveInfo', data, {
    noAuth: true
  })
}

/**
 * 消息详情列表
 */
export function msgCusInfoList(data) {
  return request.get('msg/cus/msglist', data, {
    noAuth: true
  })
}
