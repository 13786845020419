module.exports = {
  linkCustomerOne: [
    {
      headImg: 'https://51danbao.oss-ap-southeast-1.aliyuncs.com/crmebimage/pic/pic-301.png',
      name: '招商-大果',
      telQrCode: 'https://t.me/dbw51888'
    },
    {
      headImg: 'https://51danbao.oss-ap-southeast-1.aliyuncs.com/crmebimage/pic/pic-302.png',
      name: '招商-大鹏',
      telQrCode: 'https://t.me/dbw515151'
    },
    {
      headImg: 'https://51danbao.oss-ap-southeast-1.aliyuncs.com/crmebimage/pic/pic-303.png',
      name: '招商-璐璐',
      telQrCode: 'https://t.me/dbw51lulu'
    },
    {
      headImg: 'https://51danbao.oss-ap-southeast-1.aliyuncs.com/crmebimage/pic/pic-304.png',
      name: '招商-黄河',
      telQrCode: 'https://t.me/dbw5155'
    },
  ],
  linkCustomerTwo: [
    {
      headImg: 'https://51danbao.oss-ap-southeast-1.aliyuncs.com/crmebimage/pic/pic-301.png',
      name: '招商-大C',
      telQrCode: 'https://t.me/dbw5151C'
    },
    {
      headImg: 'https://51danbao.oss-ap-southeast-1.aliyuncs.com/crmebimage/pic/pic-302.png',
      name: '招商-大果',
      telQrCode: 'https://t.me/dbw51888'
    },
    {
      headImg: 'https://51danbao.oss-ap-southeast-1.aliyuncs.com/crmebimage/pic/pic-303.png',
      name: '招商-璐璐',
      telQrCode: 'https://t.me/dbw51lulu'
    },
    {
      headImg: 'https://51danbao.oss-ap-southeast-1.aliyuncs.com/crmebimage/pic/pic-304.png',
      name: '招商-黄河',
      telQrCode: 'https://t.me/dbw5155'
    },
  ],
  becauseList: [
    {
      img: require('@/assets/new/enter/c00.png'),
      title: '降低风险',
      info: '降低了商家与买家之间的信任成本，确保交易的安全性和可靠性。',
      colorLine: '#5196F6'
    },
    {
      img: require('@/assets/new/enter/c11.png'),
      title: '便捷的开店流程',
      info: '平台提供简单、快速的开店流程，商家可以在短时间内完成注册并上架产品，迅速开展业务。',
      colorLine: '#32CF89'
    },
    {
      img: require('@/assets/new/enter/c22.png'),
      title: '多元化的推广渠道',
      info: '51担保网为商家提供多种推广方式，帮助商家提升品牌曝光率，吸引更多潜在客户。',
      colorLine: '#FBB25B'
    },
    {
      img: require('@/assets/new/enter/c33.png'),
      title: '全面的服务支持',
      info: '平台提供专业的客户服务与技术支持，商家在运营过程中可以得到及时的帮助和指导，降低运营难度。',
      colorLine: '#AA9FFB'
    },
    {
      img: require('@/assets/new/enter/c44.png'),
      title: '灵活的分销模式',
      info: '通过三级分销模式，商家可以激励代理和下级分销商，拓展销售渠道，提升盈利能力。',
      colorLine: '#71B5FB'
    },
    {
      img: require('@/assets/new/enter/c55.png'),
      title: '丰富的功能模块',
      info: '平台集成了多种功能，如支付服务、订单管理、数据分析等，帮助商家高效管理业务。',
      colorLine: '#0F69E6 '
    },
  ],
  joinMerInfo: [
    {
      width: 127,
      height: 150,
      img: require('@/assets/new/enter/mer0.png'),
    },
    {
      width: 125,
      height: 130,
      img: require('@/assets/new/enter/mer1.png'),
    }
    ,
    {
      width: 124,
      height: 141,
      img: require('@/assets/new/enter/mer2.png'),
    }
    ,
    {
      width: 156,
      height: 124,
      img: require('@/assets/new/enter/mer3.png'),
    }
    ,
    {
      width: 124,
      height: 130,
      img: require('@/assets/new/enter/mer4.png'),
    }
    ,
    {
      width: 140,
      height: 130,
      img: require('@/assets/new/enter/mer5.png'),
    }
    ,
    {
      width: 138,
      height: 154,
      img: require('@/assets/new/enter/mer6.png'),
    }
    ,
    {
      width: 143,
      height: 137,
      img: require('@/assets/new/enter/mer7.png'),
    }
    ,
    {
      width: 161,
      height: 129,
      img: require('@/assets/new/enter/mer8.png'),
    }
    ,
    {
      width: 159,
      height: 116,
      img: require('@/assets/new/enter/mer9.png'),
    }
    ,
    {
      width: 122,
      height: 155,
      img: require('@/assets/new/enter/mer10.png'),
    }
    ,
    {
      width: 163,
      height: 131,
      img: require('@/assets/new/enter/mer11.png'),
    }
  ]
}
