<template>
  <div class="send-btn-agree">
    <a-checkbox v-model:checked="isAgree" @change="isAgreeClick">
      <span class="text-lg">{{ $t('login.l_00024') }}</span>
      <a-button type="link" size="small" class="xieyi" @click="targetInfo(1)">
        <span class="text-lg">《{{ $t('login.l_00025') }}》</span>
      </a-button>
      <span class="text-lg">{{ $t('login.l_00026') }}</span>
      <a-button type="link" size="small" class="yinsi" @click="targetInfo(2)">
        <span class="text-lg">《{{ $t('login.l_00027') }}》</span>
      </a-button>
    </a-checkbox>

    <a-modal v-model:open="xYVisible" :title="xYVisibleFlag === 1 ? $t('login.l_00025') : $t('login.l_00027')"
             :width="920"
             :cancelText="$t('common.com_001')">
      <template #footer>
        <a-button type="primary" @click="cancel">{{ $t('common.com_000') }}</a-button>
      </template>
      <Scroll :height="750">
        <div v-html="article.content"></div>
      </Scroll>
    </a-modal>
  </div>
</template>
<script>
import { getArticleDetails } from '@/api/api'

export default {
  props: {
    isAgree: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      xYVisible: false,
      xYVisibleFlag: 1,
      article: {},
    }
  },
  methods: {
    targetInfo(val) {
      this.xYVisibleFlag = val
      this.articleDetails(val)
      this.xYVisible = true
    },
    articleDetails(val) {
      let infoId
      if (val === 1) {
        infoId = 19
      }
      if (val === 2) {
        infoId = 20
      }
      getArticleDetails(infoId)
          .then(res => {
            this.article = res.data
          })
    },
    cancel() {
      this.xYVisible = false
    },
    isAgreeClick(e) {
      this.$emit('onChange',  e.target.checked)
    }
  }
}

</script>
<style scoped lang="scss">
.send-btn-agree {
  display: flex;
  justify-content: flex-start;
  font-size: 16px;
}

</style>
