<template>
  <div class="model-content-all">
    <div class="cursor-pointer" @click="toGuarantee">
      <a-image :preview="false" height="120px" width="1920px" :src="require('@/assets/new/home/bottom.png')"/>
    </div>
    <div class="width_1200_auto content-item">
      <div v-for="(item, index) in guideArr" :key="index">
        <span class="font-info cursor-pointer golbal-btn" @click="goArticle(item.mainId, item.subId)">{{ item.name }}</span>
        <span class="font-info-cur" v-if="index < guideArr.length - 1"> | </span>
      </div>
    </div>
    <div style="height: 90px; border-top:1px solid #313131;">
      <div class="width_1200_auto mt-8">
        <div class="cu-center-between">
          <div class="d-flex justify-start align-center">
            <div class="mr-4 cursor-pointer" @click="openQrcode">
              <a-image :src="require('@/assets/new/home/dianbao.png')" :preview="false" width="28px"
                       height="27px"></a-image>
            </div>
            <div class="mr-4 cursor-pointer" @click="toMerBack">
              <a-image :src="require('@/assets/footer/store.png')" :preview="false" width="28px"
                       height="28px"></a-image>
            </div>
            <div class="mr-4 cursor-pointer" @click="toTRX">
              <a-image :src="require('@/assets/footer/trxusdt.png')" :preview="false" width="28px"
                       height="28px"></a-image>
            </div>
            <div class="mr-4 cursor-pointer" @click="toChaU">
              <a-image :src="require('@/assets/footer/USDT.png')" :preview="false" width="28px" height="28px"></a-image>
            </div>
          </div>
          <div class="copyright text-white">
            <p>Copyright © {{ year }} {{ $t('cnf_001') }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Image } from 'view-ui-plus'

export default {
  name: 'Footer',
  components: { Image },
  data() {
    return {
      config: require('@/config'),
      guideArr: [{
        name: '关于我们',
        mainId: 0,
        subId: 0
      }, {
        name: '帮助中心',
        mainId: 1,
        subId: 0
      }, {
        name: '争议仲裁',
        mainId: 1,
        subId: 2,
      }, {
        name: '费用说明',
        mainId: 1,
        subId: 3,
      }, {
        name: '用户协议',
        mainId: 2,
        subId: 0,
      }, {
        name: '隐私政策',
        mainId: 2,
        subId: 2,
      }, {
        name: '商家入驻协议',
        mainId: 2,
        subId: 3,
      }, {
        name: '免责声明',
        mainId: 2,
        subId: 4
      }],
      year: new Date().getFullYear(), // 当前年份
    }
  },
  methods: {
    toGuarantee() {
      this.$router.push({
        path: '/Guarantee',
        query: {}
      })
    },
    openQrcode() {
      window.open('https://t.me/dbw51', '_blank')
    },
    toMerBack() {
      window.open('https://adminmer.51danbao.com/', '_blank')
    },
    toTRX() {
      window.open('https://t.me/chau51_bot', '_blank')
    },
    toChaU() {
      window.open('https://www.51chau.com/', '_blank')
    },
    goArticle(index, indexSecond) {
      if(index === 0 && indexSecond === 0){
        this.$router.push({
          path: '/US'
        })
        return
      }
      if(index === 1 && indexSecond === 0){
        this.$router.push({
          path: '/Support'
        })
        return
      }
      if (index === 0) {
        this.$router.push({
          path: '/About',
          query: {
            id: indexSecond
          }
        })
      } else if (index === 1) {
        this.$router.push({
          path: '/FooterHelp',
          query: {
            id: indexSecond
          }
        })
      } else {
        this.$router.push({
          path: '/Privacy',
          query: {
            id: indexSecond
          }
        })

      }
    },
  },
}
</script>

<style scoped lang="scss">
.model-content-all {
  width: 1920px;
  height: calc(194px + 120px) !important;
  margin: 0 auto;
  background-color: #272727;
}

.content-item {
  padding: 42px 0 30px 0;
  display: flex;
  justify-content: start;
  align-items: center;

  .font-info {
    font-weight: 400;
    font-size: 16px;
    color: #FFFFFF;
    line-height: 20px;
  }

  .font-info-cur {
    color: #515151;
    margin-left: 20px;
    margin-right: 20px;
  }
}

</style>
