<template>
  <div class="wrapper">
    <a-card class="cart-users"
            :head-style="{padding: '20px 32px', height: '79px'}"
            :bodyStyle="{padding: '20px 33px'}"
            :loading="loading">
      <template #title>关注店铺</template>
      <div class="mt-4">
        <empty v-if="!storeList || storeList.length === 0"/>
        <div class="goods-list-mer">
          <a-row :gutetr="[20,19]" v-if="loading" style="width: 1600px">
            <a-col class="mer-card" :span="6" v-for="index in 20" :key="index">
              <a-skeleton active :loading="loading" avatar :paragraph="{ rows: 5 }"/>
            </a-col>
          </a-row>
          <a-row :gutter="24" :class="storeList.length === 1 ? 'with-one' : ''">
            <a-col class="mer-card" :span="12" v-for="(item, index) in storeList" :key="index">
              <a-card :loading="loading" :bodyStyle="{padding: '24px 30px;', height: '90px'}">
                <template #title>
                  <div class="mer-card-title cursor-pointer">
                    <div class="avatar" @click="()=>{$router.push({path:'/MerHome', query:{merId: item.merId}})}">
                      <a-avatar style="border: 1px solid #E8E8E8;" :src="item.merAvatar" :size="70"></a-avatar>
                    </div>
                    <div style="width: 100%">
                      <div class="d-flex justify-space-between align-center">
                        <div class="ml-4" @click="()=>{$router.push({path:'/MerHome', query:{merId: item.merId}})}">
                          <div class="mer-name line1" style="max-width: 420px;">
                            {{ item.merName }}
                          </div>
                          <div class="custom-font">
                            <a-image :src="require('@/assets/new/product/danbao.png')" :width="20" :height="19"/>
                            保证金：{{ item.earnestMoney }} USDT
                          </div>
                        </div>
                        <a-space>
                          <div class="attention-cus-mer-over cursor-pointer"
                               @click="()=>{$router.push({path:'/MerHome', query:{merId: item.merId}})}">
                            <span>进店</span>
                          </div>
                          <div class="attention-cus-mer cursor-pointer" @click="followToggle(item)">
                            <span>取消关注</span>
                          </div>
                        </a-space>
                      </div>
                    </div>
                  </div>
                </template>
                <div class="line2 text-gray text-df cursor-pointer"
                     @click="()=>{$router.push({path:'/MerHome', query:{merId: item.merId}})}">
                  {{ item.intro }}
                </div>
              </a-card>
            </a-col>
          </a-row>
        </div>
      </div>
    </a-card>
    <!-- 分页 -->
    <div class="page-size mt-3" v-if="storeList && storeList.length > 0">
      <a-pagination :page-size="limit" :total="total" show-less-items @change="changePageNum"
                    :show-size-changer="false"
                    @page-size-change="changePageSize"/>
    </div>
  </div>
</template>

<script>
import { getMerCollectCancelApi, getMerCollectListApi } from '@/api/merchant.js'
import { PlusOutlined } from '@ant-design/icons-vue'

export default {
  name: 'MyCoupon',
  components: { PlusOutlined },
  data() {
    return {
      page: 1,
      limit: 12,
      total: 0,
      loading: false,
      storeList: [],
    }
  },
  created() {
    this.getList()
  },
  methods: {
    getList() {
      this.loading = true
      getMerCollectListApi({
        page: this.page,
        limit: this.limit
      })
          .then(res => {
            this.storeList = res.data.list
            this.total = res.data.total
            this.loading = false
          })
    },
    // 设置是否关注
    followToggle(info) {
      getMerCollectCancelApi(info.merId)
          .then(res => {
            this.page = 1
            this.getList()
          })
    },
    changePageNum(val) { // 修改页码
      this.page = val
      this.getList()
    },
    changePageSize(val) { // 修改页数
      this.page = 1
      this.limit = val
      this.getList()
    },
  }
}
</script>

<style scoped lang="scss">
.attention-cus-mer-over {
  width: 66px;
  height: 32px;
  border-radius: 16px;
  border: 1px solid #3B64DA;
  background: #3B64DA;

  font-weight: 500;
  font-size: 14px;
  color: #FFFFFF;
  line-height: 20px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.attention-cus-mer-over:hover {
  border: 1px solid #3B64DA;
  background: #FFFFFF;
  color: #3B64DA;
}

.attention-cus-mer {
  width: 86px;
  height: 32px;
  border-radius: 16px;
  border: 1px solid #3B64DA;

  font-weight: 500;
  font-size: 14px;
  color: #3B64DA;
  line-height: 20px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.attention-cus-mer:hover {
  border: 1px solid #3B64DA;
  background: #3B64DA;
  color: #FFFFFF;
}

.mer-card {
  margin-bottom: 16px;
}

.with-one {
  width: 100%;
}

.goods-show-img {
  border-radius: 10px;
}

.mer-card {
  margin-bottom: 16px;
}

.custom-font {
  font-size: 14px;
  margin-top: 14px;
  color: #FF6600;
}

.cate-nav {
  margin-top: 10px;
}

.mer-card-title {
  height: 121px;
  padding: 24px 0 27px 6px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;

  .mer-level {
    font-size: 13px;
    color: #3B64DA;
  }

  .mer-name {
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
  }

  .avatar {
    height: 66px;
    margin-right: 15px;
  }
}

.goods-list-mer {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 auto;
}

.imgs {
  position: relative;
  margin-right: 15px;

  .usdt-bottom {
    width: 100%;
    position: absolute;
    bottom: 0;
    height: 24px;
    background: rgba(0, 0, 0, 0.5);
    text-align: center;

    font-size: 12px;
    font-weight: 900;
    line-height: 26px;

    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}

.mer-card-content {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100%;
}

.left {
  height: 100%;
  border-right: 1px solid #E8E8E8;
}

.cancel-btn {
  width: 93px;
  height: 32px;
  border-radius: 50px;
  opacity: 1;
  box-sizing: border-box;
  border: 1px solid #DBDBDB;

  font-size: 12px;
  font-weight: normal;
  line-height: normal;
  letter-spacing: 0em;

  color: #999999;
}

.card-del-check-S {
  width: 72px;
  height: 24px;
  border-radius: 50px;
  box-sizing: border-box;
  border: 1px solid #e8e8e8;

  margin-left: 10px;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 13px;
  font-weight: 350;
  line-height: normal;
  letter-spacing: 0em;
  color: grey;
}

.multiple-selected {
  width: 72px !important;
  background-color: #269FFA;
  color: #FFFFFF !important;
}

.multiple {
  width: 50px;
  height: 24px;
  border-radius: 50px;
  box-sizing: border-box;
  border: 1px solid #e8e8e8;

  margin-left: 10px;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 13px;
  font-weight: 350;
  line-height: normal;
  letter-spacing: 0em;
  color: grey;
}

.product-name {
  font-size: 14px;
  font-weight: normal;
  line-height: 26px;
  letter-spacing: 0em;
  color: #3D3D3D;

  height: 56px;
}

.card-image {
  position: relative;

  .card-del-check {
    position: absolute;
    top: 5px;
    right: 15px;
    width: 28px;
    height: 28px;
    border-radius: 5px;
    opacity: 0.8;

    color: #FFFFFF;

  }

  .card-del {
    position: absolute;
    top: 5px;
    right: 15px;
    width: 28px;
    height: 28px;
    border-radius: 5px;
    opacity: 0.8;
    background: #5D5D5D;

    color: #FFFFFF;
  }
}

.wrapper {
  width: 100%;

  .cart-users {
    width: 100%;
    border-radius: 12px;
    opacity: 1;
  }
}
</style>
