/**
 * <b>功能描述：</b>只允许输入数字和字母<br>
 * <b>修订记录：</b><br>
 * <li>20240912&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;创建方法</li><br>
 */
export function OnlyNumericLetters(e) {
    let value = e.target.value
    // 使用正则表达式过滤非字母和数字字符
    const chineseCharRegex = /[\u4e00-\u9fa5]/g
    // 更新数据
    if (chineseCharRegex.test(value)) {
      e.target.value = value.replace(chineseCharRegex, '')
    }
}

export function OnlyNumeric(e) {
  let value = e.target.value
  // 使用正则表达式过滤非字母和数字字符
  const chineseCharRegex = /[^\d]/g
  // 更新数据
  if (chineseCharRegex.test(value)) {
    e.target.value = value.replace(chineseCharRegex, '')
  }
}

export function autoComplete(e) {
  let value = e
  // 使用正则表达式过滤非字母和数字字符
  const chineseCharRegex = /[\u4e00-\u9fa5]/g
  // 更新数据
  if (chineseCharRegex.test(value)) {
    e.target.value = value.replace(chineseCharRegex, '')
  }
}
