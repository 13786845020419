<template>
  <div>
    <div class="width_1200_auto page-container-all">
      <a-image :preview="false" class="all-page-container" :width="1600" :height="200"
               :src="require('@/assets/new/coupon/banner.png')"></a-image>
      <a-card class="mt-5"
              :head-style="{padding: '20px 33px', height: '78px'}"
              :body-style="{padding: '44px 28px', width:'1600px'}"
              :loading="loading">
        <template #title>
          限时抢券
        </template>
        <template #extra>
          <div class="cursor-pointer my-btn-head cu-center hover-item-big"
               @click="()=>{$router.push({path:'/MyCoupon'})}">
            我的优惠券
          </div>
        </template>

        <div class="font-info">
          <a-row :gutter="[16,24]">
            <a-col :span="12" v-for="(item,index) in list" :key="index">
              <div class="bg-img-cus">
                <div class="money-hour" v-if="item.showFlag === 1">
                  <div class="mb-7">
                    {{ item.month }}
                    <text class="money-dw">
                      {{
                        item.trialFlag === 0 ? '小时' : item.trialFlag === 1 ? '天' : item.trialFlag === 2 ? '月' : ''
                      }}
                    </text>
                  </div>
                  <a-image :preview="false" :src="require('@/assets/new/coupon/coupon_front.png')"/>
                </div>
                <div class="money-hour" v-if="item.showFlag === 0">
                  <div class="mb-7">
                    {{ item.money }}
                    <text class="money-dw">USDT</text>
                  </div>
                  <a-image :preview="false" :width="178" :height="19"
                           :src="require('@/assets/new/coupon/coupon_front.png')"/>
                </div>
                <div class="item">
                  <div class="position-absolute" style="right: 38px;top: 42px" v-if="item.lastTotal > 0 && item.isUserReceive">
                    <a-image :preview="false" :width="121" :height="121" :src="require('@/assets/new/coupon/item-line.png')"/>
                  </div>
                  <div class="position-absolute" style="right: 38px;top: 42px" v-if="item.lastTotal <= 0">
                    <a-image :preview="false" :width="121" :height="121" :src="require('@/assets/new/coupon/item-out.png')"/>
                  </div>
                  <div class="content cursor-pointer" @click="showInfo(item)">
                    <div class="text-font-s">
                      {{ item.name }}
                    </div>
                    <div v-if="item.lastTotal > 0 && !item.isUserReceive" class="cus-get cursor-pointer hover-item-big"
                         @click="onlLqCon(item)">
                      领取
                    </div>
                    <div v-else></div>
                  </div>
                  <div class="line2 text-font-xs">
                    {{ item.content }}
                  </div>
                  <div class="mt-6 text-gray text-df" style="width: 100%;">
                    有效期至: {{ item.receiveEndTime }}
                  </div>
                </div>
              </div>
            </a-col>
          </a-row>
        </div>
      </a-card>
    </div>

    <a-modal :open="show" :title="tagInfo.name" :footer="null" @cancel="show=false">
      <div class="pt-8">
        <div class="mb-2 d-flex">
          <div class="text-gray">面值:</div>
          <div class="ml-2">{{ tagInfo.money }} USDT</div>
        </div>
        <div class="mb-2 d-flex">
          <div class="text-gray">发放期:</div>
          <div class="ml-2">
            {{ tagInfo.receiveStartTime + 'ー' + tagInfo.receiveEndTime }}
          </div>
        </div>
        <div class="mb-2 d-flex">
          <div class="text-gray">使用时长:</div>
          <div class="ml-2">
            {{ tagInfo.month }}
            <text class="money-dw">
              {{
                tagInfo.trialFlag === 0 ? '小时' : tagInfo.trialFlag === 1 ? '天' : tagInfo.trialFlag === 2 ? '月' : ''
              }}
            </text>
          </div>
        </div>
        <div style="font-size: 14px;" class="text-gray mb-5">
          {{ tagInfo.content }}
        </div>
      </div>
    </a-modal>
  </div>
</template>
<script>
import { createCoupon, getCouponProList, } from '@/api/order.js'

export default {
  data() {
    return {
      type: '',
      list: [],
      loading: false,
      page: 1,
      limit: 100,
      tagInfo: '',
      show: false,
    }
  },
  created() {
    this.statusClick(7)
  },
  methods: {
    onlLqCon(conp) {
      let that_ = this
      createCoupon(conp.id)
          .then((res) => {
            that_.list = []
            that_.getList()
          })
    },
    showInfo(item) {
      this.tagInfo = item
      this.show = true
      console.log(item)
    },
    getList() {
      let that_ = this
      that_.loading = true
      getCouponProList({
        category: 7,
        page: that_.page,
        limit: that_.limit
      })
          .then(res => {
            that_.list = res.data.list
            that_.loading = false
          })
    },
    //切换类型
    statusClick: function (status) {
      this.loading = false
      this.page = 1
      this.limit = 15
      this.list = []
      this.getList()
    },
  }
}
</script>

<style scoped lang="scss">
.cus-get {
  width: 102px;
  height: 40px;
  background: #FF8427;
  border-radius: 20px;

  font-weight: 400;
  font-size: 20px;
  color: #FFFFFF;
  line-height: 34px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.my-btn-head {
  width: 112px;
  height: 32px;
  background: #3B64DA;
  border-radius: 16px;

  font-weight: 500;
  font-size: 16px;
  color: #FFFFFF;
  line-height: 34px;
}

.flex-title {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
}

.page-container-all {
  width: 1600px;
  min-height: 1200px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: flex-start;
  flex-direction: column;
  flex-wrap: nowrap;
}

.bg-img-cus {
  position: relative;
  width: 750px;
  height: 190px;
  background-image: url('@/assets/new/coupon/coupon_back.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;

  .item {
    position: absolute;
    top: 0;
    left: 267px;
    width: 457px;
    height: 100%;

    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;

    .text-font-xs {
      font-size: 16px;
      margin-top: 15px;
      width: 100%;
      color: #666666;
      height: 46px;
    }

    .content {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 30px;

      .text-font-s {
        font-weight: bold;
        font-size: 28px;
        color: #000000;
        line-height: 34px;
      }
    }
  }

  .money-hour {
    position: absolute;
    left: 0;
    top: 0;
    width: 240px;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    font-weight: bold;
    font-size: 60px;
    color: #FFFFFF;
    line-height: 34px;

    .money-dw {
      margin-left: 2px;
      padding-top: 25px;
      font-weight: 400;
      font-size: 20px;
      color: #FFFFFF;
      line-height: 34px;
    }
  }
}
</style>
