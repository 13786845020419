<template>
  <div class="cate-nav" :class="{'fixed-show':useClass === 'fixed-show'}">
    <div class="width_1200_auto">
      <div class="nav-con" :class="{'background-white':useClass === 'background-white'}">
        <div class="hover-pointer" @mouseenter="handleClickChange"
             @mouseleave="handleHoverChange"
             :class="showNavBar ? 'all-categories' : 'all-categories-fixed'">
          <a-image :preview="false" width="23px" height="21px" :src="require('@/assets/new/newcate/cate.png')"/>
          <span class="ml-1">{{ $t('nav_0000') }}</span>
          <span class="ml-1"><CaretDownOutlined/></span>
        </div>
      </div>
    </div>
    <div class="position-relative" v-if="clicked" @mouseenter="sunClickChange" @mouseleave="handleClickChange">
      <div class="top-jt">
      </div>
      <div class="position-absolute bg-white"
           style="height: 297px;width: 1920px;z-index: 2000; top: 15px;padding: 42px 197px;box-shadow: 0 3px 7px 0 rgba(100,105,118,0.07);">
        <div class="flex justify-between align-center">
          <div>
            <div class="flex">
              <div class="cu-center-items-col mr-16 cursor-pointer"
                   v-for="(item, index) in cateList" :key="index"
                   @click="showDetail(index)">
                <a-image :preview="false" :width="70" :height="70"
                         :src="`${hoverIndex === index ? cateDataSelectedImg[index].img : cateDataImg[index].img}`"></a-image>
                <div class="mt-4 text-lg" :class="hoverIndex === index ? 'golbal-btn text-blue' : ''"> {{
                    item.name
                  }}
                </div>
              </div>
            </div>
            <div class="flex mt-16">
              <div class="flex" v-for="(items, index) in panelData" :key="index">
                <a-space :size="20">
                  <div v-for="(item, subIndex) in items.childList"
                       @click="goGoodsList(item)" :key="subIndex"
                       class="detail-item cu-center"
                       :class="selectIndex === item.id ? 'detail-item-selected' : ''">
                    {{ item.name }}
                  </div>
                </a-space>
              </div>
            </div>
          </div>
          <a-image :preview="false" width="227px" height="235px" :src="require('@/assets/new/cate/right.png')"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CaretDownOutlined } from '@ant-design/icons-vue'
// 完毕数据
import cateDate from './data/index'

export default {
  name: 'GoodsListNav',
  components: { CaretDownOutlined },
  props: {
    showAlways: {
      // 总是显示下拉分类
      default: false,
      type: Boolean,
    },
    showNavBar: {
      // 显示全部商品分类右侧导航条
      default: true,
      type: Boolean,
    },
    hover: {
      default: false,
      type: Boolean,
    },
    large: {
      // 是否更高的高度
      default: false,
      type: Boolean,
    },
    opacity: {
      // 是否背景透明
      default: false,
      type: Boolean,
    },
    useClass: {
      type: null,
      default: ''
    }
  },
  data() {
    return {
      panel: false, // 二级分类展示
      panelData: [], // 二级分类数据
      showFirstList: false, // 始终展示一级列表
      isTouch: false,
      cateList: [], // 商品分类
      checked: {},
      cateDataImg: cateDate.menus,
      cateDataSelectedImg: cateDate.menusSelected,
      hoverIndex: null,
      selectIndex: null,
      clicked: false,
      sunClick: false,
    }
  },
  mounted() {
    this.$store.dispatch('MerCategoryList')
    this.$store.dispatch('MerTypeList')
    setTimeout(() => {
      this.getCate()
    }, 500)
  },
  methods: {
    sunClickChange() {
      this.sunClick = true
    },
    handleHoverChange() {
      setTimeout(() => {
        if (!this.sunClick) {
          this.clicked = !this.clicked
        }
      }, 200)
    },
    handleClickChange() {
      this.clicked = !this.clicked
      this.sunClick = false
    },
    showDetail(index) {
      // 展示全部分类
      this.panel = true
      this.hoverIndex = index
      this.checked = this.cateList[index]
      this.panelData = this.checked.childList
    },
    goGoodsList(item) {
      let categoryId = this.checked.id
      let cid = item.id
      this.selectIndex = cid
      if (categoryId === cid) {
        cid = null
      }
      let name = item.name
      this.clicked = !this.clicked
      this.$router.push({
        path: '/goodsList',
        query: {
          categoryId: categoryId,
          cid: cid,
          name: name,
        },
      })
    },
    getCate() {
      // 获取分类数据
      if (this.hover) return false
      this.cateList = JSON.parse(localStorage.getItem('merchantClassify'))
      this.showDetail(0)
    },
  },
}
</script>

<style scoped lang="scss">
.top-jt::after {
  content: '';
  position: absolute;
  top: 5px; /* 放在父元素的上方 */
  left: 230px; /* 箭头水平居中 */
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 20px solid #FFFFFF; /* 与父元素背景色一致 */
}

.detail-item {
  font-size: 14px;
  padding-left: 20px;
  padding-right: 20px;
  cursor: pointer;
  border-radius: 21px;
  height: 42px;
  border: 1px solid rgba(184, 186, 193, 0.32);
}

.detail-item-selected {
  background-color: #3A69FB;
  color: #FFFFFF;
}

.detail-item:hover {
  background-color: #3A69FB;
  color: #FFFFFF;
}

:deep(.ant-popover .ant-popover-content) {
  width: 100vw !important;
  height: 297px !important;
  background-color: #0f1011 !important;
}

.line-d {
  height: 3px;
  border-bottom: 1px solid #ccc;
}

.yaohuang {
  animation: fadeEffect 3s infinite;
}

.yaohuang-tu {
  animation: zy 2.5s .15s linear infinite;
}

/* 定义淡入淡出动画 */
@keyframes fadeEffect {
  0%, 100% {
    height: 46px;
    opacity: 0.5; /* 完全透明 */
  }
  50% {
    height: 26px;
    opacity: 1; /* 完全不透明 */
  }
}

@-webkit-keyframes zy {
  10% {
    transform: rotate(15deg);
  }
  20% {
    transform: rotate(-10deg);
  }
  30% {
    transform: rotate(5deg);
  }
  40% {
    transform: rotate(-5deg);
  }
  50%, 100% {
    transform: rotate(0deg);
  }
}

.top-btn-font {
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0em;
}

.background-white {
  background: #fff;
}

.nav-lis:hover {
  color: #269FFA !important;
  cursor: pointer;
}

.nav-lis {
  text-align: center;
  position: relative;
}

.fixed-show {
  margin-top: 0 !important;

  > .nav-con {
    > .all-categories {
      align-items: center !important;
      height: 60px;
      display: inherit;
      justify-content: center;
      padding: 0 !important;
    }
  }
}

.colum {
  height: 14.7px;
  opacity: 1;
  border: 0.7px solid #CBC8C8;
  position: absolute;
  right: 0;
}

.cate-nav {
  position: relative;
}

/** 商品分类 */
.nav-con {
  height: 42px;
  margin: 0 auto;
  display: flex;

  .all-categories-fixed {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 226px;
    font-size: 14px;
    font-weight: bold;
    line-height: normal;
    letter-spacing: 0em;
    border-radius: 20px;
  }

  .all-categories {
    display: flex;
    padding: 30px 5px !important;
    justify-content: flex-start;
    align-items: center;

    border-radius: 20px;
    font-size: 14px;
    font-weight: bold;
    line-height: normal;
    letter-spacing: 0em;
  }

  .nav-item {
    height: 42px;
    line-height: 42px;
    overflow: hidden;
    list-style: none;
    // background-color: #eee;
    display: flex;
    flex: 1;

    li {
      font-size: 17px;
      font-weight: normal;
    }
  }
}

// 分类列表
.cate-list {
  width: 230px;
  margin: 0 auto;
  position: absolute;
  left: 130px;
  top: 30px;
  z-index: 1000;
}

.nav-side {
  width: 230px;
  opacity: 0.8;
}

.large-nav {
  ul > li {
    line-height: 20px;
  }
}

.opacity-nav {
  //background-color: rgba(0, 0, 0, 0.5);
}

.nav-side ul {
  width: 100%;
  list-style: none;
}

.nav-side li {
  font-size: 14px;
  font-weight: bold;
  line-height: 50px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-side-item {
  width: 190px;
  min-width: 190px;
  border-radius: 16px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-side-item:hover {
  cursor: pointer;
  color: #FFFFFF;
  background-color: #0052D9;
}

/*显示商品详细信息*/
.detail-item-panel {
  width: 550px;
  min-height: 418px;
  position: absolute;
  /* 阴影/常规阴影 */
  top: 2px;
  opacity: 0.9;
  left: 230px;
  z-index: 1000;
  padding: 35px 20px 0 30px;
  border-radius: 10px;
}

.nav-detail-item {
  margin-top: 5px;
  margin-bottom: 15px;
  cursor: pointer;
}

.nav-detail-item span {
  padding: 6px;
  padding-left: 12px;
  margin-right: 15px;
  font-size: 12px;
  color: #333;
}

.nav-detail-item div:hover {
}

.detail-item-panel li {
  line-height: 30px;
}

.detail-item-title {
  font-weight: bold;
  font-size: 12px;
  cursor: pointer;
  color: #555555;
  padding-right: 10px;
  width: 96px;
  text-align: right;
}

.detail-item-title:hover {
  color: #269FFA;
}

.detail-item-row {
  display: flex;

  > div {
    flex: 1;
  }
}
</style>
