<template>
  <div class="container width_1200_auto">
    <div class="mer-top-card margin-top-xs">
      <!--  步骤条 -->
      <OrderSteps :current="showIndex"/>

      <a-card :body-style="{padding: '20px 30px'}" :loading="loading">
        <template #title>
          <div class="d-flex">
            <div class="me-auto">确认收货地址</div>
            <div class="address-mgr-btn cu-center cursor-pointer" @click="() =>{ $router.push({path: '/MyAddress'})}">
              <Icon type="md-settings" :size="24"/>
              <span class="ml-1">管理收货地址</span>
            </div>
          </div>
        </template>
        <arco-scrollbar style="height:80px;overflow: auto;">
          <div v-if="addressList && addressList.length>0">
            <a-radio-group v-model:value="addressId" @change="addressChange">
              <div :class="addressId === item.id ? 'radio-back' : 'radio-back-v'" v-for="(item, index) in addressList"
                   :key="index">
                <a-radio :style="radioStyle" :value="item.id">
                  <div class="d-flex" :class="addressId === item.id ? 'text-black' : ''">
                    <a-space>
                      <div>{{ item.province }}</div>
                      <div>{{ item.city }}</div>
                      <div>{{ item.detail }}</div>
                      <div>+{{ item.countryCode }} {{ item.phone }}</div>
                    </a-space>
                  </div>
                </a-radio>
              </div>
            </a-radio-group>
          </div>
          <div v-else>
            <empty :_-title="'暂无地址'"/>
          </div>
        </arco-scrollbar>
      </a-card>
    </div>

    <div class="mer-top-card margin-top-xs">
      <a-card :body-style="{padding: '20px 30px'}" :loading="loading">
        <template #title>
          <a-row :gutter="[16,24]">
            <a-col class="text-bold" :span="8">商品</a-col>
            <a-col class="cu-center text-bold" :span="2">属性</a-col>
            <a-col class="cu-center text-bold" :span="5">单价</a-col>
            <a-col class="cu-center text-bold" :span="5">数量</a-col>
            <a-col class="text-right text-bold" :span="4">小计</a-col>
          </a-row>
        </template>
        <div v-for="(item, index) in merchantOrderVoList" :key="index">
          <div v-for="(items, indexs) in item.orderInfoList" :key="indexs">
            <div class="flex mr-1 cursor-pointer"
                 @click="()=>{$router.push({path:'/MerHome', query:{merId: item.merId}})}">
              <Icon class="text-bold text-black" :size="18" custom="iconfont icon-shangdian"/>
              <div class="text-bold text-df">{{ item.merName }}</div>
            </div>
            <a-row :gutter="[16,24]">
              <a-col :span="8">
                <div class="d-flex align-center flex-wrap mt-2 cursor-pointer"
                     @click="()=>{$router.push({path:'/MerHome', query:{merId: item.merId}})}">
                  <div class="rounded mt-4">
                    <a-image class="rounded" :src="items.image" :width="98" :height="98"/>
                  </div>
                  <div class="ml-2 pro-name line3">{{ items.productName }}</div>
                </div>
              </a-col>
              <a-col :span="2" class="cu-center">
                <div class="text-grey text-df" v-if="items.sku">{{ items.sku }}</div>
              </a-col>
              <a-col :span="5" class="cu-center">
                <div class="text-red text-df">{{ items.price || items.productPrice }} USDT</div>
              </a-col>
              <a-col :span="5" class="cu-center text-df">
                <div class="text-grey text-df">x {{ items.payNum }}</div>
              </a-col>
              <a-col class="text-right d-flex align-center justify-end" :span="4">
                <div class="text-df">{{ items.price || items.productPrice }} USDT</div>
              </a-col>
            </a-row>
          </div>
        </div>
      </a-card>
    </div>

    <div class="mer-top-card margin-top-xs" v-for="(item, index) in merchantOrderVoList" :key="index">
      <a-card :body-style="{padding: '20px 30px'}" :loading="loading">
        <div class="mb-2">
          <div class="flex">
            <div class="text-gray">配送方式：</div>
            <div class="ml-2">{{ item.shippingType === 1 ? '快递配送' : '到店自提' }}</div>
          </div>
        </div>
        <div class="mt-5 mb-5">
          <div class="flex">
            <div class="text-gray">订单备注:</div>
            <div class="ml-5" style="width: 93%">
              <a-textarea class="mt-1" :rows="5" v-model:value="item.remark" placeholder='请输入订单备注'
                          @input='bindHideKeyboard(item.remark,index)'></a-textarea>
            </div>
          </div>
        </div>
      </a-card>
    </div>

    <div class="mer-top-card margin-top-xs">
      <a-card :body-style="{padding: '20px 30px'}" :loading="loading">
        <div class="flex justify-end align-center text-gray" style="height: 131px;">
          <div class="mr-4 text-df">共 {{ payCount }} 件</div>
          <span class="mr-1 text-df">|</span>
          <div class="card-title text-df ml-4 mr-2">合计:</div>
          <div class="text-red heji-money">
            {{ orderInfoVo.payFee || '0.00' }}<span class="text-red text-df ml-3">USDT</span>
          </div>
          <div class="cursor-pointer now-btn cu-center" @click="SubOrder">
            <a-spin size="mini" :spinning="loading">
              立即购买
            </a-spin>
          </div>
        </div>
      </a-card>
    </div>
  </div>
</template>

<script>
import { Debounce } from '@/utils/validate'
import { getAddressList } from '@/api/user'
import { loadPreOrderApi, orderCreate, postOrderComputed } from '@/api/order'

import OrderSteps from '@/components/orderSteps/index.vue'
import { Icon } from 'view-ui-plus'

export default {
  name: 'Confirm',
  components: {
    Icon,
    OrderSteps
  },
  props: {},
  data() {
    return {
      showIndex: 0,
      orderNo: this.$route.query.orderNo,
      platCouponFee: '',
      platUserCouponId: '',
      merCouponFee: '',
      orderMerchantRequestList: [],
      type: '',
      orderProNum: '',
      addressChangeId: '',
      merchantOrderVoList: [],
      orderInfoVo: {},
      radioStyle: {
        display: 'flex',
        height: '30px',
        lineHeight: '30px'
      },
      addressId: 0,
      addressList: [],
      loading: false,
      activeIndex: '',
      visibleView: false,
      balance: 0,
      cartArr: [],
      payCount: 0,
    }
  },
  created() {
    this.getAddressList()
    this.getLoadPreOrder()
  },
  methods: {
    //立即下单
    SubOrder: Debounce(function (e) {
      let that = this,
          data = {}
      let flag = false
      that.orderMerchantRequestList.map(function (v) {
        if (v.shippingType === 1 && !that.addressId && that.addressId <= 0) {
          flag = true
        }
      })
      if (flag) {
        this.$Message.error('请选择收货地址')
        return
      }
      data = {
        addressId: that.addressId,
        orderMerchantRequestList: that.orderMerchantRequestList,
        isUseIntegral: false,
        preOrderNo: that.orderNo,
        platUserCouponId: 0
      }
      this.loading = true
      that.payment(data)
    }),
    payment(data) {
      this.onCreate(data)
    },
    onCreate(data) {
      orderCreate(data)
          .then(res => {
            if (res.code === 200) {
              this.loading = false
              this.$router.replace({
                path: '/Payment',
                query: {
                  orderNo: res.data.orderNo,
                  payPrice: res.data.payPrice
                }
              })
            } else {
              this.$Message.error(res.message)
              this.loading = false
            }
          })
    },
    addressChange(e) {
      this.addressId = e.target.value
      this.computedPrice()
    },
    getLoadPreOrder() {
      this.loading = true
      loadPreOrderApi(this.orderNo)
          .then(res => {
            if (res.code !== 200) {
              this.$Message.error(res.message)
            } else {
              let orderInfoVo = res.data
              this.orderInfoVo = orderInfoVo
              this.merchantOrderVoList = orderInfoVo.merchantInfoList //商户端数据
              this.platCouponFee = orderInfoVo.platCouponFee //平台优惠券总金额
              this.platUserCouponId = orderInfoVo.platUserCouponId
              this.merCouponFee = orderInfoVo.merCouponFee //店铺优惠券总金额
              orderInfoVo.merchantInfoList.map(item => {
                this.orderMerchantRequestList.push({
                  shippingType: item.shippingType,
                  merId: item.merId,
                  remark: '',
                  userCouponId: item.userCouponId
                })
              })
              this.type = orderInfoVo.type //订单类型
              this.orderProNum = orderInfoVo.orderProNum
              if (orderInfoVo.addressId && orderInfoVo.addressId > 0) {
                this.addressId = orderInfoVo.addressId
              }
            }
            this.payCount = this.merchantOrderVoList[0].orderInfoList[0].payNum
            this.loading = false
          })
    },
    bindHideKeyboard: function (e, i) {
      this.orderMerchantRequestList[i].remark = e
    },
    // 计算订单价格
    computedPrice() {
      this.loading = true
      postOrderComputed({
        addressId: this.addressId,
        isUseIntegral: false,
        orderMerchantRequestList: this.orderMerchantRequestList,
        preOrderNo: this.orderNo,
        platUserCouponId: 0
      })
          .then(res => {
            if (res.code !== 200) {
              this.$Message.error(res.message)
            } else {
              let data = res.data
              data.merOrderResponseList.map((item, i) => {
                this.merchantOrderVoList[i].freightFee = item.freightFee
                this.merchantOrderVoList[i].couponFee = item.couponFee
              })
              this.orderInfoVo.platCouponUserList = data.platCouponUserList //平台优惠券数据
              this.merCouponFee = data.merCouponFee //店铺优惠券总金额
              this.orderInfoVo.couponFee = data.couponFee //优惠券优惠金额
              this.orderInfoVo.userIntegral = data.surplusIntegral //使用的积分
              this.orderInfoVo.deductionPrice = data.deductionPrice
              this.orderInfoVo.freightFee = data.freightFee
              this.orderInfoVo.payFee = data.payFee
              this.orderInfoVo.proTotalFee = data.proTotalFee
              this.orderInfoVo.surplusIntegral = data.usedIntegral //剩余积分
              this.platCouponFee = data.platCouponFee //平台优惠金额
              this.orderInfoVo.integralDeductionSwitch = data.integralDeductionSwitch //积分抵扣开关
            }
            this.loading = false
          })
    },
    getAddressList() {
      let that = this
      that.loading = true
      getAddressList()
          .then(res => {
            that.addressList = res.data
            if (that.addressList && that.addressList.length > 0) {
              that.addressId = that.addressList[0].id
            }
            that.loading = false
          })
    },
  }
}
</script>

<style scoped lang="scss">
.address-mgr-btn {
  width: 138px;
  height: 38px;
  border-radius: 19px;
  border: 1px solid #3B64DA;

  font-weight: 400;
  font-size: 14px;
  color: #3B64DA;
  line-height: 34px;
}

.now-btn {
  width: 260px;
  height: 60px;
  background: linear-gradient(-90deg, #0080D9, #0052D9);
  box-shadow: 0px 5px 16px 0px rgba(71, 100, 184, 0.3);
  border-radius: 10px;

  font-family: PingFang SC;
  font-weight: 800;
  font-size: 18px;
  color: #FFFFFF;
  line-height: 38px;

  display: flex;
  justify-content: center;
  align-items: center;

  margin-left: 30px;
}

.heji-money {
  font-size: 28px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0em;
  color: #FA0639;
}

.card-title {
  font-size: 14px;
  font-weight: normal;
  line-height: 32px;
  letter-spacing: 0em;
  color: #5D5D5D;
}

.pro-name {
  width: 360px;
  font-size: 14px;
  font-weight: normal;
  line-height: normal;
  letter-spacing: 0em;
  color: #3D3D3D;

  display: flex;
  align-items: center;
  justify-content: center;
}

.col-gray {
  border-radius: 12px 12px 0px 0px;
  box-sizing: border-box;
  padding-bottom: 10px;
  border-width: 0px 0px 1px 0px;
  border-style: solid;
  border-color: #EBEBEB;
}

.radio-back {
  margin-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 5px;
  box-sizing: border-box;
}

.radio-back-v {
  margin-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 5px;
  box-sizing: border-box;
}


.container {
  margin: 0 auto;
  padding: 10px 0;
  border-radius: 12px;
  margin-bottom: 30px;

  .mer-top-card {
    width: 100%;
  }
}
</style>
