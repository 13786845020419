<template>
  <div class="box">
    <div class="top-black flex justify-start align-center">
      <div class="width_1200_auto">
        <div class="flex justify-between align-center text-white">
          <a-row :gutter="[10, 24]">
            <a-col :span="4">
              <div class="flex justify-start align-center">
                <a-image class="mb-3" :src="require('@/assets/new/home/notice.png')" :preview="false" :width="18"
                         :height="15"/>
                <div class="golbal-btn ml-1 cu-center line1">
                  <arco-carousel :style="{ width: '250px', height: '28px',}" show-arrow="never" direction="vertical"
                                 :auto-play="true" indicator-type="never">
                    <arco-carousel-item v-for="item in dataNoteMsg" class="flex align-center justify-start cursor-pointer">
                      <div style="width: 250px;height: 28px;margin-top: 2px;"
                           @click="toNotice(item)"
                           class="text-df flex align-center justify-start line1"> {{ item.title }}
                      </div>
                    </arco-carousel-item>
                  </arco-carousel>
                </div>
              </div>
            </a-col>
            <a-col :span="14">
              <notice-top :list="dataMsg"/>
            </a-col>
            <a-col :span="6">
              <div class="d-flex justify-end text-white cursor-pointer">
                <div class="cu-center" @click="() => {$router.push({path: `/Coupon`})}">
                  <div class="text-white golbal-btn text-df golbal-btn">优惠券</div>
                </div>
                <div v-show="!!userInfo.nickname" class="cu-center">
                  <div class="ml-2 mb-1">|</div>
                  <router-link class="nav-item ml-2" :to="`/Message`">
                    <div class="login">
                      <Badge dot :offset="[5,-4]" :count="msgCount">
                        <span class="text-white golbal-btn text-df">我的消息</span>
                      </Badge>
                    </div>
                  </router-link>
                </div>
                <div class="ml-2 mb-1">|</div>
                <div class="cu-center ml-2" @click="() => {$router.push({path: `/Support`})}">
                  <div class="text-white golbal-btn text-df">帮助中心</div>
                </div>
                <div class="ml-2 mb-1">|</div>
                <div class="cu-center ml-2" @click="() => {$router.push({path: `/Announcement`})}">
                  <div class="text-white golbal-btn text-df">公告</div>
                </div>
                <div class="ml-2 mb-1">|</div>
                <div>
                  <a-popover placement="bottom">
                    <template #content>
                      <div class="d-flex">
                        <div class="cu-center flex-column">
                          <a-qrcode :size="120" :value="'Android'" :bordered="false"/>
                          <div>Android</div>
                        </div>
                        <div class="cu-center flex-column ml-4">
                          <a-qrcode :size="120" :value="'IOS'" :bordered="false"/>
                          <div>IOS</div>
                        </div>
                      </div>
                    </template>
                    <div class="cu-center text-white golbal-btn mt-1 ml-2 text-df">
                      <Icon type="ios-phone-portrait" :size="16"/>
                      <span class="text-df">手机版</span>
                    </div>
                  </a-popover>
                </div>
              </div>
            </a-col>
          </a-row>
        </div>
      </div>
    </div>
    <web-socket/>
    <admin-web-socket/>
  </div>
</template>

<script>
import { useDark, useToggle } from '@vueuse/core'
import { msgCusTempSave, readCountByMes } from '@/api/message'

import WebSocket from '@/components/socket/index.vue'
import AdminWebSocket from '@/components/socket/admin-index.vue'

import { SmileTwoTone } from '@ant-design/icons-vue'
import { getArticleList } from '@/api/api'
import noticeTop from '@/components/notice-top/index.vue'

export default {
  name: 'M-Header',
  components: {
    noticeTop,
    WebSocket,
    AdminWebSocket,
    SmileTwoTone
  },
  created() {
    const i18n = localStorage.getItem('language')
    if (i18n) {
      this.$i18n.locale = i18n
    } else {
      this.$i18n.locale = 'zh'
    }
    const status = localStorage.getItem('dark_css')
    if (status === 'true') {
      this.sun = true
      this.sunFlag = true
      this.change(this.sun)
    } else {
      this.sunFlag = false
    }
    let user = localStorage.getItem('USER_INFO')
    if (user) {
      this.userInfo = JSON.parse(user)
    }
  },
  watch: {
    '$store.getters.readCount': {
      handler(val) {
        if (this.$store.getters.token) {
          this.getAllReadCount()
        }
      }
    },
    '$store.getters.userInfo': {
      handler(newVal, oldVal) {
        this.$nextTick(() => {
          this.userInfo = newVal
        })
      }
    },
    '$store.getters.token': {
      handler(newVal, oldVal) {
        this.$nextTick(() => {
          if (!newVal) {
            this.userInfo = { nickname: '' }
            // this.IntervalMsgCount()
          }
        })
      }
    },
  },
  mounted() {
    this.getCidArticle()
    this.getCidNoteArticle()
    if (this.$store.getters.token) {
      this.getAllReadCount()
    }
  },
  data() {
    return {
      msgCount: 0,
      config: require('@/config'),
      userInfo: {}, // 用户信息
      shoppingCart: [], // 购物车
      language: localStorage.getItem('language') ? localStorage.getItem('language') : 'zh',
      sun: false,
      sunFlag: false,
      msgCountInterval: '',
      visitorUserId: '',
      dataMsg: [],
      dataNoteMsg: [],
    }
  },
  methods: {
    toNotice(item) {
      this.$router.push({
        path: '@/Notice',
        query: { id: item.id }
      })
    },
    getCidNoteArticle() {
      const data1 = {
        page: 1,
        limit: 1000
      }
      getArticleList(1, data1)
          .then(res => {
            this.dataNoteMsg = res.data.list
          })
    },
    getCidArticle() {
      const data1 = {
        page: 1,
        limit: 1000
      }
      this.dataMsg = []
      getArticleList(6, data1)
          .then(res => {
            res.data.list.forEach(item => {
              this.dataMsg.push(item.title)
            })
          })
    },
    IntervalMsgCount() {
      this.msgCountInterval = setInterval(() => {
        if (!this.$store.getters.token) {
          if (this.msgCountInterval) {
            clearInterval(this.msgCountInterval)
            this.msgCountInterval = null
          }
        } else {
          this.getAllReadCount()
        }
      }, 5000)
    },
    getAllReadCount() {
      readCountByMes()
          .then(res => {
            this.msgCount = res.data
          })
    },
    Avatar() {
      this.userInfo.avatar = require('@/assets/images/default.png')
    },
    /**
     * 退出登录
     */
    outLogin() {
      this.$store.dispatch('GETLOGOUT')
          .then(res => {
            setTimeout(() => {
              this.visitorSource()
            }, 400)
          })
    },
    visitorSource() {
      let data = {
        uid: this.$route.query.uid,
      }
      msgCusTempSave(data)
          .then(res => {
            this.visitorUserId = res.data
            this.$store.commit('VISITOR_USER_ID', this.visitorUserId)
          })
    },
    change(status) {
      this.sunFlag = status
      const html = document.documentElement
      const body = document.getElementsByTagName('body')[0]
      const isDark = useDark({
        selector: 'html',
        attribute: 'data-doc-theme',
        valueDark: 'dark',
        valueLight: 'light',
        onChanged(dark) {
          if (status) {
            const links = document.getElementsByTagName('link')
            for (let i = 0; i < links.length; i++) {
              if (links[i].href.indexOf('antd.light.css') > -1) {
                links[i].href = ''
              }
            }
            const link = document.createElement('link')
            link.rel = 'stylesheet'
            link.type = 'text/css'
            body.appendChild(link)
            document.body.setAttribute('data-theme', 'dark')
            html.setAttribute('data-doc-theme', 'dark')
            html.style.colorScheme = 'dark'
            link.href = '/style/antd.dark.css'
          } else {
            const links = document.getElementsByTagName('link')
            for (let i = 0; i < links.length; i++) {
              if (links[i].href.indexOf('antd.dark.css') > -1) {
                links[i].href = ''
              }
            }
            document.body.removeAttribute('data-theme')
            html.removeAttribute('data-doc-theme')
            html.style.colorScheme = ''

            const link = document.createElement('link')
            link.rel = 'stylesheet'
            link.type = 'text/css'
            body.appendChild(link)
            link.href = '/style/antd.light.css'
          }
        },
      })
      // 缓存增加
      localStorage.setItem('dark_css', status)
      this.$store.commit('DARK', status)
      useToggle(isDark)
    },
    changeLanguage(val) {
      this.$i18n.locale = val
      localStorage.setItem('language', val)
      this.$store.commit('LANGUAGE', val)
      // 刷新页面
      window.location.reload()
    },
    toGongxu() {
      this.$router.push({
        name: 'Supply',
      })
    },
    toIndex() {
      this.$router.push({
        path: '/',
      })
    },
    goUserCenter() {
      this.$router.push({
        name: 'UserHome'
      })
    },
    goMyOrder() {
      this.$router.push({
        name: 'MyOrder'
      })
    },
  }
}
</script>

<style scoped lang="scss">
.top-black {
  width: 100%;
  height: 28px;
  background: #072144;
  margin: 0 auto;
}

.download {
  width: 82px;
  height: 28px;
  border-radius: 5px;
  background: #269FFA;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 12px;
  font-weight: 350;
  line-height: normal;
  color: #FFFFFF;
}

.detail {
  display: flex;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
}

.user-info {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;

  margin-left: 10px;

}

.box {
  width: 100%;
  height: 28px;
  font-size: 14px;
  color: #878787;
  margin: 0 auto;
  border-bottom: 1px solid #DFDFDF;
}

.nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.nav ul {
  list-style: none;
}

.nav li {
  cursor: pointer;
  line-height: 28px;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
}

.mar-15 {
  margin-left: 15px;
}


.nav a:active {
  color: #269FFA;
  font-weight: bold;
}

.nav a:focus {
  color: #269FFA;
  font-weight: bold;
}

.nav a,
.nav-item {
  font-size: 14px;
  font-weight: 400;
  text-decoration: none;
  display: flex;
  justify-content: center;

  cursor: pointer;
  color: #878787;

  .login {
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 13px;
    font-weight: normal;
    line-height: normal;
    color: #878787;
  }
}

.register {
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 13px;
  font-weight: normal;
  line-height: normal;
  color: #878787;
}

.nav-item-down {
  width: 60px;
  text-align: left;
}

.nav-item-down {
  width: 86px;
  text-align: left;
}


.location a {
  border-left: none;
}


.icon {
  color: gray;
  vertical-align: middle;
}

.city {
  padding: 10px 15px;
}

.city-item {
  font-weight: bold;
  cursor: pointer;
  padding: 5px;
}

.city-item:hover {
  color: #F31947;
}

.person-icon {
  color: #F31947;
}


.shopping-cart-img {
  margin-right: 15px;
  width: 40px;
  height: 40px;
}

.shopping-cart-img img {
  width: 100%;
}

.shopping-cart-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: space-between;
  width: 200px;
  overflow: hidden;
  font-size: 12px;
  line-height: 20px;
}


.shopping-cart-null {
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.cart-null-icon {
  font-size: 38px;
  margin-bottom: 15px;
}

.shopping-cart-null span {
  font-size: 12px;
  line-height: 16px;
}

.username-p {
  position: relative;
  display: flex;

  div {
    cursor: pointer;

    > span {
      margin-left: 5px;
    }
  }

  .drop-items {
    position: absolute;
    display: none;
    top: 45px;
    left: 0;
    right: 0;
    margin: 0 auto;
    padding: 5px 10px;
    z-index: 20;
    height: 80px;
    background-color: #fff;
    width: 80px;
    border: 1px solid #eee;
    box-shadow: 2px 2px 7px #999;

    li {
      color: rgb(107, 106, 106);
      width: 100%;
      border-bottom: 1px solid rgb(207, 206, 206);
      font-weight: normal;
      text-align: center;

      &:last-child {
        border: none;
      }

      &:hover {
        cursor: pointer;
        color: #F31947;
      }
    }

    &::before {
      position: absolute;
      top: -20px;
      left: 30px;
      content: '';
      width: 0;
      height: 0;
      border: 10px solid #999;
      border-color: transparent transparent #fff transparent;
    }

    &::after {
      content: '';
      position: absolute;
      width: 80px;
      height: 20px;
      top: -20px;
      left: 0;
    }
  }

  &:hover {
    .drop-items {
      display: block;
    }
  }
}

.my-page {
  padding: 3px 5px;
  width: 180px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.my-page a {
  margin: 0px;
  padding: 0px;
  border: none;
}

.my-info {
  padding: 5px;
  width: 50%;
  height: 100%;
  text-align: center;
  cursor: pointer;
}

.my-info:hover {
  box-shadow: 0px 0px 5px #ccc;
}

.my-info i {
  font-size: 28px;
}

.my-info p {
  font-size: 12px;
}

.sign-out {
  padding: 5px;
  width: 50%;
  height: 100%;
  text-align: center;
  cursor: pointer;
}

.sign-out:hover {
  box-shadow: 0px 0px 5px #dddee1;
}

.sign-out i {
  font-size: 28px;
}

.sign-out p {
  font-size: 12px;
}

.goods-title:hover {
  color: #F31947;
}

.cart-badge {
  position: absolute;
  right: -8px;
  font-style: normal;
  background-color: #F31947;
  color: #fff;
  font-size: 12px;
  width: 17px;
  height: 17px;
  border-radius: 10px;
  line-height: 17px;
  text-align: center;
  z-index: 3;
  top: 3px;
}

.shopping-cart-box {
  padding: 8px 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-bottom: 1px #ccc dotted;
}

.shopping-cart-list {
  padding: 10px 10px;
  box-sizing: border-box;
  max-height: 300px;
  overflow-y: scroll;
  color: #333;

}

.shopping-cart-detail {
  color: #999;
  font-size: 12px;

}

.shopping-price {
  color: #F31947;
  font-size: 14px;
  font-weight: bold;
}

</style>
