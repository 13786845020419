<template>
  <div class="mb-4">
    <a-card style="height: 148px;" class="text-center">
      <a-steps :current="current" :items="items" label-placement="vertical"></a-steps>
    </a-card>
  </div>
</template>

<script>
export default {
  name: 'orderSteps',
  props: {
    current: {
      type: Number,
      default: 0,
    }
  },
  data() {
    return {
      items: [
        {
          title: '确认订单信息',
        },
        {
          title: '支付',
        },
        {
          title: '支付结果',
        },
      ]
    }
  },
  methods: {},
}
</script>

<style scoped lang="scss">
:deep(.ant-steps .ant-steps-item-icon) {
  width: 56.7px !important;
  height: 56.7px !important;
  font-size: 28px !important;

  display: flex;
  justify-content: center;
  align-items: center;
}

:deep(.ant-steps .ant-steps-item-tail) {
  top: 24px !important;
  margin-right: 0px !important;
  margin-left: 87px !important;
  padding: 4px 14px !important;
  z-index: 1;
  width: 680px !important;
}

:deep(.ant-steps-item-title) {
  font-size: 16px !important;
  width: 136.7px !important;
}
</style>
