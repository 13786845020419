<template>
  <div class="chatTitle">
    <div class="chatTitle-cus">
    <span class="titleName" v-if="selectItem">
      {{ selectItem.merName }}
    </span>
      <a-button style="margin-top: 15px;" type="text" shape="circle" @click="refresh">
        <template #icon>
          <ReloadOutlined/>
        </template>
      </a-button>
    </div>
  </div>
</template>

<script>
import { ReloadOutlined } from '@ant-design/icons-vue'

export default {
  name: 'chattitle',
  components: {
    ReloadOutlined
  },
  props: {
    selectItem: {
      type: Object,
      default: {}
    }
  },
  methods: {
    refresh() {
      this.$emit('refresh', this.selectItem)
    }
  }
}
</script>

<style scoped>
.chatTitle {
  width: 100%;

  .chatTitle-cus {
    width: 100%;
    height: 50px;
    display: flex;
    font-size: 25px;
    justify-content: space-between;
    border-bottom: 1px solid #D5D9E0;
  }
}


.titleName {
  font-size: 18px;
  font-weight: 500;
  padding: 20px 10px;
}

</style>
