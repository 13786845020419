<template>
  <div class="container width_1200_auto">
    <div class="margin-top-sm">
      <a-card title="申请记录">
        <empty v-if="listData.length === 0 && !loading"/>
        <a-table v-else :pagination="false" :bordered="false" :loading="loading" :columns="columns" :data-source="listData">
          <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'name'">
              <div style="color: #3B64DA;">{{ record.name }}</div>
            </template>
            <template v-if="column.key === 'createTime'">
              {{ record.createTime }}
            </template>
            <template v-if="column.key === 'handlingFee'">
              <div class="text-red text-bold" v-if="!record.isPay">{{ record.handlingFee }} USDT</div>
              <div class="text-red text-bold" v-if="record.isPay">{{ record.factPrice }} USDT</div>
            </template>
            <template v-else-if="column.key === 'auditStatus'">
              <div class="flex justify-center align-center">
                <a-image :width="24" :height="24" :preview="false" class="status-icon" v-if="record.auditStatus === 1"
                         :src="require('@/assets/images/pending.png')" mode=""></a-image>
                <a-image :width="24" :height="24" :preview="false" class="status-icon"
                         v-else-if="record.auditStatus === 2" :src="require('@/assets/images/passed.png')"
                         mode=""></a-image>
                <a-image :width="24" :height="24" :preview="false" class="status-icon"
                         v-else-if="record.auditStatus === 3 || record.auditStatus === 4"
                         :src="require('@/assets/images/not-pass.png')" mode=""></a-image>
                <text v-if="!record.isPay && record.auditStatus === 1 " style="color: #FEB902;" class="text-bold margin-left-xs">待支付</text>
                <text v-else-if="record.auditStatus === 2" class="text-green text-bold margin-left-xs">
                  {{ statusText(record.auditStatus) }}
                </text>
                <text v-else-if="record.auditStatus === 3 || record.auditStatus === 4"
                      class="status-text margin-left-xs text-bold">{{ statusText(record.auditStatus) }}
                </text>
                <text v-else class="status-text margin-left-xs text-bold">{{ statusText(record.auditStatus) }}</text>
              </div>
            </template>

            <template v-else-if="column.key === 'action'">
              <div>
                <!-- 支付 -->
                <text @click="joinVip(record)" v-if="!record.isPay&&record.auditStatus === 1"
                      class="btn margin-right-xs shadow cursor-pointer">
                  支付
                </text>
                <!-- 编辑 -->
                <text @click="goDetal(record)" v-if="!record.isPay&&record.auditStatus === 1"
                      class="btn1 margin-right-xs shadow cursor-pointer">
                  编辑
                </text>
                <!-- 取消后或者审核不通过编辑 -->
                <text @click="goDetal(record)" v-if="!record.isPay&&(record.auditStatus === 4)"
                      class="btn1 margin-right-xs shadow cursor-pointer">
                  编辑
                </text>
                <!-- 审核不通过编辑 -->
                <text @click="goDetalInfo(record)" v-if="record.auditStatus === 3"
                      class="btn1-cus margin-right-xs shadow cursor-pointer">
                  查看
                </text>
                <!-- 查看 -->
                <text @click="goDetal(record)" v-if="record.isPay &&record.auditStatus === 1"
                      class="btn1 margin-right-xs shadow cursor-pointer">
                  查看
                </text>
                <!-- 审核成功 -->
                <text @click="shareView(record)" v-if="record.auditStatus === 2"
                      class="btn2 margin-right-xs shadow cursor-pointer">
                  商户信息
                </text>
                <!-- 审核失败 -->
                <text @click="goDetal(record)" v-if="record.auditStatus === 4"
                      class="btn1 margin-right-xs shadow cursor-pointer">
                  查看
                </text>
                <!-- 取消 -->
                <text @click="goCanceled(record)" v-if="(record.auditStatus === 1 && record.auditStatus !== 4)"
                      class="btn3 margin-right-xs shadow cursor-pointer">
                  取消
                </text>
              </div>
            </template>
          </template>
        </a-table>
      </a-card>
      <!-- 分页 -->
      <div class="page-size margin-tb-sm" v-if="listData && listData.length > 0">
        <a-pagination :page-size="pageData.limit" :total="total" :show-size-changer="false" show-less-items
                      @change="changePageNum" @page-size-change="changePageSize"/>
      </div>
    </div>
    <!--  新店推荐-其他   -->
    <div class="padding-top-xs bg-white">
      <div class="danbao-content">
        <div class="danbao-content-top">
          <div class="top-font">
            已入驻商户
            <span class="top-font-right">用心甄选，每件都值得信赖！</span>
          </div>
        </div>
        <a-row class="margin-top-sm" :gutter="[19,24]">
          <a-col class="mer-card" :span="4" v-for="(item, index) in merListThree" :key="index">
            <a-card class="new-store hover-item-up-other"
                    :hoverable="true"
                    :loading="loadingThree"
                    :bordered="false"
                    :bodyStyle="{padding: '20px'}"
                    @click="()=>{$router.push({path:'/MerHome', query:{merId: item.id}})}">
              <div class="position-relative cursor-pointer">
                <div class="position-absolute cursor-pointer" style="left: 20px; top: -26px;">
                  <a-image :preview="false" :src="require('@/assets/new/home/new.png')" width="34px" height="36px"/>
                </div>
                <div class="flex align-center justify-center mt-6">
                  <a-avatar style="border: 1px solid #e8e8e8" :src="item.avatar" :size="120"></a-avatar>
                </div>
                <div class="cursor-pointer flex align-center justify-center mt-6">
                  <div class="mer-name line1 text-bold text-lg">
                    {{ item.name }}
                  </div>
                </div>

                <div class="cursor-pointer flex align-center justify-center mt-4">
                  <div class="custom-font-cus line2 padding-lr-xs text-df">
                    {{ item.intro }}
                  </div>
                </div>

                <div class="cursor-pointer flex align-center justify-center mt-4">
                  <div class="custom-money-cus">{{ $t('i_011') }}{{ item.earnestMoney }} USDT</div>
                </div>
              </div>
            </a-card>
          </a-col>
        </a-row>
      </div>
    </div>

    <!-- 常见问题 -->
    <div id="ToIssue" class="page-container-all width_1200_auto mt-5 mb-5">
      <div class="page-join-content">
        <div class="page-container-info">
          <a-card :body-style="{padding: '20px'}">
            <template #title>
              <a-image :preview="false" :src="require('@/assets/join/qa.png')" :width="32" :height="32"></a-image>
              常见问题
            </template>
            <a-collapse v-model:activeKey="activeKey" expand-icon-position="end" :bordered="false" ghost accordion>
              <a-collapse-panel v-for="(item, index) in issueList" :key="index">
                <template #header>
                  <div class="d-flex align-center">
                    <div class="iss-item-coll-title">
                      <a-image :src="require('@/assets/issue.png')" :width="28" :height="22"></a-image>
                      <div class="text-coll">{{ 'Q' + (index + 1) }}</div>
                    </div>
                    <div class="ml-1">
                      {{ item.issue }}
                    </div>
                  </div>
                </template>
                <div class="html-font" v-html="item.answer"></div>
              </a-collapse-panel>
            </a-collapse>
          </a-card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getDiyMerListReverApi, getMerSettledRecordApi, setApplyCancel } from '@/api/merchant'
import { LeftOutlined, RightOutlined } from '@ant-design/icons-vue'
import { queryNormalIssue } from '@/api/tg'

export default {
  components: {
    LeftOutlined,
    RightOutlined
  },
  data() {
    return {
      columns: [
        {
          title: '店铺名称',
          key: 'name',
          align: 'center'
        },
        {
          title: '提交时间',
          key: 'createTime',
          align: 'center'
        },
        {
          title: '状态',
          key: 'auditStatus',
          align: 'center'
        },
        {
          title: '金额',
          key: 'handlingFee',
          align: 'center'
        },
        {
          title: '操作',
          key: 'action',
          align: 'center'
        }
      ],
      loading: false,
      listData: [],
      pageData: {
        page: 1,
        limit: 15,
      },
      total: 0,
      loadingThree: false,
      merListThree: [],
      issueList: []
    }
  },
  created() {
    this.getListData()
    this.getMerListRever()
    this.getIssueList()
    setTimeout(() => {
      this.goToAboutSection(this.$route.query.to)
    }, 300)
  },
  methods: {
    goToAboutSection(toMao) {
      this.$nextTick(() => {
        if (toMao === 'ToIssue') {
          const element = document.getElementById(toMao)
          if (element) {
            element.scrollIntoView({ behavior: 'smooth' }) // 平滑滚动到该元素
          }
        }
      })
    },
    getIssueList() {
      let data = {
        page: 1,
        limit: 100,
        issueType: 3
      }
      queryNormalIssue(data)
          .then(res => {
            this.issueList = res.data.list
          })
    },
    getMerListRever() {
      this.loadingThree = true
      getDiyMerListReverApi(6)
          .then((res) => {
            this.merListThree = res.data.list
            this.loadingThree = false
          })
    },
    shareView(item) {
      this.$router.push({
        path: '/AuditSuc',
        query: {
          account: item.realName,
        }
      })
    },
    goDetalInfo(item) {
      this.$router.push({
        path: '/AuditErr',
        query: {
          key: '1',
          orderNo: item.id,
          denialReason: item.denialReason,
        }
      })
    },
    goDetal(item) {
      this.$router.push({
        path: '/Enter',
        query: {
          key: '1',
          orderNo: item.id,
        }
      })
    },
    goCanceled(item) {
      // 取消
      setApplyCancel(item)
          .then(res => {
            if (res.code === 200) {
              this.listData = []
              this.pageData = {
                page: 1,
                limit: 10,
              }
              this.getListData()
            } else {
              this.$Message.error(res.message)
            }
          })
    },
    joinVip(item) {
      this.$router.push({
        path: '/JoinPay',
        query: {
          id: item.id,
        }
      })
    },
    getListData() {
      this.loading = true
      getMerSettledRecordApi(this.pageData)
          .then(res => {
            this.total = res.data.total
            this.listData = res.data.list
            this.loading = false
          })
    },
    // 分页 修改页码
    changePageNum(val) {
      this.pageData.page = val
      this.getListData()
    },
    // 分页 修改页数
    changePageSize(val) {
      this.pageData.page = 1
      this.pageData.limit = val
      this.getListData()
    },
    statusText(number) {
      return number === 1 ? '待审核' : number === 2 ? '审核通过' :
          number === 3 ? '审核未通过' :
              number === 4 ? '已取消' : ''
    },
    statusBtn(number) {
      return number === 0 ? '编辑' : number === 1 ? '查看' :
          number === 2 ? '重新提交' : ''
    },
  }
}
</script>

<style scoped lang="scss">
.mer-card {
  margin-bottom: 8px;
}

.custom-font-cus {
  font-weight: 480;
  font-size: 14px;
  color: #6B6C6D;
  height: 40px;
}

.new-store {
  height: 344px;
  background: linear-gradient(0deg, #F3F7FB, #FFFFFF);
  box-shadow: 0px 5px 20px 0px rgba(107, 131, 200, 0.13);
  border-radius: 20px;
  border: 2px solid #FFFFFF;
  position: relative;
}

.custom-money-cus {
  height: 32px;
  padding: 0 36px;
  background: #FCF3EB;
  border-radius: 16px;

  display: flex;
  justify-content: center;
  align-items: center;

  font-weight: 500;
  font-size: 14px;
  color: #FF6E26;
  line-height: 20px;
}

.danbao-content {
  padding: 20px;
  height: 460px;

  .danbao-content-top {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;

    .top-font {
      font-size: 20px;
      font-weight: bold;
      line-height: normal;
    }

    .top-font-right {
      font-size: 14px;
      font-weight: 350;
      line-height: 26px;
      letter-spacing: 0em;
      color: #999999;
    }
  }
}

.container {
  margin: 0 auto;
  padding: 10px 0;
  border-radius: 12px;

  .card-cu {
    height: 475px;
  }

  .title {
    font-size: 28px;
    font-weight: bold;
    line-height: 25px;
  }

  .content-font-1 {
    font-size: 16px;
    font-weight: normal;
    line-height: 25px;
    text-align: center;
  }

  .content-font-2 {
    font-size: 16px;
    font-weight: normal;
    line-height: 25px;
    text-align: center;
    color: #999999;

    .conten-customer {
      color: #269FFA;
    }
  }

  .content-btn {
    width: 115px;
    height: 38px;
    border-radius: 8px;
    background: #269FFA;

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 16px;
    font-weight: 500;
    line-height: 25px;
    color: #FFFFFF;
  }
}

.btn {
  font-size: 14px;
  color: #fff;
  padding: 7px 16px;
  height: 32px;
  background: #3B64DA;
  border-radius: 16px;
}

.btn1 {
  font-size: 14px;
  color: #3B64DA;
  height: 32px;
  border-radius: 16px;
  border: 1px solid #3B64DA;
  padding: 7px 16px;
}

.btn1-cus {
  font-size: 14px;
  color: #ED4F4F;
  height: 32px;
  padding: 7px 16px;
  border-radius: 30px;
  border: 1px solid #ED4F4F;
}

.btn2 {
  font-size: 14px;
  color: #fff;
  height: 32px;
  padding: 7px 16px;
  border-radius: 30px;
  background-color: #FEB902;
}

.btn3 {
  font-size: 14px;
  height: 32px;
  color: #000000;
  padding: 7px 16px;
  border-radius: 30px;
  border: 1px solid #D1D1D1;
}
</style>
