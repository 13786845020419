<template>
  <div id="card" class="mb-2">
    <div>
      <MessageOutlined/>
      聊天列表
    </div>
    <a-input class="search mt-1" type="text" size="mini" v-model:value="keyword" placeholder="搜索"
             @input="search">
      <template #prefix>
        <Icon type="md-search" color="#BFBFBF"/>
      </template>
    </a-input>
  </div>
</template>

<script>
import { MessageOutlined } from '@ant-design/icons-vue'

export default {
  name: 'card',
  components: {
    MessageOutlined
  },
  data() {
    return {
      keyword: ''
    }
  },
  methods: {
    search(e) {
      this.$emit('search', this.keyword)
    }
  }
}
</script>

<style lang="scss" scoped>
#card {
  height: 60px;
  padding: 10px;
  background-color: #F7F7FA;

  .avatar {
    width: 40px;
    height: 40px;
    vertical-align: middle; /*这个是图片和文字居中对齐*/
  }

  .name {
    display: inline-block;
    padding: 10px;
    margin-bottom: 15px;
    font-size: 16px;
  }

  .search {
    width: 100%;
    height: 30px;
    line-height: 30px;
    padding: 0 10px;
    border: 1px solid #e9e9e9 !important;
    border-radius: 10px;
    outline: none; /*鼠标点击后不会出现蓝色边框*/
  }

  .search:hover {
    border: none !important;
  }

  .searchBtn {
    background-color: #DBD9D8;
    border: 0;
    margin-left: 3px;
  }
}
</style>
<style>
/*当前组件的el-input样式设置*/
#card .el-input__inner {
  background-color: #DBD9D8;
  outline: none; /*鼠标点击后不会出现蓝色边框*/
}
</style>
