<template>
  <div class="container width_1200_auto">
    <div class="flex-container">
      <div class="flex-item">
        <div class="position-relative">
          <a-image :preview="false" :width="812" :height="190"
                   :src="require('@/assets/new/share/sy_b_0.png')"></a-image>
          <div class="card-top-1 position-absolute">
            <div class="item-cus">
              <div class="item-title">邀请人数</div>
              <div class="item-bottom">
                <a-image :preview="false" :width="80" :height="80"
                         :src="require('@/assets/new/share/sy_b_0_0.png')"></a-image>
                <div class="ml-6">
                  <div class="item-number">{{ peopleData.count ? peopleData.count : '0' }}</div>
                  <div class="item-unit">人</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex-item">
        <div class="position-relative">
          <a-image :preview="false" :width="812" :height="190"
                   :src="require('@/assets/new/share/sy_b_1.png')"></a-image>
          <div class="card-top-2 position-absolute">
            <div class="item-cus">
              <div class="item-title">我的佣金</div>
              <div class="item-bottom">
                <a-image :preview="false" :width="80" :height="80"
                         :src="require('@/assets/new/share/sy_b_1_1.png')"></a-image>
                <div class="ml-6">
                  <div class="item-number">
                    {{
                      peopleData.brokerage ? parseFloat(peopleData.brokerage)
                          .toFixed(2) : '0.00'
                    }}
                  </div>
                  <div class="item-unit">USDT</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="margin-top-sm">
      <a-card :body-style="{width:'1600px', padding: '0'}">
        <template #title>
          <div class="flex-title">
            <div class="line-shu"></div>
            <div class="ml-2">邀请详情</div>
          </div>
        </template>
        <div>
          <div class="table-top">
            <div class="menu-group">
              <div class="menu" :class="grade === 0 ? 'back-select' :''" @click="setType(0)">一级(<span
                  class="pepole-count">{{ peopleData.firstSpreadNum ? peopleData.firstSpreadNum : '0' }}</span>)
              </div>
              <div class="menu" :class="grade === 1 ? 'back-select' :''" @click="setType(1)">二级(<span
                  class="pepole-count">{{ peopleData.secondSpreadNum ? peopleData.secondSpreadNum : '0' }}</span>)
              </div>
              <div class="menu" :class="grade === 2 ? 'back-select' :''" @click="setType(2)">三级(<span
                  class="pepole-count">{{ peopleData.thirdSpreadNum ? peopleData.thirdSpreadNum : '0' }}</span>)
              </div>
            </div>
            <div class="search">
              <a-input :placeholder="'关键词'" v-model:value="keywords">
                <template #prefix>
                  <Icon type="md-search" color="#BFBFBF"/>
                </template>
                <template #suffix>
                  <a-button :size="'small'" type="link" @click="submitForm">搜索</a-button>
                </template>
              </a-input>
            </div>
          </div>
          <a-table :pagination="false" :loading="loading" :columns="columns" :dataSource="listData">
            <template #headerCell="{ column }">
              <template v-if="column.key === 'time'">
                <div class="text-center">{{ column.title }}</div>
              </template>
            </template>
            <template #bodyCell="{ column, record }">
              <template v-if="column.key === 'nickname'">
                <div class="text-blue">{{ record.nickname }}</div>
              </template>
              <template v-if="column.key === 'uid'">
                <div class="text-gray">{{ record.uid }}</div>
              </template>
              <template v-if="column.key === 'type'">
                <div class="text-bold">{{ record.type }}</div>
              </template>
              <template v-if="column.key === 'joinTime'">
                <div class="text-gray">{{ record.joinTime }}</div>
              </template>
              <template v-if="column.key === 'joinBrokerageSum'">
                <div class="text-red text-bold">{{ record.joinBrokerageSum ? record.joinBrokerageSum : '0.00' }} USDT
                </div>
              </template>
              <template v-if="column.key === 'orderBrokerageSum'">
                <div class="text-red text-bold">{{ record.orderBrokerageSum ? record.orderBrokerageSum : '0.00' }}
                  USDT
                </div>
              </template>
            </template>
          </a-table>
        </div>
        <!-- 分页 -->
        <div class="page-size mt-5 mb-5 mr-3" v-if="listData && listData.length > 0">
          <a-pagination :page-size="pageData.limit"
                        :total="grade === 0 ? (peopleData.firstSpreadNum ? peopleData.firstSpreadNum : 0) : total"
                        show-less-items @change="changePageNum" :show-size-changer="false" @page-size-change="changePageSize"/>
        </div>
      </a-card>
    </div>
  </div>
</template>
<script>
import { inviteData, inviteDataList, } from '@/api/tg.js'

export default {
  data() {
    return {
      loading: false,
      columns: [
        {
          title: '用户',
          dataIndex: 'nickname',
          key: 'nickname',
          align: 'center',
        }, {
          title: 'ID',
          dataIndex: 'inviteCode',
          key: 'inviteCode',
          align: 'center',
        }, {
          title: '加入时间',
          dataIndex: 'joinTime',
          key: 'joinTime',
          align: 'center',
        }, {
          title: '入驻费返佣',
          dataIndex: 'joinBrokerageSum',
          key: 'joinBrokerageSum',
          align: 'center',
        }, {
          title: '返佣金额',
          dataIndex: 'orderBrokerageSum',
          key: 'orderBrokerageSum',
          align: 'center',
        },
      ],
      listData: [],
      pageData: {
        page: 1,
        limit: 10,
      },
      total: 0,
      pList: [],
      pInfo: {},
      grade: 0,
      peopleData: {},
      keywords: '',
    }
  },
  created() {
    this.upCallback()
    this.getInviteData()
  },
  methods: {
    submitForm: function () {
      this.listData = []
      this.pageData = {
        page: 1,
        limit: 10,
      }
      this.upCallback()
    },
    setType: function (grade) {
      if (this.grade !== grade) {
        this.listData = []
        this.grade = grade
        this.pageData = {
          page: 1,
          limit: 10,
        }
        this.upCallback()
      }
    },
    getInviteData() {
      inviteData()
          .then(res => {
            this.peopleData = res.data
          })
    },
    upCallback() {
      let data = {
        keyword: this.keywords,
        grade: this.grade,
        page: this.pageData.page,
        limit: this.pageData.limit,
      }
      inviteDataList(data)
          .then((res) => {
            let curPageData = res.data
            if (curPageData && curPageData.length >= 0) {
              this.listData = res.data
              if (this.listData && curPageData.length >= 1) {
                this.total = this.listData[0].total
              }
            }
          })
    },
    changePageNum(val) { // 修改页码
      this.pageData.page = val
      this.upCallback()
    },
    changePageSize(val) { // 修改页数
      this.pageData.page = 1
      this.pageData.limit = val
      this.upCallback()
    },
  }
}
</script>

<style scoped lang="scss">
.back-select {
  border: 1px solid #2b85e4 !important;
  color: #2b85e4 !important;
}

.table-top {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;

  .search {
    width: 384px;
    height: 37px;
    border-radius: 50px;
    margin-right: 20px;
  }

  .menu-group {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;

    padding: 20px;

    .menu {
      cursor: pointer;
      width: 98px;
      height: 30px;
      border-radius: 50px;
      box-sizing: border-box;
      border: 1px solid #E8E8E8;
      font-size: 14px;
      font-weight: normal;
      line-height: 26px;

      display: flex;
      justify-content: center;
      align-items: center;

      margin-right: 20px;

      .pepole-count {
        //color: #2b85e4;
      }
    }
  }
}

.flex-container {
  display: flex;
  justify-content: space-between; /* 或者 space-around */
  margin-right: 30px;
}

.flex-item {
  flex: 1;
  margin-right: -28px;
}

.card-top-1 {
  width: 100%;
  top: 31px;
  left: 41px;
}

.card-top-2 {
  width: 100%;
  top: 31px;
  left: 79px;
}

.item-cus {
  .item-title {
    font-weight: bold;
    font-size: 20px;
    color: #FFFFFF;
    line-height: 28px;
  }

  .item-bottom {
    margin-top: 27px;
    display: flex;
    justify-content: start;
    align-items: center;

    .item-number {
      font-weight: bold;
      font-size: 38px;
      color: #F8F9F8;
      line-height: 28px;
    }

    .item-unit {
      margin-top: 14px;
      font-weight: 400;
      font-size: 17px;
      color: #F8F9F8;
      line-height: 28px;
    }
  }
}

.container {
  padding: 0 0;
  border-radius: 12px;
  min-height: 1200px;
  margin-bottom: 25px;
}
</style>
