<template>
  <div class="container width_1200_auto">
    <!--  步骤条 -->
    <OrderSteps :current="1"/>
    <!-- 支付弹窗 -->
    <pay-code :balance="balance" :splus="splus" :visible="visibleView" @complete="payCommit"/>
  </div>
</template>
<script>
import payCode from '@/components/payCode/index.vue'
import { Debounce } from '@/utils/validate'
import { orderPay } from '@/api/order'

import OrderSteps from '@/components/orderSteps/index.vue'
import { isSet } from '@/api/user'
import { Modal } from 'ant-design-vue'

export default {
  name: 'Payment',
  components: {
    payCode,
    OrderSteps
  },
  data() {
    return {
      visibleView: false,
      balance: '',
      orderNo: '',
      payType: 'yue',
      payChannel: 'yue',
      cartArr: [],
      splus: 0,
    }
  },
  created() {
    this.orderNo = this.$route.query.orderNo
    this.splus = this.$route.query.payPrice
    if (!this.splus && this.splus <= 0) {
      this.$router.push({
        path: '/',
        query: {}
      })
    } else {
      this.isSetPwd()
    }
  },
  methods: {
    isSetPwd() {
      isSet()
          .then(res => {
            if (res.code === 200) {
              if (res.data) {
                Modal.confirm({
                  title: '提醒',
                  centered: true,
                  content: '您未设置支付密码,前去设置?',
                  onOk: () => {
                    this.$router.push({ path: '/AccountSafe' })
                  },
                  onCancel: () => {
                    this.$router.go(-1)
                    this.visibleView = false
                  }
                })
              } else {
                this.payConfig()
              }
            }
          })
    },
    payCommit(e) {
      if (!e) {
        this.$router.go(-1)
        this.visibleView = false
        return
      }
      this.getOrderPay(e)
    },
    getOrderPay: Debounce(function (e) {
      orderPay({
        orderId: this.orderNo,
        orderNo: this.orderNo,
        payChannel: this.payChannel,
        payType: this.payType,
        scene: 0,
        payPassword: e
      })
          .then(res => {
            if (res.code !== 200) {
              this.$Message.error(res.message)
            } else {
              if (!res.data) {
                Modal.confirm({
                  title: '提醒',
                  centered: true,
                  content: '您余额不足,前去充值?',
                  onOk: () => {
                    this.$router.push({ path: '/MyWallet' })
                  },
                  onCancel: () => {
                  }
                })
              } else {
                this.visibleView = false
                this.$router.replace({
                  path: '/OrderResult',
                  query: {
                    orderNo: this.orderNo
                  }
                })
              }
            }
          })
    }),
    // 支付配置
    payConfig() {
      // 支付方式
      let that = this
      this.$store.dispatch('getPayConfig')
          .then((res) => {
            that.balance = res.userBalance
            that.cartArr = res.payConfig
            that.active = 0
            if (that.cartArr.length > 0) {
              that.payType = that.cartArr[0].value
              that.visibleView = true
            }
          })
    },
  }
}
</script>

<style scoped lang="scss">
.container {
  margin: 0 auto;
  padding: 20px 0;
  border-radius: 12px;
  min-height: 1600px;

  .mer-top-card {
    width: 100%;
  }
}
</style>
