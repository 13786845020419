<template>
  <div>
    <div class="container width_1200_auto">
      <a-layout class="layoutAll">
        <a-layout-sider :width="230" class="side-bar" ref="side" :collapsed-width="78" theme="light">
          <!-- 列表 -->
          <a-menu class="side-menu mt-7"
                  style="padding: 10px 10px"
                  mode="inline"
                  v-model:openKeys="openKeys"
                  v-model:selectedKeys="selectedKeys"
                  @select="onSelect">
            <div v-for="(item, index) in menuList" :key="index">
              <a-menu-item class="mt-3" v-if="!item.menus" :key="item.path">
                <template #icon>
                  <a-image :preview="false" :src="link === item.path ? item.icon_selected : item.icon"/>
                </template>
                <div v-if="item.path === 'Message'" class="item-font">
                  <div class="cu-center-between">
                    <div>{{ item.title }}</div>
                    <a-badge :dot="msgCount" :offset="[-60, 0]">
                      <a-image :preview="false" :src="link === item.path ? require('@/assets/new/user/s_jt.png') : require('@/assets/new/user/jt.png')"/>
                    </a-badge>
                  </div>
                </div>
                <div v-else class="item-font">
                  <div class="cu-center-between">
                    <div>{{ item.title }}</div>
                    <a-image :preview="false" :src="link === item.path ? require('@/assets/new/user/s_jt.png') : require('@/assets/new/user/jt.png')"/>
                  </div>
                </div>
              </a-menu-item>
              <a-sub-menu class="mt-3" v-else :key="item.path">
                <template #icon>
                  <a-image :preview="false" :src="link === item.path ? item.icon_selected : item.icon"/>
                </template>
                <template #title>
                  <div class="item-font">
                    <div>{{ item.title }}</div>
                  </div>
                </template>
                <div v-for="(items, indexs) in item.menus" :key="indexs">
                  <a-menu-item :key="items.path">
                    <div class="item-font">
                      {{ items.title }}
                    </div>
                  </a-menu-item>
                </div>
              </a-sub-menu>
            </div>
          </a-menu>
        </a-layout-sider>

        <a-layout class="layout ml-5">
          <a-layout-content class="content">
            <transition mode="out-in">
              <router-view :key="$route.query.t"/>
            </transition>
          </a-layout-content>
        </a-layout>
      </a-layout>
    </div>
  </div>
</template>

<script>
import menuList from './menu'
import { Divider } from 'view-ui-plus'
import { getReadCount } from '@/api/message'
import MyAdd from '@/views/supply/myAdd.vue'

export default {
  name: 'Home',
  components: { Divider },
  data() {
    return {
      link: 'Profile',
      openLink: '',
      menuList, // 会员中心左侧列表
      userInfo: {},
      darkStatus: '',
      selectedKeys: ['Profile'],
      openKeys: [],
      msgCountInterval: '',
      msgCount: false
    }
  },
  watch: {
    '$store.getters.readCount': {
      handler(val) {
        if (this.$store.getters.token) {
          this.getAllReadCount()
        }
      }
    },
    '$store.getters.userInfo': {
      handler(newVal, oldVal) {
        this.$nextTick(() => {
          this.userInfo = newVal
        })
      }
    },
    '$route.name': {
      handler(newVal, oldVal) {
        this.$nextTick(() => {
          if (this.$route.name === 'UserHome') {
            this.$router.push({
              name: 'Profile',
              query: {}
            })
          }
          if (this.$route.name === 'OrderDetails') {
            this.link = 'OrderDetails'
            this.selectedKeys[0] = 'MyOrder'
          } else if (this.$route.name === 'MyAdd') {
            this.link = 'MyAdd'
            this.selectedKeys[0] = 'MySupply'
          } else {
            this.link = this.$route.name
            this.selectedKeys[0] = this.link
          }
        })
      }
    },
    '$store.getters.token': {
      handler(newVal, oldVal) {
        this.$nextTick(() => {
          if (!newVal) {
            this.userInfo = { nickname: '' }
            if (this.msgCountInterval) {
              clearInterval(this.msgCountInterval)
              this.msgCountInterval = null
            }
          } else {
            if (this.msgCountInterval) {
              clearInterval(this.msgCountInterval)
              this.msgCountInterval = null
            }
            this.IntervalMsgCount()
          }
        })
      }
    }
  },
  created() {
    if (this.$route.name === 'UserHome') {
      this.link = 'Profile'
      this.selectedKeys[0] = this.link
      this.$router.push({
        name: 'Profile',
        query: {}
      })
    } else {
      this.link = this.$route.name
      this.selectedKeys[0] = this.link
      if (this.link === 'UserInfo' || this.link === 'MyAddress' || this.link === 'AccountSafe' || this.link === 'WalletList') {
        this.openLink = 'AccountSafe'
        this.openKeys[0] = this.openLink
      }
      this.$router.push({
        name: this.$route.name,
        query: {}
      })
    }
    // 用户信息
    let user = localStorage.getItem('USER_INFO')
    if (user) {
      this.userInfo = JSON.parse(user)
      this.IntervalMsgCount()
    }
  },
  methods: {
    IntervalMsgCount() {
      this.getAllReadCount()
    },
    getAllReadCount() {
      getReadCount()
          .then(res => {
            if (res.data > 0) {
              this.msgCount = true
            } else {
              this.msgCount = false
            }
          })
    },
    getLink(name) {
      const statusMap = {
        'Profile': '个人中心',
        'MyOrder': '我的订单',
        'UserInfo': '个人资料',
        'MyAddress': '地址管理',
        'AccountSafe': '账户设置',
        'WalletList': 'USDT地址薄',
        'MyWallet': '我的钱包',
        'OrderDetails': '订单详情',
        'MyAdd': '编辑供需',
        'Message': '消息中心',
        'MySupply': '我的供需',
        'MyCoupon': '我的优惠券',
        'MyCollect': '我的收藏',
        'Attention': '关注店铺',
        'History': '我的足迹',
        'Help': '帮助中心'
      }
      return statusMap[name]
    },
    // 每次点击左侧bar的callback
    onSelect(name) {
      this.link = name.key
      this.$router.push({
        name: name.key,
        query: {}
      })
    },
  },
}
</script>

<style scoped lang="scss">
:deep(.ant-menu-light .ant-menu-item-selected) {
  background-color: #3B64DA !important;
  color: #fff;
}

:deep(.ant-menu-light .ant-menu-submenu-selected >.ant-menu-submenu-title) {
  color: #3B64DA;
}

.item-font {
  font-size: 16px;
  font-weight: normal;
  line-height: 38px;
  padding-left: 12px;
}

.ivu-layout {
  background: inherit !important;
}

.content {
  border-radius: 12px;
}

.side-menu,
.side-bar,
.content {
  border-radius: 12px;
}

.side-bar {
  min-height: 600px;
  height: auto;
  border-radius: 12px;
}

.layoutAll {
  min-height: 1200px;
  border-radius: 12px;
}

.container {
  border-radius: 12px;
  margin-top: 20px;
  margin-bottom: 25px;
  background-color: #F3F4FA !important;
}

.user-icon {
  height: 180px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}

.user-img {
  margin-bottom: 15px;
  width: 96px;
  height: 96px;
  border-radius: 48px;
  overflow: hidden;
}

.layout-footer-center {
  padding: 0px 15px;
  padding-bottom: 15px;
  text-align: center;
}
</style>
