module.exports = {
  menus: [
    {
      img: require('@/assets/new/cate/cate00.png'),
    },
    {
      img: require('@/assets/new/cate/cate01.png'),
    },
    {
      img: require('@/assets/new/cate/cate02.png'),
    },
    {
      img: require('@/assets/new/cate/cate03.png'),
    },
    {
      img: require('@/assets/new/cate/cate04.png'),
    },
    {
      img: require('@/assets/new/cate/cate05.png'),
    },
    {
      img: require('@/assets/new/cate/cate06.png'),
    },
    {
      img: require('@/assets/new/cate/cate07.png'),
    },
  ],
  menusSelected: [
    {
      img: require('@/assets/new/cate/cate100.png'),
    },
    {
      img: require('@/assets/new/cate/cate101.png'),
    },
    {
      img: require('@/assets/new/cate/cate102.png'),
    },
    {
      img: require('@/assets/new/cate/cate103.png'),
    },
    {
      img: require('@/assets/new/cate/cate104.png'),
    },
    {
      img: require('@/assets/new/cate/cate105.png'),
    },
    {
      img: require('@/assets/new/cate/cate106.png'),
    },
    {
      img: require('@/assets/new/cate/cate107.png'),
    },
  ],
}
