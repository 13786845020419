<template>
  <div class="page-container-all width_1200_auto">
    <div class="position-relative">
      <a-image :preview="false" :width="1600" :height="200" class="all-page-container img-top-border-radius"
               :src="require('@/assets/new/info/banner.png')"></a-image>
      <div class="position-absolute flex" style="bottom: 70px; left: 730px;">
        <div class="hover-item-big banner-btn cu-center cursor-pointer" @click="addInfo">
          <PlusOutlined/>
          <span class="ml-2">发布</span>
        </div>
        <div class="ml-3 hover-item-big banner-btn-right cu-center cursor-pointer" @click="myInfoRecord">
          我的发布 >
        </div>
      </div>
    </div>

    <div class="tabs mt-8">
      <div :class="seletcedIndex === 0 ? 'tbs-item-selected':'' " class="tbs-item cursor-pointer text-lg"
           @click="seletcedTab(0)">热门
      </div>
      <div :class="seletcedIndex === 1 ? 'tbs-item-selected':'' " class="tbs-item cursor-pointer text-lg ml-3"
           @click="seletcedTab(1)">最新
      </div>
    </div>

    <div class="width_1200_auto mt-4">
      <a-row :gutter="[20,24]">
        <a-col :span="18">
          <a-skeleton v-if="loading" active :loading="loading" avatar :paragraph="{ rows: 5 }" v-for="(item,index) in 10" :key="index" />
          <a-card :loading="loading" :bordered="false" :body-style="{padding: '28px 34px 30px 38px'}" v-for="(item,index) in cusList" :key="index" class="mt-3 mb-5" style="width: 100%;">
            <template #title>
              <div class="d-flex justify-between align-center" style="padding: 15px 0 20px 10px;">
                <div class="me-auto d-flex" @click="seletcedIndex === 0 || seletcedIndex === 1 ? clickPing(item) : ''">
                  <a-avatar :src="item.avatar" :size="48">
                    <template #icon>
                      <a-image :src="require('@/assets/images/default.png')" :preview="false"/>
                    </template>
                  </a-avatar>
                  <div class="d-flex">
                    <div class="ml-3">
                      <div class="text-xl">{{ item.nickname }}</div>
                      <div class="text-gray text-df">
                        {{ comparedateEnable(item.createTime) }}
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div v-if="seletcedIndex === 3" class="margin-right-xs">
                    <div v-if="item.auditStatus === 0" class="text-gray">
                      审核中
                    </div>
                    <div v-if="item.auditStatus === 1" class="text-red text-cut">
                      未通过
                    </div>
                    <div v-if="item.auditStatus === 2" class="text-green">
                      审核通过
                    </div>
                    <div class="text-right text-blue cursor-pointer text-df" @click="viewInfo(item)">编辑</div>
                  </div>
                </div>
              </div>
            </template>
            <div class="cursor-pointer">
              <div @click="seletcedIndex === 0 || seletcedIndex === 1 ? clickPing(item) : ''">
                <texthide :value="item.contentInfo" :index="index" :item="item"/>
              </div>
              <div class="mt-2">
                <a-row :gutter="[20,24]">
                  <a-col :span="5" v-for="(items,indexs) in item.img" :key="indexs">
                    <a-image style="border-radius: 10px;" :src="items" :width="211" :height="141"
                             class="img-fit-cover"/>
                  </a-col>
                </a-row>
              </div>
            </div>
            <div v-if="seletcedIndex === 0 || seletcedIndex === 1" class="d-flex justify-end align-center">
              <div class="grid text-center col-3 text-gray">
                <div class="padding-xs flex justify-start align-center">
                  <div class="ml-2 ">
                    <a-image :preview="false" :src="require('@/assets/info/zan.png')" width="23px" height="22px"
                             class="img-fit-cover" @click="clickZan(item)"></a-image>
                  </div>
                  <div class="ml-2 text-lg">{{ item.likeCount }}</div>
                </div>
                <div class="padding-xs flex justify-center align-center">
                  <div class="ml-2">
                    <a-image :preview="false" :src="require('@/assets/info/ping.png')" width="23px" height="22px"
                             class="img-fit-cover" @click="clickPing(item)"></a-image>
                  </div>
                  <div class="ml-2 text-lg">{{ item.remarkCount }}</div>
                </div>
                <div class="padding-xs flex justify-end align-center">
                  <div class="ml-2">
                    <!-- 分享-->
                    <a-popover placement="bottomLeft" trigger="click" style="width: 440px;">
                      <template #title>
                        <div class="d-flex justify-between" style="border-bottom: 1px solid #e8e8e8;">
                          <div style="height: 26px;">将链接分享至Telegram</div>
                        </div>
                      </template>
                      <template #content>
                        <div class="mt-6">
                          <div class="text-gray tg-url-share">
                            {{ tagUrl }}
                            <div class="copy-btn" @click="$Copy({text: tagUrl})">复制</div>
                          </div>
                          <div class="telegram-btn" @click="toTelegram">
                            将链接分享至Telegram
                          </div>
                        </div>
                      </template>
                      <div class="btn-2 cursor-pointer">
                        <a-image :preview="false" :src="require('@/assets/info/share.png')" width="23px" height="22px"
                                 class="img-fit" @click="clickShare(item)"></a-image>
                      </div>
                    </a-popover>
                  </div>
                  <div class="ml-2 text-lg">{{ item.shareCount }}</div>
                </div>
              </div>
            </div>

            <div v-else class="padding-lr flex text-right margin-bottom-xs">
              <div v-if="item.auditStatus === 1" class="text-red">
                未通过原因: {{ item.failureInfo }}
              </div>
            </div>
          </a-card>

          <div class="mt-3 mb-5 flex justify-end align-center">
            <a-pagination :page-size="limit" :total="total" show-less-items @change="changePageNum"
                          :show-size-changer="false" @page-size-change="changePageSize"/>
          </div>
        </a-col>
        <a-col :span="6" class="mt-3">
          <a-card :body-style="{padding: '10px'}" class="mb-3">
            <template #title>
              精选话题
            </template>
            <a-skeleton v-if="loading" active :loading="loading" avatar :paragraph="{ rows: 5 }" v-for="(item,index) in 3" :key="index" />
            <arco-scrollbar v-else
                            style="height:295px;overflow-y: auto;overflow-x:hidden !important;background-color: #FFFFFF !important;">
              <div class="mt-2 mb-3 flex cursor-pointer" style="width: 95%;" v-for="(item ,index) in hotInfo"
                   :key="index">
                <a-image :preview="false" :src="item.img[0]"
                         style="border-radius: 10px"
                         width="134px" height="84px" class="img-fit-cover"
                         @click="clickShare(item)"></a-image>
                <div class="cu-center-first-col">
                  <div class="ml-2 line2 text-black golbal-btn text-lg"
                       @click="toInforDetails(item)"
                       style="width: 220px;">
                    {{ item.contentInfo }}
                  </div>
                  <div class="ml-2 mt-3 text-gray text-df">
                    {{ comparedateEnable(item.createTime) }}
                  </div>
                </div>
              </div>
            </arco-scrollbar>
          </a-card>
          <a-card :body-style="{padding: '10px'}">
            <template #title>
              热门话题
            </template>
            <a-skeleton v-if="loading" active :loading="loading" :paragraph="{ rows: 3 }" v-for="(item,sindex) in 3" :key="sindex" />
            <div v-else class="text-cut mt-2 mb-3" style="width: 95%;" v-for="(item ,index) in hots" :key="index">
              <a href="javascript:void(0);" class="text-black golbal-btn hover-color-cus text-lg"
                 @click="toInforDetails(item)">·
                {{ item.contentInfo }}</a>
            </div>
          </a-card>
        </a-col>
      </a-row>
    </div>

    <InfosAdd :visible="visible" :formDataPar="formData" @change="evaluateChange"/>
  </div>
</template>
<script>
import { infoById, inforList, numCountPlus, saveInfor, updateInfor } from '@/api/infor.js'

import { formatChatListTime, } from '@/utils/chat.js'

import { company } from '@/config/index.js'

import { CloseOutlined, PlusOutlined } from '@ant-design/icons-vue'
import texthide from '@/components/texthide/index.vue'
import InfosAdd from '@/components/infos/index.vue'
import { Icon } from 'view-ui-plus'

export default {
  components: {
    Icon,
    texthide,
    PlusOutlined,
    CloseOutlined,
    InfosAdd,
  },
  data() {
    return {
      seletcedIndex: 0,
      cusList: [],
      content: [],
      newsPanelHeight: 0,
      myIndex: null,
      page: 1,
      limit: 10,
      total: 0,
      visible: false,
      formData: {},
      hots: [],
      tagUrl: '',
      visiblePop: false,
      itemIndex: -1,
      hotInfo: [],
      loading: false,
    }
  },
  created() {
    if (this.$route.query.selectId) {
      this.myInfoRecord()
    }
    this.getInformation()
    this.getHotInformation()
    this.infoHots()
  },
  methods: {
    infoHots() {
      let data = {
        page: 1,
        limit: 8,
        itemId: 0
      }
      inforList(data)
          .then((res) => {
            this.hots = res.data.list
            this.hots.forEach(item => {
              if (item.images) {
                item.img = item.images.split(',')
              }
            })
          })
    },
    toInforDetails(item) {
      this.$router.push({
        path: '/Details',
        query: { inforId: item.id }
      })
    },
    clickPing(item) {
      this.$router.push({
        path: '/Details',
        query: { inforId: item.id }
      })
    },
    evaluateChange(formData) {
      this.visible = false
      if (!formData) {
        return
      }
      let dataParam = formData
      let data = {
        title: '',
        contentInfo: dataParam.content,
        images: dataParam.images.toString()
      }
      if (!this.formData) {
        saveInfor(data)
            .then(res => {
              this.getInformation()
            })
      } else {
        let data = {
          infoId: this.formData.id,
          title: '',
          contentInfo: dataParam.content,
          images: dataParam.images.toString()
        }
        updateInfor(data)
            .then((res) => {
              this.getInformation()
            })
      }
    },
    queryInfoById(infoId) {
      infoById(infoId)
          .then(res => {
            let info = res.data
            this.formData.id = info.id
            this.formData.content = info.contentInfo
            if (info && info.images) {
              let imgs = info.images.split(',')
              let images = []
              imgs.forEach(item => {
                images.push(item)
              })
              this.formData.images = images
            } else {
              this.formData.images = []
            }
          })
    },
    addInfo() {
      this.formData = null
      this.visible = true
    },
    comparedateEnable(time) {
      return formatChatListTime(time)
    },
    viewInfo(item) {
      this.queryInfoById(item.id)
      setTimeout(() => {
        this.visible = true
      }, 500)
    },
    clickZan(item) {
      let data = {
        id: item.id,
        itemId: 0
      }
      numCountPlus(data)
          .then(res => {
            this.getInformation()
          })
    },
    clickShare(item) {
      this.itemIndex = item.id
      let data = {
        id: item.id,
        itemId: 2
      }
      numCountPlus(data)
          .then(res => {
            this.getInformation()
            this.tagUrl = company.href + '/Details?inforId=' + item.id
            this.visiblePop = true
          })
    },
    toTelegram() {
      window.open(`https://t.me/share/url?url=${this.tagUrl}`, '_blank')
    },
    myInfoRecord() {
      this.myIndex = 0
      this.seletcedIndex = 3
      this.page = 1
      this.getInformation()
    },
    seletcedTab(index) {
      this.myIndex = null
      this.seletcedIndex = index
      this.page = 1
      this.getInformation()
    },
    // 分页 修改页码
    changePageNum(val) {
      this.page = val
      this.getInformation()
    },
    // 分页 修改页数
    changePageSize(val) {
      this.page = 1
      this.limit = val
      this.getInformation()
    },
    getHotInformation() {
      let pageNum = this.page // 页码, 默认从1开始
      let pageSize = this.limit // 页长, 默认每页10条
      let data = {
        page: pageNum,
        limit: pageSize,
        itemId: 1
      }
      inforList(data)
          .then((res) => {
            let curPageData = res.data.list
            this.hotInfo = [] //追加新数据
            curPageData.forEach(item => {
              if (item.images) {
                item.img = item.images.split(',')
                this.hotInfo.push(item)
              }
            })
          })
    },
    getInformation() {
      let pageNum = this.page // 页码, 默认从1开始
      let pageSize = this.limit // 页长, 默认每页10条
      let data = {
        page: pageNum,
        limit: pageSize,
        itemId: this.seletcedIndex,
        id: this.myIndex
      }
      this.loading = true
      inforList(data)
          .then((res) => {
            let curPageData = res.data.list
            this.total = res.data.total
            this.cusList = curPageData //追加新数据
            this.cusList.forEach(item => {
              if (item.images) {
                item.img = item.images.split(',')
              }
            })
            this.loading = false
          })
    },
  }
}
</script>

<style scoped lang="scss">
.banner-btn {
  width: 143px;
  height: 50px;
  background: #FFDA7B;
  border-radius: 25px;

  font-weight: 400;
  font-size: 20px;
  color: #000000;
  line-height: 20px;
}

.banner-btn-right {
  width: 187px;
  height: 50px;
  background: #FFDA7B;
  border-radius: 25px;

  font-weight: 400;
  font-size: 20px;
  color: #000000;
  line-height: 20px;
}

.review {
  width: 84px;
  height: 26px;
  border-radius: 50px;
  background: rgba(68, 68, 68, 0.6);
  color: #fff;

  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
}

.tabs {
  display: flex;
  width: 100%;

  .tbs-item {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 136px;
    height: 42px;
    color: #999999;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 20rpx;

    border-radius: 21px;
    border: 1px solid #DBDEE6;
  }

  .tbs-item-selected {
    width: 136px;
    height: 42px;
    background: linear-gradient(-90deg, #428FEE, #305DDC);
    border-radius: 21px;
    color: #FFFFFF;
  }
}

.page-container-all {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: flex-start;
  flex-direction: column;
  flex-wrap: nowrap;

  .all-page-container {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }
}

.page-container {
  width: 1600px;
  margin-top: 300px;

  .font-info {
    font-size: 16px;
    font-weight: normal;
    line-height: 30px;
    letter-spacing: 0em;
    color: #7B7B7B;
  }
}
</style>
