<template>
  <div class="container width_1200_auto">
    <div class="flex-container">
      <div class="flex-item">
        <div class="position-relative">
          <a-image :preview="false" :width="547" :height="190" :src="require('@/assets/new/share/yj_b_0.png')"></a-image>
          <div class="card-top-1 position-absolute">
            <div class="item-cus">
              <div class="item-title">我的佣金</div>
              <div class="item-bottom">
                <a-image :preview="false" :width="80" :height="80"
                         :src="require('@/assets/new/share/yj_b_0_0.png')"></a-image>
                <div class="ml-6">
                  <div class="item-number">{{ pInfo.brokerage || '0.00' }}</div>
                  <div class="item-unit">USDT</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex-item">
        <div class="position-relative">
          <a-image :preview="false" :width="548" :height="190"
                   :src="require('@/assets/new/share/yj_b_1.png')"></a-image>
          <div class="card-top-2 position-absolute">
            <div class="item-cus">
              <div class="item-title">入驻费返佣</div>
              <div class="item-bottom">
                <a-image :preview="false" :width="80" :height="80"
                         :src="require('@/assets/new/share/yj_b_1_1.png')"></a-image>
                <div class="ml-6">
                  <div class="item-number">{{ pInfo.joinBrokerage || '0.00' }}</div>
                  <div class="item-unit">USDT</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex-item">
        <div class="position-relative">
          <a-image :preview="false" :width="547" :height="190"
                   :src="require('@/assets/new/share/yj_b_2.png')"></a-image>
          <div class="card-top-2 position-absolute">
            <div class="item-cus">
              <div class="item-title">交易佣金</div>
              <div class="item-bottom">
                <a-image :preview="false" :width="80" :height="80"
                         :src="require('@/assets/new/share/yj_b_2_2.png')"></a-image>
                <div class="ml-6">
                  <div class="item-number">{{ pInfo.orderBrokerage || '0.00' }}</div>
                  <div class="item-unit">(USDT)</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="margin-top-sm">
      <a-card :body-style="{width:'1600px', padding: '0'}">
        <template #title>
          <div class="flex-title">
            <div class="line-shu"></div>
            <div class="ml-2">推广佣金</div>
          </div>
        </template>
        <a-table :pagination="false" :loading="loading" :columns="columns" :dataSource="listData">
          <template #headerCell="{ column }">
            <template v-if="column.key === 'time'">
              <div class="text-right text-center">{{ column.title }}</div>
            </template>
          </template>
          <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'amount'">
              <div class="text-red text-bold">{{ record.amount ? record.amount : '0.00' }} USDT</div>
            </template>
            <template v-if="column.key === 'nickname'">
              <div class="text-blue">{{ record.nickname }}</div>
            </template>
            <template v-if="column.key === 'uid'">
              <div class="text-gray">{{ record.uid }}</div>
            </template>
            <template v-if="column.key === 'type'">
              <div class="text-bold">{{ record.type }}</div>
            </template>
            <template v-if="column.key === 'time'">
              <div class="text-right text-center">{{ record.time }}</div>
            </template>
          </template>
        </a-table>
        <!-- 分页 -->
        <div class="page-size mt-5 mb-5 mr-3" v-if="listData && listData.length > 0">
          <a-pagination :page-size="pageData.limit" :total="total" :show-size-changer="false"
                        show-less-items @change="changePageNum" @page-size-change="changePageSize"/>
        </div>
      </a-card>
    </div>
  </div>
</template>
<script>
import { incomeData } from '@/api/tg'

export default {
  data() {
    return {
      loading: false,
      columns: [
        {
          title: '用户',
          dataIndex: 'nickname',
          key: 'nickname',
          align: 'center',
        }, {
          title: 'ID',
          dataIndex: 'uid',
          key: 'uid',
          align: 'center',
        }, {
          title: '返佣类型',
          dataIndex: 'type',
          key: 'type',
          align: 'center',
        }, {
          title: '返佣金额',
          dataIndex: 'amount',
          key: 'amount',
          align: 'center',
        }, {
          title: '时间',
          dataIndex: 'time',
          key: 'time',
          align: 'center',
        },
      ],
      listData: [],
      pageData: {
        page: 1,
        limit: 10,
      },
      total: 0,
      pList: [],
      pInfo: {},
    }
  },
  created() {
    this.upCallback()
  },
  methods: {
    upCallback() {
      this.loading = true
      incomeData(this.pageData)
          .then((res) => {
            if (res.data) {
              this.pInfo.brokerage = res.data.brokerage
              this.pInfo.joinBrokerage = res.data.joinBrokerage
              this.pInfo.orderBrokerage = res.data.orderBrokerage
            }
            this.listData = res.data.child
            this.total = res.data.total
            this.loading = false
          })
    },
    changePageNum(val) { // 修改页码
      this.pageData.page = val
      this.upCallback()
    },
    changePageSize(val) { // 修改页数
      this.pageData.page = 1
      this.pageData.limit = val
      this.upCallback()
    },
  }
}
</script>

<style scoped lang="scss">
.flex-container {
  display: flex;
  justify-content: space-between; /* 或者 space-around */
  margin-right: 30px;
}

.flex-item {
  flex: 1;
  margin-right: -28px;
}

.card-top-1 {
  width: 100%;
  top: 31px;
  left: 41px;
}

.card-top-2 {
  width: 100%;
  top: 31px;
  left: 79px;
}

.item-cus {
  .item-title {
    font-weight: bold;
    font-size: 20px;
    color: #FFFFFF;
    line-height: 28px;
  }

  .item-bottom {
    margin-top: 27px;
    display: flex;
    justify-content: start;
    align-items: center;

    .item-number {
      font-weight: bold;
      font-size: 38px;
      color: #F8F9F8;
      line-height: 28px;
    }

    .item-unit {
      margin-top: 14px;
      font-weight: 400;
      font-size: 17px;
      color: #F8F9F8;
      line-height: 28px;
    }
  }
}

.container {
  padding: 0 0;
  border-radius: 12px;
  min-height: 1200px;
  margin-bottom: 25px;
}
</style>
