<template>
  <div class="wrapper">
    <a-card class="cart-users"
            :head-style="{padding: '20px 32px', height: '79px'}"
            :bodyStyle="{padding: '20px 30px'}"
            :loading="loading">
      <template #title>
        我的供需
      </template>
      <template #extra>
        <a-button class="item-item-btns cu-center" type="link" @click="toAdd">
          + 新增
        </a-button>
      </template>
      <empty v-if="noteRecommendList.length === 0"/>
      <div class="mt-4" v-else>
        <a-row :gutter="[29,24]">
          <a-col :span="8" v-for="(item, index) in noteRecommendList" :key="index">
            <a-card style="border-radius: 20px;" :bordered="false" :bodyStyle="{padding: '19px 0 21px 23px',height: '210px'}" @click="viewCard(item)"
                    class="cursor-pointer position-relative hover-item-up">
              <div class="position-absolute" style="top: 0;right: 0;z-index: 3;">
                <a-image :preview="false" :src="require(`@/assets/new/home/${item.categoryId === 1 ? 'gong.png' : 'xu.png' }`)" :width="53" :height="66"/>
              </div>
              <div class="position-absolute cursor-pointer" style="top: 19px;left: 21px;z-index:10;">
                <a-image :width="48" :height="40" :src="require('@/assets/new/home/yinhao.png')" :preview="false"/>
              </div>
              <div class="position-absolute cursor-pointer" style="top: 39px;left: 38px;z-index:1000;width: 338px;height: 162px;">
                <div class="list-title line1">
                  <div class="text-cut">
                    {{ item.title }}
                  </div>
                </div>
                <div class="list-content mt-2 line1">{{ item.content }}</div>
                <div class="list-time mt-4">发布时间:{{ item.createTime }}</div>
              </div>
              <!-- 审核状态:0-待审核，1-审核通过，2-审核失败，3-平台关闭 -->
              <div class="text-sm text-gray margin-top-xs position-absolute cursor-pointer" style="left: 21px;bottom: 21px;">
                <div v-if="item.auditStatus === 0 || item.auditStatus === 2 || item.auditStatus === 3" class="approval_status">
                  <div class="status_count" :class="item.auditStatus === 0 ? 'status1' : 'status0'">
                    <div class="status_title">
                      <span class="iconfont"
                            :class="(item.auditStatus === 2 || item.auditStatus === 3) ? 'icon-tishi' : 'icon-shijian'"></span>
                      <span v-if="item.auditStatus === 2" class="title"
                            style="font-size: 13px;">审核未通过，内容仅自己可见</span>
                      <span v-if="item.auditStatus === 3" class="title"
                            style="font-size: 13px;">平台关闭，内容仅自己可见</span>
                      <span v-if="item.auditStatus === 0" class="title"
                            style="font-size: 13px;">正在审核，内容仅自己可见</span>
                    </div>
                    <div v-if="item.auditStatus === 2 || item.auditStatus === 3" class="status_info"
                         style="font-size: 13px;">
                      {{ item.refusal }}
                    </div>
                  </div>
                </div>

                <div v-if="item.auditStatus === 1" class="approval_status">
                  <div class="status_count" :class="item.auditStatus === 0 ? 'status1' : 'status0'">
                    <div style="color: green;font-size: 13px;">
                      <span class="iconfont icon-xuanzhong11"></span>
                      <span v-if="item.auditStatus === 1" class="title"
                            style="color: green;font-size: 26rpx;">审核通过</span>
                    </div>
                  </div>
                </div>
              </div>
            </a-card>
          </a-col>
        </a-row>
      </div>
    </a-card>
    <!-- 分页 -->
    <div v-if="noteRecommendList && noteRecommendList.length > 0" class="page-size mt-5 mb-5">
      <a-pagination :page-size="limit" :total="total" :show-size-changer="false" show-less-items
                    @change="changePageNum" @page-size-change="changePageSize"/>
    </div>
  </div>
</template>

<script>
import { myNoteApi, } from '@/api/discover.js'

export default {
  data() {
    return {
      loading: false,
      current: ['1'],
      activeGxKey: 1,
      page: 1,
      limit: 12,
      total: null,
      noteRecommendList: [],
      where: {
        page: 1,
        limit: 12
      },
    }
  },
  mounted() {
    if (this.$store.getters.uid) {
      this.myNoteList()
    } else {
      this.$router.push({
        path: '/login',
        query: {}
      })
    }
  },
  methods: {
    // 获取我的的作品
    myNoteList: function () {
      this.loading = true
      myNoteApi(this.where)
          .then(res => {
            this.noteRecommendList = res.data.list
            this.total = res.data.total
            this.loading = false
          })
    },
    toAdd() {
      if (this.$store.getters.uid) {
        this.$router.push({
          path: '/AddSupply',
          query: {}
        })
      } else {
        this.$router.push({
          path: '/Login',
          query: {}
        })
      }
    },
    viewCard(item) {
      this.$router.push({
        path: '/MyAdd',
        query: {
          noteId: item.id
        }
      })
    },
    changePageNum(val) { // 修改页码
      this.page = val
      this.myNoteList()
    },
    changePageSize(val) { // 修改页数
      this.page = 1
      this.limit = val
      this.myNoteList()
    },
  }
}
</script>

<style scoped lang="scss">
.title-tg {
  padding-left: 10px;
  padding-right: 10px;
  height: 22px;
  font-size: 14px;
  border-radius: 100px;
  background: #FF6600;
  text-align: center;
  color: #fff;
  margin-right: 10px;
}

.approval_status {
  .status_count {
    display: flex;
    justify-content: start;
    align-items: center;
    padding-left: 22px;
    border-radius: 5px;

    .title {
      font-size: 16px;
      font-weight: bold;
    }

    &.status0 {
      background: #FDF3F2;
      width: 369px;
      height: 42px;
      border-radius: 10px;

      .title {
        color: #E93323;
      }
    }

    &.status1 {
      background: #FFF4EB;
      width: 369px;
      height: 42px;
      border-radius: 10px;

      .title {
        color: #FC8327;
      }
    }

    .iconfont {
      font-size: 15px;
    }

    .icon-shijian {
      color: #FC8327;
    }

    .icon-tishi {
      color: #E93323;
    }

    .status_info {
      font-size: 11px;
      color: #666666;
      margin: 5px 0 0 18px;
    }
  }
}

.item-item-btns {
  width: 82px;
  height: 38px;
  background: #3B64DA;
  border-radius: 19px;

  font-weight: 400;
  font-size: 16px;
  color: #FFFFFF;
  line-height: 42px;
}

.item-item-btn {
  width: 84px;
  height: 26px;
  border-radius: 50px;
  opacity: 1;
  background: rgba(68, 68, 68, 0.6);

  font-size: 12px;
  font-weight: normal;
  line-height: 25px;
  letter-spacing: 0px;
  color: #FFFFFF;
}

.list-title {
  font-size: 20px;
  font-weight: bold;
  line-height: normal;
}

.list-time {
  width: 100%;
  font-size: 14px;
  font-weight: 350;
  color: #A7A7A7;
}

.list-content {
  width: 100%;
  font-weight: 400;
  font-size: 16px;
  color: #000000;
  line-height: 24px;
}

.page-size {
  text-align: right;
}

.list-g-x {
  padding: 20px 0 20px 10px;
}

.wrapper {
  width: 100%;
  min-height: 1200px;

  .cart-users {
    width: 100%;
    min-height: 1200px;
    border-radius: 12px;
    opacity: 1;
  }
}

.ant-menu-light {
  background: inherit !important;
}

</style>
