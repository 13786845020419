<template>
  <div>
    <a-modal class="modal-view"
             v-model:open="visible"
             :maskClosable="false"
             :afterClose="cancel"
             :destroyOnClose="true"
             :width="800"
             @cancel="cancel">
      <div class="cu-center title" style="border-bottom: 1px #ECEFF5 solid; line-height: 60px;">TRX兑换</div>
      <a-spin :spinning="loading">
        <div class="padding-top-lg padding-left-xs padding-right-xs">
          <div><span class="text-bold text-xl">•</span> 当前汇率：1 USDT = <span class="">{{ trxInfo.rate }}</span> TRX
          </div>
          <div><span class="text-bold text-xl">•</span> 可兑余额：$ <span class="">{{ trxInfo.balanceUsdt }} </span> USDT
          </div>
          <div><span class="text-bold text-xl">•</span> 服务费：<span class="">1%-2% </span>（按照欧易实时买价，全行业最低）
          </div>
          <div class="padding-top-lg cu-center text-bold">USDT-TRC20地址</div>
          <div class="cu-center margin-top-xs">
            <a-qrcode :size="240" :value="trxInfo.address" :bordered="false"/>
          </div>
          <div class="cu-center mt-6">
            <div class="text-blue-cus text-bold text-center cursor-pointer add-back"
                 @click="$Copy({text: trxInfo.address})">
              {{ trxInfo.address }} <span class="margin-left-xs cursor-pointer" @click="$Copy({text: trxInfo.address})"><CopyOutlined/></span>
            </div>
          </div>
          <div class="margin-top-lg">地址细分：前6位 <span class="text-blue-cus">TRrpsB</span> | 中间6位 <span
              class="text-blue-cus">W4HSGZ</span> | 后6位 <span class="text-blue-cus">155151</span></div>
          <div class="margin-top-lg">
            <a-image class="mb-1" :src="require('@/assets/new/help/alert.png')" :width="16" :height="16"
                     :preview="false"/>
            <span class="ml-1 text-blue-cus text-bold">兑换说明：</span>
          </div>
          <div class="margin-top-sm">1.自动兑换：转账后，系统会自动处理并返还TRX。单笔兑换需不低于<span
              class="text-blue-cus">20</span>USDT，总额不超过可兑余额。
          </div>
          <div class="margin-top-xs">
            2.账户提醒：请勿使用交易所账户进行转账，以防因地址特性导致资金丢失，平台概不负责。
          </div>
          <div class="margin-top-xs">3.到账时间：交易确认后，约两分钟内到账。</div>
          <div class="margin-top margin-bottom-xl cursor-pointer text-blue-cus flex align-center" @click="toCus">
            遇到问题?
            <div class="link ml-2">
              <a-image class="mb-1" :preview="false" :src="require('@/assets/new/product/cus.png')" :width="18" :height="18"/>
              <span class="ml-1">联系客服</span>
            </div>
          </div>
        </div>
      </a-spin>
      <template #footer>
      </template>
    </a-modal>
  </div>
</template>


<script>
import { exchangeTrx } from '@/api/api'
import { CopyOutlined } from '@ant-design/icons-vue'
import { Divider } from 'view-ui-plus'

export default {
  components: {
    Divider,
    CopyOutlined
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
  },
  watch: {
    visible: {
      handler(newVal, oldVal) {
        if (newVal) {
          this.exchangeTrxQuery()
        }
      }
    }
  },
  data() {
    return {
      trxInfo: {
        rate: 0.00,
        balanceUsdt: 0,
        address: 'TMxURRnpsCvNPhJcJjUBkrq92WaH3bdb51',
      },
      loading: false,
    }
  },
  methods: {
    toCus() {
      window.open('https://t.me/dbw51kf', '_blank')
    },
    exchangeTrxQuery() {
      this.loading = true
      exchangeTrx()
          .then(res => {
            this.trxInfo = res.data
            this.loading = false
          })
          .catch(err => {
            this.$Message.error(err)
          })
    },
    cancel() {
      this.trxInfo = {
        rate: 0.00,
        balanceUsdt: 0,
        address: 'TMxURRnpsCvNPhJcJjUBkrq92WaH3bdb51',
      }
      this.$emit('close', '')
    },
    onFinish() {
      this.trxInfo = {
        rate: 0.00,
        balanceUsdt: 0,
        address: 'TMxURRnpsCvNPhJcJjUBkrq92WaH3bdb51',
      }
      this.$emit('close', '')
    },
  }
}
</script>
<style scoped lang="scss">
.link {
  width: 109px;
  height: 32px;
  background: #FFFFFF;
  border-radius: 16px;
  border: 1px solid #3B64DA;

  display: flex;
  justify-content: center;
  align-items: center;
}

.text-blue-cus {
  color: #3B64DA !important;
}

.add-back {
  width: 512px;
  height: 49px;
  background: #F4F6FD;
  border-radius: 25px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.title {
  font-weight: bold;
  font-size: 24px;
  color: #000000;
  line-height: 20px;
}

:deep(.ant-modal .ant-modal-close) {
  top: 28px !important;
}

:deep(.ant-modal .ant-modal-content) {
  padding: 10px 29px !important;
}
</style>
