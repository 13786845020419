<template>
  <div>
    <a-image :preview="false" :width="1920" :height="200" :src="require('@/assets/new/notice/banner.png')"/>
    <div class="container width_1200_auto mt-7">
      <div class="cu-center-col">
        <a-space :size="27">
          <div class="center-btn cu-center cursor-pointer hover-item-big"
               :class="activeGxKey === 1 ? 'center-btn-select' : ''"
               @click="selectedKeys(1)">最新公告
          </div>
          <div class="center-btn cu-center cursor-pointer hover-item-big"
               :class="activeGxKey === 2 ? 'center-btn-select' : ''"
               @click="selectedKeys(2)">最新活动
          </div>
        </a-space>

        <div class="cu-center-col mt-7">
          <empty v-if="dataMsg.length <= 0" :_-title="activeGxKey === 1 ? '暂无系统公告' : '暂无活动'"/>
          <a-card :bordered="false" v-else :body-style="{padding: '30px', height: '110px'}"
                  class="mb-5 cursor-pointer hover-item-up"
                  style="width: 1600px;"
                  @click="toNotice(item)"
                  v-for="(item, index) in dataMsg" :key="index">
            <div class='text-lg text-bold'>{{ item.title }}</div>
            <div class='d-flex mt-3 text-gray cu-center justify-start text-df'>
              <div class='mr-3'>发布于</div>
              <div class='mr-2'>{{ item.createTime }}</div>
            </div>
          </a-card>
        </div>
      </div>

      <div class="notice-right mt-7 mb-7">
        <a-pagination :page-size="pageData.limit" :total="total" show-less-items @change="changePageNum"
                      :show-size-changer="false"
                      @page-size-change="changePageSize"/>
      </div>
    </div>
  </div>
</template>

<script>
import { getArticleList } from '@/api/api.js'
import { PlusOutlined } from '@ant-design/icons-vue'

export default {
  components: { PlusOutlined },
  data() {
    return {
      id: 0,
      articleInfo: [],
      store_info: {},
      content: '',
      shareInfoStatus: false,
      productId: 0,
      activeGxKey: 1,
      dataMsg: [],
      pageData: {
        page: 1,
        limit: 12,
      },
      total: 0,
    }
  },
  created() {
    this.getCidArticle()
  },
  methods: {
    toNotice(item) {
      this.$router.push({
        path: '@/Notice',
        query: { id: item.id }
      })
    },
    selectedKeys(e) {
      this.activeGxKey = e
      this.getCidArticle()
    },
    getCidArticle() {
      getArticleList(this.activeGxKey, this.pageData)
          .then(res => {
            this.dataMsg = res.data.list
            this.total = res.data.total
          })
    },
    // 分页 修改页码
    changePageNum(val) {
      this.pageData.page = val
      this.getCidArticle()
    },
    // 分页 修改页数
    changePageSize(val) {
      this.pageData.page = 1
      this.pageData.limit = val
      this.getCidArticle()
    },
  }
}
</script>

<style scoped lang="scss">
.notice-right {
  text-align: right;
}

.container {
  padding: 10px 0;
  border-radius: 12px;
  min-height: 1200px;
  margin-bottom: 25px;
}

.center-btn {
  width: 136px;
  height: 42px;
  border-radius: 21px;
  border: 1px solid #DBDEE6;

  font-weight: bold;
  font-size: 20px;
  color: #000000;
  line-height: 20px;
}

.center-btn-select {
  color: #FFFFFF;
  background: linear-gradient(-90deg, #428FEE, #305DDC);
}

.center-btn:hover {
  color: #FFFFFF;
  background: linear-gradient(-90deg, #428FEE, #305DDC);
}


</style>
