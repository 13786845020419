<template>
  <div class="customer-service" @click="showCusService" :style="{bottom: bottom}">
    <div class="cu-center flex-direction">
      <a-image :preview="false" :src="require('@/assets/new/home/kefu.png')" width="32px" height="36px" alt="客服"/>
      <div class="text-white mt-3">官方客服</div>
    </div>
  </div>
</template>
<script>

export default {
  props: {
    bottom: {
      type: String,
      default: 0
    }
  },
  methods: {
    showCusService() {
      let routeUrl = this.$router.resolve({
        path: '/ChatIm',
        query: { //要传的参数 可传多个
          dbMerId: 0
        }
      })
      window.open(routeUrl.href, '_blank')
    },
  }
}
</script>

<style scoped lang="scss">
.customer-service {
  position: fixed;
  bottom: 20px; /* 距离底部 20px */
  right: 35px; /* 距离右侧 20px */
  width: 90px; /* 按钮宽度 */
  height: 90px; /* 按钮高度 */
  background-color: #3B64DA; /* 按钮背景色 */
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 5px 20px 0 rgba(107, 131, 200, 0.06);
  cursor: pointer;
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.customer-service:hover {
  transform: scale(1.1); /* 鼠标悬浮时放大 */
  background-color: #3B64DA; /* 悬浮时改变背景色 */
  color: #3A69FB;
}

.customer-service img {
  width: 50%; /* 图标大小 */
  height: 50%;
  object-fit: contain;
}
</style>
