<template>
  <div class="container width_1200_auto">
    <div class="position-relative">
      <a-image :preview="false" :width="1600" :height="200" :src="require('@/assets/new/supply/banner.png')"/>
      <div class="position-absolute flex" style="bottom: 70px; left: 730px;">
        <div class="ml-3 hover-item-big banner-btn-right cu-center cursor-pointer"
             @click="() => {$router.push({path: '/MySupply'})}">
          我的供需 >
        </div>
      </div>
    </div>
    <div class="mt-5">
      <a-card :bodyStyle="{padding: '50px'}" title="发布供需">
        <a-form :model="formData"
                :label-col="{ style: { width: '210px', display: 'flex', justifyContent: 'end', alignItems: 'center', paddingRight: '17px' }}"
                :wrapper-col="{ span: 14 }"
                @finish="onFinish">
          <a-form-item label="供需" name="categoryId" :rules="[{ required: true, message: '请选择供需类别' }]">
            <a-radio-group v-model:value="formData.categoryId">
              <a-radio :value="item.id" v-for="(item, index) in categoryList" :key="index">
                {{ item.name }}
              </a-radio>
            </a-radio-group>
          </a-form-item>
          <a-form-item label="标题" name="title" :rules="[{ required: true, message: '请填写标题' }]">
            <a-input :maxlength="20" class="input-cell-supply" v-model:value="formData.title"
                     placeholder="请填写标题"></a-input>
          </a-form-item>
          <a-form-item label="供需描述" name="content" :rules="[{ required: true, message: '请填写供需描述' }]">
            <a-textarea class="input-cell-supply-area" :rows="4" :maxlength="formData.type === 1?600:200"
                        v-model:value="formData.content"
                        placeholder="请填写供需描述"></a-textarea>
          </a-form-item>
          <a-form-item label="电报账号" name="telegram" :rules="[{ required: true, message: '请输入电报账号连接' }]">
            <a-input class="input-cell-supply" v-model:value="formData.telegram" placeholder="请输入电报账号连接"
                     @input="phoneInput"></a-input>
          </a-form-item>
          <a-form-item label=" " :colon="false">
            <a-button class="submit-btn" type="primary" html-type="submit">发布</a-button>
          </a-form-item>
        </a-form>
      </a-card>
    </div>
    <div class="mt-12 tuijian-font">
      <a-image class="mb-1" :preview="false" :src="require('@/assets/new/blue.png')" :width="6" :height="18"/>
      <span class="ml-3">为你推荐</span>
    </div>
    <div class="mt-12 mb-13">
      <a-row :gutter="[19,24]">
        <a-col v-if="loading" :span="12" v-for="index in 4" :key="index">
          <a-skeleton active :loading="loading" :paragraph="{ rows: 4 }"/>
        </a-col>
        <a-col v-else :span="12" v-for="(item, noteIndex) in noteRecommendList" :key="noteIndex">
          <a-card :bordered="false" :bodyStyle="{padding: 'padding: 29px 0 26px 29px',height:'180px'}"
                  @click="viewCard(item)" :loading="loading" class="cursor-pointer hover-item-up position-relative">
            <div class="position-absolute" style="top: 0px;right: 0px;z-index: 3;">
              <a-image :preview="false" :src="require(`@/assets/new/home/${item.categoryId === 1 ? 'gong.png' : 'xu.png' }`)" :width="53" :height="66"/>
            </div>
            <div class="position-relative">
              <div class="position-absolute" style="top: 14px;left: 1px;z-index: 3;">
                <a-image :preview="false" :src="require('@/assets/new/home/yinhao.png')" :width="48" :height="40"/>
              </div>
              <div class="position-relative" style="z-index: 10">
                <div class="list-title line2">
                  <div class="line1" style="width: 730px;">
                    {{ item.title }}
                  </div>
                </div>
                <div class="list-content line2">{{ item.content }}</div>
                <div class="list-time">发布时间:{{ item.createTime }}</div>
              </div>
            </div>
          </a-card>
        </a-col>
      </a-row>
    </div>

    <a-modal v-model:open="openProduct" title="商品选择" @ok="handleOk" :width="'65%'"
             :bodyStyle="{height:'600px'}"
             okText='确认'
             cancelText="取消">
      <a-card :bodyStyle="{height:'600px'}">
        <a-tabs v-model:activeKey="activeKey" @change="changeProduct">
          <a-tab-pane key="1" tab="已购">
            <Scroll :height="500">
              <a-card dis-hover v-for="(item, index) in bought" :key="index" :bodyStyle="{padding: '10px'}"
                      class="mb-5">
                <List>
                  <ListItem>
                    <ListItemMeta description="">
                      <template #avatar>
                        <a-avatar :size="65" :shape="'square'" :src="item.image"/>
                      </template>
                      <template #title>
                        <div class="danbao-cell-title">{{ item.name }}</div>
                      </template>
                      <template #description>
                        <div class="danbao-cell-description">{{ item.price }} USDT</div>
                      </template>
                    </ListItemMeta>
                    <template #action>
                      <li>
                        <div class="mt-6 view-order">
                          <a-checkbox v-model:checked="item.checked" @change="ProCheck($event, item)"></a-checkbox>
                        </div>
                      </li>
                    </template>
                  </ListItem>
                </List>
              </a-card>
            </Scroll>
          </a-tab-pane>
          <a-tab-pane key="2" tab="收藏">
            <Scroll :height="500">
              <a-card dis-hover v-for="(item, index) in collect" :key="index" :bodyStyle="{padding: '10px'}"
                      class="mb-5">
                <List>
                  <ListItem>
                    <ListItemMeta description="">
                      <template #avatar>
                        <a-avatar :size="65" :shape="'square'" :src="item.image"/>
                      </template>
                      <template #title>
                        <div class="danbao-cell-title">{{ item.name }}</div>
                      </template>
                      <template #description>
                        <div class="danbao-cell-description">{{ item.price }} USDT</div>
                      </template>
                    </ListItemMeta>
                    <template #action>
                      <li>
                        <div class="mt-6 view-order">
                          <a-checkbox v-model:checked="item.checked" @change="ProCheck($event, item)"></a-checkbox>
                        </div>
                      </li>
                    </template>
                  </ListItem>
                </List>
              </a-card>
            </Scroll>
          </a-tab-pane>
          <a-tab-pane key="3" tab="浏览">
            <Scroll :height="500">
              <a-card dis-hover v-for="(item, index) in browse" :key="index" :bodyStyle="{padding: '10px'}"
                      class="mb-5">
                <List>
                  <ListItem>
                    <ListItemMeta description="">
                      <template #avatar>
                        <a-avatar :size="65" :shape="'square'" :src="item.image"/>
                      </template>
                      <template #title>
                        <div class="danbao-cell-title">{{ item.name }}</div>
                      </template>
                      <template #description>
                        <div class="danbao-cell-description">{{ item.price }} USDT</div>
                      </template>
                    </ListItemMeta>
                    <template #action>
                      <li>
                        <div class="mt-6 view-order">
                          <a-checkbox v-model:checked="item.checked" @change="ProCheck($event, item)"></a-checkbox>
                        </div>
                      </li>
                    </template>
                  </ListItem>
                </List>
              </a-card>
            </Scroll>
          </a-tab-pane>
        </a-tabs>
      </a-card>
    </a-modal>
  </div>
</template>
<script>
import { getCollectUserList, proBrowseApi, purchasedApi } from '@/api/product'

import { communityCategoryListApi, discoverListApi, noteAddApi } from '@/api/discover.js'
import infiniteScroll from '@/components/scroll/infiniteScroll.vue'
import upLoad from '@/components/upload/index.vue'
import { Debounce } from '@/utils/validate'
import { PlusOutlined } from '@ant-design/icons-vue'
import { Divider } from 'view-ui-plus'

export default {
  name: 'AddSupply',
  components: {
    Divider,
    PlusOutlined,
    infiniteScroll,
    upLoad
  },
  data() {
    return {
      categoryList: [], //逛逛分类
      formData: {
        categoryId: 1,
        image: '',
        content: '',
        cover: '',
        topicIds: '',
        proIds: '',
        video: '',
        id: 0,
        replyStatus: 1,
        title: '',
        type: 1, //1图文，2视频
        telegram: '',
      },
      activeKey: '1',
      proList: [],
      openProduct: false,
      prefix: 'https://t.me/',
      whereb: {
        page: 1,
        limit: 100,
      },
      wherec: {
        page: 1,
        limit: 100,
      },
      wheres: {
        page: 1,
        limit: 100,
      },
      checked: [],
      list: [],
      collect: [],
      bought: [],
      browse: [],
      checkedArr: [],
      disabled: false,
      showUploadType: 0,
      image: [],
      loading: false,
      noteRecommendList: [],
      cover: 'https://51danbao.oss-ap-southeast-1.aliyuncs.com/crmebimage/public/product/2024/08/10/ed8440fc0bf74bb488be8fcb81f78491zpf7rklzzi.png'
    }
  },
  watch: {
    checkedObj: {
      handler(n) {
      },
    }
  },
  mounted() {
    this.getCommunityCategoryList()
    this.getDiscoverList()
  },
  methods: {
    viewCard(item) {
      this.$router.push({
        path: '/SupplyInfo',
        query: {
          id: item.id,
        }
      })
    },
    getDiscoverList() {
      let where = {
        page: 1,
        limit: 4
      }
      this.loading = true
      discoverListApi(where)
          .then(res => {
            this.noteRecommendList = res.data.list
            this.loading = false
          })
    },
    /**
     * 提交数据
     */
    formSubmit: Debounce(function (e) {
      let that = this
      if (!that.formData.title) {
        return this.$Message.error('请输入标题')
      }
      if (!that.formData.content) {
        return this.$Message.error('请输入描述')
      }
      if (!that.formData.telegram) {
        return this.$Message.error('请输入电报')
      }
      // 正确的电报账号
      if (that.formData.telegram.indexOf('https://') <= -1 ||
          that.formData.telegram === that.prefix ||
          that.formData.telegram.length <= that.prefix.length) {
        return this.$Message.error('电报输入不正确')
      }
      if (!that.formData.categoryId) {
        return this.$Message.error('请选择供需类别')
      }
      if (this.formData.image.length > 0) {
        this.formData.cover = this.formData.image[0]
        if (this.formData.type === 2) {
          this.formData.cover = this.cover
          this.formData.video = this.formData.image[0]
        }
      } else {
        this.formData.cover = this.cover
      }
      this.formData.proIds = this.proList.map(val => val.productId)
          .join(',')
      this.formData.image = this.formData.image.toString()
      noteAddApi(that.formData)
          .then(res => {
            if (res.code !== 200) {
              this.$Message.error(res.message)
            } else {
              this.$router.push({
                name: 'Supply',
                query: {}
              })
            }
          })
    }),
    uploads(e) {
      this.formData.image = e.uploadImgs
      this.showUploadType = e.type
      this.formData.type = e.type
      if (e.type === 0) {
        this.formData.type = 1
      }
    },
    handleOk() {
      this.proList = this.checkedArr
      this.openProduct = false
    },
    ProCheck(event, item) {
      if (item.checked) {
        this.checkedArr.push(item)
        this.checkedArr = Array.from(new Set(this.checkedArr))
      } else {
        let checkdItems = []
        this.checkedArr.forEach(foritem => {
          if (foritem.id !== item.id) {
            checkdItems.push(foritem)
          }
        })
        this.checkedArr = checkdItems
      }
    },
    changeProduct(e) {
      if (e === '1') {
        this.getBounht()
      }
      if (e === '2') {
        this.getCollect()
      }
      if (e === '3') {
        this.getBrowse()
      }
    },
    viewProduct() {
      this.getBounht()
      this.openProduct = true
    },
    onFinish(e) {
      this.formSubmit()
    },
    //已购
    getBounht() {
      let that = this
      purchasedApi(that.whereb)
          .then(res => {
                that.getInitchecked(res.data.list)
                that.bought = res.data.list
              }
          )
    },
    //收藏
    getCollect() {
      let that = this
      getCollectUserList(that.wherec)
          .then(res => {
                that.getInitchecked(res.data.list)
                that.collect = res.data.list
              }
          )
    },
    //足迹
    getBrowse() {
      var that = this
      proBrowseApi(that.wheres)
          .then(res => {
                that.getInitchecked(res.data.list)
                that.browse = res.data.list
              }
          )
    },
    /*获取初始化选中的数据*/
    getInitchecked(arr) {
      let that = this
      if (arr.length > 0) {
        console.log(that.checkedArr)
        arr.forEach((item, index) => {
          that.checkedArr.forEach((val, i) => {
            if ((item.productId === val.productId)) {
              item.checked = true
            }
          })
        })
      }
    },
    //分类列表
    getCommunityCategoryList() {
      communityCategoryListApi()
          .then(res => {
            this.categoryList = res.data
          })
    },
    phoneInput(e) {
      this.$nextTick(() => {
        let inputValue = e.target.value
        let value = 'https://t.me'
        if (inputValue === value) {
          this.formData.telegram = this.prefix
          return
        }
        value = 'https://t.m'
        if (inputValue === value) {
          this.formData.telegram = this.prefix
          return
        }
        value = 'https://t.'
        if (inputValue === value) {
          this.formData.telegram = this.prefix
          return
        }
        value = 'https://t'
        if (inputValue === value) {
          this.formData.telegram = this.prefix
          return
        }
        value = 'https://'
        if (inputValue === value) {
          this.formData.telegram = this.prefix
          return
        }
        const nonChineseValue = inputValue.replace(/[\u4e00-\u9fa5\s]/g, '')
            .replace(/@/g, '')
        if (nonChineseValue !== inputValue) {
          if (!nonChineseValue.startsWith(this.prefix)) {
            this.formData.telegram = this.prefix + nonChineseValue
          } else {
            this.formData.telegram = nonChineseValue
          }
        } else {
          if (!inputValue.startsWith(this.prefix)) {
            this.formData.telegram = this.prefix + nonChineseValue
          } else {
            this.formData.telegram = nonChineseValue
          }
        }
      })
    },
    toIndex() {
      this.$router.push({
        path: '/',
        query: {}
      })
    },
    tolist() {
      this.$router.push({
        name: 'Supply',
        query: {}
      })
    },
  }
}
</script>

<style scoped lang="scss">
.tuijian-font {
  height: 20px;
  font-weight: 800;
  font-size: 20px;
  color: #000000;
  line-height: 20px;
}

.list-title {
  margin-top: 6px;
  font-size: 18px;
  font-weight: 500;
  line-height: normal;
}

.list-time {
  margin-top: 16px;
  font-weight: 400;
  font-size: 14px;
  color: #6B6C6D;
  line-height: 28px;
}

.list-content {
  margin-top: 13px;
  font-weight: 400;
  font-size: 14px;
  color: #999999;
  line-height: 24px;
}

.submit-btn {
  width: 191px;
  height: 48px;
  background: linear-gradient(-90deg, #0080D9, #0052D9);
  box-shadow: 0px 5px 16px 0px rgba(71, 100, 184, 0.3);
  border-radius: 10px;

  font-weight: 800;
  font-size: 20px;
  color: #FFFFFF;
  line-height: 38px;
}

.input-cell-supply {
  height: 48px;
  box-shadow: 0px 3px 10px 0px rgba(107, 131, 200, 0.08);
  border-radius: 10px;
  border: 1px solid #E9EAEB;
}

.input-cell-supply-area {
  height: 120px;
  box-shadow: 0px 3px 10px 0px rgba(107, 131, 200, 0.08);
  border-radius: 10px;
  border: 1px solid #E9EAEB;
}

.banner-btn-right {
  width: 187px;
  height: 50px;
  background: #4271E2;
  border-radius: 25px;

  font-weight: 400;
  font-size: 20px;
  color: #FFFFFF;
  line-height: 20px;
}

.container {
  margin: 0 auto;
  padding: 10px 0;
  border-radius: 12px;
}
</style>
