<template>
  <div class="page-container-all width_1200_auto">
    <div class="filter-container">
      <div class="main-top">
        <div class="mer-top-card">
          <MerTopNew :show-join="false" :merchant-info="merchantInfo" :store="store" :isCollect="isCollect"
                     @followToggle="followToggle"></MerTopNew>
          <div class="margin-top-xs margin-bottom">
            <a-row :gutter="[16, 24]">
              <a-col :span="5">
                <a-card :bodyStyle="{padding: '10px'}">
                  <template #title>
                    <div class="text-body-1 text-bold">商品分类</div>
                  </template>
                  <a-menu mode="inline" @click="handleClick" v-model:selectedKeys="selectedKeys">
                    <a-menu-item-group>
                      <a-menu-item class="mt-2" v-for="(item,index) in category" :key="item.id">
                        <div class="flex justify-between align-center">
                          <div>{{ item.name }}</div>
                          <div style="color: #C8C8C8;">
                            <a-image :src="require('@/assets/new/product/right.png')" :width="6" :height="10"
                                     :preview="false"/>
                          </div>
                        </div>
                      </a-menu-item>
                    </a-menu-item-group>
                  </a-menu>
                </a-card>
              </a-col>
              <a-col :span="19">
                <a-card :bodyStyle="{padding: '32px'}">
                  <div class="flex mb-3">
                    <a-space>
                      <a-button :class="morenSed ? 'button-moren-seleced' :''" type="link" class="button-moren"
                                @click="moRenClick">
                        综合
                      </a-button>
                      <a-button :class="isPriceSed ? 'button-moren-seleced' :''" type="link" class="button-moren-1"
                                @click="priceClick(isPrice)">
                        价格
                        <div class="flex flex-direction align-center ml-2">
                          <CaretUpOutlined style="width: 20px;height: 8px;"
                                           :style="!isPrice && isPriceSed ? {color : '#3B64DA'} : {color : '#B9B9BD'}"/>
                          <CaretDownOutlined style="width: 20px;height: 8px;"
                                             :style="isPrice && isPriceSed ? {color : '#3B64DA'} : {color : '#B9B9BD'}"/>
                        </div>
                      </a-button>
                      <a-button :class="isSalesSed ? 'button-moren-seleced' :''" type="link" class="button-moren-1"
                                @click="salesClick(isSales)">
                        销量
                        <div class="flex flex-direction align-center ml-2">
                          <CaretUpOutlined style="width: 20px;height: 8px;"
                                           :style="!isSales && isSalesSed ? {color : '#3B64DA'} : {color : '#B9B9BD'}"/>
                          <CaretDownOutlined style="width: 20px;height: 8px;"
                                             :style="isSales && isSalesSed ? {color : '#3B64DA'} : {color : '#B9B9BD'}"/>
                        </div>
                      </a-button>
                    </a-space>
                  </div>
                  <empty :_-title="'暂无更多商品数据'" v-if="(!productList || productList.length === 0 && !goodsLoading)"/>
                  <a-row :gutter="[20,24]" class="mt-6">
                    <a-col v-if="goodsLoading" :span="6" v-for="(item, Twoindex) in 12" :key="Twoindex" class="mb-6">
                      <a-skeleton active :loading="goodsLoading" :paragraph="{ rows: 5 }"/>
                    </a-col>
                    <a-col v-else :span="6" v-for="(item, index) in productList" :key="index" class="mb-6">
                      <a-card hoverable :bordered="false" :bodyStyle="{padding: '12px',height: '401.8px'}"
                              :loading="goodsLoading" class="cursor-pointer hover-item-up"
                              @click="() => {$router.push({path:'/Product', query:{ id: item.id}})}">
                        <div>
                          <a-image width="100%" :height="260.2"
                                   style="border-radius: 16px;"
                                   class="img-fit-cover" :preview="false"
                                   :src="item.image"/>
                        </div>
                        <div class="line2 mt-2 pa-1" style="height: 48px;">
                          {{ item.name }}
                        </div>
                        <div class="text-red mt-3 text-bold">
                          {{ item.price }} USDT
                        </div>
                        <div class="text-gray mt-2 ml-1 text-df text-caption flex">
                          <div>{{ item.sales || 0 }} 人付款</div>
                          <div class="padding-lr-xs">|</div>
                          <div>{{ merchantInfo.name }}</div>
                        </div>
                      </a-card>
                    </a-col>
                  </a-row>
                </a-card>
                <!-- 分页 -->
                <div class="page-size mt-5 mb-3" v-if="productList && productList.length > 0">
                  <a-pagination :page-size="where.limit" :total="total" show-less-items
                                :show-size-changer="false"
                                @change="changePageNum" @page-size-change="changePageSize"/>
                </div>
              </a-col>
            </a-row>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  getMerCategoryApi,
  getMerCollectAddApi,
  getMerCollectCancelApi,
  getMerDetailApi,
  getMerIndexInfoApi,
  getMerProListApi
} from '@/api/merchant.js'

import MerTopNew from '@/components/merTopNew/index.vue'
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons-vue'

export default {
  components: {
    CaretDownOutlined,
    CaretUpOutlined,
    MerTopNew
  },
  data() {
    return {
      merid: 0,
      merchantInfo: {},
      store: {},
      isCollect: false,
      skeletonShow: false,
      goodsLoading: false,
      price: 0,
      stock: 0,
      where: {
        merId: '',
        priceOrder: '',
        salesOrder: '',
        cid: '',
        page: 1,
        limit: 12,
      },
      selectedKeys: [''],
      productList: [],
      total: 0,
      moren: true,
      isPrice: false,
      isSales: false,
      morenSed: true,
      isPriceSed: false,
      isSalesSed: false,
      category: [],
    }
  },
  created() {
    this.merid = this.$route.query.merId
    this.getMerIndexInfo()
    this.getStore()
    this.getMerCategory()
    this.getGoods()
  },
  methods: {
    MerHome() {
    },
    getStore() {
      getMerDetailApi(this.merid)
          .then(res => {
            this.store = res.data
          })
    },
    handleClick(e) {
      this.where.cid = e.key
      this.getGoods()
    },
    getMerCategory() {
      getMerCategoryApi(this.merid)
          .then(res => {
            let all = {
              id: '',
              name: '全部分类'
            }
            this.category = res.data
            this.category.unshift(all)
          })
    },
    moRenClick() {
      this.morenSed = true
      this.isPriceSed = false
      this.isSalesSed = false
      this.moren = true
      this.isPrice = false
      this.isSales = false
      this.price = 0
      this.stock = 0
      this.getGoods()
    },
    priceClick(val) {
      this.morenSed = false
      this.isPriceSed = true
      this.isSalesSed = false

      this.moren = false
      this.isSales = false

      this.price = 2
      if (val) {
        this.price = 1
      }
      this.stock = 0
      this.isPrice = !this.isPrice
      this.getGoods()
    },
    salesClick(val) {
      this.morenSed = false
      this.isPriceSed = false
      this.isSalesSed = true

      this.moren = false
      this.isPrice = false

      this.stock = 2
      if (val) {
        this.stock = 1
      }
      this.price = 0
      this.isSales = !this.isSales
      this.getGoods()
    },
    getGoods() {
      this.goodsLoading = true
      this.where.merId = this.merid
      if (this.price === 0) {
        this.where.priceOrder = ''
      } else if (this.price === 1) {
        this.where.priceOrder = 'asc'
      } else if (this.price === 2) {
        this.where.priceOrder = 'desc'
      }
      if (this.stock === 0) {
        this.where.salesOrder = ''
      } else if (this.stock === 1) {
        this.where.salesOrder = 'asc'
      } else if (this.stock === 2) {
        this.where.salesOrder = 'desc'
      }
      getMerProListApi(this.where)
          .then(res => {
            this.productList = res.data.list
            this.goodsLoading = false
            this.total = res.data.total
          })
    },
    // 分页 修改页码
    changePageNum(val) {
      this.where.page = val
      this.getGoods()
    },
    // 分页 修改页数
    changePageSize(val) {
      this.where.page = 1
      this.where.limit = val
      this.getGoods()
    },
    getMerIndexInfo() {
      getMerIndexInfoApi(this.merid)
          .then(res => {
            this.merchantInfo = res.data
            this.isCollect = this.merchantInfo.isCollect
            this.$store.commit('MERCHANTJINFO', res.data)
            this.skeletonShow = false
          })
    },
    // 设置是否关注
    followToggle() {
      if (this.merchantInfo.isCollect) {
        getMerCollectCancelApi(this.merid)
            .then(res => {
              this.$nextTick(() => {
                this.isCollect = !this.isCollect
              })
            })
      } else {
        getMerCollectAddApi(this.merid)
            .then(res => {
              this.$nextTick(() => {
                this.isCollect = !this.isCollect
              })
            })
      }
    },
  }
}
</script>

<style scoped lang="scss">
:deep(.ant-menu-light .ant-menu-item-selected) {
  background-color: #3B64DA !important;
  color: #fff !important;
}

.ant-menu-root.ant-menu-inline {
  border-inline-end: none !important;
}

.button-moren-seleced {
  color: #269FFA !important;
}

.button-moren {
  width: 46px;
  height: 30px;
  border-radius: 5px;
  box-sizing: border-box;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 14px;
  font-weight: normal;
  line-height: 28px;

  letter-spacing: 0;
  color: #5D5D5D;
}

.button-moren-1 {
  width: 62px;
  height: 30px;
  border-radius: 5px;
  box-sizing: border-box;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 14px;
  font-weight: normal;
  line-height: 28px;

  letter-spacing: 0;
  color: #5D5D5D;
}


.btn-1 {
  width: 100px;
  height: 32px;
  border-radius: 50px;
  background: #269FFA;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 13px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0em;

  color: #FFFFFF;
}

.btn-2 {
  width: 100px;
  height: 32px;
  border-radius: 50px;
  background: #FFFFFF;
  box-sizing: border-box;
  border: 1px solid #D9D9D9;

  margin-left: 10px;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 13px;
  font-weight: 350;
  line-height: normal;
  letter-spacing: 0em;
  color: #999999;
}

.page-container-all {
  //width: 100%;
  margin-top: 3px;
  min-height: 1600px;

  .filter-container {
    width: 100%;

    .main-top {
      width: 1600px;
      margin: 0 auto;

      .mer-top-card {
        width: 100%;

        .merName {
          font-size: 18px;
          font-weight: 500;
          line-height: normal;
          letter-spacing: 0em;
          color: #3D3D3D;
        }

        .danbao-name {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: flex-start;

          font-size: 14px;
          font-weight: normal;
          letter-spacing: 0em;
          color: #999999;

          .gold-font {
            margin-left: 5px;
            font-weight: 700;
            font-size: 16px;
            color: #FF6F4A;
          }

          .usdt {
            margin-left: 5px;
            font-weight: 400;
            font-size: 12px;
            color: #FF6F4A;
          }
        }
      }
    }
  }
}
</style>
