<template>
  <div>
    <div class="container width_1200">
      <div class="position-relative">
        <a-image :preview="false" :width="1600" :height="200" :src="require('@/assets/new/supply/banner.png')"/>
        <div class="position-absolute flex" style="bottom: 70px; left: 730px;">
          <div class="hover-item-big banner-btn cu-center cursor-pointer" @click="toAdd">
            <PlusOutlined/>
            <span class="ml-2">新增</span>
          </div>
          <div class="ml-3 hover-item-big banner-btn-right cu-center cursor-pointer"
               @click="() => {$router.push({path: '/MySupply'})}">
            我的供需 >
          </div>
        </div>
      </div>

      <div class="mt-8 cu-center">
        <a-space :size="27">
          <div class="center-btn cu-center cursor-pointer hover-item-big"
               :class="activeGxKey === 1 ? 'center-btn-select' : ''"
               @click="selectedKeys(1)">供应
          </div>
          <div class="center-btn cu-center cursor-pointer hover-item-big"
               :class="activeGxKey === 2 ? 'center-btn-select' : ''"
               @click="selectedKeys(2)">需求
          </div>
        </a-space>
      </div>

      <empty v-if="noteRecommendList.length === 0 && !loading"/>
      <div class="mt-8">
        <a-row :gutter="[19,24]">
          <a-col class="list-g-x" v-if="loading" :span="12" v-for="index in 24" :key="index">
            <a-skeleton active :loading="loading" :paragraph="{ rows: 4 }"/>
          </a-col>
          <a-col v-else :span="12" v-for="(item, noteIndex) in noteRecommendList" :key="noteIndex">
            <a-card :bordered="false" :bodyStyle="{padding: 'padding: 29px 0 26px 29px',height:'180px'}"
                    @click="viewCard(item)" :loading="loading" class="cursor-pointer hover-item-up position-relative">
              <div class="position-absolute" style="top: 0;right: 0;z-index: 3;">
                <a-image :preview="false" :src="require(`@/assets/new/home/${item.categoryId === 1 ? 'gong.png' : 'xu.png' }`)" :width="53" :height="66"/>
              </div>
              <div class="position-relative">
                <div class="position-absolute" style="top: 14px;left: 1px;z-index: 3;">
                  <a-image :preview="false" :src="require('@/assets/new/home/yinhao.png')" :width="48" :height="40"/>
                </div>
                <div class="position-relative" style="z-index: 10">
                  <div class="list-title line2">
                      <div class="line1" style="width: 730px;">
                        {{ item.title }}
                      </div>
                  </div>
                  <div class="list-content line2">{{ item.content }}</div>
                  <div class="list-time">发布时间:{{ item.createTime }}</div>
                </div>
              </div>
            </a-card>
          </a-col>
        </a-row>
      </div>
      <!-- 分页 -->
      <div v-if="noteRecommendList && noteRecommendList.length > 0" class="page-size mt-4 mb-3">
        <a-pagination :page-size="limit" :total="total" :show-size-changer="false" show-less-items
                      @change="changePageNum" @page-size-change="changePageSize"/>
      </div>
    </div>
  </div>
</template>

<script>
import { discoverListApi, } from '@/api/discover.js'
import { PlusOutlined } from '@ant-design/icons-vue'
import { Divider } from 'view-ui-plus'

export default {
  components: {
    Divider,
    PlusOutlined,
  },
  data() {
    return {
      current: '1',
      activeGxKey: 1,
      page: 1,
      limit: 24,
      total: null,
      loading: false,
      noteRecommendList: [],
    }
  },
  mounted() {
    this.getDiscoverList()
  },
  methods: {
    toAdd() {
      if (this.$store.getters.uid) {
        this.$router.push({
          path: '/AddSupply',
          query: {}
        })
      } else {
        this.$router.push({
          path: '/Login',
          query: {}
        })
      }
    },
    viewCard(item) {
      this.$router.push({
        path: '/SupplyInfo',
        query: {
          id: item.id,
        }
      })
    },
    //发现列表
    getDiscoverList() {
      this.where = {
        page: this.page,
        limit: this.limit,
        categoryId: this.activeGxKey,
      }
      this.loading = true
      discoverListApi(this.where)
          .then(res => {
            this.noteRecommendList = res.data.list
            this.total = res.data.total
            this.loading = false
          })
    },
    changePageNum(val) { // 修改页码
      this.page = val
      this.getDiscoverList()
    },
    changePageSize(val) { // 修改页数
      this.page = 1
      this.limit = val
      this.getDiscoverList()
    },
    selectedKeys(e) {
      this.page = 1
      this.activeGxKey = e
      this.getDiscoverList()
    }
  }
}
</script>

<style scoped lang="scss">
.center-btn {
  width: 136px;
  height: 42px;
  border-radius: 21px;
  border: 1px solid #DBDEE6;

  font-weight: bold;
  font-size: 20px;
  color: #000000;
  line-height: 20px;
}

.center-btn-select {
  color: #FFFFFF;
  background: linear-gradient(-90deg, #428FEE, #305DDC);
}

.center-btn:hover {
  color: #FFFFFF;
  background: linear-gradient(-90deg, #428FEE, #305DDC);
}

.banner-btn {
  width: 143px;
  height: 50px;
  background: #4271E2;
  border-radius: 25px;

  font-weight: 400;
  font-size: 20px;
  color: #FFFFFF;
  line-height: 20px;
}

.banner-btn-right {
  width: 187px;
  height: 50px;
  background: #4271E2;
  border-radius: 25px;

  font-weight: 400;
  font-size: 20px;
  color: #FFFFFF;
  line-height: 20px;
}

.title-tg {
  padding-left: 10px;
  padding-right: 10px;
  height: 22px;
  font-size: 14px;
  border-radius: 100px;
  background: #FF6600;
  text-align: center;
  color: #fff;
  margin-right: 10px;
}

.item-item-btns {
  width: 84px;
  height: 26px;
  border-radius: 50px;
  opacity: 1;
  background: #2b85e4;

  font-size: 12px;
  font-weight: normal;
  line-height: 25px;
  letter-spacing: 0px;
  color: #FFFFFF;
}

.item-item-btn {
  width: 84px;
  height: 26px;
  border-radius: 50px;
  opacity: 1;
  background: rgba(68, 68, 68, 0.6);

  font-size: 12px;
  font-weight: normal;
  line-height: 25px;
  letter-spacing: 0px;
  color: #FFFFFF;
}

.list-title {
  margin-top: 6px;
  font-size: 18px;
  font-weight: 500;
  line-height: normal;
}

.list-time {
  margin-top: 16px;
  font-weight: 400;
  font-size: 14px;
  color: #6B6C6D;
  line-height: 28px;
}

.list-content {
  margin-top: 13px;
  font-weight: 400;
  font-size: 14px;
  color: #999999;
  line-height: 24px;
}

.page-size {
  text-align: right;
}

.container {
  margin: 0 auto;
  border-radius: 12px;

  .item-font {
    font-size: 16px;
    font-weight: bold;
    line-height: normal;
  }
}

.ant-menu-light {
  background: inherit !important;
}

</style>
