<template>
  <div class="page-container-all">
    <div class="cu-center-between padding-lr-cus width_1200_auto">
      <div>
        <div class="us-font-1">出海交易<span class="us-font-2">新模式，</span></div>
        <div class="us-font-1">电商与担保服务的完美</div>
        <div class="mt-6">
          <a-image :preview="false" :width="49" :height="7" :src="require('@/assets/new/us/blue-line.png')"/>
        </div>
        <div class="mt-11" style="width: 707px;">
          51担保网是一家创新型平台，将Telegram担保服务与电商交易模式无缝融合，
          专门服务于出海企业。平台汇集了行业内的各大平台、实力商家及行业资源，致力于为出海供需双方提供高效、
          便捷、安全的在线交易解决方案。我们打破了传统Telegram担保交易的限制，优化了交易担保流程，为买方和卖方提供完善的担保服务，确保商家和买家的权益。
        </div>
      </div>
      <div>
        <a-image :preview="false" :width="585" :height="512" :src="require('@/assets/new/us/all.png')"/>
      </div>
    </div>
    <div class="mt-5">
      <vue3-video-play v-bind="playerOptions" :src="playerOptions.src"/>
    </div>
    <div class="cu-center-col">
      <div class="us-font-3">
        我们的故事
      </div>
      <div class="us-font-4">
        从信任到全球化：51担保网的创新之旅
      </div>
      <div class="wave-line"></div>
      <div class="flex mt-6" style="padding-left: 170px;padding-right: 231px">
        <div>
          <a-image style="border-radius: 20px !important;" :preview="false" :width="434" :height="534" :src="require('@/assets/new/us/story.jpg')"/>
        </div>
        <div class="us-font-5 cu-center-first-col">
          <div class="mt-12"></div>
          51担保网诞生于全球化交易需求日益增长的时代背景下。<br>
          <div class="mt-6"></div>
          在跨境电商和在线交易蓬勃发展的同时，信任问题成为商家与买家之间的最大障碍。传统的Telegram担保服务虽然方便，但存在流程复杂、不透明和安全性不足的痛点。而商家开拓海外市场时，往往面临资源分散、推广受限、交易纠纷频发等难题。<br>
          <div class="mt-6"></div>
          我们深刻洞察到这些痛点，结合电商交易的便捷性与Telegram担保服务的信任机制，创造了一个全新的综合平台——51担保网。我们的目标是打破传统交易的边界，为出海企业和全球买家提供高效、安全的交易体验，让信任成为每一笔交易的基石。<br>
          <div class="mt-6"></div>
          平台汇聚了行业内的顶级资源，包括实力商家、大型交易平台和行业专家，为用户提供全面的支持。从买家寻找优质商品，到商家拓展全球市场，我们优化了交易担保流程，确保每一方都能安心、无忧地进行交易。<br>
          <div class="mt-6"></div>
          51担保网不仅是一个平台，更是商家和买家之间的桥梁。我们通过不断优化平台功能，提供丰富的推广模式、灵活的分销渠道和完善的技术支持，帮助每一位用户在交易中实现共赢。<br>
          <div class="mt-6"></div>
          我们相信，随着技术的进步和全球市场的扩大，51担保网将成为出海企业的首选合作伙伴，推动跨境交易迈向更高效、更透明的未来。<br>
        </div>
      </div>
    </div>

    <div class="cu-center-col">
      <div class="us-font-3">
        我们的故事
      </div>
      <div class="us-font-4">
        从信任到全球化：51担保网的创新之旅
      </div>
      <div class="wave-line"></div>
      <div class="width_1200_auto mt-10">
        <div class="flex-container">
          <div class="flex-item" v-for="(item, index) in linkCustomer()" :key="index">
            <a-card :bordered="false" :body-style="{padding: '0', height: '290px'}"
                    class="cursor-pointer hover-item-up" @click="toTelegram(item)">
              <div class="cu-center-col mt-8">
                <div class="position-relative">
                  <a-avatar :size="110" style="border: 1px solid #e9e9e9;" :src="item.headImg"/>
                  <div class="position-absolute" style="bottom: 0;right: 0;">
                    <a-image :preview="false" :width="32" :height="32" :src="require('@/assets/new/us/dianbao.png')"/>
                  </div>
                </div>
                <div class="golbal-btn cursor-pointer mt-7 text-bold">{{ item.name }}</div>
                <div class="mt-6">
                  <div class="golbal-btn cursor-pointer">{{ item.account }}</div>
                  <div class="golbal-btn cursor-pointer">{{ item.telPhone }}</div>
                </div>
              </div>
            </a-card>
          </div>
        </div>
      </div>
    </div>

    <div class="width_1200_auto mt-6 mb-6 cursor-pointer">
      <a-image :preview="false" :width="1600" :height="90" :src="require('@/assets/new/us/joinus.png')"
               @click="toEnter"/>
    </div>

  </div>
</template>
<script>
import { Divider } from 'view-ui-plus'
// 完毕数据
import { joinMerInfo, linkCustomerOne } from './data/index'

export default {
  components: { Divider },
  data() {
    return {
      playerOptions: {
        width: '1920px', //播放器高度
        height: '660px', //播放器高度
        color: '#409eff', //主题色
        muted: false, //静音
        webFullScreen: false,
        speedRate: ['0.75', '1.0', '1.25', '1.5', '2.0'], //播放倍速
        autoPlay: false, //自动播放
        loop: false, //循环播放
        mirror: false, //镜像画面
        ligthOff: false,  //关灯模式
        volume: 0.3, //默认音量大小
        control: false, //是否显示控制器
        title: '51担保网', //视频名称
        src: 'https://51danbao.oss-ap-southeast-1.aliyuncs.com/crmebimage/public/user/old.mp4', //视频源
        poster: 'https://51danbao.oss-ap-southeast-1.aliyuncs.com/crmebimage/public/user/old_0001.jpg', //封面
      },
    }
  },
  watch: {},
  created() {
  },
  methods: {
    toTelegram(item) {
      window.open(item.telQrCode, '_blank')
    },
    linkCustomer() {
      return linkCustomerOne
    },
    joinMerInfo() {
      return joinMerInfo
    },
    toEnter() {
      this.$router.push({ path: '/Enter' })
    }
  }
}
</script>

<style scoped lang="scss">
.flex-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.flex-item {
  width: 18.5%;
  padding: 10px;
  box-sizing: border-box;
  text-align: center;
  margin-bottom: 20rpx;
}

.us-font-5 {
  width: 1042px;
  height: 464px;
  font-weight: 400;
  font-size: 16px;
  color: #000000;
  line-height: 32px;
  padding-left: 43px;
}

.wave-line {
  width: 103px;
  height: 10px;
  background: repeating-linear-gradient(
          90deg,
          transparent,
          transparent 6px,
          #5f9eea 6px,
          #5f9eea 12px
  );
  background-size: 12px 100%; /* 波浪大小 */
  position: relative;
  transform: rotate(180deg);
}

.us-font-3 {
  margin-top: 83px;
  font-weight: bold;
  font-size: 38px;
  color: #000000;
  line-height: 54px;
}

.us-font-4 {
  margin-top: 14px;
  font-weight: 400;
  font-size: 16px;
  color: #6B6C6D;
  line-height: 54px;
}

.page-container-all {
  margin-top: 40px;
  margin-bottom: 40px;
  min-height: 1200px;
}

.padding-lr-cus {
  padding-left: 100px;
  padding-right: 100px;
}

.us-font-1 {
  font-weight: bold;
  font-size: 38px;
  color: #000000;
  line-height: 56px;

  .us-font-2 {
    color: #3B64DA;
  }

}
</style>
