<template>
  <div class="register-home" id="app">
    <div class="sign-up">
      <!-- 顶部logo -->
      <div class="top-content">
        <div class="logo-box">
          <div class="left">
            <img src="../assets/new/register/logo.png" class="logo-img cursor-pointer" alt="" @click="toHome"/>
          </div>
          <div class="to-login cursor-pointer golbal-btn" @click="toHome">
            <a-image :preview="false" width="20px" height="20px" :src="require('@/assets/new/register/home.png')"/>
            <span class="text-black golbal-btn cursor-pointer ml-1">首页</span>
          </div>
        </div>
      </div>

      <div class="login-center">
        <div class="login-container">
          <div class="cu-center">
            <a-image
                style="margin-left: 86px;"
                :width="462"
                :height="388"
                :preview="false"
                :src="require('@/assets/new/login/left.png')"
                :fallback="require('@/assets/index/logo.png')"
            />
          </div>
          <div class="container-right">
            <div class="flex justify-center align-center mb-8">
              <a-space>
                <div class="golbal-btn text-xl cursor-pointer email-phone-cus"
                     :class="activeKey === '1' ? 'email-phone-select' :''"
                     @click="changeActive('1')">
                  <div class="flex align-center justify-center flex-direction">
                    <div>邮箱找回</div>
                    <div class="border-cus" :class="activeKey === '1' ? 'border-cus-selected' : ''"></div>
                  </div>
                </div>
                <div style="border-right: 2px solid #E7E7E7;width: 3px;height: 15px;" class="ml-5 mr-5"></div>
                <div class="golbal-btn text-xl cursor-pointer email-phone-cus"
                     :class="activeKey === '2' ? 'email-phone-select' :''" @click="changeActive('2')">
                  <div class="flex align-center justify-center flex-direction">
                    <div>手机找回</div>
                    <div class="border-cus" :class="activeKey === '2' ? 'border-cus-selected' : ''"></div>
                  </div>
                </div>
              </a-space>
            </div>
            <div v-if="activeKey === '1'">
              <div style="padding: 10px 0 0 0" v-if="step === 1">
                <a-form :model="formRegist" @finish="nextEmail">
                  <a-form-item name="email"
                               :hasFeedback="showClearIconEmail"
                               :validate-status="emailHelp ? 'error' :'success'"
                               :help="emailHelp"
                               :rules="[{required: true,message: this.$t('signUp.s_00045') }, {type:'email',message: this.$t('pwd.pwd_025'),trigger: 'blur'}]">
                    <!--  自动完成 -->
                    <a-auto-complete v-model:value="formRegist.email"
                                     :options="options"
                                     @search="handleSearch"
                                     @change="onKeyInputEmail">
                      <template #default>
                        <a-input class="input-email"
                                 @input="$InputEvent.OnlyNumericLetters($event)"
                                 :placeholder="$t('pwd.pwd_007')"
                                 :allow-clear="!showClearIconEmail">
                          <template #prefix>
                            <div class="cu-center">
                              <a-image class="mb-2" :preview="false" :src="require('@/assets/new/register/email.png')"
                                       :width="24" :height="20"></a-image>
                              <a-divider type="vertical"/>
                            </div>
                          </template>
                        </a-input>
                      </template>
                    </a-auto-complete>
                    <!--  自动完成 -->
                  </a-form-item>
                  <a-form-item>
                    <div v-if="submitErr" class="text-red mt-3">
                      {{ submitErr }}
                    </div>
                    <a-spin :spinning="sendCodeLoading">
                      <a-button class="bottom-pane" style="width: 100%" type="primary" html-type="submit"
                                :disabled="disabled">
                        {{ $t('pwd.pwd_019') }}
                      </a-button>
                    </a-spin>
                  </a-form-item>
                </a-form>

                <div class="flex justify-end align-center">
                  {{ $t('signUp.s_00008') }}
                  <div class="flex justify-center align-center target-click-hover">
                    <a class="text-blue golbal-btn-bold text-lg" @click="toLogin">
                      {{ $t('signUp.s_00010') }}
                    </a>
                    <RightOutlined class="to-login-btn-cus-2" @click="toLogin"/>
                  </div>
                </div>
              </div>
              <div style="padding: 10px 0 0 0;" v-if="step === 2">
                <div class="yanz-font-1">{{ $t('pwd.pwd_007') }}</div>
                <div class="yanz-font-2">
                  {{ $t('pwd.pwd_008') }}:{{ formRegist.email }}, {{ $t('pwd.pwd_009') }}
                </div>
                <div class="yanz-input">
                  <code-l-r-input
                      @change="completedInput"
                      :masked="false"
                      :fields="6"
                      :fieldWidth="80"
                      :fieldHeight="80"
                      :required="true"
                  />
                </div>
                <div class="send-btn">
                  <a-button type="link" :disabled="disabled" :class="disabled ? 'text-gray' : 'text-blue'"
                            size="small" @click="nextEmail">{{ text }}
                  </a-button>
                </div>
                <div>
                  <div v-if="submitErr" class="text-red mb-2">
                    {{ submitErr }}
                  </div>
                  <a-button class="bottom-pane mt_10" style="width: 100%" type="primary" html-type="submit"
                            @click="emailCheckCode">
                    {{ $t('pwd.pwd_010') }}
                  </a-button>
                </div>
              </div>
              <div style="padding: 2px 0 0 0;" v-if="step === 3">
                <div class="yanz-font-1"> {{ $t('pwd.pwd_011') }}</div>
                <div class="yanz-font-2 mb_10">
                  {{ $t('pwd.pwd_012') }}
                </div>
                <a-form
                    :model="formRegist"
                    name="basic"
                    :rules="passwordRules"
                    autocomplete="off">
                  <a-form-item name="email">
                    <a-input-password class="input-email" :placeholder="$t('pwd.pwd_013')"
                                      @input="$InputEvent.OnlyNumericLetters($event)"
                                      v-no-chinese
                                      @change="changePwdError1"
                                      v-model:value="formRegist.password">
                      <template #prefix>
                        <div class="flex justify-center align-center">
                          <a-image :preview="false" :src="require('@/assets/new/register/pwd.png')" :width="21"
                                   :height="24"></a-image>
                          <a-divider type="vertical"></a-divider>
                        </div>
                      </template>
                    </a-input-password>
                    <div class="text-red mb-2">{{ pwdError1 }}</div>
                  </a-form-item>
                  <a-form-item name="captcha">
                    <a-input-password class="input-email" :placeholder="$t('pwd.pwd_014')"
                                      @input="$InputEvent.OnlyNumericLetters($event)"
                                      v-no-chinese
                                      @change="changePwdError2"
                                      v-model:value="formRegist.password1">
                      <template #prefix>
                        <div class="flex justify-center align-center">
                          <a-image :preview="false" :src="require('@/assets/new/register/pwd.png')" :width="21"
                                   :height="24"></a-image>
                          <a-divider type="vertical"></a-divider>
                        </div>
                      </template>
                    </a-input-password>
                    <div class="text-red mb-2">{{ pwdError2 }}</div>
                  </a-form-item>
                  <a-form-item :label="$t('pwd.pwd_015')">
                    <password :password="formRegist.password" @change="pwdChange"/>
                  </a-form-item>
                  <a-form-item>
                    <div v-if="submitErr" class="text-red mb-2">
                      {{ submitErr }}
                    </div>
                    <a-button class="bottom-pane" style="width: 100%" type="primary" html-type="submit"
                              @click="emailRegister" :disabled="pwdError1 !== '' || pwdError2 !== ''">
                      {{ $t('pwd.pwd_010') }}
                    </a-button>
                  </a-form-item>
                </a-form>
              </div>
            </div>
            <div v-if="activeKey === '2'">
              <div style="padding: 10px 0 0 0" v-if="phonstep === 1">
                <a-form
                    layout="vertical"
                    :model="formRegist"
                    name="basic"
                    :rules="phoneRules"
                    autocomplete="off">
                  <a-form-item name="phone"
                               :hasFeedback="showClearIconPhone"
                               :validate-status="phoneHelp ? 'error' :'success'"
                               :help="phoneHelp">
                    <a-input class="input-phone" :placeholder="$t('pwd.pwd_017')" v-model:value="formRegist.phone"
                             @input="$InputEvent.OnlyNumericLetters($event)"
                             v-in-number
                             @change="onKeyInputPhone"
                             :allow-clear="!showClearIconPhone">
                      <template #prefix>
                        <Vue3CountryIntl
                            schema="popover"
                            :selectedText="$t('pwd.pwd_018')"
                            :useChinese="true"
                            :searchInputPlaceholder="$t('common.com_002')"
                            v-model="formRegist.countryCode"
                            v-model:visible="countryView"
                            @change="changeCountry">
                          <Vue3CountryFlag :value="formRegist.iso2"
                                           class="cursor-pointer"
                                           @click="countryView = !countryView">
                            <template v-slot="{country}">
                              <span style="margin-left: 3px;">{{ '+' + country.dialCode }}</span>
                              <Icon type="md-arrow-dropdown"/>
                            </template>
                          </Vue3CountryFlag>
                        </Vue3CountryIntl>
                        <a-divider type="vertical" style="margin-left: -6px;"/>
                      </template>
                    </a-input>
                  </a-form-item>
                  <a-form-item>
                    <div v-if="submitErr" class="text-red mt-3">
                      {{ submitErr }}
                    </div>
                    <a-button class="bottom-pane" style="width: 100%" type="primary" @click="codePhone"
                              :disabled="disabled">
                      {{ $t('pwd.pwd_019') }}
                    </a-button>
                  </a-form-item>
                </a-form>

                <div class="flex justify-end align-center">
                  {{ $t('signUp.s_00008') }}
                  <div class="flex justify-center align-center target-click-hover">
                    <a class="text-blue golbal-btn-bold text-lg" @click="toLogin">
                      {{ $t('signUp.s_00010') }}
                    </a>
                    <RightOutlined class="to-login-btn-cus-2" @click="toLogin"/>
                  </div>
                </div>
              </div>
              <div style="padding: 10px 0 0 0;" v-if="phonstep === 2">
                <div class="yanz-font-1">{{ $t('pwd.pwd_022') }}</div>
                <div class="yanz-font-2">
                  {{ $t('pwd.pwd_008') }}: + {{ formRegist.countryCode }} {{ formRegist.phone }},
                  {{ $t('pwd.pwd_024') }}
                </div>
                <div class="yanz-input">
                  <code-l-r-input
                      @change="completedInput"
                      :masked="false"
                      :fields="6"
                      :fieldWidth="80"
                      :fieldHeight="80"
                      :required="true"
                  />
                </div>
                <div class="send-btn">
                  <a-button type="link" :disabled="disabled" :class="disabled ? 'text-gray' : 'text-blue'"
                            size="small" @click="codePhone">{{ text }}
                  </a-button>
                </div>
                <div>
                  <div v-if="submitErr" class="text-red mb-2">
                    {{ submitErr }}
                  </div>
                  <a-button class="bottom-pane mt_10" style="width: 100%" type="primary" html-type="submit"
                            @click="phoneCheckCode">
                    确认
                  </a-button>
                </div>
              </div>
              <div style="padding: 2px 0 0 0;" v-if="phonstep === 3">
                <div class="yanz-font-1">{{ $t('pwd.pwd_011') }}</div>
                <div class="yanz-font-2 mb_10">
                  {{ $t('pwd.pwd_012') }}
                </div>
                <a-form
                    :model="formRegist"
                    name="basic"
                    :rules="passwordRules"
                    autocomplete="off">
                  <a-form-item name="email">
                    <a-input-password class="input-email" :placeholder="$t('pwd.pwd_013')"
                                      @input="$InputEvent.OnlyNumericLetters($event)"
                                      v-no-chinese
                                      @change="changePwdError1"
                                      v-model:value="formRegist.password">
                      <template #prefix>
                        <div class="flex justify-center align-center">
                          <a-image :preview="false" :src="require('@/assets/new/register/pwd.png')" :width="21"
                                   :height="24"></a-image>
                          <a-divider type="vertical"></a-divider>
                        </div>
                      </template>
                    </a-input-password>
                    <div class="text-red mb-2">{{ pwdError1 }}</div>
                  </a-form-item>
                  <a-form-item name="captcha">
                    <a-input-password class="input-email" :placeholder="$t('pwd.pwd_014')"
                                      @input="$InputEvent.OnlyNumericLetters($event)"
                                      v-no-chinese
                                      @change="changePwdError2"
                                      v-model:value="formRegist.password1">
                      <template #prefix>
                        <div class="flex justify-center align-center">
                          <a-image :preview="false" :src="require('@/assets/new/register/pwd.png')" :width="21"
                                   :height="24"></a-image>
                          <a-divider type="vertical"></a-divider>
                        </div>
                      </template>
                    </a-input-password>
                    <div class="text-red mb-2">{{ pwdError2 }}</div>
                  </a-form-item>
                  <a-form-item :label="$t('pwd.pwd_015')">
                    <password :password="formRegist.password" @change="pwdChange"/>
                  </a-form-item>
                  <a-form-item>
                    <div v-if="submitErr" class="text-red mb-2">
                      {{ submitErr }}
                    </div>
                    <a-button class="bottom-pane" style="width: 100%" type="primary" html-type="submit"
                              @click="phoneRegister" :disabled="pwdError1 !== '' || pwdError2 !== ''">
                      确认
                    </a-button>
                  </a-form-item>
                </a-form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 底部栏 -->
    <FooterSign></FooterSign>
  </div>
</template>

<script>
import sendVerifyCode from '@/utils/SendVerifyCode'
import password from '@/components/pwd.vue'
import {
  checkEmail,
  checkPhone,
  emailForgetCode,
  emailResetPassword,
  phoneForgetCode,
  phoneResetPassword,
  resetPasswordCheck
} from '@/api/user'

import { Debounce } from '@/utils/validate'
import FooterSign from '@/components/footer/Footer.vue'
import { RightOutlined } from '@ant-design/icons-vue'
import CodeLRInput from '@/components/codeLRInput/codeInput.vue'

export default {
  name: 'SignUp',
  components: {
    CodeLRInput,
    RightOutlined,
    FooterSign,
    password,
  },
  mixins: [sendVerifyCode],
  data() {
    return {
      previewVisible: false,
      config: require('@/config'),
      year: new Date().getFullYear(),
      activeKey: '1',
      formRegist: {
        email: '',
        invitationCode: '',
        captcha: '',
        phone: '',
        password: '',
        password1: '',
        countryCode: '1',
        iso2: 'us',
      },
      flag: 1,
      color: '',
      countryView: false,
      emailRules: {
        email: [
          {
            required: true,
            message: this.$t('signUp.s_00045')
          },
          {
            pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
            message: this.$t('pwd.pwd_025'),
            trigger: 'blur'
          }
        ]
      },
      phoneRules: {
        phone: [
          {
            required: true,
            message: this.$t('pwd.pwd_026')
          },
        ]
      },
      passwordRules: {
        password: [
          {
            required: true,
            message: this.$t('pwd.pwd_013')
          },
        ],
        password1: [
          {
            required: true,
            message: this.$t('pwd.pwd_014')
          },
        ],
      },
      showClearCheckEmail: false,
      showClearIconEmail: false,
      showClearIconInvite: false,
      showClearIconPhone: false,
      step: 1,
      phonstep: 1,
      pwdLevel: 0,
      sendCodeLoading: false,
      emailHelp: '',
      phoneHelp: '',
      submitErr: '',
      pwdError1: '',
      pwdError2: '',
      options: [],
      emailSuffixOptions: [
        'gmail.com',
        'yahoo.com',
        'hotmail.com',
        'outlook.com',
        'icloud.com',
      ],
    }
  },
  mounted() {
    if (window.innerHeight > 1050) {
      document.querySelector('.sign-up').style.height = (window.innerHeight - 330) + 'px'
    } else {
      document.querySelector('.sign-up').style.height = '750px'
    }
    this.setScale()
    window.addEventListener('resize', this.setScale)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setScale)
  },
  methods: {
    setScale() {
      const designWidth = 1920 // 设计稿宽度
      const windowWidth = window.innerWidth // 当前窗口宽度
      // 计算缩放比例（基于宽度）
      const scale = windowWidth / designWidth
      const app = document.getElementById('app')
      app.style.transform = `scale(${scale})`

      const body = document.body
      // 在窗口大小变化时，滚动到顶部
      setTimeout(() => {
        body.scrollTo({
          top: 0,
          behavior: 'smooth' // 平滑滚动
        })
      }, 100)
    },
    toHome() {
      this.$router.push({
        path: '/',
      })
    },
    changePwdError1(e) {
      let message = e.target.value
      this.pwdError2 = ''
      console.log(message)
      if (message !== this.formRegist.password1 && this.formRegist.password1) {
        this.pwdError2 = '两次输入的密码不一致，请重新输入。'
      }
    },
    changePwdError2(e) {
      let message = e.target.value
      this.pwdError2 = ''
      if (message !== this.formRegist.password) {
        this.pwdError2 = '两次输入的密码不一致，请重新输入。'
      }
    },
    handleSearch(val) {
      let res = []
      if (!val || val.indexOf('@') >= 0) {
        res = []
      } else {
        res = this.emailSuffixOptions.map(domain => ({ value: `${val}@${domain}` }))
      }
      this.options = res
    },
    showCusService() {
      let routeUrl = this.$router.resolve({
        path: '/ChatIm',
        query: { //要传的参数 可传多个
          dbMerId: 0
        }
      })
      window.open(routeUrl.href, '_blank')
    },
    changeActive(event) {
      this.activeKey = event
      this.pwdError1 = ''
      this.pwdError2 = ''
    },
    completedInput(e) {
      this.formRegist.captcha = e
    },
    phoneRegister: Debounce(function () {
      let that = this
      if (!that.formRegist.phone) return this.submitErr = this.$t('signUp.s_00030')
      if (!that.formRegist.captcha) return this.submitErr = this.$t('signUp.s_00031')
      if (!/^[\w\d]+$/i.test(that.formRegist.captcha)) this.submitErr = this.$t('signUp.s_00032')
      if (!that.formRegist.password) return this.submitErr = this.$t('signUp.s_00033')
      // 密码复杂度校验
      // 密码必须包括字母和数字，长度6到18位。
      if (!/^(?=.*[A-Za-z])(?=.*\d).{8,}$/i.test(that.formRegist.password)) return this.pwdError1 = this.$t('signUp.s_00034')
      if (!that.formRegist.password1) return this.pwdError2 = this.$t('signUp.s_00035')
      if (that.formRegist.password !== that.formRegist.password1) return this.pwdError2 = this.$t('signUp.s_00036')
      phoneResetPassword({
        'phone': that.formRegist.phone,
        'countryCode': that.formRegist.countryCode,
        'captcha': that.formRegist.captcha,
        'newPassword': that.formRegist.password,
        'passwordAgain': that.formRegist.password1
      })
          .then(res => {
            if (res.code === 200) {
              that.$router.push({ path: '/login' })
            } else {
              this.submitErr = res.message
            }
          })
    }),
    phoneCheckCode() {
      let that = this
      if (!that.formRegist.captcha) {
        this.submitErr = this.$t('signUp.s_00031')
      }
      this.checkCaptcha(1)
    },
    codePhone: Debounce(function () {
      if (!this.formRegist.phone) {
        return
      }
      if (!this.showClearIconPhone) {
        return
      }
      if (this.formRegist.invitationCode && !this.showClearIconInvite) {
        return
      }
      if (this.disabled) return
      phoneForgetCode(this.formRegist.phone)
          .then(res => {
            if (res.code === 200) {
              this.sendCode()
              this.phonstep = 2
              this.submitErr = ''
            } else {
              this.submitErr = res.message
              if (res.message.indexOf('5分钟') > -1) {
                this.sendCode()
                this.phonstep = 2
              }
            }
          })
          .catch(err => {
            this.submitErr = res.message
          })
    }),
    // 手机键盘输入实时反馈
    onKeyInputPhone: function (event) {
      if (!this.formRegist.phone) {
        return
      }
      // 检测手机是否已注册
      let data = {
        phone: this.formRegist.phone,
        countryCode: this.formRegist.countryCode
      }
      checkPhone(data)
          .then(res => {
            if (res.code === 200) {
              this.showClearIconPhone = true
              this.phoneHelp = ''
            } else {
              this.showClearIconPhone = false
              this.phoneHelp = '手机不存在'
            }
          })
          .catch(res => {
            this.showClearIconPhone = false
            this.phoneHelp = '手机不存在'
          })
    },
    emailRegister: Debounce(function () {
      let that = this
      if (!that.formRegist.email) return that.submitErr = this.$t('signUp.s_00041')
      if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(that.formRegist.email)) return that.submitErr = this.$t('signUp.s_00042')
      if (!that.formRegist.captcha) return that.submitErr = this.$t('signUp.s_00031')
      if (!that.formRegist.password) that.submitErr = this.$t('signUp.s_00033')
      if (!/^(?=.*[A-Za-z])(?=.*\d).{8,}$/i.test(that.formRegist.password)) return that.pwdError1 = this.$t('signUp.s_00034')
      if (!that.formRegist.password1) return that.pwdError2 = this.$t('signUp.s_00043')
      if (that.formRegist.password !== that.formRegist.password1) return that.pwdError2 = this.$t('signUp.s_00036')
      emailResetPassword({
        'captcha': that.formRegist.captcha,
        'email': that.formRegist.email,
        'newPassword': that.formRegist.password,
        'passwordAgain': that.formRegist.password1
      })
          .then(res => {
            if (res.code === 200) {
              that.$router.push({ path: '/login' })
              that.submitErr = ''
            } else {
              that.submitErr = res.message
            }
          })
          .catch(err => {
          })
    }),
    pwdChange(event) {
      this.pwdLevel = event
      this.pwdError1 = ''
      if (this.formRegist.password && this.pwdLevel <= 1) {
        this.pwdError1 = '密码必须包含至少1个字母和至少1个数字，且长度不低于8位。'
      }
    },
    changeCountry(event) {
      this.formRegist.iso2 = event.iso2
      this.formRegist.countryCode = event.dialCode
      this.onKeyInputPhone()
    },
    checkCaptcha(val) {
      let data = {
        checkType: val,
        countryCode: this.formRegist.countryCode,
        account: this.activeKey === '1' ? this.formRegist.email : this.formRegist.phone,
        captcha: this.formRegist.captcha,
      }
      resetPasswordCheck(data)
          .then(res => {
            if (res.code === 200) {
              val === 0 ? this.step = 3 : this.phonstep = 3
              this.submitErr = ''
            } else {
              this.submitErr = res.message
            }
          })
          .catch(err => {
          })
    },
    emailCheckCode() {
      let that = this
      if (!that.formRegist.captcha) {
        return that.submitErr = this.$t('signUp.s_00044')
      }
      this.checkCaptcha(0)
    },
    nextEmail() {
      if (!this.formRegist.email) {
        this.emailHelp = this.$t('signUp.s_00045')
        return
      }
      if (!this.showClearCheckEmail) {
        return
      }
      if (!this.showClearIconEmail) {
        return
      }
      if (this.formRegist.invitationCode && !this.showClearIconInvite) {
        return
      }
      this.sendCodeLoading = true
      emailForgetCode(this.formRegist.email)
          .then(res => {
            this.sendCodeLoading = false
            if (res.code === 200) {
              this.step = 2
              this.sendCode()
              this.emailHelp = ''
            } else {
              this.emailHelp = res.message
            }
          })
          .catch(err => {
            this.emailHelp = res.message
          })
    },
    // 邮箱键盘输入实时反馈
    onKeyInputEmail() {
      let that = this
      that.submitErr = ''
      if (!that.formRegist.email) {
        this.emailHelp = '请输入邮箱'
        return
      }
      // 校验邮箱格式
      if (!/^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/i.test(that.formRegist.email)) {
        this.showClearCheckEmail = false
        this.emailHelp = '请输入正确邮箱'
        return
      }
      this.showClearCheckEmail = true
      // 检测邮箱是否已注册
      checkEmail(that.formRegist.email)
          .then(res => {
            if (!res.data) {
              this.showClearIconEmail = true
              this.emailHelp = ''
            } else {
              this.showClearIconEmail = false
              this.emailHelp = '输入邮箱不存在'
            }
          })
          .catch(res => {
            this.showClearIconEmail = false
            this.emailHelp = '输入邮箱不存在'
          })
    },
    toLogin() {
      this.$router.push({
        path: '/login',
      })
    },
  },
}
</script>
<style scoped lang="scss">
.sign-up {
  margin-bottom: 210px;
}

.border-cus {
  width: 30px;
  height: 10px;
}

.border-cus-selected {
  border-bottom: 2px solid #3B64DA;
}

.to-login-btn-cus-2 {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid #0081ff;
  cursor: pointer;
  font-size: 10px;
  color: #0081ff;

  display: flex;
  justify-content: center;
  align-items: center;
}

.email-phone-cus {
  height: 36px;
  font-size: 20px;
  line-height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.email-phone-select {
  font-weight: bold;
  color: #3B64DA;
}

.email-phone-cus:hover {
  font-weight: bold;
  color: #3B64DA;
}

.register-home {
  height: 100vh; /* 使容器的高度为视口高度 */
  background-image: url("@/assets/new/register/homePage.png");
  background-size: cover; /* 背景图铺满整个容器 */
  background-position: center; /* 背景图居中 */
  background-repeat: no-repeat; /* 防止背景图重复 */
  background-attachment: fixed; /* 背景图固定（滚动时背景图不动） */
}

.item-sert {
  border-radius: 10rpx;
  background: #fff;
  margin-bottom: 10rpx;

  // 安全等级
  .securitylevel {
    width: 100%;
    height: 80rpx;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24rpx;

    .text1 {
      width: 150rpx;
      height: 30rpx;
      // font-weight: 600;
    }

    .text2 {
      display: inline-block;
      width: 50rpx;
      height: 30rpx;
      margin-left: 10rpx;
    }

    .item2 {
      display: inline-block;
      width: calc(480rpx / 3);
      height: 30rpx;
      background-color: #ddd;
      margin-left: 10rpx;
    }
  }
}

.send-btn {
  display: flex;
  justify-content: flex-end;
}

:deep(.ant-input-affix-wrapper .ant-input-clear-icon) {
  color: red !important;
}

.yanz-font-1 {
  font-size: 18px;
  font-weight: normal;
  line-height: 28px;
  display: flex;
  align-items: center;
  letter-spacing: 0px;
  color: #3D3D3D;
}

.yanz-font-2 {
  margin-top: 10px;
  font-size: 14px;
  font-weight: normal;
  line-height: normal;
  display: flex;
  align-items: center;
  letter-spacing: 0px;
  color: #999999;
}

.yanz-input {
  margin-top: 10px;
}

:deep(.vue3-yn-country-flag .country-flag-img) {
  width: 30px;
}

.input-email {
  width: 100%;
  height: 60px;
  border-radius: 12px;

  border: 1px solid rgba(102, 102, 102, 0.35);
}

.input-captcha {
  width: 100%;
  height: 60px;
  border-radius: 12px;

  border: 1px solid rgba(102, 102, 102, 0.35);
}

.bottom-pane {
  width: 100%;
  height: 60px;
  font-size: 24px;
  opacity: 1;
  background: linear-gradient(-90deg, #0080D9, #0052D9);
  box-shadow: 0px 5px 16px 0px rgba(71, 100, 184, 0.3);
  border-radius: 10px;
}

.input-phone {
  width: 100%;
  height: 60px;
  border-radius: 12px;

  border: 1px solid rgba(102, 102, 102, 0.35);

}


.login-center {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 50px;

  .diccc {
    height: 473px;
    margin-left: 142px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: center;

    .customer {
      position: relative;
      width: 136px;
      height: 204px;
      border-radius: 10px;
      opacity: 1;
      box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.1);

      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: center;

      .font-top {
        padding: 10px 0;
        font-family: Source Han Sans;
        font-size: 20px;
        font-weight: bold;
        line-height: 25px;
        text-align: center;
        letter-spacing: 0px;
        color: #269FFA;
      }

      .cou-img {
        width: 91.81px;
        height: 91.31px;
      }

      .font-bom {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 42px;
        border-radius: 0px 0px 10px 10px;
        opacity: 1;
        background: #269FFA;

        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;

        font-family: Source Han Sans;
        font-size: 14px;
        font-weight: normal;
        line-height: 25px;
        text-align: center;
        letter-spacing: 0px;
        color: #FFFFFF;
      }
    }

  }

  .login-container {
    width: 1250px;
    height: 640px;
    border-radius: 24px;
    border: 1px solid #F4F4F4;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    .container-right {
      width: 100%;
      height: 702px;
      padding: 60px 60px 0 140px;
    }
  }
}

.top-content {
  width: 1250px;
  height: 80px;
  position: relative;
  z-index: 1;
  margin: 0 auto;

  .to-login {
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 16px;
    font-weight: normal;
    line-height: normal;
    letter-spacing: 0px;
    color: #269FFA;

    margin-bottom: 20px;

    button {
      color: #269FFA;
    }
  }

  .logo-img {
    max-width: 196px;
    height: 48px;
    cursor: pointer;
    margin-right: 32px;
  }

  .logo-box {
    max-width: 1250px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: nowrap;
    padding-top: 60px;

    .left {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      flex-wrap: nowrap;

      .register {
        font-family: HarmonyOS Sans;
        font-size: 16px;
        font-weight: normal;
        line-height: normal;
        letter-spacing: 0px;
        color: #999999;
      }

      img {
        width: 196px;
        height: 48px;
        cursor: pointer;
      }

      div {
        font-size: 20px;
      }
    }
  }
}

.logo-box {
  height: 80px;
  margin: 0 auto;
  display: flex;
  align-items: center;

  img {
    width: 150px;
    cursor: pointer;
  }

  div {
    font-size: 20px;
    margin-top: 10px;
  }
}

.verify-con {
  position: absolute;
  left: 140px;
  top: 80px;
  z-index: 10;
}

.other-login {
  margin: 0 auto;

  .ivu-icon {
    font-size: 24px;
  }
}

.regist {
  display: flex;
  justify-content: flex-end;
  margin-top: -10px;

  span {
    margin-left: 10px;

    &:hover {
      cursor: pointer;
      color: #F31947;
    }
  }
}

.foot {
  position: fixed;
  bottom: 4vh;
  width: 368px;
  left: calc(50% - 184px);
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;

  .help {
    margin: 0 auto;
    margin-bottom: 1vh;
    width: 60%;

    .item {
      color: rgba(0, 0, 0, 0.45);
    }

    :hover {
      color: rgba(0, 0, 0, 0.65);
    }
  }
}
</style>
