<template>
  <div class="mb-5">
    <!-- BANNER -->
    <div id="ToTop">
      <arco-carousel style="height: 480px; width:100%;background-color: #FFFFFF;" indicator-position="bottom"
                     :dots="true" :autoPlay="true" show-arrow="never" :indicatorType="false">
        <arco-carousel-item class="car-item cursor-pointer">
          <a-image :preview="false" width="1920px" height="480px"
                   :src="require('@/assets/new/enter/banner.png')"></a-image>
        </arco-carousel-item>
        <arco-carousel-item class="car-item cursor-pointer">
          <a-image :preview="false" width="1920px" height="480px"
                   :src="require('@/assets/new/enter/banner1.png')"></a-image>
        </arco-carousel-item>
        <arco-carousel-item class="car-item cursor-pointer">
          <a-image :preview="false" width="1920px" height="480px"
                   :src="require('@/assets/new/enter/banner2.png')"></a-image>
        </arco-carousel-item>
      </arco-carousel>
    </div>

    <!-- 申请表单 -->
    <div id="joinForm" class="page-container-all width_1200_auto">
      <div class="page-join-content">
        <div class="page-container">
          <a-card :body-style="{padding: '20px'}">
            <template #title>
              填写信息
            </template>
            <div class="pt-5 pl-10 pr-10">
              <a-form :model="merchantData"
                      :label-col="{ style: { width: '150px', display: 'flex', justifyContent: 'end', alignItems: 'center' } }"
                      :wrapper-col="{ span: 14 }"
                      @finish="checkPayPwd">
                <a-form-item label="商户名称" name="name" :rules="[{ required: true, message: '请输入商户名称' }]">
                  <a-input class="input-cell" :maxlength="16" v-model:value="merchantData.name"
                           :disabled="((merchantData.auditStatus && merchantData.auditStatus > 4) || merchantData.isPay)"
                           placeholder="请输入商户名称"></a-input>
                </a-form-item>
                <a-form-item label="商户登录用户名" name="realName"
                             :rules="[{ required: true, message: '请输入商户用户名' }]">
                  <a-input class="input-cell" :maxlength="20" v-model:value="merchantData.realName"
                           @input="validateInput"
                           :disabled="((merchantData.auditStatus && merchantData.auditStatus > 4) || merchantData.isPay)"
                           placeholder="请输入商户用户名"></a-input>
                </a-form-item>
                <a-form-item label="电报" name="phone"
                             :rules="[{ required: true, message: '请输入真实电报链接,方便审核与您取得联系' }]">
                  <a-input class="input-cell" @input="phoneInput" v-model:value="merchantData.phone"
                           :disabled="((merchantData.auditStatus && merchantData.auditStatus  > 4) || merchantData.isPay)"
                           placeholder="请输入真实电报链接,方便审核与您取得联系"></a-input>
                </a-form-item>
                <a-form-item :label="$t('pwd.pwd_003')" name="email"
                             :rules="[{ required: true, message: $t('pwd.pwd_004') },{ type: 'email', message: '请输入正确的邮箱' }]">
                  <a-input class="input-cell" v-model:value="merchantData.email" :placeholder="$t('pwd.pwd_004')"
                           v-no-chinese
                           :disabled="((merchantData.auditStatus && merchantData.auditStatus  > 4) || merchantData.isPay)"
                  ></a-input>
                </a-form-item>
                <a-form-item label="商户分类" name="categoryId"
                             :rules="[{ required: true, message: '请选择商户分类' }]">
                  <a-select v-model:value="merchantData.categoryId"
                            show-search
                            placeholder="请选择商户分类"
                            :default-active-first-option="false"
                            :filter-option="false"
                            :not-found-content="null"
                            :disabled="((merchantData.auditStatus && merchantData.auditStatus  > 4) || merchantData.isPay)"
                            @change="handleChange">
                    <a-select-option v-for="(item, index) in $store.getters.merchantClassify" :key="index"
                                     :value="item.id">
                      <div class="mt-2">{{ item.name }}</div>
                      <div v-for="(items, indexs) in item.childList" :key="indexs" class="d-flex text-gray mt-1">
                        <div v-for="(itemss, indexs1) in items.childList" :key="indexs1"
                             :class="indexs1 >0 ?'ml-2' : ''"
                             class="d-flex">
                          {{ itemss.name }}
                        </div>
                      </div>
                    </a-select-option>
                  </a-select>
                </a-form-item>
                <a-form-item label=""
                             v-if="!merchantData.isPay && (merchantData.auditStatus === 1 ||  merchantData.auditStatus === 4 || merchantData.auditStatus === 3 ||!merchantData.auditStatus)">
                  <a-button type="link" class="from-btn margin-150" html-type="submit">{{
                      $t('pwd.pwd_019')
                    }}
                  </a-button>
                </a-form-item>
                <a-form-item label="" name="checked"
                             v-if="!merchantData.isPay && (merchantData.auditStatus === 1 || merchantData.auditStatus === 4 || merchantData.auditStatus === 3 ||!merchantData.auditStatus)">
                  <a-checkbox class="margin-150" v-model:checked="checked">已阅读并同意</a-checkbox>
                  <span class="check-font cursor-pointer golbal-btn" @click="xYVisible = true">《商家入驻相关条款》</span>
                </a-form-item>
              </a-form>
            </div>
          </a-card>
        </div>
        <!-- 价格明细 -->
        <div class="margin-top-xs" style="width: 100%"
             v-if="((merchantData.auditStatus && merchantData.auditStatus === 2) || merchantData.isPay)">
          <a-card title="价格明细" :body-style="{padding: '20px'}">
            <a-form>
              <a-form-item label="店铺费用">
                <text class="text-gray">{{ merchantData.handlingFee + ' USDT' }}</text>
              </a-form-item>
              <a-form-item label="优惠券">
                <text class="text-gray">{{ merchantData.couponInfo ? merchantData.couponInfo : '无' }}
                </text>
              </a-form-item>
              <a-form-item label="实际支付金">
                <text class="text-red flex justify-end text-bold">{{ merchantData.factPrice + ' USDT' }}</text>
              </a-form-item>
            </a-form>
          </a-card>
        </div>
        <div class="page-container-info">
          <a-row :gutter="[20,24]">
            <a-col :span="8">
              <a-card :body-style="{padding: '20px', height: '160px'}">
                <div class="flex align-center justify-center">
                  <a-image :preview="false" :src="require('@/assets/new/enter/herf1.png')" :width="171" :height="117"/>
                  <div class="ml-7">
                    <a href="#ToTop">
                      <div class="flex align-center justify-start cursor-pointer">
                        <span class="mr-2 title-2-import hover-item-bold">入驻流程</span>
                        <a-image class="mb-1-2" :preview="false" :src="require('@/assets/new/enter/enter-btn.png')"
                                 :width="28"/>
                      </div>
                    </a>
                    <div class="mt-4 text-df">入驻前先看，新手开店不用慌！</div>

                  </div>
                </div>
              </a-card>
            </a-col>
            <a-col :span="8">
              <a-card :body-style="{padding: '11px', height: '160px'}">
                <div class="flex align-center justify-start">
                  <a-image :preview="false" :src="require('@/assets/new/enter/herf2.png')" :width="165" :height="133"/>
                  <div class="ml-7">
                    <div class="flex align-center justify-start cursor-pointer" @click="toJoinHis('ToIssue')">
                      <span class="mr-2 title-2-import hover-item-bold">常见问题</span>
                      <a-image class="mb-1-2" :preview="false" :src="require('@/assets/new/enter/enter-btn.png')"
                               :width="28"/>
                    </div>
                    <div class="mt-4 text-df">帮您解决入驻过程中的各类问题</div>
                  </div>
                </div>
              </a-card>
            </a-col>
            <a-col :span="8">
              <a-card :body-style="{padding: '27px', height: '160px'}">
                <div class="flex align-center justify-start">
                  <a-image :preview="false" :src="require('@/assets/new/enter/herf3.png')" :width="160" :height="114"/>
                  <div class="ml-7">
                    <div class="flex align-center justify-start cursor-pointer" @click="toJoinHis('ToHis')">
                      <span class="mr-2 title-2-import hover-item-bold">入驻记录</span>
                      <a-image class="mb-1-2" :preview="false" :src="require('@/assets/new/enter/enter-btn.png')"
                               :width="28"/>
                    </div>
                    <div class="mt-4 text-df">查看入驻申请的所有状态</div>
                  </div>
                </div>
              </a-card>
            </a-col>
          </a-row>
        </div>
      </div>
    </div>

    <!-- 招商团队 -->
    <div class="bg-white mt-5">
      <div class="width_1200_auto" style="padding: 59px 0;">
        <div class="text-xxxl text-center">专业招商团队，为您保驾护航！</div>
        <div class="text-df text-center text-gray mt-1">我们的招商团队，致力于每一位商户提供贴心、高效的服务。</div>
        <div class="text-center mt-11">
          <arco-carousel :style="{ width: '1600px',height: '470px',background: '#fff'}" indicator-type="never"
                         :default-current="1">
            <arco-carousel-item>
              <div class="carousel-item">
                <a-row :gutter="[40,24]">
                  <a-col :span="6" v-for="(item, index) in linkCustomer" :key="index">
                    <div class="carousel-item-col hover-item-mini" :class="`link-tel-box-show${index}`">
                      <a-avatar :src="item.headImg" :size="139"></a-avatar>
                      <div class="mt-7 mb-6 text-xl text-bold">{{ item.name }}</div>
                      <div class="qr-container">
                        <QRCodeCus :tel-url="item.telQrCode"/>
                      </div>
                      <div class="link-tel-btn mt-5 cu-center hover-item-big"
                           @click="toTurl(item.telQrCode)"
                           :class="`link-tel-btn-color${index}`">
                        <a-avatar :shape="'square'" :src="require(`@/assets/new/enter/c${index}.png`)" :size="20"/>
                        <span class="ml-1">立即交谈</span>
                      </div>
                    </div>
                  </a-col>
                </a-row>
              </div>
            </arco-carousel-item>
            <arco-carousel-item>
              <div class="carousel-item">
                <a-row :gutter="[40,24]">
                  <a-col :span="6" v-for="(item, index) in linkCustomer2" :key="index">
                    <div class="carousel-item-col hover-item-mini" :class="`link-tel-box-show${index}`">
                      <a-avatar :src="item.headImg" :size="139"></a-avatar>
                      <div class="mt-7 mb-6 text-xl text-bold">{{ item.name }}</div>
                      <div class="qr-container">
                        <QRCodeCus/>
                      </div>
                      <div class="link-tel-btn mt-5 cu-center hover-item-big"
                           @click="toTurl(item.telQrCode)"
                           :class="`link-tel-btn-color${index}`">
                        <a-avatar :shape="'square'" :src="require(`@/assets/new/enter/c${index}.png`)" :size="20"/>
                        <span class="ml-1">立即交谈</span>
                      </div>
                    </div>
                  </a-col>
                </a-row>
              </div>
            </arco-carousel-item>
          </arco-carousel>
        </div>
      </div>
    </div>

    <!-- 加入理由 -->
    <div class="mt-5">
      <div class="width_1200_auto" style="padding: 59px 0 0 0;">
        <div class="text-xxxl text-center">选择51担保网，无法抗拒的理由</div>
        <div class="text-df text-center text-gray mt-1">我们的招商团队，致力于每一位商户提供贴心、高效的服务。</div>
        <div class="text-center mt-11">
          <div class="carousel-item-c2">
            <a-row :gutter="[40,24]">
              <a-col :span="4" v-for="(item, index) in becauseItemList" :key="index">
                <div class="carousel-item-col-c2 hover-item-up">
                  <a-image :preview="false" :src="item.img"
                           :width="117"
                           :height="115"
                  ></a-image>
                  <div class="mt-3 mb-4 text-xl text-bold">{{ item.title }}</div>
                  <div class="cu-center mb-7">
                    <span :style="`width: 24px;height: 4px;background-color: ${item.colorLine}`"/>
                  </div>
                  <div class="text-df">
                    {{ item.info }}
                  </div>
                </div>
              </a-col>
            </a-row>
          </div>
        </div>
      </div>
    </div>

    <!-- banner -->
    <div class="width_1200_auto mt-5 cursor-pointer">
      <a href="#joinForm">
        <a-image :preview="false" :src="require('@/assets/new/enter/cbanner.png')" :height="160"></a-image>
      </a>
    </div>

    <!-- 加入商 -->
    <div class="mt-5">
      <div class="width_1200_auto" style="padding: 59px 0;">
        <div class="text-xxxl text-center">他们都在51担保网</div>
        <div class="text-df text-center text-gray mt-1">我们的招商团队，致力于每一位商户提供贴心、高效的服务。</div>
        <div class="text-center mt-11">

          <div class="scroll-x-cus mt-3">
            <div class="carousel-item-c3">
              <div class="carousel-item-c3-row" v-for="(itemIndex) in 3" :key="itemIndex">
                <div class="ml-5" v-for="(item, index) in merListThreeChunArray[0]" :key="index">
                  <a-card :hoverable="false" class="carousel-item-col-c3 hover-item-up cursor-pointer"
                          @click="toMer(item)">
                    <a-avatar style="border: 1px solid #EEEEEE;" :src="item.avatar" :size="150"></a-avatar>
                  </a-card>
                </div>
              </div>
            </div>
          </div>

          <div class="scroll-x-cus mt-5">
            <div class="carousel-item-c3-r">
              <div class="carousel-item-c3-row" v-for="(itemIndex) in 3" :key="itemIndex">
                <div class="ml-5" v-for="(item, index) in merListThreeChunArray[1]" :key="index">
                  <a-card :hoverable="false" class="carousel-item-col-c3 hover-item-up cursor-pointer"
                          @click="toMer(item)">
                    <a-avatar style="border: 1px solid #EEEEEE;" :src="item.avatar" :size="150"></a-avatar>
                  </a-card>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

    <a-modal v-model:open="xYVisible" title="商家入驻相关条款"
             :width="920"
             cancelText="取消">
      <template #footer>
        <a-button :type="'primary'" @click="cancel">确认</a-button>
      </template>
      <Scroll :height="750">
        <div v-html="protocol"></div>
      </Scroll>
    </a-modal>

    <ShopTip :show-model="showModel" :tips="tipsOne" @close="showModel = false"></ShopTip>
  </div>
</template>
<script>
import { getArticleDetails } from '@/api/api'
import { queryNormalIssue } from '@/api/tg'
import { getDiyMerListReverApi, getMerSettledApplyCheckApi } from '@/api/merchant'
import { Debounce } from '@/utils/validate'
import { merchantQuery } from '@/api/order'
import { isSet } from '@/api/user'
import ShopTip from '@/components/shopTip/index.vue'
import Payment from '@/components/payment/index.vue'
import QRCodeCus from '@/components/qrcode/index.vue'
// 完毕数据
import enterDate from './data/index'

export default {
  components: {
    Payment,
    ShopTip,
    QRCodeCus
  },
  data() {
    return {
      checked: false,
      protocol: '',
      xYVisible: false,
      issueList: [],
      activeKey: '',
      prefix: 'https://t.me/',
      merchantData: {
        name: '',
        realName: '',
        email: '',
        phone: '',
        captcha: '',
        categoryId: null,
        handlingFee: '',
        keywords: '',
        qualificationPicture: '',
        typeId: 0,
        auditStatus: 0,
      },
      typeData: [],
      isKey: '',
      orderNo: '',
      showIndex: 0,
      tabCur: 0,
      showModel: false,
      tipsOne: '',
      linkCustomer: enterDate.linkCustomerOne,
      linkCustomer2: enterDate.linkCustomerTwo,
      becauseItemList: enterDate.becauseList,
      joinMerInfo: enterDate.joinMerInfo,
      currentIndex: 0,
      merListThreeChunArray: [],
    }
  },
  created() {
    this.getConfig()
    if (this.$route.query.key !== '1') {
      this.isKey = ''
    } else {
      this.orderNo = this.$route.query.orderNo
      this.isKey = this.$route.query.key
      this.queryOrderById(this.orderNo)
    }
    this.getMerListByOrderNo()
  },
  mounted() {
    // 每隔3秒调用rotateElements方法
    setInterval(this.rotateElements, 3000)
  },
  methods: {
    getMerListByOrderNo() {
      getDiyMerListReverApi(12)
          .then((res) => {
            let merListThree = res.data.list
            this.merListThreeChunArray = []
            this.merListThreeChunArray = this.chunkArray(merListThree, 6)
          })
    },
    chunkArray(array, size) {
      const result = []
      for (let i = 0; i < array.length; i += size) {
        result.push(array.slice(i, i + size))
      }
      return result
    },
    // 顺时针替换顺序
    rotateElements() {
      const firstItem = this.joinMerInfo.shift() // 拿出第一个元素
      this.joinMerInfo.push(firstItem) // 将第一个元素加入到数组最后
    },
    toTurl(url) {
      window.open(url)
    },
    validateInput(event) {
      let that = this
      let value = event.target.value
      // 使用正则表达式过滤非字母和数字字符
      value = value.replace(/[^A-Za-z0-9]/g, '')
      // 更新数据
      that.$nextTick(() => {
        that.merchantData.realName = value
      })
    },
    queryOrderById(id) {
      merchantQuery(id)
          .then((res) => {
            this.merchantData = res.data
            this.endPrice = this.merchantData.handlingFee
          })
    },
    toJoinHis(to) {
      this.$router.push({
        path: '/JoinHis',
        query: { to: to }
      })
    },
    toMer(item) {
      this.$router.push({
        path: '/MerHome',
        query: { merId: item.id }
      })
    },
    handleChange(e) {
      this.merchantData.categoryId = e
    },
    checkPayPwd() {
      isSet()
          .then((res) => {
            if (!res.data) {
              this.onFinish()
            } else {
              this.tipsOne = '未设置支付密码,前去设置?'
              this.showModel = true
            }
          })
    },
    onFinish: Debounce(function (e) {
      if (!this.checked) {
        return this.$Message.error('请确认入驻条款')
      }
      getMerSettledApplyCheckApi(this.merchantData)
          .then(res => {
            if (res.code === 200) {
              this.$router.push({
                path: '/JoinPay',
                query: {
                  id: res.data.id,
                },
              })
            } else {
              this.$Message.error(res.message)
            }
          })
    }),
    phoneInput(e) {
      this.$nextTick(() => {
        let inputValue = e.target.value
        let value = 'https://t.me'
        if (inputValue === value) {
          this.merchantData.phone = this.prefix
          return
        }
        value = 'https://t.m'
        if (inputValue === value) {
          this.merchantData.phone = this.prefix
          return
        }
        value = 'https://t.'
        if (inputValue === value) {
          this.merchantData.phone = this.prefix
          return
        }
        value = 'https://t'
        if (inputValue === value) {
          this.merchantData.phone = this.prefix
          return
        }
        value = 'https://'
        if (inputValue === value) {
          this.merchantData.phone = this.prefix
          return
        }
        value = '@'
        if (inputValue === value) {
          this.merchantData.phone = this.prefix
          return
        }
        const nonChineseValue = inputValue.replace(/[\u4e00-\u9fa5\s]/g, '')
            .replace(/@/g, '')
        if (nonChineseValue !== inputValue) {
          if (!nonChineseValue.startsWith(this.prefix)) {
            this.merchantData.phone = this.prefix + nonChineseValue
          } else {
            this.merchantData.phone = nonChineseValue
          }
        } else {
          if (!inputValue.startsWith(this.prefix)) {
            this.merchantData.phone = this.prefix + nonChineseValue
          } else {
            this.merchantData.phone = nonChineseValue
          }
        }
      })
    },
    cancel() {
      this.xYVisible = false
    },
    getConfig() {
      getArticleDetails(21)
          .then(res => {
            if (res.data) {
              this.protocol = res.data.content
            }
          })
    },
    getIssueList() {
      let data = {
        page: 1,
        limit: 100,
        issueType: 3
      }
      queryNormalIssue(data)
          .then(res => {
            this.issueList = res.data.list
          })
    },
  }
}
</script>

<style scoped lang="scss">
.scroll-x-cus {
  width: 100%; /* 容器宽度，根据需要调整 */
  overflow: hidden; /* 隐藏溢出的内容 */
  position: relative;
}

.carousel-item-c3-row {
  display: flex;
  margin-left: 20rpx;
}

.carousel-item-c3 {
  margin: 0 auto;

  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;

  animation: scroll-left 20s linear infinite;
}

.carousel-item-c3-r {
  margin: 0 auto;

  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;

  animation: scroll-right 20s linear infinite;
}

@keyframes scroll-left {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%); /* 完全左移 */
  }
}

@keyframes scroll-right {
  0% {
    transform: translateX(0); /* 起始位置 */
  }
  100% {
    transform: translateX(100%); /* 半路，滚动一半 */
  }
}

.qr-container {
  width: 120px;
  height: 120px;
}

.carousel-item-col {
  width: 285px;
  height: 466px;
  border-radius: 20px;
  border: 1px solid #EEEEEE;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.carousel-item-col-c3 {
  height: 250px;
  padding: 50px 60px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel-item-col-c2 {
  width: 250px;
  height: 394px;
  padding: 50px 25px;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.6), rgba(243, 245, 250, 0.6));
  box-shadow: 0px 3px 18px 0px rgba(107, 131, 200, 0.02);
  border-radius: 20px;
}

.carousel-item-c2 {
  width: 1600px;
  margin: 0 auto;
  height: 394px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel-item {
  width: 1500px;
  margin: 0 auto;
  height: 466px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.link-tel-btn {
  width: 120px;
  height: 38px;
  background: #EDF1FD;
  border-radius: 10px;

  font-weight: bold;
  font-size: 14px;
  line-height: 20px;

  cursor: pointer;
}

.link-tel-btn-color0 {
  background: #EDF1FD;
  color: #3B64DA;
}

.link-tel-box-show0 {
  box-shadow: 0 10px 10px -6px #3B64DA; /* 下方阴影 */
}

.link-tel-box-show1 {
  box-shadow: 0 10px 10px -5px #D18603; /* 下方阴影 */
}

.link-tel-box-show2 {
  box-shadow: 0 10px 10px -5px #33A1D5; /* 下方阴影 */
}

.link-tel-box-show3 {
  box-shadow: 0 10px 10px -5px #EA564A; /* 下方阴影 */
}

.link-tel-btn-color1 {
  background: #FDF3DC;
  color: #D18603;
}

.link-tel-btn-color2 {
  background: #E7F5FC;
  color: #33A1D5;
}

.link-tel-btn-color3 {
  background: #FFECEA;
  color: #EA564A;
}

:deep(.arco-carousel-arrow > div:hover) {
  background: #2448DD !important;
  border: 1px solid #2448DD !important;
}

:deep(.arco-carousel-arrow > div > svg:hover) {
  color: #FFFFFF !important;
}

:deep(.arco-carousel-arrow > div) {
  width: 52px !important;
  height: 52px !important;
  background: #FFFFFF !important;
  border: 1px solid #e9e9e9 !important;
}

:deep(.arco-carousel-arrow > div > svg) {
  color: #000000 !important;
  font-size: 24px !important;
}

.title-2-import {
  height: 20px;
  font-weight: 500;
  font-size: 20px;
  color: #3F7EEA;
  line-height: 20px;
}

:deep(.ant-select-selector) {
  height: 48px !important; /* 自定义高度 */
  line-height: 48px !important; /* 保持内容垂直居中 */
  box-shadow: 0px 3px 10px 0px rgba(107, 131, 200, 0.08);
  border-radius: 10px;
  border: 1px solid #E9EAEB;
}

:deep(.ant-select-selection-placeholder) {
  line-height: 48px !important;
}

.extra {
  width: 102px;
  height: 32px;
  background: #3B64DA;
  border-radius: 16px;

  font-weight: 500;
  font-size: 16px;
  color: #FFFFFF;
  line-height: 34px;
}

.html-font {
  font-size: 14px;
  font-weight: normal;
  color: #999999;
}

.margin-150 {
  margin-left: 150px;
}

.check-font {
  margin-top: 30px;
  font-size: 14px;
  font-weight: normal;
  color: #0052D9;
}

.from-btn {
  width: 260px;
  height: 60px;
  background: linear-gradient(-90deg, #0080D9, #0052D9);
  box-shadow: 0px 5px 16px 0px rgba(71, 100, 184, 0.3);
  border-radius: 10px;

  font-weight: 800;
  font-size: 18px;
  color: #FFFFFF;
  line-height: 38px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.input-cell {
  height: 48px;
  box-shadow: 0px 3px 10px 0px rgba(107, 131, 200, 0.08);
  border-radius: 10px;
  border: 1px solid #E9EAEB;
}

.page-container-all {
  margin-top: 3px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: flex-start;
  flex-direction: column;
  flex-wrap: nowrap;

  .page-join-content {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-wrap: nowrap;

    .page-container {
      margin-top: 20px;
      width: 100%;
    }

    .page-container-info {
      margin-top: 20px;
      width: 100%;
    }

    .font-top1 {
      font-size: 80px;
      font-weight: normal;
      line-height: 25px;
      letter-spacing: 0px;
      color: #FFFFFF;
      text-shadow: 0px 6px 0px rgba(121, 14, 14, 0.6);
    }

    .font-top2 {
      font-size: 45px;
      font-weight: normal;
      line-height: 25px;
      letter-spacing: 0px;
      /* 全球合伙人 赚取 */
      color: #FFFFFF;
      margin-top: 60px;
    }

    .font-top3 {
      /* 高额佣金 */
      color: #FFF52E;
    }

    .page-join {
      margin-top: 30px;
      width: 132px;
      height: 38px;
      border-radius: 50px;
      opacity: 1;
      background: #FFCC00;

      display: flex;
      justify-content: center;
      align-items: center;

      .font-cus {
        font-size: 16px;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: -0.17px;
        color: #3D3D3D;
      }
    }
  }

}
</style>
