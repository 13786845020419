<template>
  <div class="wrapper">
    <a-card class="cart-users"
            :head-style="{padding: '20px 32px', height: '79px'}"
            :bodyStyle="{padding: '20px 32px'}">
      <template #title>
        <div>我的优惠券</div>
      </template>
      <template #extra>
        <div class="d-flex">
          <div class="cu-center">
            <a-button :class="selectedIndex === 'usable' ? 'btn-blue text-white' : 'btn-gray'" type="link"
                      @click="showList('usable')">
              未使用
            </a-button>
          </div>
          <div class="ml-3 cu-center">
            <a-button :class="selectedIndex === 'unusable' ? 'btn-blue text-white' : 'btn-gray'" type="link"
                      @click="showList('unusable')">
              已使用
            </a-button>
          </div>
          <div class="ml-3 cu-center">
            <a-button :class="selectedIndex === 'overdue' ? 'btn-blue text-white' : 'btn-gray'" type="link"
                      @click="showList('overdue')">
              已过期
            </a-button>
          </div>
        </div>
      </template>

      <div style="width: 100%">
        <empty v-if="!couponsList || couponsList.length === 0"/>
        <a-row v-else :gutter="[16,24]">
          <a-col :span="8" v-for="(item,index) in couponsList" :key="index">
            <div class="bg-img-cus" :class="item.validStr === 'usable' ? 'online' : 'invite'">
              <div class="bg-pos">
                <div class="money-hour">
                  <div v-if="item.showFlag === 1">
                    <span :class="item.validStr !== 'usable' ? 'text-black' : ''">{{ item.month }}</span>
                    <span class="ml-1" :class="item.validStr !== 'usable' ? 'money-dw text-black' : 'money-dw'">
                      {{
                        item.trialFlag === 0 ? '小时' : item.trialFlag === 1 ? '天' : item.trialFlag === 2 ? '月' : ''
                      }}
                    </span>
                  </div>
                  <div v-if="item.showFlag === 0">
                    <span class="pad-minus" :class="item.validStr !== 'usable' ? 'text-black' : ''">{{
                        item.money
                      }}</span>
                    <span class="ml-1"
                          :class="item.validStr !== 'usable' ? 'money-dw text-black' : 'money-dw'">USDT</span>
                  </div>
                  <!-- 按钮 -->
                  <div :class="item.validStr==='usable' ? 'action' : 'moneyGray-cus'">
                    <div class="btn-cus cursor-pointer hover-item-big" v-if="item.validStr==='usable'" @click="goCouponsProList(item)">
                      使用
                    </div>
                    <div class="btn-gray-unline" v-if="item.validStr==='unusable'">
                      已使用
                    </div>
                    <div class="btn-gray-unline" v-if="item.validStr==='overdue'">
                      已过期
                    </div>
                  </div>
                </div>
                <div class="mt-4 flex align-center">
                  <div class="text-bold text-xl">{{ item.name }}</div>
                  <div class="ml-3 text-lg" :class="item.validStr !== 'usable' ? 'text-black' : 'text-orange'">
                    {{ '限商家入驻使用' }}
                  </div>
                </div>
                <div class="mt-8 text-grey text-df flex align-center">
                  {{ getCouponTime(item.startTime, item.endTime) }}
                </div>
              </div>
            </div>
          </a-col>
        </a-row>
      </div>
    </a-card>
  </div>
</template>

<script>
import { getUserCoupons } from '@/api/api.js'

export default {
  name: 'MyCoupon',
  data() {
    return {
      selectedIndex: 'usable',
      page: 1,
      limit: 30,
      couponsList: []
    }
  },
  mounted() {
    this.getUseCoupons()
  },
  methods: {
    goCouponsProList(item) {
      if (item.category === 7) {
        this.$router.push({
          path: '/Enter',
          query: {}
        })
      }
    },
    /**优惠券展示的使用有效期均根据今天进行判断，
     * 没到使用期的展示「开始日期-结束日期 有效」，
     * 已经可以使用的展示「有效期至 结束日期」
     * @param {Object} time
     */
    getCouponTime(startTime, endTime) {
      let start = new Date(startTime).getTime()
      let newTime = Date.now()
      let reg = new RegExp('-', 'g') //g代表全部
      if (newTime < start) {
        return startTime.slice(0, 16)
            .replace(reg, '.') + ' - ' + endTime.slice(0, 16)
            .replace(reg, '.') + ' 可用'
      } else {
        return '有效期至 ' + endTime.slice(0, 16)
            .replace(reg, '.')
      }
    },
    getUseCoupons() {
      let that = this
      this.loading = true
      getUserCoupons({
        page: that.page,
        limit: that.limit,
        type: that.selectedIndex
      })
          .then(res => {
            that.couponsList = res.data ? res.data.list : []
            that.loading = false
          })
    },
    showList(e) {
      this.selectedIndex = e
      this.page = 1
      this.getUseCoupons()
    }
  },
}
</script>

<style scoped lang="scss">
.pad-minus {
  margin-left: -5px !important;
}

.btn-cus {
  width: 72px;
  height: 32px;
  background: #FE7E26;
  border-radius: 16px;

  font-weight: 400;
  font-size: 16px;
  color: #FFFFFF;
  line-height: 34px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-gray-unline {
  width: 72px;
  height: 32px;
  background: #CBCED2;
  border-radius: 16px;

  font-weight: 400;
  font-size: 16px;
  color: #FFFFFF;
  line-height: 34px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.bg-pos {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 20px 24px 18px 30px;

  .money-hour {
    width: 100%;
    height: 32px;

    font-size: 43px;
    font-weight: bold;
    line-height: normal;
    letter-spacing: 0em;
    color: #FF6F4A;

    display: flex;
    justify-content: space-between;
    align-items: center;

    .money-dw {
      font-size: 16px;
      color: #FF6F4A;
    }
  }
}

.bg-img-cus {
  position: relative;
  margin-top: 10px;
  width: 412px;
  height: 161px;
  border-radius: 12px;
  opacity: 1;
  box-sizing: border-box;
}

.btn-blue {
  width: 90px;
  height: 38px;
  background: #3B64DA;
  border: 1px solid #3B64DA;
  border-radius: 19px;

  font-weight: 400;
  font-size: 16px;
  color: #FFFFFF;
  line-height: 34px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-gray {
  width: 90px;
  height: 38px;
  border-radius: 19px;
  border: 1px solid #D1D1D1;

  font-weight: 400;
  font-size: 16px;
  color: #000000;
  line-height: 34px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.online {
  background-image: url("@/assets/new/coupon/item-back.png");
  background-size: cover; /* 背景图铺满整个容器 */
  background-position: center; /* 背景图居中 */
  background-repeat: no-repeat; /* 防止背景图重复 */
  background-attachment: fixed; /* 背景图固定（滚动时背景图不动） */
}

.invite {
  background-image: url("@/assets/new/coupon/item-invite.png");
  background-size: cover; /* 背景图铺满整个容器 */
  background-position: center; /* 背景图居中 */
  background-repeat: no-repeat; /* 防止背景图重复 */
  background-attachment: fixed; /* 背景图固定（滚动时背景图不动） */
}

.wrapper {
  width: 100%;
  min-height: 1200px;
  margin-bottom: 25px;

  .cart-users {
    width: 100%;
    border-radius: 12px;
    opacity: 1;
    min-height: 1200px;
  }
}
</style>
