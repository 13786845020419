<template>
  <div id="list">
    <arco-scrollbar style="height:610px;overflow-y: auto;overflow-x:hidden !important;background-color: #F7F7FA;">
      <a-menu class="side-menu" mode="inline" v-model:selectedKeys="selectedKeys" @click="changeCurrentSession">
        <a-menu-item v-for="(item,index) in messages" :key="index">
          <a-badge :dot="item.mesCount" :offset="[2,3]">
            <div class="d-flex" @mouseenter="showMessage = true;index= item.id"
                 @mouseleave="showMessage = false;index= 0">
              <div class="me-auto">
                <Icon v-if="showMessage && index===item.id" type="ios-close" size="22" color="#B2B2B2"
                      @click="closeItem(item)"/>
                <a-avatar :src="item.headImg" :size="42"/>
              </div>
              <div class="ml-1 mt-1"
                   style="width: 165px;height: 35px;display: flex; flex-direction: column;justify-content: space-between;">
                <div class="d-flex justify-between" style="width: 165px">
                  <div class="text-cut" style="width: 100px;">{{ item.merName }}</div>
                  <div class="text-gray text-sm">
                    {{ time(item.updateFormatTime) === '' ? item.updateTime : time(item.updateFormatTime) }}
                  </div>
                </div>
                <div class="text-cut text-sm text-gray text-cut" style="width: 165px;">
                  {{ item.topTitle ? item.topTitle : '' }}
                </div>
              </div>
            </div>
          </a-badge>
        </a-menu-item>
      </a-menu>
    </arco-scrollbar>
  </div>
</template>

<script>
import { delCusList, msgCusList } from '@/api/message'

import FingerprintJS from '@fingerprintjs/fingerprintjs'

import { formatChatListTimeStr } from '@/utils/chat'

export default {
  name: 'list',
  props: {
    keyword: {
      type: String,
      default: ''
    },
    dbMerId: {
      type: Number,
      default: ''
    },
    // 刷新INDEX
    refreshList: {
      type: Number,
      default: -1
    },
  },
  watch: {
    keyword: {
      handler(val) {
        this.queryMsgCusList()
      }
    },
    refreshList: {
      handler(val) {
        this.queryMsgCusList()
      }
    }
  },
  data() {
    return {
      messages: [],
      openKeys: [],
      selectedKeys: [],
      index: 0,
      showMessage: false,
      delItem: false,
    }
  },
  created() {
    // this.queryMsgCusList()
  },
  methods: {
    closeItem(item) {
      this.index = item.id
      if (this.delItem) {
        return
      }
      this.delItem = true
    },
    time(dateStr) {
      return formatChatListTimeStr(dateStr)
    },
    queryMsgCusList() {
      FingerprintJS.load()
          .then(fp => {
            fp.get()
                .then(result => {
                  let visitorId = result.visitorId
                  let data = {
                    headImg: visitorId
                  }
                  msgCusList(data)
                      .then(res => {
                        let arrass = res.data
                        this.messages = []
                        if (arrass && arrass.length > 0) {
                          arrass.forEach(item => {
                            if (item.merName.indexOf(this.keyword) > -1) {
                              this.messages.push(item)
                            }
                          })
                          this.selectedKeys = []
                          if (!this.keyword) {
                            this.messages.forEach((item, index) => {
                              if (Number.parseInt(this.dbMerId) === Number.parseInt(item.merId)) {
                                this.selectedKeys.push(index)
                                this.$emit('change', item)
                              }
                            })
                          } else {
                            this.selectedKeys.push(0)
                            this.$emit('change', this.messages[0])
                          }
                        }
                      })
                })
          })
    },
    changeCurrentSession(item) {
      let mesItem = this.messages[item.key]
      if (!this.delItem) {
        this.selectedKeys = []
        this.selectedKeys.push(item.key)
        mesItem.index = item.key + 1
        this.$emit('change', mesItem)
      }
      setTimeout(() => {
        FingerprintJS.load()
            .then(fp => {
              fp.get()
                  .then(result => {
                    let visitorId = result.visitorId
                    if (this.delItem) {
                      this.delItem = false
                      // 删除操作
                      let data = {
                        id: mesItem.id,
                        headImg: visitorId
                      }
                      delCusList(data)
                          .then(res => {
                            if (res.code === 200 && res.data) {
                              this.queryMsgCusBackList(visitorId)
                            }
                          })
                    } else {
                      this.queryMsgCusBackList(visitorId)
                    }
                  })
            })
      }, 200)
    },
    queryMsgCusBackList(visitorId) {
      let data = {
        headImg: visitorId,
      }
      msgCusList(data)
          .then(res => {
            let arrass = res.data
            this.messages = []
            if (arrass && arrass.length > 0) {
              arrass.forEach(item => {
                if (item.merName.indexOf(this.keyword) > -1) {
                  this.messages.push(item)
                }
              })
            }
          })
    },
  }
}
</script>

<style lang="scss" scoped>
:deep(ul) {
  margin-left: 0 !important;
}

:deep(.ant-menu-item) {
  padding-left: 5px !important;
  height: 62px;
}

:deep(.ant-menu-title-content) {
  padding: 0px !important;
}

#list {
  background-color: #F7F7FA;
  margin-top: 10px;

  ul {
    padding-left: 0px;
    margin-left: 2px;
  }

  li {
    padding-top: 14px;
    padding-bottom: 14px;
    //padding-right: 40px;
    //border-bottom: 1px solid #E5E5E5;
    list-style: none;
    cursor: pointer;
    background-color: #F6F7F6;

    &:hover {
      background-color: #D8D6D6;
    }
  }

  li.active { /*注意这个是.不是冒号:*/
    background-color: #C8C6C6;
  }

  .avatar {
    width: 45px;
    height: 45px;
    vertical-align: middle;
  }

  .name {
    display: inline-block;
    margin-left: 6px;
    font-size: 13px;
  }

  .nameId {
    display: inline-block;
    margin-left: 6px;
    font-size: 14px;
    margin-top: 5px;
    color: #666666;
  }

  .userList {
    max-height: 600px;
    background-color: #F6F7F6 !important;
  }

  .el-scrollbar__wrap.default-scrollbar__wrap {
    overflow-x: auto;
  }
}
</style>
