export default {
  home: {
    name: 'home',
    login: 'Login',
  },
  header: {
    hello: 'Hello!',
    login: 'Login',
    register: 'Register',
    phone: 'Phone',
    homepage: 'My Home',
    wallet: 'My Wallet',
    order: 'My Order',
    logout: 'Logout',
    chinese: 'Simplified Chinese',
    or: 'Or',
  },
  common: {
    com_000: 'Confirm',
    com_001: 'Cancel',
    com_002: 'Enter country or area code to search',
    com_003: 'Service',
  },
  login: {
    l_00001: 'Welcome to login',
    l_00002: 'Go register',
    l_00003: 'Mailbox',
    l_00004: 'Please enter the email address',
    l_00005: 'Please enter the login password',
    l_00006: 'Forgot password? ',
    l_00007: 'Login',
    l_00008: 'Phone number',
    l_00009: 'Please enter your mobile phone number',
    l_00010: 'Please enter the login password',
    l_00011: 'Forgot password? ',
    l_00012: 'Login',
    l_00013: 'Other ways to log in',
    l_00014: 'Service',
    l_00015: 'Invitation code',
    l_00016: 'Login',
    l_00017: 'Cancel',
    l_00018: 'Please enter the invitation code',
    l_00019: 'Password cannot be empty',
    l_00020: 'Please read and agree to the agreement rules and Privacy Policy',
    l_00021: 'Mailbox cannot be empty',
    l_00022: 'Email address is not entered correctly',
    l_00023: 'Mobile phone number cannot be empty',
    l_00024: 'I have read and agree',
    l_00025: 'User Agreement',
    l_00026: 'and',
    l_00027: 'Privacy Policy',
  },
  signUp: {
    s_00001: 'Welcome to register',
    s_00002: 'Go to login',
    s_00003: 'Mailbox',
    s_00004: 'Please enter your email',
    s_00005: 'Invitation code',
    s_00006: 'Optional',
    s_00007: 'Next',
    s_00008: 'Already have an account,',
    s_00009: 'Please enter the invitation code',
    s_00010: 'Go to login',
    s_00011: 'Please enter the verification code',
    s_00012: 'Verification code sent to',
    s_00013: 'The email verification code may be judged as spam, please check it carefully',
    s_00014: 'Set login password',
    s_00015: 'Please enter a password consisting of at least 8 digits of English letters and numbers.',
    s_00016: 'Please enter password',
    s_00017: 'Please re-enter password',
    s_00018: 'Password security:',
    s_00019: 'Register',
    s_00021: 'Mobile phone number',
    s_00022: 'Please enter your mobile phone number',
    s_00023: 'Selected',
    s_00024: 'The mobile phone verification code may be judged as spam text message, please check it carefully',
    s_00025: 'Please enter your email address',
    s_00026: 'Please enter the correct email address',
    s_00027: 'Please enter your mobile phone number',
    s_00028: 'Please enter password',
    s_00029: 'Please re-enter password',
    s_00030: 'Mobile phone number cannot be empty',
    s_00031: 'Verification code cannot be empty',
    s_00032: 'Verification code is incorrect',
    s_00033: 'Password cannot be empty',
    s_00034: 'Please enter a password consisting of at least 8 digits of English letters and numbers',
    s_00035: 'Repeat password cannot be empty',
    s_00036: 'The two passwords are different',
    s_00037: 'Verification code cannot be empty',
    s_00038: 'Please enter your mobile phone number',
    s_00039: 'Mobile phone number already exists or is incorrect',
    s_00040: 'The invitation code you entered is invalid',
    s_00041: 'Mailbox cannot be empty',
    s_00042: 'Email is incorrect',
    s_00043: 'Repeat cannot be empty',
    s_00044: 'Please enter the verification code',
    s_00045: 'Please enter your email address',
    s_00046: 'The email address you entered is incorrect',
    s_00047: 'The email address you entered already exists',
  },
  pwd: {
    pwd_001: 'Forgot password',
    pwd_002: 'Go to login',
    pwd_003: 'Mailbox',
    pwd_004: 'Please enter your email',
    pwd_005: 'Already have an account?',
    pwd_006: 'Go to login',
    pwd_007: 'Please enter the verification code',
    pwd_008: 'Verification code sent to',
    pwd_009: 'The email verification code may be judged as spam, please check it carefully. ',
    pwd_010: 'Confirm',
    pwd_011: 'Set login password',
    pwd_012: 'Please enter a password consisting of at least 8 digits of English letters and numbers.',
    pwd_013: 'Please enter password',
    pwd_014: 'Please re-enter password',
    pwd_015: 'Password security:',
    pwd_016: 'Mobile phone number',
    pwd_017: 'Please enter your mobile phone number',
    pwd_018: 'Selected',
    pwd_019: 'Next',
    pwd_020: 'Already have an account',
    pwd_021: 'Go to login',
    pwd_022: 'Please enter the verification code',
    pwd_023: 'Verification code sent to',
    pwd_024: 'The mobile phone verification code may be judged as a spam text message, please check it carefully. ',
    pwd_025: 'Please enter the correct email address',
    pwd_026: 'Please enter your mobile phone number',
    pwd_027: 'The email address you entered does not exist',
  },
  f_00001: 'Official Telegram channel',
  f_00002: 'Official customer service telegram',
  f_00003: 'Online hours',
  f_00004: 'About us',
  f_00005: 'Website Introduction',
  f_00006: 'Company Profile',
  f_00007: 'Join us',
  f_00008: 'User Agreement',
  f_00009: 'Merchant Settlement Agreement',
  f_00010: 'Transaction Guarantee',
  f_00011: 'Usage tutorial',
  f_00012: 'Dispute Arbitration',
  f_00013: 'Disclaimer',
  f_00014: 'Help Center',
  f_00015: 'Settlement rate',
  f_00016: 'Guarantee rate',
  f_00017: 'Share invitation',
  f_00018: 'Partner',
  f_00019: 'Help and Support',
  f_00020: 'FAQ',
  f_00021: 'Dispute Arbitration',
  f_00022: 'Expense description',
  f_00023: 'Policy and Privacy',
  f_00024: 'User Agreement',
  f_00025: 'Privacy Policy',
  f_00026: 'Merchant settlement agreement',
  f_00027: 'Disclaimer',
  srh_0001: 'Search for products/stores',
  srh_0002: 'Commodity',
  srh_0003: 'Shop',
  nav_0000: 'All transaction categories',
  nav_0001: 'Home',
  nav_0002: '51 Guarantee',
  nav_0003: 'Merchant settlement',
  nav_0004: 'Promote sharing',
  nav_0005: 'Supply and demand',
  nav_0006: 'Become a partner',
  nav_0007: 'Information',
  nav_0008: 'About us',
  i_001: 'Welcome to 51 Guarantee Network! ',
  i_002: 'Login',
  i_003: 'Register',
  i_004: 'Announcement message',
  i_005: 'TRX Exchange',
  i_006: 'Supply & Demand',
  i_007: 'Information',
  i_008: 'Top picks',
  i_009: 'New store recommendation',
  i_010: 'More',
  i_011: 'Margin:',
  i_012: '51 Secured Transaction',
  i_013: 'Conversation',
  i_014: 'Initiate',
  i_015: 'Supply',
  i_016: 'Demand',
  i_017: 'Exposure, information',
  i_018: 'Popular, exposure, information ranking',
  i_019: 'Transactions are risky',
  i_020: 'Come to 51 for guarantee',
  i_021: 'Online Transaction',
  i_022: 'Safe and worry-free',
  cnf_000: '51 Guarantee',
  cnf_001: '51 All rights reserved'
}
