<template>
  <div class="wrapper">
    <a-card class="cart-users"
            :head-style="{padding: '20px 32px', height: '79px'}"
            :bodyStyle="{padding: '20px 32px'}"
            :loading="loading">
      <template #title>
        我的收藏
      </template>
      <template #extra>
        <div class="d-flex" v-if="collectProductList && collectProductList.length > 0">
          <div class="ml-3 cu-center cursor-pointer" v-if="selected">
            <a-checkbox v-model:checked="checked" @change="allSelectItems">全选</a-checkbox>
          </div>
          <div class="ml-2 card-del-check-S cu-center cursor-pointer" v-if="selected"
               @click="delCollectionAll">
            删除
          </div>
          <div class="multiple cursor-pointer" :class="selected ? 'multiple-selected' : ''" @click="allSelect">
            <SettingOutlined v-if="!selected" />
            <span v-if="selected">{{ '退出管理'}}</span>
            <span class="ml-2" v-if="!selected">{{ '批量管理' }}</span>
          </div>
        </div>
      </template>
      <div class="mt-3">
        <empty v-if="!collectProductList || collectProductList.length === 0"/>
        <div class="container">
          <a-card class="item hover-item-up cursor-pointer"
                  :bodyStyle="{padding: '10px', height: '318.6px', width: '229.7px'}" :bordered="false"
                  @mouseenter="showFirstIndex = item.productId" @mouseleave="showFirstIndex = -1"
                  @click="!selected ? '' : itemChangeChecked(item)"
                  v-for="(item,index) in collectProductList" :key="index">
            <div class="card-image">
              <a-image :width="212" :height="212" :preview="false" :src="item.image"
                       style="border-radius: 20px"
                       @click="selected ? '' : viewProduct(item.productId)"/>
              <div class="card-del cu-center cursor-pointer" v-if="showFirstIndex === item.productId && !selected"
                   @click="delCollection(item.productId)">
                <Icon class="text-bold" type="ios-trash-outline" :size="14"/>
              </div>
              <div class="card-del-check cu-center cursor-pointer" v-if="selected">
                <a-checkbox v-model:checked="item.right" v-model:value="item.productId"
                            @change="itemChange($event,item.productId)"/>
              </div>
            </div>
            <div class="padding-5 product-name line2" @click="selected ? '' : viewProduct(item.productId)"> {{
                item.name
              }}
            </div>
            <div class="padding-5 text-red text-bold text-xl mb-3"
                 @click="selected ? '' : viewProduct(item.productId)">
              {{
                item.price
              }} USDT
            </div>
          </a-card>
        </div>
      </div>
    </a-card>
    <!-- 分页 -->
    <div class="page-size mt-3" v-if="collectProductList && collectProductList.length > 0">
      <a-pagination :page-size="limit" :total="total" show-less-items @change="changePageNum"
                    :show-size-changer="false"
                    @page-size-change="changePageSize"/>
    </div>
  </div>
</template>

<script>
import { collectCancel, getCollectUserList } from '@/api/product.js'
import { SettingOutlined } from '@ant-design/icons-vue'

export default {
  name: 'MyCoupon',
  components: { SettingOutlined },
  data() {
    return {
      selected: false,
      showFirstIndex: 0,
      footerswitch: true,
      hostProduct: [],
      loading: false,
      collectProductList: [],
      limit: 12,
      page: 1,
      hotScroll: false,
      isAllSelect: false, //全选
      selectValue: [], //选中的数据
      delBtnWidth: 80, //左滑默认宽度
      total: 0,
      checked: false,
      checked2: false,
    }
  },
  created() {
    this.get_user_collect_product()
  },
  methods: {
    viewProduct(goodsId) {
      this.$router.push({
        path: '/Product',
        query: {
          id: goodsId,
        }
      })
    },
    itemChangeChecked(item) {
      item.right = !item.right
      if (item.right) {
        if (this.selectValue && this.selectValue.length > 0) {
          this.selectValue.push(item.productId)
        } else {
          this.selectValue = []
          this.selectValue.push(item.productId)
        }
      } else {
        if (this.selectValue && this.selectValue.length > 0) {
          let array = []
          this.selectValue.forEach((items) => {
            if (item.productId !== items) {
              array.push(item.productId)
            }
          })
          this.selectValue = array
        }
      }
    },
    itemChange(e, itemId) {
      if (e.target.checked) {
        if (this.selectValue && this.selectValue.length > 0) {
          this.selectValue.push(itemId)
        } else {
          this.selectValue = []
          this.selectValue.push(itemId)
        }
      } else {
        if (this.selectValue && this.selectValue.length > 0) {
          let array = []
          this.selectValue.forEach((item) => {
            if (item !== itemId) {
              array.push(item)
            }
          })
          this.selectValue = array
        }
      }
    },
    allSelectItems(e) {
      this.selectValue = []
      if (this.selected) {
        if (e.target.checked) {
          this.collectProductList.forEach(item => {
            item.right = true
            this.selectValue.push(item.productId)
          })
        } else {
          this.collectProductList.forEach(item => {
            item.right = false
          })
        }
      }

    },
    allSelect() {
      this.selected = !this.selected
      this.collectProductList.map(item => {
        item.right = false
      })
    },
    /**
     * 取消收藏
     */
    delCollection: function (id) {
      this.selectValue = id
      this.del({
        ids: this.selectValue.toString()
      })
    },
    delCollectionAll: function () {
      if (!this.selectValue || this.selectValue.length === 0) {
        return this.$Message.error('请选择要取消的商品')
      }
      this.del({
        ids: this.selectValue.toString()
      })
    },
    del: function (data) {
      collectCancel(data)
          .then(res => {
            this.selectValue = []
            this.collectProductList = []
            this.selected = false
            this.page = 1
            this.get_user_collect_product()
          })
    },
    changePageNum(val) { // 修改页码
      this.page = val
      this.get_user_collect_product()
    },
    changePageSize(val) { // 修改页数
      this.page = 1
      this.limit = val
      this.get_user_collect_product()
    },
    get_user_collect_product() {
      let that = this
      that.loading = true
      getCollectUserList({
        page: that.page,
        limit: that.limit
      })
          .then(res => {
            res.data.list.map(item => {
              item.right = false
            })
            that.total = res.data.total
            that.collectProductList = res.data.list
            that.loading = false
          })
    },
  }
}
</script>

<style scoped lang="scss">
.container {
  display: grid;
  grid-template-columns: repeat(5, 1fr); /* 每行最多 5 个元素，均分 */
  gap: 10px; /* 每个元素之间的间距 */
}

.item {
  width: 229.7px !important;
  height: 318.6px !important;
  border-radius: 20px;
  border: 1px solid #EEEEEE;
  margin-bottom: 20px;
}

.card-del-check-S {
  width: 92px;
  height: 38px;
  background: linear-gradient(-90deg, red, #e72b5d);
  box-shadow: 0px 5px 16px 0px rgba(71, 100, 184, 0.3);
  border-radius: 19px;

  margin-left: 10px;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 16px;
  font-weight: 350;
  line-height: normal;
  color: #FFFFFF;
}

.multiple-selected {
  border-radius: 19px !important;
  background: linear-gradient(-90deg, #0080D9, #0052D9);
  box-shadow: 0px 5px 16px 0px rgba(71, 100, 184, 0.3);
  color: #FFFFFF !important;
}

.multiple {
  width: 142px;
  height: 38px;
  background: #3B64DA;
  border-radius: 19px;

  margin-left: 10px;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 16px;
  font-weight: 350;
  line-height: normal;
  letter-spacing: 0em;
  color: #FFFFFF;
}

.product-name {
  font-weight: 500;
  font-size: 16px;
  color: #000000;

  height: 56px;
}

.card-image {
  position: relative;

  .card-del-check {
    position: absolute;
    top: 5px;
    left: 15px;
    width: 28px;
    height: 28px;
    border-radius: 5px;
    opacity: 0.8;

    color: #FFFFFF;

  }

  .card-del {
    position: absolute;
    top: 5px;
    right: 15px;
    width: 28px;
    height: 28px;
    border-radius: 5px;
    opacity: 0.8;
    background: #5D5D5D;

    color: #FFFFFF;
  }
}

.wrapper {
  width: 100%;

  .cart-users {
    width: 100%;
    border-radius: 12px;
    opacity: 1;
  }
}
</style>
