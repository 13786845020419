<template>
  <div class="wrapper">
    <a-card class="cart-users"
            :head-style="{padding: '20px 32px', height: '79px'}"
            :bodyStyle="{padding: '32px 37px'}"
            :loading="loading">
      <template #title>
        我的足迹
      </template>
      <empty v-if="!list || list.length === 0"/>
      <div v-else>
        <div>共
          <sapn class="text-red">{{ total }}</sapn>
          条，最多为您保存
          <sapn class="text-red">100</sapn>
          条
        </div>
        <div class="mt-4">
          <div class="mb-5" v-for="(item,index) in list" :key="index">
            <div class="text-df text-bold me-auto mb-7 date-cus m-1">{{ item.date }}</div>
            <div class="container">
              <a-card class="item mb-3 cursor-pointer hover-item-up"
                      :bodyStyle="{padding: '10px', height: '318.6px', width: '229.7px'}"
                      v-for="(items,indexs) in item.list"
                      :key="indexs"
                      :bordered="false"
                      @click="() => { $router.push({path: '/Product' , query:{id: items.productId}})}">
                <a-image style="border-radius: 20px" :src="items.image" :preview="false" :width="212" :height="212"/>
                <div class="padding-5 product-name line2" >
                  {{ items.name }}
                </div>
                <div class="padding-5 text-red text-bold text-xl mb-3">
                  {{ items.price }} USDT
                </div>
              </a-card>
            </div>
          </div>
        </div>
      </div>
    </a-card>
  </div>
</template>

<script>
import { browseRecordApi, } from '@/api/user.js'

export default {
  name: 'MyCoupon',
  data() {
    return {
      selected: false,
      total: 0,
      loading: false,
      list: [],
      allArr: [],
    }
  },
  created() {
    this.getList()
  },
  methods: {
    getList() {
      this.list = []
      this.loading = true
      browseRecordApi()
          .then(({
            data
          }) => {
            this.list = data
            data.map(item => {
              this.total += item.list.length
              item.date = item.date.slice(5, 7) + '月' + item.date.slice(8, 10) + '日'
            })
            this.loading = false
          })
    },
    changePageNum(val) { // 修改页码
      this.page = val
      this.getList()
    },
    changePageSize(val) { // 修改页数
      this.page = 1
      this.limit = val
      this.getList()
    },
  }
}
</script>

<style scoped lang="scss">
.product-name {
  font-weight: 500;
  font-size: 16px;
  color: #000000;

  height: 56px;
}

.container {
  display: grid;
  grid-template-columns: repeat(5, 1fr); /* 每行最多 5 个元素，均分 */
  gap: 10px; /* 每个元素之间的间距 */
}

.item {
  width: 229.7px !important;
  height: 318.6px !important;
  border-radius: 20px;
  border: 1px solid #EEEEEE;
  margin-bottom: 20px;
}

:deep(.ant-card-bordered) {
  border: none !important;
}

.date-cus {
  width: 100%;
  height: 42px;
  background: #F3F5FA;
  border-radius: 10px;
  padding-left: 22px;
  display: flex;
  justify-content: start;
  align-items: center;
}

.card-del {
  position: absolute;
  top: 5px;
  right: 35px;
  width: 28px;
  height: 28px;
  border-radius: 5px;
  opacity: 0.8;
  background: #5D5D5D;

  color: #FFFFFF;
}

.wrapper {
  width: 100%;

  .cart-users {
    width: 100%;
    border-radius: 12px;
    opacity: 1;
  }
}
</style>
