<template>
  <div class="container width_1200_auto">
    <a-breadcrumb separator=">">
      <a-breadcrumb-item>
        <router-link to="/">
          首页
        </router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item>
        <router-link to="/Enter">
          商家入驻
        </router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item>
        审核通过
      </a-breadcrumb-item>
    </a-breadcrumb>
    <div class="margin-top-sm">
      <a-card :bordered="false" :body-style="{width: '100%'}">
        <div class="content margin-top-xl">
          <a-image :src="require('@/assets/join/join-suc.png')" :preview="false" :width="192" :height="142"/>
          <div class="result-info">
            恭喜,您的申请已通过！
          </div>

          <div class="content-info">
            <a-form>
              <a-form-item label="登录地址">
                <text class="form-info">{{ mer_url }}</text>
              </a-form-item>
              <a-form-item label="商户账号">
                <text class="form-info">{{ $route.query.account }}</text>
              </a-form-item>
              <a-form-item label="密&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;码">
                <div class="form-info flex justify-between align-end" style="width: 100%">
                  <div>000000</div>
                  <div class="copy cursor-pointer" @click="copy">复制</div>
                </div>
              </a-form-item>
            </a-form>
          </div>
          <div class="form-info-warning">温馨提示:初始密码默认为000000，请初次登录后及时修改！</div>
        </div>
      </a-card>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      mer_url: 'https://adminmer.51danbao.com'
    }
  },
  methods: {
    copy() {
      let shareText = '登录地址: ' + this.mer_url + '      ' + '商户账号: ' + this.$route.query.account + '      ' + '密码: 000000'
      this.$Copy({ text: shareText })
    }
  }
}
</script>

<style scoped lang="scss">
.ant-form-item {
  margin-bottom: 6px !important;
}

.form-info-warning {
  width: 810px;
  margin-top: 20px;
  font-size: 14px;
  font-weight: normal;
  line-height: 25px;
  text-align: left;
  letter-spacing: 0px;
  color: #7E7E7E;

  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.copy {
  width: 85px;
  height: 30px;
  border-radius: 50px;
  opacity: 1;
  box-sizing: border-box;
  border: 1px solid #C3C3C3;

  font-size: 13px;
  font-weight: normal;
  line-height: 26px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .content-info {
    padding: 20px;
    margin-top: 62px;
    width: 810px;
    border-radius: 10px;
    box-sizing: border-box;
    border: 1px solid #EBEBEB;

    .form-info {
      font-size: 14px;
      font-weight: normal;
      line-height: 26px;
      letter-spacing: 0em;
      color: #999999;
    }
  }
}

.result-info {
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0em;
  color: #4AA349;

  margin-top: 28px;
}

.container {
  margin: 0 auto;
  padding: 40px 0;
  border-radius: 12px;
  min-height: 1600px;

  .card-cu {
    height: 475px;
  }

  .title {
    font-size: 28px;
    font-weight: bold;
    line-height: 25px;
  }

  .content-font-1 {
    font-size: 16px;
    font-weight: normal;
    line-height: 25px;
    text-align: center;
  }

  .content-font-2 {
    font-size: 16px;
    font-weight: normal;
    line-height: 25px;
    text-align: center;
    color: #999999;

    .conten-customer {
      color: #269FFA;
    }
  }

  .content-btn {
    width: 115px;
    height: 38px;
    border-radius: 8px;
    background: #269FFA;

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 16px;
    font-weight: 500;
    line-height: 25px;
    color: #FFFFFF;
  }
}
</style>
