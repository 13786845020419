<template>
  <div class="wrapper">
    <div class="position-relative">
      <a-image :preview="false" :src="require('@/assets/new/help/supInfo.png')" :width="1920" :height="200"/>
      <div class="position-absolute " style="top: 90px;left: 200px;">
        <div class="top-font-help">
          {{ mainMenus.title }}
          <span class="help-subTitle">
            {{ mainMenus.subTitle }}
          </span>
        </div>
      </div>
    </div>

    <div class="width_1200_auto mt-7 mb-7">
      <a-card :body-style="{padding: '10px'}" :title="`文章列表(${total})`">
        <div class="cursor-pointer" v-for="(item, index) in issueList" :key="index" @click="toDetail(item)">
          <div class="flex align-center justify-start" style="padding: 20px 38px">
            <div class="point"></div>
            <div class="content ml-3">
              {{ item.title }}
            </div>
          </div>
          <div class="border-bottom-cus"></div>
        </div>
      </a-card>
    </div>
  </div>
</template>

<script>
import { getArticleList } from '@/api/api'
import * as exData from '../data/index'

export default {
  name: 'Help',
  data() {
    return {
      loading: false,
      issueList: [],
      pageData: {
        page: 1,
        limit: 100
      },
      menusMain: exData.menus,
      mainMenus: {},
      cid: 0,
      total: 0,
    }
  },
  created() {
    this.cid = this.$route.query.cid
    this.mainMenus = this.menusMain.find(item => item.cid.toString() === this.cid)
    this.queryArticleList()
  },
  methods: {
    toDetail(item) {
      this.$router.push({path : '/SupportDetail', query: {cid: item.cid, id: item.id}})
    },
    queryArticleList() {
      getArticleList(this.cid, this.pageData)
          .then((res) => {
            this.issueList = res.data.list
            this.total = res.data.total
          })
    },
  }
}
</script>

<style scoped lang="scss">
.border-bottom-cus {
  width: 100%;
  height: 2px;
  border-bottom: 1px #EEEEEE solid;
}

.content {
  font-weight: 400;
  font-size: 16px;
  color: #000000;
  line-height: 46px;
}

.point {
  width: 10px;
  height: 10px;
  background: #E1E3EA;
  border-radius: 50%;
}

.top-font-help {
  font-weight: bold;
  font-size: 48px;
  color: #FFFFFF;
  line-height: 20px;

  .help-subTitle {
    font-size: 16px;
    color: #EEEEEE;
    font-weight: 300 !important;
  }
}

.wrapper {
  font-weight: 400;
  font-size: 18px;
  color: #FFFFFF;
  line-height: 31px;
  margin-bottom: 25px;
  min-height: 1200px;
}
</style>
