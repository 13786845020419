<template>
  <div id="toolbar">
    <div>咨询订单/商品历史(按发送时间倒序)</div>
    <div class="cu-chat">
      <div class="main" v-for="(item, index) in order" style="padding: 10px">
        <div class="content bg-white" style="padding: 10px;position: relative" v-if="item.linkId">
          <div v-if="item.orderNo" class="cu-tag badge bg-green text-black">订单</div>
          <div v-else class="cu-tag badge text-black" style="background-color: yellow">商品</div>
          <div class="cu-card article">
            <div class="cu-item-cus">
              <div class="title text-cut">
                <div v-if="item.orderNo">
                  <span class="text-bold text-sm" :style="{color: getColor(item.orderStatus)}">
                    ({{ getOrderStatus(item.orderStatus) }})
                  </span>
                  订单号: {{ item.orderNo }}
                </div>
                <div v-else>
                  商品名称: {{ item.title }}
                </div>
              </div>
              <div style="margin-top: 5px;width: 300px;display: flex;">
                <el-image :showLoading="true" :src="item.icon" style="width: 80px;height: 80px" mode="aspectFill"
                          :preview-src-list="Array.of(item.icon)">>
                </el-image>
                <div class="desc flex-col">
                  <div class="text-red text-lg" style="margin-right: 10px;padding-left: 5px">
                    <span class="text-sm"> {{ item.orderTime ? '原订单金额:' : '商品金额:' }}</span>
                    {{ item.price }}
                    <span class="text-sm margin-left-xs">USDT</span>
                  </div>
                  <div class="text-red text-lg" style="margin-right: 10px;padding-left: 5px">
                    <span class="text-sm"> {{ item.orderTime ? '现订单金额:' : '现商品金额:' }}</span>
                    {{ item.orderPrice }}
                    <span class="text-sm margin-left-xs">USDT</span>
                  </div>
                  <div v-if="item.orderTime" style="margin-right: 10px;padding-left: 5px">
                    下单时间: {{ item.orderTime }}
                  </div>
                  <div v-else style="margin-right: 10px;padding-left: 5px">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style="margin-top: 10px;">咨询时间: {{ item.msgTime }}</div>
          <div style="margin-top: 10px;display: flex;justify-content: space-between;align-items: center">
            <div v-if="item.orderStatus === 0 || !item.orderNo">
              <div>金额:
                <el-input-number
                    v-model="item.orderPrice"
                    @change="handleChange"
                    :min="0" :precision="2" :step="1">
                </el-input-number>
              </div>
              <div v-if="!item.orderNo" style="margin-top: 3px">
                数量:
                <el-input-number
                    v-model="item.payNum"
                    @change="handleChangePayNum"
                    :min="1" :step="1" :max="99">
                </el-input-number>
              </div>
              <div style="text-align: right; margin-top: 3px">
                <el-button type="primary" size="small" plain @click="updateOrder(item)">确认更改</el-button>
              </div>
            </div>
            <el-button type="primary" size="small" @click="delOrder(item)">结束服务</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { delCusOrder, msglistByBack2Day, updateCusOrder } from '@/api/message'

export default {
  name: 'toolbar',
  data() {
    return {
      order: [],
      orderPrice: null,
      payNum: 1
    }
  },
  props: {
    selectItem: {
      type: Object,
      default: 0
    },
    refreshIndex: {
      type: Number,
      default: -1
    }
  },
  watch: {
    refreshIndex: {
      handler(val) {
        if (val > -1) {
          this.queryMsglistByBack2Day()
        }
      }
    }
  },
  methods: {
    updateOrder(item) {
      if (!this.orderPrice) {
        this.orderPrice = item.orderPrice
      }
      if (this.orderPrice <= 0) {
        this.$message.error('更改金额只能大于0')
        return
      }
      if (!this.payNum) {
        this.payNum = item.payNum
      }
      if (!item.orderNo && this.payNum <= 0) {
        this.$message.error('购买数量必须大于0')
        return
      }
      const title = !item.orderNo ? '商品价格修改为:' + this.orderPrice + ' USDT 购买数量为:' + this.payNum + ', 确认? ' : '订单号:' + item.orderNo + ', 订单价格修改为:' + this.orderPrice + ' USDT, 确认? '
      this.$confirm(title, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
          .then(() => {
            console.log(this.orderPrice)
            let data = {
              msgId: item.id,
              merId: item.merId,
              uid: item.uid,
              payNum: this.payNum,
              orderPrice: this.orderPrice,
              headImg: item.icon
            }
            updateCusOrder(data)
                .then(res => {
                  this.queryMsglistByBack2Day()
                  this.$emit('refresh')
                })
          })
          .catch((action) => {
            this.$message({
              type: 'info',
              message: '取消修改'
            })
          })
    },
    getOrderStatus(status) {
      const statusMap = {
        0: '待支付',
        1: '待发货',
        4: '待收货',
        5: '已收货',
        6: '已完成',
        9: '已取消'
      }
      return statusMap[status]
    },
    getColor(status) {
      const statusMap = {
        0: '#269FFA',
        1: '#269FFA',
        4: '#FFAB1B',
        5: '#FFAB1B',
        6: 'green',
        9: '#999999'
      }
      return statusMap[status]
    },
    handleChange(price) {
      this.orderPrice = price
    },
    handleChangePayNum(payNum) {
      this.payNum = payNum
    },
    delOrder(item) {
      let dataParam = {
        msgId: item.id
      }
      delCusOrder(dataParam)
          .then(res => {
            this.queryMsglistByBack2Day()
          })
    },
    queryMsglistByBack2Day() {
      if (!this.selectItem.uid) {
        return
      }
      let dataParam = {
        uid: this.selectItem.uid
      }
      msglistByBack2Day(dataParam)
          .then(res => {
            this.order = res
            if (this.order && this.order.length > 0) {
              this.order.forEach(item => {
                item.payNum = item.payNum ? item.payNum : 1
              })
            }
            this.payNum = 0
            this.orderPrice = 0
          })
    }
  }
}
</script>

<style lang="scss" scoped>
.cu-tag {
  font-size: 12px;
  vertical-align: middle;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 0px 8px;
  height: 24px;
  font-family: Helvetica Neue, Helvetica, sans-serif;
  white-space: nowrap;
}

.cu-tag.badge {
  border-radius: 100px;
  position: absolute;
  top: -5px;
  right: -5px;
  font-size: 10px;
  padding: 0px 5px;
  height: 14px;
}

#toolbar {
  width: 100%;
  height: 100%;
  padding: 10px;
  max-height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}

.flex-col {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.bg-green {
  background-color: #39b54a;
  color: #ffffff;
}

.bg-white {
  background-color: #ffffff;
  color: #666666;
}

.el-image {
  width: 260px;
  height: 160px;
}

.cu-chat {
  display: flex;
  flex-direction: column;
}

.cu-chat .cu-item {
  display: flex;
  //padding: 10px 10px 25.5px;
  position: relative;
  border-radius: 10px;
}

.cu-chat .cu-item > .cu-avatar {
  width: 40px;
  height: 40px;
}

.cu-chat .cu-item > .main {
  max-width: calc(100% - 80px);
  margin: 0 20px;
  display: flex;
  align-items: center;
  word-break: break-all;
  word-wrap: break-word;
  border-radius: 10px;
}

.cu-chat .cu-item > image {
  height: 160px;
}

.cu-chat .cu-item > .main .content {
  padding: 10px;
  display: inline-flex;
  max-width: 100%;
  align-items: center;
  font-size: 15px;
  position: relative;
  min-height: 40px;
  line-height: 20px;
  text-align: left;
  word-break: break-all;
  word-wrap: break-word;
  border-radius: 10px;
}

.cu-chat .cu-item > .main .content:not([class*="bg-"]) {
  background-color: #ffffff;
  color: #333333;
  border-radius: 10px;
}

.cu-chat .cu-item .date {
  position: absolute;
  font-size: 12px;
  color: #8799a3;
  width: calc(100% - 160px);
  bottom: -15px;
  left: 95px;
}

.cu-chat .cu-item .action {
  padding: 0 15px;
  display: flex;
  align-items: center;
}

.cu-chat .cu-item > .main .content::after {
  content: "";
  top: 13.5px;
  transform: rotate(45deg);
  position: absolute;
  z-index: 100;
  display: inline-block;
  overflow: hidden;
  width: 12px;
  height: 12px;
  left: -6px;
  right: initial;
  background-color: inherit;
}

.cu-chat .cu-item.self > .main .content::after {
  left: auto;
  right: -6px;
}

.cu-chat .cu-item > .main .content::before {
  content: "";
  top: 15px;
  transform: rotate(45deg);
  position: absolute;
  z-index: -1;
  display: inline-block;
  overflow: hidden;
  width: 12px;
  height: 12px;
  left: -12px;
  right: initial;
  background-color: inherit;
  filter: blur(2.5px);
  opacity: 0.3;
}

.cu-chat .cu-item > .main .content:not([class*="bg-"])::before {
  background-color: #333333;
  opacity: 0.1;
}

.cu-chat .cu-item.self > .main .content::before {
  left: auto;
  right: -6px;
}

.cu-chat .cu-item.self {
  justify-content: flex-end;
  text-align: right;
}

.cu-chat .cu-info {
  margin: 10px auto;
  font-size: 12px;
  padding: 4px 6px;
  //background-color: rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  color: rgba(0, 0, 0, 0.2);
  max-width: 200px;
  line-height: 1.4;
}

#message {
  padding: 15px;
  height: 62%;
  max-height: 62%;
  overflow-y: scroll;
  overflow-x: hidden;
}


.text-xs {
  font-size: 10px;
}

.text-sm {
  font-size: 12px;
}

.text-df {
  font-size: 14px;
}

.text-lg {
  font-size: 16px;
}

.text-xl {
  font-size: 18px;
}

.text-Abc {
  text-transform: Capitalize;
}

.text-ABC {
  text-transform: Uppercase;
}

.text-abc {
  text-transform: Lowercase;
}

.text-price::before {
  content: "¥";
  font-size: 80%;
  margin-right: 4upx;
}

.text-cut {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.text-bold {
  font-weight: bold;
}

.text-center {
  text-align: center;
}

.text-content {
  line-height: 1.6;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-red,
.line-red,
.lines-red {
  color: #e54d42;
}

.text-orange,
.line-orange,
.lines-orange {
  color: #f37b1d;
}

.text-yellow,
.line-yellow,
.lines-yellow {
  color: #fbbd08;
}

.text-olive,
.line-olive,
.lines-olive {
  color: #8dc63f;
}

.text-green,
.line-green,
.lines-green {
  color: #39b54a;
}

.text-cyan,
.line-cyan,
.lines-cyan {
  color: #1cbbb4;
}

.text-blue,
.line-blue,
.lines-blue {
  color: #0081ff;
}

.text-purple,
.line-purple,
.lines-purple {
  color: #6739b6;
}

.text-mauve,
.line-mauve,
.lines-mauve {
  color: #9c26b0;
}

.text-pink,
.line-pink,
.lines-pink {
  color: #e03997;
}

.text-brown,
.line-brown,
.lines-brown {
  color: #a5673f;
}

.text-grey,
.line-grey,
.lines-grey {
  color: #8799a3;
}

.text-gray,
.line-gray,
.lines-gray {
  color: #aaaaaa;
}

.text-black,
.line-black,
.lines-black {
  color: #333333;
}

.text-white,
.line-white,
.lines-white {
  color: #ffffff;
}
</style>
