<template>
  <div class="cate-nav width_1200_auto" :class="{'fixed-show':useClass === 'fixed-show'}">
    <!-- 全部商品分类 -->
    <div class="cate-list"
         v-show="showTab"
         @mouseenter="showFirstList = true;isTouch = true"
         @mouseleave="cateListDis">
      <!-- 第一级分类 -->
      <arco-scrollbar style="background-color: #FFFFFF;height: 480px;width:230px;overflow: auto !important;">
        <div class="nav-side" :class="{ 'large-nav': large, 'opacity-nav': opacity }" @mouseleave="panelDisable">
          <ul class="">
            <li v-for="(item, index) in cateList"
                :key="index"
                class="cursor-pointer"
                @click="goGoodsList(item)"
                @mouseenter="showDetail(index)"
                @mouseleave="hoverIndex = null">
              <div class="nav-side-item" @click="goGoodsList(item)">
                <div style="width: 120px;">
                  <a-image v-if="hoverIndex === index" :preview="false"
                           :src="require(`@/assets/new/home/menus/${hoverIndex+'_0'}.png`)"></a-image>
                  <a-image v-if="hoverIndex !== index" :preview="false"
                           :src="require(`@/assets/new/home/menus/${index}.png`)"></a-image>
                  <span class="margin-left-smm text-lg"> {{ item.name }} </span>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </arco-scrollbar>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GoodsListNav',
  props: {
    showAlways: {
      // 总是显示下拉分类
      default: false,
      type: Boolean,
    },
    showNavBar: {
      // 显示全部商品分类右侧导航条
      default: true,
      type: Boolean,
    },
    hover: {
      default: false,
      type: Boolean,
    },
    large: {
      // 是否更高的高度
      default: false,
      type: Boolean,
    },
    opacity: {
      // 是否背景透明
      default: false,
      type: Boolean,
    },
    useClass: {
      type: null,
      default: ''
    }
  },
  data() {
    return {
      hoverIndex: null,
      showTab: false,
      panel: false, // 二级分类展示
      panelData: [], // 二级分类数据
      showFirstList: false, // 始终展示一级列表
      isTouch: false,
      cateList: [], // 商品分类
      navList: [{
        name: this.$t('nav_0001'),
        url: '/'
      }, {
        name: this.$t('nav_0002'),
        url: '/Guarantee'
      }, {
        name: this.$t('nav_0003'),
        url: '/Enter'
      }, {
        name: this.$t('nav_0004'),
        url: '/Share'
      }, {
        name: this.$t('nav_0005'),
        url: '/Supply'
      },
        {
          name: this.$t('nav_0006'),
          url: '/Partner'
        },
        {
          name: this.$t('nav_0007'),
          url: '/Infor'
        }
      ],
      checked: {},
      urlSwitchName: ['enter', 'IndexPage', 'Share'], // 不显示交易类型下拉框
      urlSwitchPath: ['/enter', '/', '/Share'], // 不显示交易类型下拉框
    }
  },
  watch: {
    '$route.path': {
      handler(newVal, oldVal) {
        this.$nextTick(() => {
          if (!this.urlSwitchPath.includes(newVal)) {
            this.showTab = false
          } else {
            this.showTab = true
          }
        })
      }
    },
    '$route.name': {
      handler(newVal, oldVal) {
        this.$nextTick(() => {
          if (!this.urlSwitchName.includes(newVal)) {
            this.showTab = false
          } else {
            this.showTab = true
          }
        })
      }
    }
  },
  mounted() {
    this.$store.dispatch('MerCategoryList')
    this.$store.dispatch('MerTypeList')
    setTimeout(() => {
      this.getCate()
    }, 500)
    this.$nextTick(() => {
      if (!this.urlSwitchPath.includes(this.$route.path)) {
        this.showTab = false
      } else {
        this.showTab = true
      }
    })
  },
  methods: {
    cateListDis() {
      if (!this.urlSwitchPath.includes(this.$route.path)) {
        this.showFirstList = false
        this.showTab = false
        this.isTouch = false
      }
      this.panel = false
    },
    panelDisable() {
      if (!this.urlSwitchPath.includes(this.$route.path)) {
        this.panel = false
      }
    },
    showDetail(index) {
      // 展示全部分类
      this.panel = true
      this.hoverIndex = index
      this.checked = this.cateList[index]
      this.panelData = this.checked.childList
    },
    goGoodsList(item) {
      if (!this.urlSwitchName.includes(this.$route.name)) {
        this.showTab = false
      } else {
        this.showTab = true
      }
      let categoryId = this.checked.id
      let cid = item.id
      if (categoryId === cid) {
        cid = null
      }
      let name = item.name
      this.$router.push({
        path: '/GoodsList',
        query: {
          categoryId: categoryId,
          cid: cid,
          name: name,
          selectFlag: '0'
        },
      })
    },
    getCate() {
      // 获取分类数据
      if (this.hover) return false
      this.cateList = JSON.parse(localStorage.getItem('merchantClassify'))
    },
  },
}
</script>

<style scoped lang="scss">
.line-d {
  height: 3px;
  border-bottom: 1px solid #ccc;
}

.yaohuang {
  animation: fadeEffect 3s infinite;
}

.yaohuang-tu {
  animation: zy 2.5s .15s linear infinite;
}

/* 定义淡入淡出动画 */
@keyframes fadeEffect {
  0%, 100% {
    height: 46px;
    opacity: 0.5; /* 完全透明 */
  }
  50% {
    height: 26px;
    opacity: 1; /* 完全不透明 */
  }
}

@-webkit-keyframes zy {
  10% {
    transform: rotate(15deg);
  }
  20% {
    transform: rotate(-10deg);
  }
  30% {
    transform: rotate(5deg);
  }
  40% {
    transform: rotate(-5deg);
  }
  50%, 100% {
    transform: rotate(0deg);
  }
}

.top-btn-font {
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0em;
}

.background-white {
  background: #fff;
}

.nav-lis:hover {
  color: #269FFA !important;
  cursor: pointer;
}

.nav-lis {
  text-align: center;
  position: relative;
}

.fixed-show {
  margin-top: 0 !important;

  > .nav-con {
    > .all-categories {
      align-items: center !important;
      height: 60px;
      display: inherit;
      justify-content: center;
      padding: 0 !important;
    }
  }
}

.colum {
  height: 14.7px;
  opacity: 1;
  border: 0.7px solid #CBC8C8;
  position: absolute;
  right: 0;
}

.cate-nav {
  position: relative;
}

/** 商品分类 */
.nav-con {
  height: 42px;
  margin: 0 auto;
  display: flex;

  .all-categories-fixed {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 226px;
    font-size: 14px;
    font-weight: bold;
    line-height: normal;
    letter-spacing: 0em;
    background: #269FFA;
    color: #FFFFFF;
  }

  .all-categories {
    display: flex;
    padding-left: 20px;
    justify-content: flex-start;
    align-items: center;

    background: #269FFA;
    width: 226px;
    font-size: 14px;
    font-weight: bold;
    line-height: normal;
    letter-spacing: 0em;
    color: #FFFFFF;
  }

  .nav-item {
    height: 42px;
    line-height: 42px;
    overflow: hidden;
    list-style: none;
    // background-color: #eee;
    display: flex;
    flex: 1;

    li {
      font-size: 17px;
      font-weight: normal;
    }
  }
}

// 分类列表
.cate-list {
  width: 230px;
  margin: 0 auto;
  position: absolute;
  z-index: 1000;
}

.nav-side {
  width: 230px;
  opacity: 0.8;
}

.large-nav {
  ul > li {
    line-height: 20px;
  }
}

.opacity-nav {
  //background-color: rgba(0, 0, 0, 0.5);
}

.nav-side ul {
  width: 100%;
  list-style: none;
}

.nav-side li {
  font-size: 16px;
  font-weight: bold;
  line-height: 41px;
  margin-top: 10px;
  margin-left: 20px;
  margin-right: 20px;
}

.nav-side li:first-child {
  margin-top: 20px;
}

.nav-side-item {
  border-radius: 8px;
  padding-left: 10px;
}

.nav-side-item:hover {
  cursor: pointer;
  color: #FFFFFF;
  background-color: #0052D9;
}

/*显示商品详细信息*/
.detail-item-panel {
  width: 550px;
  min-height: 418px;
  position: absolute;
  /* 阴影/常规阴影 */
  top: 2px;
  opacity: 0.9;
  left: 226px;
  z-index: 1000;
  padding: 15px;

  border-right: 1px solid #269FFA !important;
  border-top: 1px solid #269FFA !important;
  border-bottom: 1px solid #269FFA !important;
}

.nav-detail-item {
  margin-top: 5px;
  margin-bottom: 15px;
  cursor: pointer;
}

.nav-detail-item span {
  padding: 6px;
  padding-left: 12px;
  margin-right: 15px;
  font-size: 12px;
  color: #333;
}

.nav-detail-item div:hover {
}

.detail-item-panel li {
  line-height: 30px;
}

.detail-item-title {
  font-weight: bold;
  font-size: 12px;
  cursor: pointer;
  color: #555555;
  padding-right: 10px;
  width: 96px;
  text-align: right;
}

.detail-item-title:hover {
  color: #269FFA;
}

.detail-item-row {
  display: flex;

  > div {
    flex: 1;
  }
}

.detail-item {
  font-size: 13px;
  padding-left: 8px;
  padding-right: 8px;
  cursor: pointer;

  &:first-child {
    border: none;
    padding-left: 0;
  }
}

.detail-item:hover {
  color: #269FFA;
}
</style>
