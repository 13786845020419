<template>
  <div class="wrapper">
    <div class="position-relative">
      <a-image :preview="false" :src="require('@/assets/new/help/banner.png')" :width="1920" :height="319"></a-image>
      <div class="position-absolute " style="top: 57px;left: 0;width: 100%;height: 100%;">
        <div class="cu-center flex-direction">
          <div class="top-font-help">
            帮助中心
          </div>
          <div class="cus-all cu-center">
            <a-image class="mb-3" :preview="false" :src="require('@/assets/new/help/cus.png')" :width="20"
                     :height="22"></a-image>
            <span class="ml-1 cursor-pointer" @click="toTel('dbw51')">24小时在线客服</span>
          </div>
          <div class="mt-10" style="margin-left: 555px;margin-right: 555px;">
            <a-row :gutter="[16, 24]">
              <a-col :span="12">
                <a-image :preview="false" :src="require('@/assets/new/help/menu-1.png')" :width="22"
                         :height="23"></a-image>
                <span class="cus-mnue-s cursor-pointer" @click="toTel('dbw51')">51担保网24小时在线客服：@dbw51</span>
              </a-col>
              <a-col :span="12">
                <a-image :preview="false" :src="require('@/assets/new/help/menu-1.png')" :width="22"
                         :height="23"></a-image>
                <span class="cus-mnue-s cursor-pointer" @click="toTel('dbw51sq')">51担保网官方频道：@dbw51sq</span>
              </a-col>
              <a-col :span="12">
                <a-image :preview="false" :src="require('@/assets/new/help/menu-1.png')" :width="22"
                         :height="23"></a-image>
                <span class="cus-mnue-s cursor-pointer" @click="toTel('dbw51zc')"> 51担保网纠纷仲裁：@dbw51zc</span>
              </a-col>
              <a-col :span="12">
                <a-image :preview="false" :src="require('@/assets/new/help/menu-1.png')" :width="22"
                         :height="23"></a-image>
                <span class="cus-mnue-s cursor-pointer" @click="toTel('dbw51gx')">51担保网资源交流群：@dbw51gx</span>
              </a-col>
            </a-row>
          </div>
        </div>
      </div>
    </div>

    <div class="width_1200_auto mt-7 mb-7">
      <a-row :gutter="[30, 24]">
        <a-col :span="8" v-for="(item, index) in supportIndex" :key="index">
          <a-card :bordered="false" :body-style="{padding: '14px', height: '400px'}"
                  class="cursor-pointer cu-center hover-item-up"
                  @click="toSupportInfo(item)">
            <div class="position-relative">
              <a-image :preview="false" :width="485" :height="280" :src="support[index].img"/>
              <div class="position-absolute" style="top: 14px; left: 0;">
                <div class="item-cus-font">
                  <span class="fount-info" v-if="supportIndex.length > 0">{{ item.itemId }} 篇文章</span>
                </div>
              </div>
            </div>
            <div class="text-lg text-bold mt-5 text-center">{{ support[index].title }}</div>
            <div class="text-df text-gray mt-4 text-center">{{ support[index].subTitle }}</div>
          </a-card>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
import { queryNormalIssue } from '@/api/tg'
import enterData from './data/index'
import { getArticleCountList } from '@/api/api'

export default {
  name: 'Help',
  data() {
    return {
      activeKey: '',
      loading: false,
      issueList: [],
      support: enterData.menus,
      supportIndex: [],
      beginCid: 9,
      endCid: 19,
    }
  },
  created() {
    this.getIssueList()
    this.queryArticleList()
  },
  methods: {
    toSupportInfo(item) {
      this.$router.push({
        path: `/SupportInfo`,
        query: { cid: item.id }
      })
    },
    queryArticleList() {
      getArticleCountList(this.beginCid, this.endCid)
          .then((res) => {
            this.supportIndex = res.data
          })
    },
    toTel(telName) {
      let url = `https://t.me/${telName}`
      window.open(url, '_blank')
    },
    getIssueList(type) {
      let data = {
        page: 1,
        limit: 100
      }
      queryNormalIssue(data)
          .then(res => {
            this.issueList = res.data.list
          })
    },
  }
}
</script>

<style scoped lang="scss">
.item-cus-font {
  padding-right: 30px;
  padding-left: 10px;
  height: 28px;
  background-color: #8E8E97;
  opacity: 0.6;

  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;

  .fount-info {
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
    line-height: 20px;
  }
}

.cus-mnue-s {
  margin-top: 54px;
  width: 281px;
  height: 17px;
  font-weight: 400;
  font-size: 18px;
  color: #FFFFFF;
  line-height: 20px;
}

.top-font-help {
  height: 46px;
  font-weight: bold;
  font-size: 48px;
  color: #FFFFFF;
  line-height: 20px;
}

.cus-all {
  margin-top: 10px;
  width: 189px;
  height: 38px;
  background: #3B64DA;
  border-radius: 19px;

  font-weight: 400;
  font-size: 18px;
  color: #FFFFFF;
  line-height: 31px;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 20px 0;
}

.wrapper {
  width: 100%;

  .cart-users {
    width: 100%;
    border-radius: 12px;
    opacity: 1;
  }
}
</style>
