<template>
  <div>
    <a-card :body-style="{padding: '36px 20px 37px 40px', height: showJoin ? '180px': '228px'}">
      <div class="flex" :class="showJoin ? 'align-center' : ''">
        <a-avatar :size="110" :src="merchantInfo.avatar"
                  style="border-radius: 50%;border: 1px solid #E8E8E8;"
                  class="cursor-pointer"
                  :preview="false"
                  @click="() => {$router.push({ path: '/MerInfo',query:{merId:merchantInfo.merId} })}">
        </a-avatar>
        <div class="ml-8 cu-center-items-col">
          <div class="flex justify-between align-center cursor-pointer" style="width: 1390px">
            <div class="merName" @click="() => {$router.push({ path: '/MerInfo',query:{merId:merchantInfo.merId} })}">
              {{ merchantInfo.name }}
            </div>
            <div class="d-flex" style="margin-left:10px">
              <div v-if="showJoin" class="btn-1 mr-2 cursor-pointer" @click="() => {$router.push({ path: '/MerHome',query:{ merId:merchantInfo.merId } })}">
                <a-image class="mb-2" :preview="false" :width="18" :height="16" :src="require('@/assets/new/product/store.png')" />
                <span class="ml-1">进店</span>
              </div>
              <div class="btn-1 cursor-pointer" @click="followToggle(isCollect)">
                {{ isCollect ? '已关注' : '+ 关注店铺' }}
              </div>
              <a-popover placement="bottomLeft" trigger="click" style="width: 440px;">
                <template #title>
                  <div style="border-bottom: 1px solid #e8e8e8;height: 26px;">将链接分享至Telegram</div>
                </template>
                <template #content>
                  <div class="mt-6">
                    <div class="text-gray tg-url-share">
                      {{ tagUrl }}
                      <div class="copy-btn" @click="$Copy({text: tagUrl})">复制</div>
                    </div>
                    <div class="telegram-btn" @click="toTelegram">
                      将链接分享至Telegram
                    </div>
                  </div>
                </template>
                <div class="btn-2 cursor-pointer">
                  <a-image class="mb-1" :preview="false" :src="require('@/assets/new/product/pro.png')" :width="20"
                           :height="21"/>
                  <span class="ml-1">分享店铺</span>
                </div>
              </a-popover>
              <div class="btn-3 cursor-pointer" @click="customerOpen">
                <a-image class="mb-1" :preview="false" :src="require('@/assets/new/product/cus.png')" :width="18"
                         :height="18"/>
                <span class="ml-1">联系商家</span>
              </div>
              <div class="btn-4 cursor-pointer ml-2" @click="customerDb">
                <a-image class="mb-1" :preview="false" :src="require('@/assets/new/product/dianbao.png')" :width="20"
                         :height="17"/>
                <span class="ml-1">商家电报</span>
              </div>
            </div>
          </div>
          <div style="width: 1390px" @click="() => {$router.push({ path: '/MerInfo',query:{ merId:merchantInfo.merId } })}">
            <div class="danbao-name cursor-pointer">
              <a-image class="mb-1" :src="require('@/assets/new/product/danbao.png')" :width="20" :height="19"/>
              <span class="star ml-2">担保金 :</span>
              <span class="gold-font">  {{ merchantInfo.earnestMoney }} </span>
              <span class="usdt">USDT</span>
            </div>
          </div>
          <div class="mt-5 intro-cus" v-if="!showJoin">
            <span v-if="store.intro && !showJoin" class="text-gray text-df">{{ store.intro }}</span>
          </div>
        </div>
      </div>
    </a-card>
    <!-- 遮罩层 -->
    <div v-if="open" class="fullscreen-overlay">
      <div class="close cursor-pointer" @click="open = false">
        <Icon class="close" type="md-close" color="#FFFFFF" :size="25"/>
      </div>
      <div class="overlay-content">
        <a-card :bodyStyle="{padding: '10px', textAlign: 'center'}" class="cu-center">
          <div id="posterHtml" class="posterBox">
            <div class="qrcodeBoxBanner" :style="{backgroundImage: 'url(' + merchantInfo.avatar + ')' }"></div>
            <div class="text-lg text-bold mt-3">{{ merchantInfo.name }}</div>
            <div class="line3 margin-top-xs">{{ store.intro }}</div>
            <div class="line-ge margin-top-lg">
            </div>
            <div class="infoBox">
              <div class="text-bold text-left home-name">51担保网</div>
              <div class="text-black text-left mt-3 text-lg">www.51danbao.com</div>
              <div class="text-black mt-3">
                信任始于保障 安全源于担保
              </div>
            </div>
            <div class="qrcodeBox">
              <a-spin size="large" :spinning="loading">
              </a-spin>
              <div id="qrcodeImg"></div>
            </div>
          </div>
          <div class="cu-center mt-5 btn-down">
            <a-button type="link" class="myButton" @click="createPoster()">下载分享海报</a-button>
          </div>
        </a-card>
      </div>
    </div>
    <Chat :preview-visible="previewVisible" :db-mer-id="$route.query.merId" @close="close()"/>
  </div>
</template>
<script>
import { company } from '@/config'
import QRCode from 'qrcodejs2-fix'
import html2canvas from 'html2canvas'

export default {
  props: {
    merchantInfo: {
      type: Object,
      default: {}
    },
    store: {
      type: Object,
      default: {}
    },
    isCollect: {
      type: Boolean,
      default: false
    },
    showJoin: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      open: false,
      tagUrl: company.href + '/MerHome?merId=',
    }
  },
  created() {
    this.tagUrl = this.tagUrl + this.$route.query.merId
  },
  methods: {
    toTelegram() {
      window.open(`https://t.me/share/url?url=${this.tagUrl}`, '_blank')
    },
    customerDb() {
      window.open(this.store.phone, '_blank')
    },
    customerOpen() {
      if (!this.$store.getters.token) {
        this.$Message.error('请先登录')
        this.$router.push({ path: '/Login' })
      } else {
        let routeUrl = this.$router.resolve({
          path: '/ChatIm',
          query: {
            dbMerId: this.$route.query.merId
          }
        })
        window.open(routeUrl.href, '_blank')
      }
    },
    shareOpen() {
      this.open = true
      this.loading = true
      setTimeout(() => {
        this.make()
        this.loading = false
      }, 1000)
    },
    // 生成二维码；
    make() {
      let href = this.tagUrl
      const elementId = 'qrcodeImg'
      let qrcodeImgEl = document.getElementById(elementId)
      qrcodeImgEl.innerHTML = ''
      let qrcode = new QRCode(qrcodeImgEl, {
        width: 120,
        height: 120,
        colorDark: '#000000',
        colorLight: '#ffffff',
        correctLevel: QRCode.CorrectLevel.H
      })
      qrcode.makeCode(href)
    },
    createPoster() {
      let that = this
      let posterDOM = document.getElementById('posterHtml')
      html2canvas(posterDOM, {
        width: posterDOM.offsetWidth,
        height: posterDOM.offsetHeight,
        //按比例增加分辨率
        scale: window.devicePixelRatio, // 设备像素比
        useCORS: true,//（图片跨域相关）
        allowTaint: true,//允许跨域（图片跨域相关）
        logging: false,
        letterRendering: true,
      })
          .then(function (canvas) {
            that.posterImgURL = canvas.toDataURL('image/png')
            let a = document.createElement('a')
            a.download = '51担保'
            a.href = that.posterImgURL
            a.dispatchEvent(new MouseEvent('click'))
          })
    },
    followToggle(e) {
      this.$emit('followToggle', e)
    }
  }
}
</script>
<style scoped lang="scss">
.intro-cus {
  width: 1390px;
}

.home-name {
  font-size: 18px;
  font-weight: 900;
}

.telegram-btn {
  margin-top: 15px;
  cursor: pointer;
  background: linear-gradient(-90deg, #428FEE, #305DDC);
  box-shadow: 0px 3px 8px 0px rgba(49, 94, 221, 0.27);
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 10px;
  height: 36px;

  display: flex;
  justify-content: center;
  align-items: center;
  color: #FFFFFF;
}

.tg-url-share {
  border: 1px solid #e8e8e8;
  height: 36px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  padding: 10px;

  .copy-btn {
    cursor: pointer;
    margin-left: 10px;
    padding-left: 15px;
    padding-right: 15px;
    border: 1px solid #e8e8e8;
    height: 25px;
    border-radius: 10px;
    color: #666666;

    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.line-ge {
  border-bottom: 1px solid #000000;
}

/*海报*/
.posterBox {
  position: relative;
  width: 407px;
  height: 700px;
  background-repeat: no-repeat;
  background-size: cover;

  .qrcodeBoxBanner {
    width: 407px;
    height: 407px;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .qrcodeBox {
    position: absolute;
    right: 20px;
    bottom: 15px;
    z-index: 9999;
  }

  .infoBox {
    position: absolute;
    left: 10px;
    bottom: 30px;
    z-index: 9999;

    .infoBox-font {
      width: 197px;
      text-align: left;
    }
  }
}


.close {
  position: absolute;
  top: 10px;
  right: 10px;
  color: white;
}

.fullscreen-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* 半透明背景 */
  z-index: 9000; /* 确保遮罩层在最上层 */
}

.overlay-content {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  /* 其他样式 */
}

.btn-1 {
  width: 118px;
  height: 38px;
  background: linear-gradient(-90deg, #428FEE, #305DDC);
  box-shadow: 0px 3px 8px 0px rgba(49, 94, 221, 0.27);
  border-radius: 19px;

  display: flex;
  justify-content: center;
  align-items: center;

  font-weight: 500;
  font-size: 14px;
  color: #FFFFFF;
  line-height: 20px;
}

.btn-4 {
  width: 118px;
  height: 38px;
  border-radius: 19px;
  border: 1px solid #F66114;

  margin-left: 10px;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 14px;
  font-weight: 350;
  line-height: normal;
  letter-spacing: 0em;
  color: #F66114;
}


.btn-3 {
  width: 118px;
  height: 38px;
  border-radius: 19px;
  border: 1px solid #3B64DA;

  margin-left: 10px;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 14px;
  font-weight: 350;
  line-height: normal;
  letter-spacing: 0em;
  color: #3B64DA;
}

.btn-2 {
  width: 118px;
  height: 38px;
  border-radius: 19px;
  border: 1px solid #3B64DA;

  margin-left: 10px;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 14px;
  font-weight: 350;
  line-height: normal;
  letter-spacing: 0em;
  color: #3B64DA;
}

.merName {
  margin-top: 10px;
  font-size: 20px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0em;

  display: flex;
  justify-content: end;
  align-items: end;
}

.danbao-name {
  margin-top: 16px;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  padding-left: 10px;

  height: 32px;
  background: #FFF6F1;
  border-radius: 16px;

  font-weight: 500;
  font-size: 14px;
  color: #FF6E26;
  line-height: 20px;

  .star {
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    color: #FF6E26;
  }

  .gold-font {
    margin-left: 5px;
    font-weight: 700;
    font-size: 14px;
    color: #FF6E26;
  }

  .usdt {
    margin-left: 5px;
    font-weight: 400;
    font-size: 14px;
    color: #FF6E26;
  }
}
</style>
