<template>
  <div class="container">
    <a-image class="cursor-pointer" @click="toShare" :preview="false"
             :src="require('@/assets/new/share/banner_share.png')" width="100%" :height="197"/>
    <div class="width_1200_auto">
      <div class="mt-8 cu-center">
        <a-space :size="27">
          <div class="center-btn cu-center cursor-pointer hover-item-big"
               :class="activeGxKey === '1' ? 'center-btn-select' : ''"
               @click="changeGxActive('1')">海报
          </div>
          <div class="center-btn cu-center cursor-pointer hover-item-big"
               :class="activeGxKey === '2' ? 'center-btn-select' : ''"
               @click="changeGxActive('2')">文案
          </div>
        </a-space>
      </div>

      <div class='distribution-posters mt-8'>
        <a-row :gutter="24" v-if="activeGxKey === '1'" style="padding-left: -24px;padding-right: -24px;">
          <a-col :span="6" v-for="(item, index) in spreadInfo.shareList" :key="index" class="cu-center flex-column">
            <a-card :bodyStyle="{padding: '10px', textAlign: 'center'}" class="cu-center">
              <div :id="'posterHtml' + index" class="posterBox" :style="{backgroundImage: 'url(' + item + ')' }">
                <div class="qrcodeBox">
                  <div style="width: 124px; height: 124px;">
                    <QRCodeCus :tel-url="copApp + '/pages/users/invite/index?spread=' + invitationCode"/>
                  </div>
                </div>
                <div class="infoBox" :id="`posterHtmlFront${index}`">
                  <div class="mb-3 text-black">{{ infoUser.nickname }}</div>
                  <div class="mb-2 text-black">邀请您加入</div>
                  <div class="mb-1 text-black">邀请码: <span class="text-blue text-bold">{{ invitationCode }}</span></div>
                </div>
              </div>
              <div class="cu-center mt-5 mb-5 btn-down">
                <a-button type="link" class="myButton mt-5" @click="createPoster(index)">下载分享海报</a-button>
              </div>
            </a-card>
          </a-col>
        </a-row>

        <div style="padding: 10px 0" v-else>
          <a-row :gutter="[21, 24]">
            <a-col :span="12" v-for="(item,index) in spreadInfo.copyWriterLists" :key="index">
              <a-card :body-style="{padding: '31px 37px 25px 28px', height: '157px'}">
                <div class="cu-center-between-col" style="height: 100%">
                  <div class="content-font">
                    {{ item.info }}
                  </div>
                  <div class="btn-copy cursor-pointer hover-item-big" @click="copy(item)">
                    <text class="copy-font">复制</text>
                  </div>
                </div>
              </a-card>
            </a-col>
          </a-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getUserInfo, spreadBanner } from '@/api/user.js'
import { spreadInfo } from '@/api/tg.js'
import { company } from '@/config'

import QRCode from 'qrcodejs2-fix'
import html2canvas from 'html2canvas'
import QRCodeCus from '@/components/qrcode/index.vue'

export default {
  components: { QRCodeCus },
  data() {
    return {
      imgUrls: [],
      indicatorDots: false,
      circular: false,
      autoplay: false,
      interval: 3000,
      duration: 500,
      swiperIndex: 0,
      spreadList: [],
      poster: '',
      qrcodeSize: 1000,
      PromotionCode: '',
      base64List: [],
      canvasStatus: true, //海报绘图标签
      bgColor: '#e93323',
      invitationCode: '',
      posterImgURL: '',
      indexCurret: 0,
      spreadInfo: {},
      baseList: [
        '@/assets/share/s_1.png',
        '@/assets/share/s_2.png',
        '@/assets/share/s_3.png',
        '@/assets/share/s_4.png',
      ],
      swiperList: ['@/assets/share/posterbanner.png'],
      activeGxKey: '1',
      copApp: company.app,
    }
  },
  mounted() {
    if (this.$store.getters.isLogin) {
      this.getUserInfo()
      this.getSpreadInfo()
    }
  },
  methods: {
    toShare() {
      this.$router.push({ path: '/share' })
    },
    copy(item) {
      this.$Copy({ text: item.info })
    },
    changeGxActive(e) {
      this.activeGxKey = e
      if (e === '1') {
        this.getImageBase64(this.spreadInfo.shareList)
      }
    },
    getSpreadInfo() {
      spreadInfo()
          .then((res) => {
            this.spreadInfo = res.data
            if (!this.spreadInfo.bannerList || this.spreadInfo.bannerList.length === 0) {
              this.spreadInfo.bannerList = this.swiperList
            }
            if (!this.spreadInfo.shareList || this.spreadInfo.shareList.length === 0) {
              this.spreadInfo.shareList = this.baseList
            }
            if (!this.spreadInfo.copyWriterLists || this.spreadInfo.copyWriterLists.length === 0) {
              this.spreadInfo.copyWriterLists = this.copyWriterLists
            }
            this.getImageBase64(this.spreadInfo.shareList)
          })
    },
    getUserInfo: function () {
      let that = this
      getUserInfo({
        page: 1,
        limit: 5
      })
          .then(res => {
            this.infoUser = res.data
            that.invitationCode = res.data.invitationCode
          })
    },
    userSpreadBannerList() {
      let that = this
      spreadBanner({
        page: 1,
        limit: 5
      })
          .then(res => {
            that.spreadList = res.data
          })
    },
    getImageBase64(shareList) {
      shareList.forEach((item, index) => {
        setTimeout(() => {
          // this.make(index)
        }, 500)
      })
    },
    // 生成二维码；
    make(index) {
      let href = company.app + '/pages/users/invite/index?spread=' + this.invitationCode
      const elementId = 'qrcodeImg' + index
      let qrcodeImgEl = document.getElementById(elementId)
      qrcodeImgEl.innerHTML = ''
      let qrcode = new QRCode(qrcodeImgEl, {
        width: 124,
        height: 124,
        colorDark: '#000000',
        colorLight: '#ffffff',
        correctLevel: QRCode.CorrectLevel.H
      })
      qrcode.makeCode(href)
    },
    createPoster(index) {
      let posterHtmlFront = document.getElementById('posterHtmlFront' + index)
      posterHtmlFront.style.letterSpacing = '3.2px'

      let posterDOM = document.getElementById('posterHtml' + index)
      posterDOM.style.transform = 'none'
      html2canvas(posterDOM, {
        width: posterDOM.innerWidth,
        height: posterDOM.innerHeight,
        //按比例增加分辨率
        scale: 2, // 设备像素比
        useCORS: true,//（图片跨域相关）
        allowTaint: true,//允许跨域（图片跨域相关）
        logging: false,
        letterRendering: true,
      })
          .then(function (canvas) {
            const imgData = canvas.toDataURL('image/png')
            const link = document.createElement('a')
            link.href = imgData
            link.download = '51担保'
            link.click()
            posterHtmlFront.style.letterSpacing = '0'
          })
    },
  }
}
</script>

<style lang="scss" scoped>
.center-btn {
  width: 136px;
  height: 42px;
  border-radius: 21px;
  border: 1px solid #DBDEE6;

  font-weight: bold;
  font-size: 20px;
  color: #000000;
  line-height: 20px;
}

.center-btn-select {
  color: #FFFFFF;
  background: linear-gradient(-90deg, #428FEE, #305DDC);
}

.center-btn:hover {
  color: #FFFFFF;
  background: linear-gradient(-90deg, #428FEE, #305DDC);
}

.content-s {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  cursor: pointer;
}

.btn-copy {
  width: 64px;
  height: 26px;
  border-radius: 50px;
  background: #FFFFFF;
  box-sizing: border-box;
  border: 1px solid #269FFA;
  text-align: center;

  .copy-font {
    text-align: right;
    font-size: 14px;
    font-weight: 350;
    line-height: 25px;
    letter-spacing: 0px;
    color: #269FFA;
  }
}

.btn-down {
  border-top: 1px solid #E8E8E8;
}

.container {
  margin: 0 auto;
  padding: 3px 0;
  border-radius: 12px;
  min-height: 1200px;
}

.poster-content {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
}

/*海报*/
.posterBox {
  position: relative;
  width: 320px;
  height: 520px;
  background-repeat: no-repeat;
  background-size: cover;

  .qrcodeBox {
    position: absolute;
    left: 2px;
    bottom: 0;
    z-index: 9999;
  }

  .infoBox {
    position: absolute;
    left: 142px;
    bottom: 10px;
    z-index: 9999;

    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
  }
}


.distribution-posters {
  width: 100%;
  height: 100%;
  margin-bottom: 25px;
}


</style>
