<template>
  <div class="container width_1200_auto">
    <div class="position-relative">
      <a-image :preview="false" :width="1600" :height="200" :src="require('@/assets/new/supply/banner.png')"/>
      <div class="position-absolute flex" style="bottom: 70px; left: 730px;">
        <div class="ml-3 hover-item-big banner-btn-right cu-center cursor-pointer"
             @click="() => {$router.push({path: '/MySupply'})}">
          我的供需 >
        </div>
      </div>
    </div>

    <div class="mt-6">
      <a-card :bodyStyle="{padding: '47px 0 43px 41px'}" class="position-relative">
        <div class="position-absolute" style="top: 0;right: 0;z-index: 3;">
          <a-image :preview="false" :src="require(`@/assets/new/home/${noteDetails.categoryId === 1 ? 'gong.png' : 'xu.png' }`)" :width="53" :height="66"/>
        </div>
        <div class="flex justify-sm-start flex-row">
          <div class="infos">
            <div class="info-title line1 text-cut cu-center-first-row">
                {{ noteDetails.title }}
            </div>
            <div class="info-time">
              发布时间:{{ noteDetails.createTime }}
            </div>
            <a-divider style="width: 100%;"></a-divider>
            <arco-scrollbar class="info-content"
                            style="white-space: pre-wrap;margin-bottom:240px;overflow-y: scroll;overflow-x:hidden !important;">
              {{ noteDetails.content }}
            </arco-scrollbar>
            <div class="info-product">
              <div class="info-link">
                联系方式: <span class="link" @click="openLink(noteDetails.telegram)">{{ noteDetails.telegram }}</span>
                <icon-copy class="cursor-pointer" @click="$Copy({text: noteDetails.telegram})"/>
              </div>
            </div>
          </div>
        </div>
      </a-card>
    </div>

    <div class="info-bottom">
      <a-card :bodyStyle="{padding: '33px 0 37px 32px'}" >
        <div>
          <div class="info-content-title mb-3">提醒声明</div>
          <div class="info-content">1、51担保平台对发布的信息不确保真实、安全性。不建议私下交易，可通过平台担保交易更安全；
          </div>
          <div class="info-content">2、非经过平台线上的交易，请勿联系平台客服处理，如对方要求私下交易的请联系客服或举报；
          </div>
        </div>
      </a-card>
    </div>

    <div class="mt-12 tuijian-font">
      <a-image class="mb-1" :preview="false" :src="require('@/assets/new/blue.png')" :width="6" :height="18"/>
      <span class="ml-3">为你推荐</span>
    </div>

    <div class="mt-12 mb-13">
      <a-row :gutter="[19,24]">
        <a-col v-if="loading" :span="12" v-for="index in 4" :key="index">
          <a-skeleton active :loading="loading" :paragraph="{ rows: 4 }"/>
        </a-col>
        <a-col v-else :span="12" v-for="(item, noteIndex) in noteRecommendList" :key="noteIndex">
          <a-card :bordered="false" :bodyStyle="{padding: 'padding: 29px 0 26px 29px',height:'180px'}"
                  @click="viewCard(item)" :loading="loading" class="cursor-pointer hover-item-up position-relative">
            <div class="position-absolute" style="top: 0px;right: 0px;z-index: 3;">
              <a-image :preview="false" :src="require(`@/assets/new/home/${item.categoryId === 1 ? 'gong.png' : 'xu.png' }`)" :width="53" :height="66"/>
            </div>
            <div class="position-relative">
              <div class="position-absolute" style="top: 14px;left: 1px;z-index: 3;">
                <a-image :preview="false" :src="require('@/assets/new/home/yinhao.png')" :width="48" :height="40"/>
              </div>
              <div class="position-relative" style="z-index: 10">
                <div class="list-title line2">
                  <div class="line1" style="width: 730px;">
                    {{ item.title }}
                  </div>
                </div>
                <div class="list-content line2">{{ item.content }}</div>
                <div class="list-time">发布时间:{{ item.createTime }}</div>
              </div>
            </div>
          </a-card>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
import { discoverListApi, noteDetailApi } from '@/api/discover.js'

export default {
  name: 'info',
  data() {
    return {
      fromData: { id: '' },
      list3: [],
      noteDetails: {},
      playerOptions: {
        width: '544px', //播放器高度
        height: '592px', //播放器高度
        color: '#409eff', //主题色
        muted: false, //静音
        webFullScreen: false,
        speedRate: ['0.75', '1.0', '1.25', '1.5', '2.0'], //播放倍速
        autoPlay: false, //自动播放
        loop: false, //循环播放
        mirror: false, //镜像画面
        ligthOff: false,  //关灯模式
        volume: 0.3, //默认音量大小
        control: true, //是否显示控制器
        title: '', //视频名称
        src: '', //视频源
        poster: '', //封面
      },
      loading: false,
      noteRecommendList: [],
    }
  },
  watch: {
    '$route.query.id': {
      handler(newVal, oldVal) {
        this.$nextTick(() => {
          if(newVal){
            this.fromData.id = newVal
            this.getNoteDetail()
            this.getDiscoverList()
          }
        })
      }
    },
  },
  created() {
    this.fromData.id = this.$route.query.id
    this.getNoteDetail()
    this.getDiscoverList()
  },
  methods: {
    viewCard(item) {
      this.$router.push({
        path: '/SupplyInfo',
        query: {
          id: item.id,
        }
      })
    },
    getDiscoverList() {
      let where = {
        page: 1,
        limit: 4
      }
      this.loading = true
      discoverListApi(where)
          .then(res => {
            this.noteRecommendList = res.data.list
            this.loading = false
          })
    },
    openLink(url) {
      if (this.$store.getters.token) {
        window.open(url)
      } else {
        this.$router.push({
          path: '/Login',
          query: {}
        })
      }
    },
    load({ done }) {
      done('empty')
    },
    // 内容详情
    getNoteDetail() {
      noteDetailApi(this.fromData.id)
          .then(res => {
            this.noteDetails = res.data
            this.list3 = []
            if (this.noteDetails.image) {
              this.list3 = this.noteDetails.image.split(',')
            }
            // if (this.noteDetails.cover) {
            //   this.list3.push(this.noteDetails.cover)
            // }
            if (this.noteDetails.video) {
              this.playerOptions.poster = this.noteDetails.cover
              this.playerOptions.src = this.noteDetails.video
            }
          })
          .catch(err => {
          })
    },
    toIndex() {
      this.$router.push({
        path: '/',
        query: {}
      })
    },
    tolist() {
      this.$router.push({
        name: 'Supply',
        query: {}
      })
    },
  }
}
</script>


<style scoped lang="scss">
.tuijian-font {
  height: 20px;
  font-weight: 800;
  font-size: 20px;
  color: #000000;
  line-height: 20px;
}

.title-tg {
  padding-left: 10px;
  padding-right: 10px;
  height: 22px;
  font-size: 14px;
  border-radius: 100px;
  background: #FF6600;
  color: #fff;
  margin-right: 10px;
}

.list-title {
  margin-top: 6px;
  font-size: 18px;
  font-weight: 500;
  line-height: normal;
}

.list-time {
  margin-top: 16px;
  font-weight: 400;
  font-size: 14px;
  color: #6B6C6D;
  line-height: 28px;
}

.list-content {
  margin-top: 13px;
  font-weight: 400;
  font-size: 14px;
  color: #999999;
  line-height: 24px;
}

.info-bottom {
  margin-top: 20px;
  margin-bottom: 20px;

  .info-content-title {
    font-size: 16px;
    font-weight: bold;
    line-height: 20px;
    color: #FF6F4A;
  }
}

.product {
  margin-top: 20px;
  margin-bottom: 20px;
  overflow-y: hidden !important;
}

.imgs {
  margin-right: 15px;
  position: relative;

  .usdt-bottom {
    width: 100%;
    position: absolute;
    bottom: 0;
    height: 24px;
    background: rgba(0, 0, 0, 0.5);
    text-align: center;

    font-size: 12px;
    font-weight: 900;
    line-height: 26px;
    color: #FFFFFF;

    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}

.infos {
  flex: 1;
  position: relative;
}

.info-product {
  position: absolute;
  bottom: 10px;
  width: 86%;
}

.info-link {
  width: 86%;
  height: 52px;
  border-radius: 8px;
  background: #FCF3EB;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 10px;

  font-size: 16px;
  line-height: 20px;
  color: #FF6E26;

  .link {
    font-size: 16px;
    font-weight: bold;
    line-height: 20px;
    color: #FF6F4A;
    margin-right: 10px;
    margin-left: 10px;
    cursor: pointer;
  }

  .link:hover {
    color: #2b85e4 !important;
  }

  .copy {
    background: #999999;
  }
}

.info-content {
  font-size: 14px;
  line-height: 24px;
  color: #999999;
}

.info-title {
  font-size: 24px;
  font-weight: 500;
  line-height: normal;
}

.info-time {
  margin-top: 23px;
  font-size: 14px;
  font-weight: 350;
  color: #A7A7A7;
}

.banner-btn-right {
  width: 187px;
  height: 50px;
  background: #4271E2;
  border-radius: 25px;

  font-weight: 400;
  font-size: 20px;
  color: #FFFFFF;
  line-height: 20px;
}

.container {
  margin: 0 auto;
  padding: 10px 0;
  border-radius: 12px;
}
</style>
